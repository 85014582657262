import React, { useContext, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import { useDispatch, useSelector } from 'react-redux';
import { getRandomColor, createImageFromInitials } from "../utils/ImageFromInitials";
import { useTranslation } from "react-i18next";

const SidebarProfile = () => {
	const { t } = useTranslation('common');

	const user = useSelector(state => state.userReducer);
	const [profileActive, setProfileActive] = useState(false);
	const history = useHistory();


	const handleProfileExpand = () => {
		setProfileActive(!profileActive);
	}
	return (
		<AppSettings.Consumer>
			{({ appSidebarMinify }) => (
				(user.selectedTenant != null && user.selectedTenant.agentRoles) &&
				<div>
					<div className={"menu-profile " + (profileActive ? "expand " : "")}>
						<Link to="/" onClick={() => handleProfileExpand()} className="menu-profile-link">
							<div className="menu-profile-cover with-shadow"></div>
							<div className="menu-profile-image">
								{/* <img src="../assets/img/user/user-13.jpg" alt="" /> */}
								<img
									id='userImage'
									src={
										(user.selectedTenant.imgSrc && user.selectedTenant.imgSrc.length > 0)
											? user.selectedTenant.imgSrc
											: createImageFromInitials(200, user.selectedTenant.fullName, "#aa3300") //getRandomColor()
									}
									alt={user.selectedTenant.fullName}
								/>
							</div>
							<div className="menu-profile-info">
								<div className="d-flex align-items-center">
									<div className="flex-grow-1">
										{user.selectedTenant.fullName}
									</div>
									<div className="menu-caret ms-auto"></div>
								</div>
								<small>{t("roles.rol_" + user.selectedTenant.agentRoles)}</small>
							</div>
						</Link>
					</div>
					<div className={"collapse " + (profileActive && !appSidebarMinify ? "show " : "")}>
						{user.selectedTenant.agentRoles && (user.selectedTenant.agentRoles.toUpperCase() === "Administrator".toUpperCase() || user.selectedTenant.agentRoles.toUpperCase() === "Manager".toUpperCase()) &&
							<div className="menu-item pt-5px">
								<Link to="/account" className="menu-link">
									<div className="menu-icon"><i className="fa fa-cog"></i></div>
									<div className="menu-text">{t('common.settings')}</div>
								</Link>
							</div>
						}
						{/* <div className="menu-item">
							<Link to="/" className="menu-link">
								<div className="menu-icon"><i className="fa fa-pencil-alt"></i></div>
								<div className="menu-text"> Send Feedback</div>
							</Link>
						</div>*/}
						<div className="menu-item pb-5px">
							<Link to="/help" className="menu-link">
								<div className="menu-icon"><i className="fa fa-question-circle"></i></div>
								<div className="menu-text">{t('common.help')}</div>
							</Link>
						</div>
						<div className="menu-divider m-0"></div>
						{/* <div className="menu-item pb-5px">
							<Link to="/user/logout" className="menu-link">
								<div className="menu-icon"><i className="fa fa-sign-out"></i></div>
								<div className="menu-text">{t('common.logout')}</div>
							</Link>
						</div> */}
					</div>
				</div>
			)}
		</AppSettings.Consumer>
	)
}

export default SidebarProfile;
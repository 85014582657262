import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Pagination = ({ children, toggleSplitCallBack, dataCount, take }) => {

    const [actualPage, setActualPage] = useState(0);

    const ChangePage = page => {
        if (page >= 0 && page < pages.length) {
            let skip = (page * take);
            setActualPage(page);
            toggleSplitCallBack(skip);
        }
    }

    let length = parseInt(dataCount / take);
    if ((dataCount % take) > 0)
        length++;

    var pages = [];
    for (let i = 0; i < length; i++) {
        pages.push(<li className={`page-item ${actualPage === i && "active"}`} key={i}><Link to="#" onClick={() => ChangePage(i)} className="page-link">{i + 1}</Link></li>);
    }

    //const ChangePage = ({ skip, newPage }) => {
    //    if (newPage) {
    //        let pageNew = actualPage + newPage;
    //        skip = pageNew * take;
    //        if (pageNew >= 0 && pageNew < pages.length) {
    //            setActualPage(pageNew);
    //            toggleSplitCallBack(skip);
    //        }
    //    }
    //    else {
    //        let page = skip / take;
    //        setActualPage(page);
    //        toggleSplitCallBack(skip);
    //    }
    //}

    return (
        <>
            {length > 1 &&
                <div className='row'>
                    <div className="ms-auto /*d-none*/ d-lg-flex align-items-end flex-column flex-wrap mb-15px">
                        <ul className="pagination mb-5px">
                            <li className={`page-item ${actualPage === 0 && "disabled"}`}><Link to="#" className="page-link" onClick={() => ChangePage(actualPage - 1)}><i className='fa fa-arrow-left'></i></Link></li>
                            {pages}
                            <li className={`page-item ${actualPage === length - 1 && "disabled"}`}><Link to="#" className="page-link" onClick={() => ChangePage(actualPage + 1)}><i className='fa fa-arrow-right'></i></Link></li>
                        </ul>
                        <small hidden>Page {actualPage + 1} of {pages.length}</small>
                    </div>
                </div>
            }
            {children}
            {length > 1 &&
                <div className='row'>
                    <div className="ms-auto d-lg-flex align-items-end flex-column flex-wrap mt-15px">
                        <ul className="pagination mb-5px">
                            <li className={`page-item ${actualPage === 0 && "disabled"}`}><Link to="#" className="page-link" onClick={() => ChangePage(actualPage - 1)}><i className='fa fa-arrow-left'></i></Link></li>
                            {pages}
                            <li className={`page-item ${actualPage === length - 1 && "disabled"}`}><Link to="#" className="page-link" onClick={() => ChangePage(actualPage + 1)}><i className='fa fa-arrow-right'></i></Link></li>
                        </ul>
                        <small>Page {actualPage + 1} of {pages.length}</small>
                    </div>
                </div>
            }
        </>
    )
}

export default Pagination;

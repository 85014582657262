import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { pdfjs } from 'react-pdf';
import { useWindowDimensions } from '../../utils/common';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const PdfReader = ({ file }) => {
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [zoom, setZoom] = useState(1.0); // Add this line
    const { width } = useWindowDimensions();

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function goToPrevPage() {
        setPageNumber(prevPageNumber => prevPageNumber - 1);
    }

    function goToNextPage() {
        setPageNumber(prevPageNumber => prevPageNumber + 1);
    }
    function zoomIn() {
        setZoom(prevZoom => prevZoom + 0.1);
    }

    function zoomOut() {
        setZoom(prevZoom => prevZoom - 0.1);
    }
    // console.log(file)
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {/* 
                <div>
                    <button onClick={goToPrevPage}>Prev</button>
                    <button onClick={goToNextPage}>Next</button>
                    <button onClick={zoomIn}>Zoom In</button>
                    <button onClick={zoomOut}>Zoom Out</button>
                </div>
                <div>
                    <p>
                        Page {pageNumber} of {numPages}
                    </p>
                </div> 
                */}

            <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#ccc' }}>

                <Document file={file} onLoadSuccess={onDocumentLoadSuccess} error="No se pudo cargar el archivo. Por favor intente nuevamente.">
                    <div style={{ display: 'flex', margin: 10, flexDirection: 'column', justifyContent: 'center' }}>
                        {Array.from({ length: numPages }, (_, index) => (
                            <div key={'div' + index} style={{ margin: 10 }}>
                                <Page key={index} pageNumber={index + 1} scale={zoom - 0.1} width={width * 0.75} />
                            </div>
                        ))}
                    </div>
                </Document>
            </div>
            {/* 
                <div>
                    <button onClick={goToPrevPage}>Prev</button>
                    <button onClick={goToNextPage}>Next</button>
                </div> 
                <p>
                    Page {pageNumber} of {numPages}
                </p>
                */}
        </div>
    );
}

export default PdfReader;
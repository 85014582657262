import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from "react-hook-form";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Panel, PanelBody, PanelHeader } from '../../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { AddMovement, DeleteMovement, GetPettyCash, ListMovements } from '../../../services/redux/pettyCashSlice';

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';



const Dashboard = () => {
    const { t } = useTranslation('common');

    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const user = useSelector(state => state.userReducer);
    const pettyCash = useSelector(state => state.pettyCashReducer);

    const [modalAddExpense, setModalAddExpense] = useState(false);
    const [movementDate, setMovementDate] = useState(null);
    const [selectedMovementId, setSelectedMovementId] = useState(null);
    const [modalDeleteMovement, setModalDeleteMovement] = useState(false);
    const [mergeByMonth, setMergeByMonth] = useState([]);

    const todayDate = new Date();

    function combineObjects(data1, data2) {
        // Combinar los objetos
        const combinedData = [...data1, ...data2];

        // Crear un objeto para agrupar registros por año y mes
        const groupedData = {};
        combinedData.forEach(item => {
            const key = `${item.year}-${item.month}`;
            if (!groupedData[key]) {
                groupedData[key] = {
                    year: item.year,
                    month: item.month,
                    total: 0,
                    estimatedCost: 0,
                };
            }
            if (item.total) {
                groupedData[key].total += item.total;
            }
            if (item.estimatedCost) {
                groupedData[key].estimatedCost += item.estimatedCost;
            }
        });

        // Convertir el objeto agrupado a un array
        const finalData = Object.values(groupedData);

        // Ordenar los datos por año y mes
        const sortedData = finalData.sort((a, b) => {
            if (a.year !== b.year) {
                return a.year - b.year;
            }
            return a.month - b.month;
        });

        return sortedData;
    }

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        getPettyCash();
    }, []);

    useEffect(() => {

        // interface RecordByMonth {  // interface for typechecking object
        //     year: number;
        //     month: number;
        //     total: number;
        //     estimatedCost: number; 
        // }

        if (pettyCash.selectedPettyCash) {
            const totalByMonth = pettyCash.selectedPettyCash.totalByMonth;
            const totalEstimatedByMonth = pettyCash.selectedPettyCash.totalEstimatedByMonth;
            const mergeByMonth = combineObjects(totalByMonth, totalEstimatedByMonth);

            setMergeByMonth(mergeByMonth);
            // console.log(totalByMonth);
            // console.log(totalEstimatedByMonth);
            // console.log(mergeByMonth);
        }
    }, [pettyCash.selectedPettyCash]);

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    const getPettyCash = async () => {
        const requestData = {
            "tenantId": user.selectedTenant.id,
            "id": user.selectedTenant.pettyCashId[0]
        };
        await dispatch(GetPettyCash({ authToken: user.authToken, params: requestData })).then((response) => {
            //console.log(response);
            response.payload &&
                listMovements(response.payload.data.id);
        });
    };

    const listMovements = async (pettyCashId) => {
        const requestData = {
            "tenantId": user.selectedTenant.id,
            "pettyCashId": pettyCashId
        };
        await dispatch(ListMovements({ authToken: user.authToken, body: requestData })).then((response) => {
        });
    };

    const addMovement = async (data) => {
        //console.log(pettyCash.selectedPettyCash)
        const requestData = {
            "tenantId": user.selectedTenant.id,
            "pettyCashId": data.pettyCashId,
            "typeId": data.typeId,
            "description": data.description,
            "movementDate": movementDate,
            "amount": data.amount,
            "createdById": user.selectedTenant.agentId,
        };
        await dispatch(AddMovement({ authToken: user.authToken, body: requestData })).then((response) => {
            if (!response.error) {
                getPettyCash();
                toggleModal('modalAddExpense');
                reset();
            }
        });
    };

    const deleteMovement = async () => {
        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": selectedMovementId,
        }

        await dispatch(DeleteMovement({ authToken: user.authToken, body: requestData })).then((response) => {
            if (!response.error) {
                response && response.payload &&
                    getPettyCash();
                toggleModal('modalDeleteMovement');
            }
        });
    }

    const toggleModal = (name) => {
        switch (name) {
            case 'modalAddExpense':
                setModalAddExpense(!modalAddExpense);
                break;
            case 'modalDeleteMovement':
                setModalDeleteMovement(!modalDeleteMovement);
                break;
            default:
                break;
        }
    }

    let strMonth = ['Months', t('month_names.Jan'), t('month_names.Feb'), t('month_names.Mar'), t('month_names.Apr'), t('month_names.May'), t('month_names.Jun'), t('month_names.Jul'), t('month_names.Aug'), t('month_names.Sep'), t('month_names.Oct'), t('month_names.Nov'), t('month_names.Dec')]
    let strMonthLong = ['Months', t('month_names_long.Jan'), t('month_names_long.Feb'), t('month_names_long.Mar'), t('month_names_long.Apr'), t('month_names_long.May'), t('month_names_long.Jun'), t('month_names_long.Jul'), t('month_names_long.Aug'), t('month_names_long.Sep'), t('month_names_long.Oct'), t('month_names_long.Nov'), t('month_names_long.Dec')]

    return (
        <div>
            <ol className="breadcrumb float-xl-end" hidden>
                <li className="breadcrumb-item"><Link to="/dashboard">Inicio</Link></li>
                <li className="breadcrumb-item active">{t('menu.budget')}</li>
            </ol>
            <h1 className="page-header">{t('menu.budget')}</h1>

            {!pettyCash.selectedPettyCash &&
                <div>
                    {t('budget.no_budget')}
                </div>
            }

            {pettyCash.selectedPettyCash &&
                <>
                    <div className="row" hidden>
                        <div className="col-xl-3 col-md-6">
                            <div className="widget widget-stats bg-blue">
                                <div className="stats-icon stats-icon-lg"><i className="fa fa-bank fa-fw"></i></div>
                                <div className="stats-content">
                                    <div className="stats-title">{t('budget.monthly_budget').toUpperCase()}</div>
                                    <div className="stats-number">{pettyCash.selectedPettyCash.total} {pettyCash.selectedPettyCash.currencyCode}</div>
                                    <div className="stats-progress progress">
                                        <div className="progress-bar" style={{ width: Number(pettyCash.selectedPettyCash.totalMonthExpenses / pettyCash.selectedPettyCash.total * 100).toFixed(2) + '%' }}></div>
                                    </div>
                                    <div className="stats-desc">Porcentaje utilizado ({Number(pettyCash.selectedPettyCash.totalMonthExpenses / pettyCash.selectedPettyCash.total * 100).toFixed(2)}%)</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="widget widget-stats bg-teal">
                                <div className="stats-icon stats-icon-lg"><i className="fa fa-dollar-sign fa-fw"></i></div>
                                <div className="stats-content">
                                    <div className="stats-title">{t('budget.monthly_total_expenses').toUpperCase()}</div>
                                    <div className="stats-number">{pettyCash.selectedPettyCash.totalMonthExpenses} {pettyCash.selectedPettyCash.currencyCode}</div>
                                    <div className="stats-progress progress" hidden>
                                        <div className="progress-bar" style={{ width: '70.1%' }}></div>
                                    </div>
                                    <div className="stats-desc mt-20px mb-4px">Fecha {todayDate.toDateString()}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6" hidden>
                            <div className="widget widget-stats bg-default text-dark">
                                <div className="stats-icon stats-icon-lg"><i className="fa fa-archive fa-fw"></i></div>
                                <div className="stats-content">
                                    <div className="stats-title">NEW ORDERS</div>
                                    <div className="stats-number">38,900</div>
                                    <div className="stats-progress progress">
                                        <div className="progress-bar" style={{ width: '76.3%' }}></div>
                                    </div>
                                    <div className="stats-desc">Better than last week (76.3%)</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6" hidden>
                            <div className="widget widget-stats bg-dark">
                                <div className="stats-icon stats-icon-lg"><i className="fa fa-comment-alt fa-fw"></i></div>
                                <div className="stats-content">
                                    <div className="stats-title">NEW COMMENTS</div>
                                    <div className="stats-number">3,988</div>
                                    <div className="stats-progress progress">
                                        <div className="progress-bar" style={{ width: '54.9%' }}></div>
                                    </div>
                                    <div className="stats-desc">Better than last week (54.9%)</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="col-xl-12">
                                <Panel>
                                    <PanelHeader noButton={true}>
                                        <div className="d-flex justify-content-between">
                                            {t('common.movements')}
                                            {user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" &&
                                                <button
                                                    className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-2"
                                                    onClick={() => toggleModal('modalAddExpense')}>
                                                    {t('budget.add_expense')}
                                                </button>
                                            }
                                        </div>
                                    </PanelHeader>
                                    <PanelBody>
                                        {(pettyCash.movements && pettyCash.movements.length > 0) ?
                                            <table className="table table-hover">
                                                <tbody>
                                                    {
                                                        pettyCash.movements.map((movement, index) =>
                                                            // <div id={index}>{movement.description} - ${movement.amount}</div>
                                                            <tr key={movement.id}>
                                                                <td>{moment(movement.movementDate).format("DD/MM/YY")}</td>
                                                                <td align='center' className='d-none d-sm-block'><span className="badge bg-default text-dark me-5px">{t('budget.movement_type_' + movement.typeNormalizedName)}</span></td>
                                                                <td>{movement.description}</td>
                                                                <td align='right'>{movement.amount.toFixed(2).toLocaleString("es-AR")} {pettyCash.selectedPettyCash.currencyCode}</td>
                                                                {user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" &&
                                                                    <td align='center'><Link to="#" onClick={() => { setSelectedMovementId(movement.id); toggleModal('modalDeleteMovement'); }}> <i className="fa fa-trash text-danger"></i> </Link></td>
                                                                }
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>
                                            :
                                            <div className="text-center">{t('common.no_records_found')}</div>
                                        }
                                    </PanelBody>
                                    {/* <PanelFooter></PanelFooter> */}
                                </Panel>
                            </div>
                        </div>

                        {mergeByMonth && mergeByMonth.length > 0 &&
                            <div className="col-xl-6">
                                <div className="col-xl-12">
                                    <Panel>
                                        <PanelHeader noButton={true}>
                                            <div className="d-flex justify-content-between">
                                                {t('budget.total_per_month')}
                                            </div>
                                        </PanelHeader>
                                        <PanelBody>
                                            <table className="table table-hover">
                                                <tbody>
                                                    <tr>
                                                        <td className='d-block d-sm-none'><b>{t('common.month')}</b></td>
                                                        <td className='d-none d-sm-block'><b>{t('common.month')}</b></td>
                                                        <td align='right'><b>{t('common.estimated')}</b></td>
                                                        <td align='right'><b>{t('common.total')}</b></td>
                                                    </tr>
                                                    {mergeByMonth.map((total, index) =>
                                                        <tr key={index}>
                                                            <td className='d-block d-sm-none'>{total.year} {strMonth[total.month]}</td>
                                                            <td className='d-none d-sm-block'>{total.year} {strMonthLong[total.month]}</td>
                                                            {total.estimatedCost ?
                                                                <td align='right'>{total.estimatedCost.toFixed(2).toLocaleString("es-AR")} {pettyCash.selectedPettyCash.currencyCode}</td>
                                                                :
                                                                <td align='right'>-</td>
                                                            }
                                                            {total.total ?
                                                                <td align='right'>{total.total?.toFixed(2).toLocaleString("es-AR")} {pettyCash.selectedPettyCash.currencyCode}</td>
                                                                :
                                                                <td align='right'>-</td>
                                                            }
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </PanelBody>
                                    </Panel>
                                </div>
                                <div className="col-xl-12" hidden>
                                    <Panel>
                                        <PanelHeader noButton={true}>
                                            <div className="d-flex justify-content-between">
                                                {t('budget.total_per_month')}
                                            </div>
                                        </PanelHeader>
                                        <PanelBody>
                                            {/* {pettyCash.totalByMonth[0].year} */}
                                            <table className="table table-hover">
                                                <tbody>
                                                    {pettyCash.selectedPettyCash.totalByMonth &&
                                                        pettyCash.selectedPettyCash.totalByMonth.map((total, index) =>
                                                            <tr key={index}>
                                                                <td>{total.year} {strMonth[total.month]}</td>
                                                                <td></td>
                                                                <td align='right'>{total.total.toFixed(2).toLocaleString("es-AR")} {pettyCash.selectedPettyCash.currencyCode}</td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>
                                        </PanelBody>
                                    </Panel>
                                </div>
                                <div className="col-xl-12" hidden>
                                    <Panel>
                                        <PanelHeader noButton={true}>
                                            <div className="d-flex justify-content-between">
                                                Estimado por mes
                                                {/* {t('budget.total_per_month')} */}
                                            </div>
                                        </PanelHeader>
                                        <PanelBody>
                                            {/* {pettyCash.totalByMonth[0].year} */}
                                            <table className="table table-hover">
                                                <tbody>
                                                    {pettyCash.selectedPettyCash.totalEstimatedByMonth &&
                                                        pettyCash.selectedPettyCash.totalEstimatedByMonth.map((total, index) =>
                                                            <tr key={index}>
                                                                <td>{total.year} {strMonth[total.month]}</td>
                                                                <td></td>
                                                                <td align='right'>{total.estimatedCost.toFixed(2).toLocaleString("es-AR")} {pettyCash.selectedPettyCash.currencyCode}</td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>
                                        </PanelBody>
                                    </Panel>
                                </div>
                            </div>
                        }

                    </div>

                    <Modal size="md" style={{ maxWidth: '400px', width: '100%' }} isOpen={modalAddExpense} toggle={() => toggleModal('modalAddExpense')} >
                        <ModalHeader toggle={() => toggleModal('modalAddExpense')}>{t('budget.add_expense')}</ModalHeader>
                        <ModalBody>
                            <form onSubmit={handleSubmit(addMovement)}>

                                {/* <div className="form-floating mb-15px">
                                    <select className="form-select" id="areaId" {...register("typeId", {
                                        required: true,
                                    })}>
                                        <option></option>
                                        <option key="871248AF-4047-40DE-BF3A-0565262CD584" value="871248AF-4047-40DE-BF3A-0565262CD584">Gasto</option>
                                        <option key="2E6F33A3-20BE-44C9-8DBC-19BCEF63FE56" value="2E6F33A3-20BE-44C9-8DBC-19BCEF63FE56">Cambio Monto {t('menu.budget')}</option>
                                    </select>
                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                        Tipo
                                    </label>
                                </div> */}

                                <input type="hidden" id="pettyCashId" {...register("pettyCashId", {
                                    value: user.selectedTenant.pettyCashId[0]
                                })} />

                                <input type="hidden" id="typeId" {...register("typeId", {
                                    value: "871248AF-4047-40DE-BF3A-0565262CD584" //ExpenseType
                                })} />

                                <div className="form-floating mb-15px">
                                    <input type="text" className="form-control fs-15px" id="description" {...register("description", {
                                        required: true,
                                    })} />
                                    <label htmlFor="floatingInput" className=" fs-13px">
                                        {t('common.description')}
                                    </label>
                                    {errors.description && <span className='invalid-feedback'>This field is required</span>}
                                </div>

                                <div className="form-floating mb-15px">
                                    <DatePicker className="form-control fs-15px pt-25px" dateFormat="dd-MM-yyyy" selected={movementDate} onChange={(date) => setMovementDate(date)} />
                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-11px pt-1px mb-10px pb-30px">
                                        {t('common.date')}
                                    </label>
                                </div>

                                <div className="form-floating mb-15px">
                                    <input type="text" pattern="[+-]?\d+(?:[.]\d+)?" className="form-control fs-15px" id="amount" {...register("amount", {
                                        required: true,
                                    })} />
                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                        {t('common.amount')}
                                    </label>
                                </div>

                                <div className="mb-20px">
                                    <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value={t('common.add')} />
                                    {pettyCash.error && <div className='text-center'>
                                        <span className='text-danger'>{t("common.error", { message: pettyCash.errorMessage })}</span></div>}
                                </div>
                            </form>
                        </ModalBody>
                        <ModalFooter>
                            {/* <button className="btn btn-primary" onClick={() => addMovement()}>Agregar</button> */}
                            <button className="btn btn-white" onClick={() => toggleModal('modalAddExpense')}>{t('common.close')}</button>
                        </ModalFooter>
                    </Modal>

                    <Modal size="md" style={{ maxWidth: '300px', width: '100%' }} isOpen={modalDeleteMovement} toggle={() => toggleModal('modalDeleteMovement')} >
                        <ModalHeader toggle={() => toggleModal('modalDeleteMovement')}>Eliminar Movimiento</ModalHeader>
                        <ModalBody>
                            <span>¿Esta seguro que desea eliminar el Movimiento
                                {/* <b>{movement.selectedMovement.name}</b>, creado por <b>{movement.selectedMovement.createdBy}</b> el <b>{moment(movement.selectedMovement.createdOn).format("DD/MM/YYYY")}</b>  */}
                                ?</span>
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-danger" onClick={() => deleteMovement()}>Si, borrar!</button>
                            <button className="btn btn-white" onClick={() => toggleModal('modalDeleteMovement')}>No</button>
                        </ModalFooter>
                    </Modal>
                </>
            }
        </div >
    )
};

export default Dashboard;
import React, { useRef, useState } from 'react';
import useFileUpload from 'react-use-file-upload';
import { Loader } from '../../../pages/widget/loader.js';
import { Panel, PanelHeader, PanelBody } from '.././../../components/panel/panel.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, DropdownToggle, UncontrolledButtonDropdown, DropdownMenu, DropdownItem } from 'reactstrap';
// import { t } from 'i18next';
// import { fi } from 'date-fns/locale';
import { AddLink } from '../../../services/redux/fileSlice.js';
// import { useTranslation } from 'react-i18next';

const Upload = ({ onSubmit, onClose, fileTypeId, referenceId, isOpen, toggleModal, isLibrary }) => {

    const dispatch = useDispatch();
    // const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);

    const {
        //files,
        fileNames,
        // fileTypes,
        // IsPrivate,
        // totalSize,
        // totalSizeInBytes,
        handleDragDropEvent,
        clearAllFiles,
        createFormData,
        setFiles,
        removeFile,
    } = useFileUpload();

    const inputRef = useRef();
    const file = useSelector(state => state.fileReducer)
    const [externalLinkName, setExternalLinkName] = useState("");
    const [externalLink, setExternalLink] = useState("");
    const [extension, setExtension] = useState(["", "Seleccione tipo de archivo"]);
    const [isPrivate, setIsPrivate] = useState(true);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = createFormData();
        if (isLibrary) {
            formData.append("IsPrivate", isPrivate);
        }

        try {
            await onSubmit(formData)
        } catch (error) {
            console.error(`Failed to submit files. ${error.message}`);
        }

        clearAllFiles();
        setIsPrivate(true);
    };

    const handleAddLink = async (e) => {
        if (!externalLinkName) {
            //marcar como no valido el input de enlace
            return;
        }

        if (!externalLink) {
            //marcar como no valido el input de enlace
            return;
        }

        if (extension[0] === "") {
            //marcar como no valido el input de enlace
            return;
        }

        e.preventDefault();
        if (externalLink) {
            const requestData = {
                "name": externalLinkName,
                "description": externalLinkName,
                "targetUrl": externalLink,
                "extension": extension[0],
                "isPrivate": (isLibrary && isPrivate) ? true : false
            };

            try {
                await onAddLinkSubmit(requestData)
            } catch (error) {
                console.error(`Failed to submit files. ${error.message}`);
            }
            setExternalLink("");
            setExtension(["", "Seleccione tipo de archivo"]);
            setIsPrivate(true);
        }
    }

    const onAddLinkSubmit = async (requestData) => {

        requestData.tenantId = user.selectedTenant.id;
        requestData.createdById = user.selectedTenant.agentId;
        requestData.createdOn = "2015-08-05T19:33:26Z";
        requestData.fileTypeId = fileTypeId;
        requestData.originalFileName = "EXTERNAL_LINK";
        requestData.referenceId = referenceId ? referenceId : "00000000-0000-0000-0000-000000000000";

        await dispatch(AddLink({ authToken: user.authToken, body: requestData })).then((response) => {
            onClose();
        });
    };


    return (
        <Loader isLoading={file.isFetching}>
            <Modal isOpen={isOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Subir Archivos</ModalHeader>
                <ModalBody>
                    <Panel>
                        <PanelHeader noButton={true}>Subir Archivos</PanelHeader>
                        <PanelBody>
                            <p>Utilice el formulario para cargar cualquier archivo de su elección.</p>
                            <div className="form-container">
                                {/* Display the files to be uploaded */}
                                <div>
                                    {fileNames.map((name) => (
                                        <div key={name} className="p-1">
                                            <span onClick={() => removeFile(name)}>
                                                <i className="btn btn-close btn-red px-2" />
                                            </span>
                                            <span className='ps-1'>{name}</span>
                                        </div>
                                    ))}

                                    {/* {files.length > 0 && (
                            <ul>
                                <li>Total de Archivos a subir: {fileTypes.join(', ')}</li>
                                <li>Total Tamaño: {totalSize}</li>
                                <li>Total Bytes: {totalSizeInBytes}</li>

                                <li className="clear-all">
                                    <button onClick={() => clearAllFiles()}>Limpiar</button>
                                </li>
                            </ul>
                        )} */}
                                </div>

                                {/* Provide a drop zone and an alternative button inside it to upload files. */}
                                <div
                                    className='border border-4 boxed-layout m-1 p-4'
                                    onDragEnter={handleDragDropEvent}
                                    onDragOver={handleDragDropEvent}
                                    onDrop={(e) => {
                                        handleDragDropEvent(e);
                                        setFiles(e, 'a');
                                    }}
                                >
                                    <p>Arrastra y suelta archivos aquí</p>

                                    <button onClick={() => inputRef.current.click()}>O seleccione archivos para cargar</button>

                                    {/* Hide the crappy looking default HTML input */}
                                    <input
                                        ref={inputRef}
                                        type="file"
                                        multiple
                                        style={{ display: 'none' }}
                                        onChange={(e) => {
                                            setFiles(e, 'a');
                                            inputRef.current.value = null;
                                        }}
                                    />
                                </div>
                            </div>
                            {isLibrary &&
                                <div className="row mt-15px mb-15px me-5px align-items-left">
                                    {/* <div className="col-4 control-label text-dark fw-bold"></div> */}
                                    <div className="col-12 d-flex">
                                        <div className="form-check form-switch ms-auto mb-0">
                                            <input type="checkbox" className="form-check-input" name="IsPrivate" onChange={(e) => setIsPrivate(!e.target.checked)} id="IsPrivate" checked={!isPrivate} value={isPrivate} />
                                            <label className="form-check-label" htmlFor="appHeaderInverse"><b>¿Este archivo estará disponible para todos?</b></label>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="submit">
                                <button onClick={handleSubmit} className="btn btn-primary w-100" disabled={file.isFetching}>
                                    {file.isFetching ? "Subiendo archivos..." : (isLibrary ? "Subir archivos" : "Subir y asociar archivos")}
                                </button>
                            </div>
                        </PanelBody>
                    </Panel>
                    {fileTypeId &&
                        <Panel>
                            <PanelHeader noButton={true}>Agregar Link Externo</PanelHeader>
                            <PanelBody>
                                <p>{externalLink}</p>
                                <p>Utilice el formulario para agregar un enlace externo a un archivo.</p>
                                <div className="form-container">
                                    <div className="form-group">
                                        <label htmlFor="externalLink">Enlace Externo</label>
                                        <input type="text" className="form-control" id="externalLink" placeholder="https://www.example.com" onChange={(e) => setExternalLink(e.target.value)} />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="externalLinkName">Nombre descriptivo</label>
                                        <input type="text" className="form-control" id="externalLinkName" placeholder="Ej.: Manual de uso" onChange={(e) => setExternalLinkName(e.target.value)} />
                                    </div>

                                    <UncontrolledButtonDropdown className="me-2 mt-2">
                                        <DropdownToggle color="white" caret>
                                            <b className="caret"></b>&nbsp;{extension[1]}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => setExtension([".xls", "Hoja de calculo"])} key={".xls"}>{"Hoja de cálculo"}</DropdownItem>
                                            <DropdownItem onClick={() => setExtension([".doc", "Documento de texto"])} key={".doc"}>{"Documento de texto"}</DropdownItem>
                                            <DropdownItem onClick={() => setExtension([".ppt", "Diapositivas"])} key={".ppt"}>{"Diapositivas"}</DropdownItem>
                                            <DropdownItem onClick={() => setExtension([".pdf", "Documento PDF"])} key={".pdf"}>{"Documento PDF"}</DropdownItem>
                                            <DropdownItem onClick={() => setExtension([".jpg", "Imagen"])} key={".jpg"}>{"Imagen"}</DropdownItem>
                                            <DropdownItem onClick={() => setExtension([".mp4", "Video"])} key={".mp4"}>{"Video"}</DropdownItem>
                                            <DropdownItem onClick={() => setExtension([".aud", "Audio"])} key={".aud"}>{"Audio"}</DropdownItem>
                                            <DropdownItem onClick={() => setExtension([".zip", "Archivo ZIP"])} key={".zip"}>{"Archivo ZIP"}</DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem onClick={() => setExtension(["none", "Otro"])} key="none">Otro</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>

                                    {isLibrary &&
                                        <div className="row mt-15px mb-15px me-5px align-items-left">
                                            {/* <div className="col-4 control-label text-dark fw-bold"></div> */}
                                            <div className="col-12 d-flex">
                                                <div className="form-check form-switch ms-auto mb-0">
                                                    <input type="checkbox" className="form-check-input" name="IsPrivate" onChange={(e) => setIsPrivate(!e.target.checked)} id="IsPrivate" checked={!isPrivate} value={isPrivate} />
                                                    <label className="form-check-label" htmlFor="appHeaderInverse"><b>¿Este enlace estará disponible para todos?</b></label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="submit mt-2">
                                        <button onClick={handleAddLink} className="btn btn-primary w-100">Agregar Enlace</button>
                                    </div>
                                </div>
                            </PanelBody>

                        </Panel>
                    }
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-white" onClick={toggleModal}>Cancel</button>
                    {/*<button className="btn btn-primary">Create</button>*/}
                </ModalFooter>
            </Modal>
        </Loader>
    );
};

export default Upload;
import React, { useContext, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { AppSettings } from './../../config/app-settings.js';
import { useDispatch, useSelector } from 'react-redux';
import { PostLogin } from './../../services/redux/userSlice';
import { Loader } from './../../pages/widget/loader';
import { useTranslation } from "react-i18next";
import logo from '../../assets/img/logo_100.png';

export default function Login() {
	const dispatch = useDispatch();
	const context = useContext(AppSettings);
	const user = useSelector(state => state.userReducer);
	const { t } = useTranslation('common');

	const { register, handleSubmit, formState: { errors } } = useForm();

	const onSubmit = async (data) => {
		await dispatch(PostLogin(data)).then((response) => {
		});
	};

	const activeBackground = '/assets/img/login-bg/login-bg-17.jpg';

	useEffect(() => {
		context.handleSetAppSidebarNone(true);
		context.handleSetAppHeaderNone(true);
		context.handleSetAppContentClass('p-4');
	}, []);

	if (user.authToken) {
		// console.log("login.js - Go to dashboard")
		return <Redirect to='/dashboard' />;
	}
	return (
		<Loader isLoading={user.isFetching}>
			<div className="login login-v2 fw-bold">
				<div className="login-cover">
					<div className="login-cover-img" style={{ backgroundImage: 'url(' + activeBackground + ')' }}></div>
					<div className="login-cover-bg"></div>
				</div>
				<div className="login-container">
					<div className="login-header">
						<div className="brand">
							<div className="d-flex align-items-center">
								<img src={logo} height="50" />
								{/* <span className="logo"></span> <b>Wartun</b> */}
							</div>
							{/* <small>Smart & Collaborative Community</small> */}
						</div>
						<div className="icon">
							<i className="fa fa-lock"></i>
						</div>
					</div>

					<div className="login-content">
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="form-floating mb-20px">
								<input type="text" className={"form-control fs-13px h-45px border-0 " + (errors.username ? 'is-invalid' : '')}
									placeholder="Email Address" id="emailAddress" {...register("username", {
										required: true,
										pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
									})} />
								{errors.username?.type === 'required' && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
								{errors.username?.type === 'pattern' && <span className='invalid-feedback'>{t('validation.invalid_email')}</span>}
								<label htmlFor="emailAddress" className="d-flex align-items-center text-gray-600 fs-13px">{t('login.username')}</label>
							</div>
							<div className="form-floating mb-20px">
								<input type="password" className={"form-control fs-13px h-45px border-0 " + (errors.password ? 'is-invalid' : '')} placeholder="Password"{...register("password", { required: true })} />
								{errors.password && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
								<label htmlFor="emailAddress" className="d-flex align-items-center text-gray-600 fs-13px">{t('login.password')}</label>
							</div>
							{/* <div className="form-check mb-20px">
								<input className="form-check-input border-0" type="checkbox" value="1" id="rememberMe" />
								<label className="form-check-label fs-13px text-gray-500" htmlFor="rememberMe">
								{t('login.forgot_password')}
								</label>
							</div> */}
							<div className="mb-20px">
								<input type="submit" className="btn btn-success d-block w-100 h-45px btn-lg" value={t('login.login_button')} />
								{user.errorMessage && <div className='text-center'>
									<span className='text-danger'>Ha ocurrido un problema: {user.errorMessage}</span></div>}
							</div>
							<div className="text-gray-500">
								<Link to="/user/forgotPassword">{t('login.forgot_password')}</Link>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Loader>
	)
}

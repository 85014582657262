import React, { useContext, useState, useEffect } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { AppSettings } from '../../config/app-settings.js';
import { useDispatch, useSelector } from 'react-redux';
import { PostForgotPassword } from '../../services/redux/userSlice';
import { Loader } from '../widget/loader';
import { useTranslation } from "react-i18next";

export default function ForgotPassword() {
	const dispatch = useDispatch();
	const location = useLocation();
	const context = useContext(AppSettings);
	const { t } = useTranslation('common');
	const query = new URLSearchParams(location.search);

	const user = useSelector(state => state.userReducer);
	const [showMsgThanks, setShowsgThanks] = useState(false);

	const { register, handleSubmit, formState: { errors } } = useForm();
	const onSubmit = async (data) => {
		// console.log(data);
		await dispatch(PostForgotPassword(data)).then((response) => {
			setShowsgThanks(true);
		});
	};

	useEffect(() => {
		context.handleSetAppSidebarNone(true);
		context.handleSetAppHeaderNone(true);
		context.handleSetAppContentClass('p-0');
	}, []);

	return (
		<Loader isLoading={user.isFetching}>
			<div className="register register-with-news-feed">
				<div className="news-feed">
					<div className="news-image" style={{ backgroundImage: 'url(/assets/img/login-bg/login-bg-17.jpg)' }}></div>
					<div className="news-caption">
						<h4 className="caption-title"><b>Wartun</b></h4>
						{/* <p>
							Transformando la forma en que administramos nuestras comunidades	</p> */}
					</div>
				</div>
				{!showMsgThanks ?
					<div className="register-container">
						<div className="register-header mb-25px h1">
							<div className="mb-1">{t('screen_forgot_password.question')}</div>
							<small className="d-block fs-15px lh-16">{t('screen_forgot_password.desription')}</small>
						</div>
						<div className="register-content">
							<form onSubmit={handleSubmit(onSubmit)} className="fs-13px">
								<div className="mb-3">
									<label className="mb-2">{t('screen_user_profile.email')} <span className="text-danger">*</span></label>
									<input type="text" className={"form-control fs-13px" + (errors.email ? ' is-invalid' : '')} id="email" {...register("email", {
										required: true,
										pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									})} placeholder={t('login.username')} readOnly={false} />
									{errors.email?.type === 'required' && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
									{errors.email?.type === 'pattern' && <span className='invalid-feedback'>{t('validation.invalid_email')}</span>}
								</div>
								<div className="mb-4">
									<button type="submit" className="btn btn-primary d-block w-100 btn-lg h-45px fs-13px">
									{t('screen_forgot_password.recover_password')}
									</button>
								</div>
								<div className="mb-4 pb-5">
								<Link to="/user/login">{t('screen_forgot_password.back_to_login')}</Link>
									{/* Already a member? Click <Link to="/user/login">here</Link> to login. */}
								</div>
								<hr className="bg-gray-600 opacity-2" />
								<p className="text-center text-gray-600">
									&copy; Wartun All Right Reserved 2023
								</p>
							</form>

						</div>
					</div>
					:
					<div className="register-container">
						<div className="register-header mb-25px h1">
							<div className="mb-1">Listo!</div>
							<small className="d-block fs-15px lh-16">Se ha enviado un mail a su casilla de correo para que continue con el proceso de recuperación de contraseña.</small>
						</div>
						{/* <div className="register-content">
							<div className="mb-4">
								<Link type='button' to="/user/login" className="btn btn-primary d-block w-100 btn-lg fs-13px">Iniciar Sesión</Link>
							</div>
						</div> */}
					</div>
				}
			</div>
		</Loader>
	)
}
import React, { useContext, useState, useEffect } from 'react';
import { Panel, PanelHeader, PanelBody, PanelFooter } from '../../components/panel/panel.jsx';
import { Card, CardGroup, CardImg, CardImgOverlay, CardText, CardBody, CardTitle, CardSubtitle, CardHeader, CardFooter, Button, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Alert } from 'reactstrap';
import Calendar from 'react-calendar';
import NVD3Chart from 'react-nvd3';
import d3 from 'd3';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import Chart from 'react-apexcharts';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { AppSettings } from '../../config/app-settings.js';
import { GetDashboard } from '../../services/redux/dashboardSlice';
import { MaintenanceTasksIdExecution, MaintenanceTasksIdPending } from '../../utils/generalConstants.js'

import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const Dashboard = () => {
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    // const state = useSelector(state => state);
    const user = useSelector(state => state.userReducer);
    const dashboard = useSelector(state => state.dashboardReducer);

    // const [redirect, setRedirect] = useState(0);
    // const activeBackground = '/assets/img/login-bg/login-bg-15.jpg';
    const { t } = useTranslation('common');

    const getDashboard = async () => {
        if (user?.authToken != "") {
            const requestData = {
                "tenantId": user.selectedTenant.id
            };

            await dispatch(GetDashboard({ authToken: user.authToken, body: requestData })).then((response) => {
            });
        }
    };

    useEffect(() => {
        getDashboard();
    }, [user]);

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        //context.handleSetAppDarkMode(true);

        getDashboard();
    }, []);

    if (user && user?.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    //#region Dashboard Functions

    const convertNumberWithCommas = function (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const formatDate = (d) => {
        //console.log(d);
        var monthsName = [t('month_names.Jan'), t('month_names.Feb'), t('month_names.Mar'), t('month_names.Apr'), t('month_names.May'), t('month_names.Jun'), t('month_names.Jul'), t('month_names.Aug'),
        t('month_names.Sep'), t('month_names.Oct'), t('month_names.Nov'), t('month_names.Dec')];
        d = new Date(d);
        d = monthsName[d.getMonth()] + ' ' + d.getDate();
        return d;
    }
    const getDate_Old = (minusDate) => {
        var d = new Date();
        d = d.setDate(d.getDate() - minusDate);
        return d;
    }

    const donutChartOptions = {
        donut: true,
        showLegend: false,
        growOnHover: false,
        arcsRadius: [
            { inner: 0.65, outer: 0.93 },
            { inner: 0.6, outer: 1 }
        ],
        margin: { 'left': 10, 'right': 10, 'top': 10, 'bottom': 10 },
        donutRatio: 0.5,
        labelFormat: d3.format(',.0f')
    };
    const donutChartData = [
        { 'label': 'Return Visitors', 'value': 784466, 'color': context.color.blue },
        { 'label': 'New Visitors', 'value': 416747, 'color': context.color.teal }
    ];

    const areaChartOptions = {
        pointSize: 0.5,
        useInteractiveGuideline: true,
        durection: 300,
        showControls: false,
        controlLabels: {
            stacked: 'Stacked'
        },
        yAxis: {
            tickFormat: d3.format(',.0f')
        },
        xAxis: {
            tickFormat: function (d) {
                var monthsName = [t('month_names.Jan'), t('month_names.Feb'), t('month_names.Mar'), t('month_names.Apr'), t('month_names.May'), t('month_names.Jun'), t('month_names.Jul'), t('month_names.Aug'),
                t('month_names.Sep'), t('month_names.Oct'), t('month_names.Nov'), t('month_names.Dec')];
                d = new Date(d);
                d = monthsName[d.getMonth()] + ' ' + d.getDate();
                return d;
            }
        }
    };

    const getDate = (year, month, day) => {
        var d = new Date(year, month, day);
        d = d.setDate(d.getDate());
        return d;
    }

    const areaChartData = [
        {
            'key': 'Gastos',
            'color': context.color.cyan,
            'values': [
                { x: getDate(2023, 1, 1), y: 11 },
                { x: getDate(2023, 2, 1), y: 13 },
                { x: getDate(2023, 3, 1), y: 6 },
                { x: getDate(2023, 4, 1), y: 6 },
                { x: getDate(2023, 5, 1), y: 6 },
                { x: getDate(2023, 6, 1), y: 5 },
                { x: getDate(2023, 7, 1), y: 5 },
                { x: getDate(2023, 8, 1), y: 5 },
                { x: getDate(2023, 9, 1), y: 6 },
                { x: getDate(2023, 10, 1), y: 7 },
                { x: getDate(2023, 11, 1), y: 6 },
                { x: getDate(2023, 12, 1), y: 9 }

            ]
        }
        // , {
        //     'key': t('menu.budget'),
        //     'color': context.color.blue,
        //     'values': [
        //         { x: getDate(77), y: 14 }, { x: getDate(76), y: 14 }, { x: getDate(75), y: 14 },
        //         { x: getDate(73), y: 14 }, { x: getDate(72), y: 14 }, { x: getDate(71), y: 14 }, { x: getDate(70), y: 14 },
        //         { x: getDate(69), y: 14 }, { x: getDate(68), y: 14 }, { x: getDate(67), y: 14 }, { x: getDate(66), y: 14 },
        //         { x: getDate(65), y: 14 }
        //     ]
        // }
    ];

    const map = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 9
    }
    const date = new Date();

    const totalSalesChart = {
        options: {
            chart: {
                type: 'line',
                width: 200,
                height: 36,
                sparkline: {
                    enabled: true
                },
                stacked: true
            },
            stroke: {
                curve: 'smooth',
                width: 3
            },
            fill: {
                type: 'gradient',
                gradient: {
                    opacityFrom: 1,
                    opacityTo: 1,
                    colorStops: [{
                        offset: 0,
                        color: context.color.blue,
                        opacity: 1
                    },
                    {
                        offset: 100,
                        color: context.color.indigo,
                        opacity: 1
                    }]
                },
            },
            tooltip: {
                theme: 'dark',
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    },
                    formatter: (value) => { return '$' + convertNumberWithCommas(value) },
                },
                marker: {
                    show: false
                }
            },
            responsive: [{
                breakpoint: 1500,
                options: {
                    chart: {
                        width: 130
                    }
                }
            }, {
                breakpoint: 1300,
                options: {
                    chart: {
                        width: 100
                    }
                }
            }, {
                breakpoint: 1200,
                options: {
                    chart: {
                        width: 200
                    }
                }
            }, {
                breakpoint: 576,
                options: {
                    chart: {
                        width: 180
                    }
                }
            }, {
                breakpoint: 400,
                options: {
                    chart: {
                        width: 120
                    }
                }
            }]
        },
        series: [{
            data: [9452.37, 11018.87, 7296.37, 6274.29, 7924.05, 6581.34, 12918.14]
        }]
    };
    const conversionRateChart = {
        options: {
            chart: {
                type: 'line',
                width: 160,
                height: 28,
                sparkline: {
                    enabled: true
                }
            },
            stroke: {
                curve: 'smooth',
                width: 3
            },
            fill: {
                type: 'gradient',
                gradient: {
                    opacityFrom: 1,
                    opacityTo: 1,
                    colorStops: [{
                        offset: 0,
                        color: context.color.red,
                        opacity: 1
                    },
                    {
                        offset: 50,
                        color: context.color.orange,
                        opacity: 1
                    },
                    {
                        offset: 100,
                        color: context.color.lime,
                        opacity: 1
                    }]
                },
            },
            labels: ['Jun 23', 'Jun 24', 'Jun 25', 'Jun 26', 'Jun 27', 'Jun 28', 'Jun 29'],
            xaxis: {
                crosshairs: {
                    width: 1
                },
            },
            tooltip: {
                theme: 'dark',
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    },
                    formatter: (value) => { return value + '%' },
                },
                marker: {
                    show: false
                }
            },
            responsive: [{
                breakpoint: 1500,
                options: {
                    chart: {
                        width: 120
                    }
                }
            }, {
                breakpoint: 1300,
                options: {
                    chart: {
                        width: 100
                    }
                }
            }, {
                breakpoint: 1200,
                options: {
                    chart: {
                        width: 160
                    }
                }
            }, {
                breakpoint: 900,
                options: {
                    chart: {
                        width: 120
                    }
                }
            }, {
                breakpoint: 576,
                options: {
                    chart: {
                        width: 180
                    }
                }
            }, {
                breakpoint: 400,
                options: {
                    chart: {
                        width: 120
                    }
                }
            }]
        },
        series: [{
            data: [2.68, 2.93, 2.04, 1.61, 1.88, 1.62, 2.80]
        }]
    };
    const storeSessionChart = {
        options: {
            chart: {
                type: 'line',
                width: 160,
                height: 28,
                sparkline: {
                    enabled: true
                },
                stacked: false
            },
            stroke: {
                curve: 'smooth',
                width: 3
            },
            fill: {
                type: 'gradient',
                gradient: {
                    opacityFrom: 1,
                    opacityTo: 1,
                    colorStops: [{
                        offset: 0,
                        color: context.color.teal,
                        opacity: 1
                    },
                    {
                        offset: 50,
                        color: context.color.blue,
                        opacity: 1
                    },
                    {
                        offset: 100,
                        color: context.color.cyan,
                        opacity: 1
                    }]
                },
            },
            labels: ['Jun 23', 'Jun 24', 'Jun 25', 'Jun 26', 'Jun 27', 'Jun 28', 'Jun 29'],
            xaxis: {
                crosshairs: {
                    width: 1
                },
            },
            tooltip: {
                theme: 'dark',
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    },
                    formatter: (value) => { return convertNumberWithCommas(value) },
                },
                marker: {
                    show: false
                }
            },
            responsive: [{
                breakpoint: 1500,
                options: {
                    chart: {
                        width: 120
                    }
                }
            }, {
                breakpoint: 1300,
                options: {
                    chart: {
                        width: 100
                    }
                }
            }, {
                breakpoint: 1200,
                options: {
                    chart: {
                        width: 160
                    }
                }
            }, {
                breakpoint: 900,
                options: {
                    chart: {
                        width: 120
                    }
                }
            }, {
                breakpoint: 576,
                options: {
                    chart: {
                        width: 180
                    }
                }
            }, {
                breakpoint: 400,
                options: {
                    chart: {
                        width: 120
                    }
                }
            }]
        },
        series: [{
            data: [10812, 11393, 7311, 6834, 9612, 11200, 13557]
        }]
    };

    const startDate = moment().subtract(7, 'days');
    const endDate = moment();

    const dateRange = {
        currentWeek: moment().subtract('days', 7).format('D MMMM YYYY') + ' - ' + moment().format('D MMMM YYYY'),
        prevWeek: moment().subtract('days', 15).format('D MMMM') + ' - ' + moment().subtract('days', 8).format('D MMMM YYYY')
    }


    const handleDateApplyEvent = (event, picker) => {
        var startDate = picker.startDate;
        var endDate = picker.endDate;
        var gap = endDate.diff(startDate, 'days');

        var currentWeek = startDate.format('D MMMM YYYY') + ' - ' + endDate.format('D MMMM YYYY');
        var prevWeek = moment(startDate).subtract('days', gap).format('D MMMM') + ' - ' + moment(startDate).subtract('days', 1).format('D MMMM YYYY');

        dateRange.currentWeek = currentWeek;
        dateRange.prevWeek = prevWeek;


        //setState(dateRange => ({
        //	currentWeek: currentWeek,
        //	prevWeek: prevWeek
        //}));
    }

    //#endregion

    return (
        <div>
            <ol className="breadcrumb float-xl-end" hidden>
                <li className="breadcrumb-item active">{t('menu.dashboard')}</li>
            </ol>
            <h1 className="page-header">{t('menu.dashboard')}</h1>
            {user.selectedTenant.agentRoles &&
                (() => {
                    switch (user.selectedTenant.agentRoles.toUpperCase()) {
                        case "MANAGER".toUpperCase():
                        case "COUNCILOR".toUpperCase():
                            return <>
                                {dashboard.data && dashboard.data.taskReviewSentCount > 0 &&
                                    <>
                                        <Alert color="danger" className='border border-ligth shadow-lg p-3 rounded'>
                                            <blockquote className="d-flex align-items-center mt-0 mb-0 text-ellipsis">
                                                <Link to="/taskReview/list?state=sent" className="btn btn-white btn-sm btn-rounded px-3 rounded-pill me-5px">
                                                    {/* <i className="fa fa-magnifying-glass me-1"></i> */}
                                                    {t('dashboard.title_task_review_button')}{/* {t("menu.tasks_list")} */}
                                                </Link>
                                                {/* https://getbootstrap.com/docs/4.0/utilities/display/ */}
                                                <div className="d-none d-sm-block">{t('dashboard.title_task_review_warning', { count: dashboard.data.taskReviewSentCount })}</div>
                                                <div className="d-block d-sm-none text-ellipsis">{t('dashboard.title_task_review_warning', { count: dashboard.data.taskReviewSentCount })}</div>
                                            </blockquote>
                                        </Alert>
                                        {/* https://getbootstrap.com/docs/4.0/utilities/display/ */}
                                        {/* <Card hidden body outline className="card-outline-warning bg-yellow-200 fw-bold text-black text-center mb-20px">
                                            <blockquote className="d-flex align-items-center mt-0 mb-0 text-ellipsis">
                                                <Link to="/taskReview/list" className="btn btn-white btn-sm btn-rounded px-3 rounded-pill me-5px">
                                                    Revisar
                                                </Link>
                                                <div className="d-none d-sm-block">Hay {dashboard.data.taskReviewSentCount} tarea/s relevadas pendientes que requieren su atención.</div>
                                                <div className="d-block d-sm-none text-ellipsis">Hay {dashboard.data.taskReviewSentCount} tarea/s relevadas pendientes que requieren su atención.</div>
                                            </blockquote>
                                        </Card> */}
                                    </>
                                }

                                <div className='row' hidden>
                                    {dashboard.data && dashboard.data.maintenanceTasksTotalCount &&
                                        <div className="col-xl-3 col-md-6">
                                            <Link to="/task/dashboard">
                                                <div className="widget widget-stats bg-pink mb-7px">
                                                    <div className="stats-icon stats-icon-lg"><i className="fa fa-file-alt fa-fw"></i></div>
                                                    <div className="stats-content">
                                                        <div className="stats-title">{t('tasks_panel.title')}</div>
                                                        <div className="stats-number">{t('common.total')} {dashboard.data.maintenanceTasksTotalCount}</div>
                                                        <div className="stats-progress progress">
                                                            <div className="progress-bar" style={{ width: (100 - dashboard.data.maintenanceTasksPendingCount / dashboard.data.maintenanceTasksTotalCount * 100).toFixed(0) + '%' }}></div>
                                                        </div>
                                                        <div className="stats-desc">{t('dashboard.title_tasks_totalCount')} ({(100 - dashboard.data.maintenanceTasksPendingCount / dashboard.data.maintenanceTasksTotalCount * 100).toFixed(0)}%)</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    }
                                    <div className="col-xl-3 col-md-6">
                                        <div className="widget widget-stats bg-blue">
                                            <div className="stats-icon"><i className="fa fa-desktop"></i></div>
                                            <div className="stats-info">
                                                <h4>{t('dashboard.title_budget')}</h4>
                                                <p>$ 3,291,922</p>
                                            </div>
                                            <div className="stats-link">
                                                <Link to="/dashboard/v1">{t('common.view_detail')} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                    {dashboard.data &&
                                        <div className="col-xl-3 col-md-6">
                                            <div className="widget widget-stats bg-info">
                                                <div className="stats-icon"><i className="fa-solid fa-cash-register"></i></div>
                                                <div className="stats-info">
                                                    <h4>{t('dashboard.title_assets_in_service')}</h4>
                                                    <p>{dashboard.data.assetsActiveCount}</p>
                                                </div>
                                                <div className="stats-link">
                                                    <Link to="/asset/list?InService=true">{t('common.view_detail')} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {dashboard.data &&
                                        <div className="col-xl-3 col-md-6">
                                            <div className="widget widget-stats bg-red">
                                                <div className="stats-icon"><i className="fa-solid fa-bandage"></i></div>
                                                <div className="stats-info">
                                                    <h4>{t('dashboard.title_assets_out_service')}</h4>
                                                    <p>{dashboard.data.assetsOutOfServiceCount}</p>
                                                </div>
                                                <div className="stats-link">
                                                    <Link to="/asset/list?OutOfService=true">{t('common.view_detail')} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                {dashboard.data &&
                                    <div className="row">
                                        {dashboard.data && dashboard.data.maintenanceTasksDueCount > 0 &&
                                            <div className="col-xl-3 col-md-6">
                                                <div className="widget widget-stats bg-danger shadow-lg rounded">
                                                    <div className="stats-icon"><i className="fa fa-clock"></i></div>
                                                    <div className="stats-info">
                                                        <h4>{t('dashboard.title_tasks_due')}</h4>
                                                        <p>{dashboard.data.maintenanceTasksDueCount}</p>
                                                    </div>
                                                    <div className="stats-link">
                                                        <Link to="/task/dashboard">{t('common.view_detail')} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {/* {dashboard.data && dashboard.data.maintenanceTasksPendingCount > 0 && */}
                                        {true &&
                                            <div className="col-xl-3 col-md-6">
                                                <div className="widget widget-stats bg-black-100 shadow-lg rounded">
                                                    <div className="stats-icon"><i className="fa fa-tasks-alt"></i></div>
                                                    <div className="stats-info">
                                                        <h4>{t('dashboard.title_tasks_pending')}</h4>
                                                        <p>{dashboard.data.maintenanceTasksPendingCount}
                                                            {dashboard.data.totalTasksEstimatedTime > '0' &&
                                                                <small className="text-muted" style={{ fontSize: '1rem' }}> (Horas Estimadas: {dashboard.data.totalTasksEstimatedTime})</small>
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="stats-link">
                                                        <Link to="/task/list?stateid=e9c76a30-1792-4800-b6ed-68381849bfaa">{t('common.view_detail')} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {/* {dashboard.data && dashboard.data.maintenanceTasksStartedCount > 0 && */}
                                        {true &&
                                            <div className="col-xl-3 col-md-6">
                                                <div className="widget widget-stats bg-gradient-info shadow-lg rounded">
                                                    <div className="stats-icon"><i className="fa fa-tasks-alt"></i></div>
                                                    <div className="stats-info">
                                                        <h4>{t('dashboard.title_tasks_active')}</h4>
                                                        <p>{dashboard.data.maintenanceTasksStartedCount}</p>
                                                    </div>
                                                    <div className="stats-link">
                                                        <Link to="/task/list?stateid=b2308b58-ed8a-4779-a34a-13f947f53f3a">{t('common.view_detail')} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {dashboard.data && dashboard.data.workOrdersToValidateCount > 0 &&
                                            <div className="col-xl-3 col-md-6">
                                                <div className="widget widget-stats bg-orange-400 shadow-lg rounded">
                                                    <div className="stats-icon"><i className="fa fa-tasks"></i></div>
                                                    <div className="stats-info">
                                                        <h4>{t('dashboard.title_tasks_to_validate')}</h4>
                                                        <p>{dashboard.data.workOrdersToValidateCount}</p>
                                                    </div>
                                                    <div className="stats-link">
                                                        <Link to="/task/dashboard">{t('common.view_detail')} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {false && dashboard.data && dashboard.data.totalTasksEstimatedTime > 0 &&
                                            <div className="col-xl-3 col-md-6">
                                                <div className="widget widget-stats bg-orange-400 shadow-lg rounded">
                                                    <div className="stats-icon"><i className="fa fa-tasks"></i></div>
                                                    <div className="stats-info">
                                                        <h4>Horas Estimadas</h4>
                                                        <p>{dashboard.data.totalTasksEstimatedTime}</p>
                                                    </div>
                                                    <div className="stats-link">
                                                        <Link to="/task/dashboard">{t('common.view_detail')} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {false && dashboard.data && dashboard.data.maintenanceTasksFinishedCount > 0 &&
                                            <div className="col-xl-3 col-md-6">
                                                <div className="widget widget-stats bg-black-100 shadow-lg rounded">
                                                    <div className="stats-icon"><i className="fa fa-tasks"></i></div>
                                                    <div className="stats-info">
                                                        <h4>{t('dashboard.title_tasks_finished')}</h4>
                                                        <p>{dashboard.data.maintenanceTasksFinishedCount}</p>
                                                    </div>
                                                    <div className="stats-link" hidden>
                                                        <Link to="/task/list">{t('common.view_detail')} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {/* {dashboard.data && dashboard.data.assetsActiveCount > 0 && */}
                                        {true &&
                                            <div className="col-xl-3 col-md-6">
                                                <div className="widget widget-stats bg-green-500 shadow-lg rounded">
                                                    <div className="stats-icon"><i className="fa fa-tasks"></i></div>
                                                    <div className="stats-info">
                                                        <h4>{t('dashboard.title_assets_in_service')}</h4>
                                                        <p>{dashboard.data.assetsActiveCount}</p>
                                                    </div>
                                                    <div className="stats-link">
                                                        <Link to="/asset/list?InService=true">{t('common.view_detail')} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {dashboard.data && dashboard.data.assetsOutOfServiceCount > 0 &&
                                            <div className="col-xl-3 col-md-6">
                                                <div className="widget widget-stats bg-red-500 shadow-lg rounded">
                                                    <div className="stats-icon"><i className="fa fa-warning"></i></div>
                                                    <div className="stats-info">
                                                        <h4>{t('dashboard.title_assets_out_service')}</h4>
                                                        <p>{dashboard.data.assetsOutOfServiceCount}</p>
                                                    </div>
                                                    <div className="stats-link">
                                                        <Link to="/asset/list?OutOfService=true">{t('common.view_detail')} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {dashboard.data && dashboard.data.assetsIsBackupCount > 0 &&
                                            <div className="col-xl-3 col-md-6">
                                                <div className="widget widget-stats bg-gradient-blue shadow-lg rounded">
                                                    <div className="stats-icon"><i className="fa fa-database"></i></div>
                                                    <div className="stats-info">
                                                        <h4>{t('dashboard.title_assets_isBackup')}</h4>
                                                        <p>{dashboard.data.assetsIsBackupCount}</p>
                                                    </div>
                                                    <div className="stats-link">
                                                        <Link to="/asset/list?IsBackup=true">{t('common.view_detail')} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {false && dashboard.data && dashboard.data.assetsOutOfServiceCount > 0 &&
                                            <div className="col-xl-3 col-md-6">
                                                <div className="widget widget-stats bg-orange shadow-lg rounded">
                                                    <div className="stats-icon"><i className="fa fa-check-circle"></i></div>
                                                    <div className="stats-info">
                                                        <h4>{t('dashboard.title_workOrders_completed')}</h4>
                                                        <p>50</p>
                                                    </div>
                                                    <div className="stats-link">
                                                        <Link to="/asset/list">{t('common.view_detail')} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {false && dashboard.data && dashboard.data.assetsOutOfServiceCount > 0 &&
                                            <div className="col-xl-3 col-md-6">
                                                <div className="widget widget-stats bg-green shadow-lg rounded">
                                                    <div className="stats-icon"><i className="fa fa-check-double"></i></div>
                                                    <div className="stats-info">
                                                        <h4>{t('dashboard.title_workOrders_validated')}</h4>
                                                        <p>4</p>
                                                    </div>
                                                    <div className="stats-link">
                                                        <Link to="/asset/list">{t('common.view_detail')} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                                <div className="row" hidden>
                                    <div className="col-xl-8 col-lg-6">
                                        <div className="card border-0 mb-3 bg-gray-800 text-white">
                                            <div className="card-body">
                                                <div className="mb-3 text-gray-500">
                                                    <b>{t('menu.budget')}</b>
                                                    <span className="ms-2">
                                                        <i className="fa fa-info-circle" id="popover4"></i>
                                                        <UncontrolledPopover trigger="hover" placement="top" target="popover4">
                                                            <PopoverHeader>Top products with units sold</PopoverHeader>
                                                            <PopoverBody>Products with the most individual units sold. Includes orders from all sales channels.</PopoverBody>
                                                        </UncontrolledPopover>
                                                    </span>
                                                </div>
                                                <div className="row" >
                                                    <div className="col-xl-3 col-4">
                                                        <h3 className="mb-1">127.1K</h3>
                                                        <div>New Visitors</div>
                                                        <div className="text-gray-500 small text-truncate"><i className="fa fa-caret-up"></i> 25.5% from previous 7 days</div>
                                                    </div>
                                                    <div className="col-xl-3 col-4">
                                                        <h3 className="mb-1">179.9K</h3>
                                                        <div>Returning Visitors</div>
                                                        <div className="text-gray-500 small text-truncate"><i className="fa fa-caret-up"></i> 5.33% from previous 7 days</div>
                                                    </div>
                                                    <div className="col-xl-3 col-4">
                                                        <h3 className="mb-1">766.8K</h3>
                                                        <div>Total Page Views</div>
                                                        <div className="text-gray-500 small text-truncate"><i className="fa fa-caret-up"></i> 0.323% from previous 7 days</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body p-0">
                                                <div style={{ height: '269px' }}>
                                                    <div className="widget-chart-full-width dark-mode" style={{ height: '254px' }}>
                                                        <NVD3Chart type="stackedAreaChart" datum={areaChartData} height={260} options={areaChartOptions} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6">
                                        <Panel>
                                            <PanelHeader>
                                                Calendar
                                            </PanelHeader>
                                            <Calendar value={new Date()} />
                                        </Panel>
                                        <div className="card bg-gray-800 border-0 text-white mb-3" hidden>
                                            <div className="card-body">
                                                <div className="mb-2 text-grey">
                                                    <b>SESSION BY LOCATION</b>
                                                    <span className="ms-2">
                                                        <i className="fa fa-info-circle" id="popover5"></i>
                                                        <UncontrolledPopover trigger="hover" placement="top" target="popover5">
                                                            <PopoverHeader>Total sales</PopoverHeader>
                                                            <PopoverBody>Net sales (gross sales minus discounts and returns) plus taxes and shipping. Includes orders from all sales channels.</PopoverBody>
                                                        </UncontrolledPopover>
                                                    </span>
                                                </div>
                                                <div className="bg-black mb-3" style={{ height: '192px' }}>
                                                </div>
                                                <div>
                                                    <div className="d-flex align-items-center text-white mb-2">
                                                        <div className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px" style={{ backgroundImage: 'url(/assets/img/flag/us.jpg)' }}></div>
                                                        <div className="d-flex w-100">
                                                            <div>United States</div>
                                                            <div className="ms-auto text-gray-500">39.85%</div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center text-white mb-2">
                                                        <div className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px" style={{ backgroundImage: 'url(/assets/img/flag/cn.jpg)' }}></div>
                                                        <div className="d-flex w-100">
                                                            <div>China</div>
                                                            <div className="ms-auto text-gray-500">14.23%</div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center text-white mb-2">
                                                        <div className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px" style={{ backgroundImage: 'url(/assets/img/flag/de.jpg)' }}></div>
                                                        <div className="d-flex w-100">
                                                            <div>Germany</div>
                                                            <div className="ms-auto text-gray-500">12.83%</div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center text-white mb-2">
                                                        <div className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px" style={{ backgroundImage: 'url(/assets/img/flag/fr.jpg)' }}></div>
                                                        <div className="d-flex w-100">
                                                            <div>France</div>
                                                            <div className="ms-auto text-gray-500">11.14%</div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center text-white mb-0">
                                                        <div className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px" style={{ backgroundImage: 'url(/assets/img/flag/jp.jpg)' }}></div>
                                                        <div className="d-flex w-100">
                                                            <div>Japan</div>
                                                            <div className="ms-auto text-gray-500">10.75%</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        case "MAINTENANCECHIEF".toUpperCase():
                        case "MAINTENANCEOPERATOR".toUpperCase():
                            return <Redirect to='/task/dashboard' />;
                        case "ADMINISTRATOR".toUpperCase():
                            return <Redirect to='/account' />;
                        case "AUDITOR".toUpperCase():
                            return <Redirect to='/audit/list' />;
                        case "TASKIDENTIFIER".toUpperCase():
                            return <Redirect to='/taskreview/list' />;
                        default:
                            return null
                    }
                })()
            }
            {/* HIDEN CONTENT */}
            <div className="row" hidden>
                <div className="col-xl-8 col-lg-6">
                    <div className="card border-0 mb-3 bg-gray-800 text-white">
                        <div className="card-body">
                            <div className="mb-3 text-gray-500">
                                <b>VISITORS ANALYTICS</b>
                                <span className="ms-2">
                                    <i className="fa fa-info-circle" id="popover4"></i>
                                    <UncontrolledPopover trigger="hover" placement="top" target="popover4">
                                        <PopoverHeader>Top products with units sold</PopoverHeader>
                                        <PopoverBody>Products with the most individual units sold. Includes orders from all sales channels.</PopoverBody>
                                    </UncontrolledPopover>
                                </span>
                            </div>
                            <div className="row">
                                <div className="col-xl-3 col-4">
                                    <h3 className="mb-1">127.1K</h3>
                                    <div>New Visitors</div>
                                    <div className="text-gray-500 small text-truncate"><i className="fa fa-caret-up"></i> 25.5% from previous 7 days</div>
                                </div>
                                <div className="col-xl-3 col-4">
                                    <h3 className="mb-1">179.9K</h3>
                                    <div>Returning Visitors</div>
                                    <div className="text-gray-500 small text-truncate"><i className="fa fa-caret-up"></i> 5.33% from previous 7 days</div>
                                </div>
                                <div className="col-xl-3 col-4">
                                    <h3 className="mb-1">766.8K</h3>
                                    <div>Total Page Views</div>
                                    <div className="text-gray-500 small text-truncate"><i className="fa fa-caret-up"></i> 0.323% from previous 7 days</div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <div style={{ height: '269px' }}>
                                <div className="widget-chart-full-width dark-mode" style={{ height: '254px' }}>
                                    <NVD3Chart type="stackedAreaChart" datum={areaChartData} height={260} options={areaChartOptions} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6">
                    <div className="card bg-gray-800 border-0 text-white mb-3">
                        <div className="card-body">
                            <div className="mb-2 text-grey">
                                <b>SESSION BY LOCATION</b>
                                <span className="ms-2">
                                    <i className="fa fa-info-circle" id="popover5"></i>
                                    <UncontrolledPopover trigger="hover" placement="top" target="popover5">
                                        <PopoverHeader>Total sales</PopoverHeader>
                                        <PopoverBody>Net sales (gross sales minus discounts and returns) plus taxes and shipping. Includes orders from all sales channels.</PopoverBody>
                                    </UncontrolledPopover>
                                </span>
                            </div>
                            <div className="bg-black mb-3" style={{ height: '192px' }}>
                            </div>
                            <div>
                                <div className="d-flex align-items-center text-white mb-2">
                                    <div className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px" style={{ backgroundImage: 'url(/assets/img/flag/us.jpg)' }}></div>
                                    <div className="d-flex w-100">
                                        <div>United States</div>
                                        <div className="ms-auto text-gray-500">39.85%</div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center text-white mb-2">
                                    <div className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px" style={{ backgroundImage: 'url(/assets/img/flag/cn.jpg)' }}></div>
                                    <div className="d-flex w-100">
                                        <div>China</div>
                                        <div className="ms-auto text-gray-500">14.23%</div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center text-white mb-2">
                                    <div className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px" style={{ backgroundImage: 'url(/assets/img/flag/de.jpg)' }}></div>
                                    <div className="d-flex w-100">
                                        <div>Germany</div>
                                        <div className="ms-auto text-gray-500">12.83%</div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center text-white mb-2">
                                    <div className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px" style={{ backgroundImage: 'url(/assets/img/flag/fr.jpg)' }}></div>
                                    <div className="d-flex w-100">
                                        <div>France</div>
                                        <div className="ms-auto text-gray-500">11.14%</div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center text-white mb-0">
                                    <div className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px" style={{ backgroundImage: 'url(/assets/img/flag/jp.jpg)' }}></div>
                                    <div className="d-flex w-100">
                                        <div>Japan</div>
                                        <div className="ms-auto text-gray-500">10.75%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" hidden>
                <div className="col-xl-4 col-lg-6">
                    <div className="card border-0 mb-3 bg-gray-900 text-white">
                        <div className="card-body" style={{ background: 'no-repeat bottom right', backgroundImage: 'url(/assets/img/svg/img-4.svg)', backgroundSize: 'auto 60%' }}>
                            <div className="mb-3 text-gray-500 ">
                                <b>SALES BY SOCIAL SOURCE</b>
                                <span className="text-gray-500 ms-2">
                                    <i className="fa fa-info-circle" id="popover6"></i>
                                    <UncontrolledPopover trigger="hover" placement="top" target="popover6">
                                        <PopoverHeader>Sales by social source</PopoverHeader>
                                        <PopoverBody>Total online store sales that came from a social referrer source.</PopoverBody>
                                    </UncontrolledPopover>
                                </span>
                            </div>
                            <h3 className="mb-10px">$55,547.89</h3>
                            <div className="text-gray-500 mb-1px"><i className="fa fa-caret-up"></i> 45.76% increased</div>
                        </div>
                        <div className="widget-list rounded-bottom dark-mode">
                            <Link to="/dashboard/v3" className="widget-list-item rounded-0 pt-3px">
                                <div className="widget-list-media icon">
                                    <i className="fab fa-apple bg-indigo text-white"></i>
                                </div>
                                <div className="widget-list-content">
                                    <div className="widget-list-title">Apple Store</div>
                                </div>
                                <div className="widget-list-action text-nowrap text-gray-500">
                                    $34,840.17
                                </div>
                            </Link>
                            <Link to="/dashboard/v3" className="widget-list-item">
                                <div className="widget-list-media icon">
                                    <i className="fab fa-facebook-f bg-blue text-white"></i>
                                </div>
                                <div className="widget-list-content">
                                    <div className="widget-list-title">Facebook</div>
                                </div>
                                <div className="widget-list-action text-nowrap text-gray-500">
                                    $12,502.67
                                </div>
                            </Link>
                            <Link to="/dashboard/v3" className="widget-list-item">
                                <div className="widget-list-media icon">
                                    <i className="fab fa-twitter bg-info text-white"></i>
                                </div>
                                <div className="widget-list-content">
                                    <div className="widget-list-title">Twitter</div>
                                </div>
                                <div className="widget-list-action text-nowrap text-gray-500">
                                    $4,799.20
                                </div>
                            </Link>
                            <Link to="/dashboard/v3" className="widget-list-item">
                                <div className="widget-list-media icon">
                                    <i className="fab fa-google bg-red text-white"></i>
                                </div>
                                <div className="widget-list-content">
                                    <div className="widget-list-title">Google Adwords</div>
                                </div>
                                <div className="widget-list-action text-nowrap text-gray-500">
                                    $3,405.85
                                </div>
                            </Link>
                            <Link to="/dashboard/v3" className="widget-list-item pb-3px rounded-bottom">
                                <div className="widget-list-media icon">
                                    <i className="fab fa-instagram bg-pink text-white"></i>
                                </div>
                                <div className="widget-list-content">
                                    <div className="widget-list-title">Instagram</div>
                                </div>
                                <div className="widget-list-action text-nowrap text-gray-500">
                                    $0.00
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6">
                    <div className="card border-0 mb-3 bg-gray-800 text-white">
                        <div className="card-body">
                            <div className="mb-3 text-gray-500">
                                <b>TOP PRODUCTS BY UNITS SOLD</b>
                                <span className="ms-2">
                                    <i className="fa fa-info-circle" id="popover7"></i>
                                    <UncontrolledPopover trigger="hover" placement="top" target="popover7">
                                        <PopoverHeader>Top products with units sold</PopoverHeader>
                                        <PopoverBody>Products with the most individual units sold. Includes orders from all sales channels.</PopoverBody>
                                    </UncontrolledPopover>
                                </span>
                            </div>
                            <div className="d-flex align-items-center mb-15px">
                                <div className="widget-img rounded-3 me-10px bg-white p-3px w-30px">
                                    <div className="h-100 w-100" style={{ background: 'url(/assets/img/product/product-8.jpg) center no-repeat', backgroundSize: 'auto 100%' }}></div>
                                </div>
                                <div className="text-truncate">
                                    <div>Apple iPhone XR (2021)</div>
                                    <div className="text-gray-500">$799.00</div>
                                </div>
                                <div className="ms-auto text-center">
                                    <div className="fs-13px">195</div>
                                    <div className="text-gray-500 fs-10px">sold</div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-15px">
                                <div className="widget-img rounded-3 me-10px bg-white p-3px w-30px">
                                    <div className="h-100 w-100" style={{ background: 'url(/assets/img/product/product-9.jpg) center no-repeat', backgroundSize: 'auto 100%' }}></div>
                                </div>
                                <div className="text-truncate">
                                    <div>Apple iPhone XS (2021)</div>
                                    <div className="text-gray-500">$1,199.00</div>
                                </div>
                                <div className="ms-auto text-center">
                                    <div className="fs-13px">185</div>
                                    <div className="text-gray-500 fs-10px">sold</div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-15px">
                                <div className="widget-img rounded-3 me-10px bg-white p-3px w-30px">
                                    <div className="h-100 w-100" style={{ background: 'url(/assets/img/product/product-10.jpg) center no-repeat', backgroundSize: 'auto 100%' }}></div>
                                </div>
                                <div className="text-truncate">
                                    <div>Apple iPhone XS Max (2021)</div>
                                    <div className="text-gray-500">$3,399</div>
                                </div>
                                <div className="ms-auto text-center">
                                    <div className="fs-13px">129</div>
                                    <div className="text-gray-500 fs-10px">sold</div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-15px">
                                <div className="widget-img rounded-3 me-10px bg-white p-3px w-30px">
                                    <div className="h-100 w-100" style={{ background: 'url(/assets/img/product/product-11.jpg) center no-repeat', backgroundSize: 'auto 100%' }}></div>
                                </div>
                                <div className="text-truncate">
                                    <div>Huawei Y5 (2021)</div>
                                    <div className="text-gray-500">$99.00</div>
                                </div>
                                <div className="ms-auto text-center">
                                    <div className="fs-13px">96</div>
                                    <div className="text-gray-500 fs-10px">sold</div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="widget-img rounded-3 me-10px bg-white p-3px w-30px">
                                    <div className="h-100 w-100" style={{ background: 'url(/assets/img/product/product-12.jpg) center no-repeat', backgroundSize: 'auto 100%' }}></div>
                                </div>
                                <div className="text-truncate">
                                    <div>Huawei Nova 4 (2021)</div>
                                    <div className="text-gray-500">$499.00</div>
                                </div>
                                <div className="ms-auto text-center">
                                    <div className="fs-13px">55</div>
                                    <div className="text-gray-500 fs-10px">sold</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6">
                    <div className="card border-0 mb-3 bg-gray-800 text-white">
                        <div className="card-body">
                            <div className="mb-3 text-gray-500">
                                <b>MARKETING CAMPAIGN</b>
                                <span className="ms-2">
                                    <i className="fa fa-info-circle" id="popover8"></i>
                                    <UncontrolledPopover trigger="hover" placement="top" target="popover8">
                                        <PopoverHeader>Marketing Campaign</PopoverHeader>
                                        <PopoverBody>Campaign that run for getting more returning customers.</PopoverBody>
                                    </UncontrolledPopover>
                                </span>
                            </div>
                            <div className="row align-items-center pb-1px">
                                <div className="col-4">
                                    <div className="h-100px d-flex align-items-center justify-content-center">
                                        <img src="/assets/img/svg/img-2.svg" alt="" className="mw-100 mh-100" />
                                    </div>
                                </div>
                                <div className="col-8">
                                    <div className="mb-2px text-truncate">Email Marketing Campaign</div>
                                    <div className="mb-2px  text-gray-500  fs-11px">Mon 12/6 - Sun 18/6</div>
                                    <div className="d-flex align-items-center mb-2px">
                                        <div className="flex-grow-1">
                                            <div className="progress h-5px rounded-pill bg-white bg-opacity-10">
                                                <div className="progress-bar progress-bar-striped bg-indigo" style={{ width: '80%' }}></div>
                                            </div>
                                        </div>
                                        <div className="ms-2 fs-11px w-30px text-center">80%</div>
                                    </div>
                                    <div className="text-gray-500 small mb-15px text-truncate">
                                        57.5% people click the email
                                    </div>
                                    <Link to="/dashboard/v3" className="btn btn-xs btn-indigo fs-10px ps-2 pe-2">View campaign</Link>
                                </div>
                            </div>
                            <hr className="bg-white-transparent-2 mt-20px mb-20px" />
                            <div className="row align-items-center">
                                <div className="col-4">
                                    <div className="h-100px d-flex align-items-center justify-content-center">
                                        <img src="/assets/img/svg/img-3.svg" alt="" className="mw-100 mh-100" />
                                    </div>
                                </div>
                                <div className="col-8">
                                    <div className="mb-2px text-truncate">Facebook Marketing Campaign</div>
                                    <div className="mb-2px  text-gray-500  fs-11px">Sat 10/6 - Sun 18/6</div>
                                    <div className="d-flex align-items-center mb-2px">
                                        <div className="flex-grow-1">
                                            <div className="progress h-5px rounded-pill bg-white bg-opacity-10">
                                                <div className="progress-bar progress-bar-striped bg-warning" style={{ width: '60%' }}></div>
                                            </div>
                                        </div>
                                        <div className="ms-2 fs-11px w-30px text-center">60%</div>
                                    </div>
                                    <div className="text-gray-500 small mb-15px text-truncate">
                                        +124k visitors from facebook
                                    </div>
                                    <Link to="/dashboard/v3" className="btn btn-xs btn-warning fs-10px ps-2 pe-2">View campaign</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
};

export default Dashboard;
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import common_es from "../assets/translation/es.json";
import common_en from "../assets/translation/en.json";
import common_it from "../assets/translation/it.json";
import common_pt from "../assets/translation/pt.json";
import common_fr from "../assets/translation/fr.json";

i18next
  .use(Backend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'es',
    lng: 'es',
    debug: true,
    interpolation: {
      escapeValue: false // react already safes from xss
    },                              // language to use
    resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        es: {
            common: common_es
        },
        pt: {
            common: common_pt
        },
        it: {
            common: common_it
        },
        fr: {
            common: common_fr
        },
    },
  });

  export default i18next;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { PURGE } from "redux-persist";

const initialState = {
    maintenanceTasks: null,
    dataCount: null,
    dataCountFiltered: null,
    selectedMaintenanceTask: null,
    lastSync: null,
    isFetching: false,
    error: false,
    errorMessage: "",
    status: ""
}

export const GetMaintenanceTask = createAsyncThunk('maintenanceTask/fetchGetMaintenanceTask', async ({ authToken, params }) => {
    let response = await api.getMaintenanceTask(authToken, params);
    return response;
});

export const ListMaintenanceTasks = createAsyncThunk('maintenanceTask/fetchListMaintenanceTasks', async ({authToken, body}) => {
    let response = await api.listMaintenanceTasks(authToken, body);
    return response;
});

export const CreateMaintenanceTask = createAsyncThunk('maintenanceTask/fetchCreateMaintenanceTask', async ({ authToken, body }) => {
    let response = await api.createMaintenanceTask(authToken, body);
    return response;
});

export const EditMaintenanceTask = createAsyncThunk('maintenanceTask/fetchEditMaintenanceTask', async ({ authToken, body }) => {
    //console.log(body);
    let response = await api.editMaintenanceTask(authToken, body);
    return response;
});

export const DeleteMaintenanceTask = createAsyncThunk('tasks/fetchDeleteTask', async ({ authToken, body }) => {
    let response = await api.deleteMaintenanceTask(authToken, body);
    return response;
});

const maintenanceTaskSlice = createSlice({
    name: "maintenanceTask",
    initialState,
    reducers: {

    },
    extraReducers: {
        [ListMaintenanceTasks.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [ListMaintenanceTasks.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.maintenanceTasks = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [ListMaintenanceTasks.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [GetMaintenanceTask.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [GetMaintenanceTask.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedMaintenanceTask = payload.data;
        },
        [GetMaintenanceTask.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [CreateMaintenanceTask.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [CreateMaintenanceTask.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
        },
        [CreateMaintenanceTask.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [EditMaintenanceTask.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [EditMaintenanceTask.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
        },
        [EditMaintenanceTask.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [DeleteMaintenanceTask.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [DeleteMaintenanceTask.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedMaintenanceTask = null;
            state.fullList = null;
        },
        [DeleteMaintenanceTask.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [PURGE]: (state) => {
            return initialState;
        },
    }
})

export default maintenanceTaskSlice.reducer; 
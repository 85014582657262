import React, { useContext, useState, useEffect } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { Panel, PanelHeader, PanelFooter, PanelBody } from '../../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { GetFunctionalUnit, DeleteFunctionalUnit } from '../../../services/redux/functionalUnitSlice';
import Upload from '../../../components/app/file/upload.js';
import Viewer from '../../../components/app/file/viewer.js';
import { postfileData } from '../../../services/redux/fileSlice';
import { FileTypes, WorkOrderStateFinishedId } from '../../../utils/generalConstants';
import { useTranslation } from "react-i18next";
import BuildingSiteItem from '../../../components/app/buildingSite/listItem';

const FunctionalUnitDetail = () => {
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const history = useHistory();
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const functionalUnit = useSelector(state => state.functionalUnitReducer);

    const query = new URLSearchParams(window.location.search);

    const [modalAddFiles, setModalAddFiles] = useState(false);
    const [modalViewDeleteFile, setModalViewDeleteFile] = useState(false);

    const getFunctionalUnit = async () => {
        const requestData = {
            "tenantId": user.selectedTenant.id,
            "id": query.get("id")
        };
        await dispatch(GetFunctionalUnit({ authToken: user.authToken, params: requestData })).then((response) => {
        });
    };

    const deleteFunctionalUnit = async (id) => {
        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": id,
        }
        //console.log(requestData);
        await dispatch(DeleteFunctionalUnit({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload &&
                history.push("/functionalUnit/list");
        });
    }

    const onUploadSubmit = async (data) => {
        data.append("TenantId", user.selectedTenant.id);
        data.append("AgentId", user.selectedTenant.agentId);
        data.append("CreatedOn", "2015-08-05T19:33:26Z");
        data.append("ReferenceId", functionalUnit.selectedFunctionalUnit.id);
        data.append("FileTypeId", FileTypes.filter(f => f.NormalizeName === 'FUNCTIONALUNIT IMAGE')[0].id);

        await dispatch(postfileData({ authToken: user.authToken, body: data })).then((response) => {
            setModalAddFiles(false);
            getFunctionalUnit();
        });
    };

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        getFunctionalUnit();
    }, []);

    const toggleModal = (name) => {
        switch (name) {
            case 'modalAddFiles':
                setModalAddFiles(!modalAddFiles);
                break;
            case 'modalViewDeleteFile':
                setModalViewDeleteFile(!modalViewDeleteFile);
                break;
            default:
                break;
        }
    }

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (
        <div>
            {functionalUnit.selectedFunctionalUnit &&
                <div>
                    <ol className="breadcrumb float-xl-end" hidden>
                        <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/functionalUnit/list">Control de Obra</Link></li>
                        <li className="breadcrumb-item active">{functionalUnit.selectedFunctionalUnit.number}</li>
                    </ol>
                    <h1 className="page-header">Unidad Funcional #{functionalUnit.selectedFunctionalUnit.number} <small>{/*{functionalUnit.selectedFunctionalUnit.typeNormalizedName}*/}</small></h1>

                    {(user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
                        user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF") &&
                        <div className="d-flex align-items-center mb-2">
                            <div className="ms-auto">
                                <Link to="/functionalUnit/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                                {/* <Link to="/functionalUnit/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("common.add")}</Link> */}
                            </div>
                        </div>
                    }

                    <div className="row">

                        <div className="col-xl-6">
                            <div className="col-xl-12">
                                <Panel>
                                    <PanelHeader noButton={true}>
                                        <div className="d-flex justify-content-between">
                                            {t("common.general_data")}
                                            {(user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
                                                user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF") &&
                                                <div>
                                                    <Link className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill" to={{
                                                        pathname: "/functionalUnit/edit",
                                                        state: {
                                                            functionalUnit: functionalUnit.selectedFunctionalUnit
                                                        }
                                                    }}> {t("common.edit")}</Link>
                                                    <button
                                                        className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-2"
                                                        onClick={() => toggleModal('modalAddFiles')}>
                                                        <i className="fa fa-plus me-1"></i> Agregar Archivos
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </PanelHeader>
                                    <PanelBody>
                                        <p>Número: {functionalUnit.selectedFunctionalUnit.number}</p>
                                        <p>Propietario: {functionalUnit.selectedFunctionalUnit.owner}</p>
                                        {functionalUnit.selectedFunctionalUnit.ownerContactInfo &&
                                            <p>Información de contacto: {functionalUnit.selectedFunctionalUnit.ownerContactInfo}</p>
                                        }
                                        {functionalUnit.selectedFunctionalUnit.geolocation &&
                                            <p>{t("common.geolocation")}: {functionalUnit.selectedFunctionalUnit.geolocation}</p>
                                        }
                                        {functionalUnit.selectedFunctionalUnit.observations &&
                                            <p>Observations: {functionalUnit.selectedFunctionalUnit.observations}</p>
                                        }
                                        <p>{t("common.created_by")}: {functionalUnit.selectedFunctionalUnit.createdBy}</p>
                                        <p>{t("common.createdOn")}: {moment(functionalUnit.selectedFunctionalUnit.createdOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm:ss")}</p>
                                    </PanelBody>
                                    <PanelFooter>
                                        <div className="d-flex align-items-center">
                                            <div className="ms-auto">
                                                {query.get("taskid") &&
                                                    <Link to={"/task/detail?id=" + query.get("taskid")} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill me-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                                }
                                                {query.get("servicetaskid") &&
                                                    <Link to={"/servicetask/detail?id=" + query.get("servicetaskid")} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill me-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                                }
                                                {query.get("routinetaskid") &&
                                                    <Link to={"/routinetask/detail?id=" + query.get("routinetaskid")} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill me-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                                }
                                                {query.get("workorderid") &&
                                                    <Link to={"/workorder/detail?id=" + query.get("workorderid")} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill me-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                                }
                                                {query.get("serviceworkorderid") &&
                                                    <Link to={"/serviceworkorder/detail?id=" + query.get("serviceworkorderid")} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill me-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                                }
                                                {query.get("routineworkorderid") &&
                                                    <Link to={"/routineworkorder/detail?id=" + query.get("routineworkorderid")} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill me-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                                }
                                                {query.get("taskreviewid") &&
                                                    <Link to={"/taskreview/detail?id=" + query.get("taskreviewid")} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill me-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                                }
                                                {(user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
                                                    user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF") &&
                                                    <button className='btn btn-danger' onClick={() => { toggleModal('modalViewDeleteFile') }}> Eliminar <i className="fa fa-trash"></i> </button>
                                                }
                                            </div>
                                        </div>
                                    </PanelFooter>
                                </Panel>
                            </div>
                            {(user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
                                user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF") &&
                                <div className="col-xl-12">
                                    <Panel>
                                        <PanelHeader noButton={true}>
                                            <div className="d-flex justify-content-between">
                                                Obras Iniciadas
                                                <div>
                                                    <Link className="btn btn-green btn-sm btn-rounded px-3 rounded-pill" to={{
                                                        pathname: "/buildingSite/create",
                                                        state: {
                                                            functionalUnitId: functionalUnit.selectedFunctionalUnit.id,
                                                            functionalUnitNumber: functionalUnit.selectedFunctionalUnit.number
                                                        }
                                                    }}><i className="fa fa-plus me-1"></i> {t("common.add")}</Link>

                                                    {/* 
                                                <Link to="/buildingSite/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("common.add")}</Link>

                                                <button
                                                    className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-2"
                                                    onClick={() => toggleModal('modalAddFiles')}>
                                                    {t("common.add_files")}
                                                </button> */}
                                                </div>
                                            </div>
                                        </PanelHeader>
                                        <PanelBody>
                                            {functionalUnit.selectedFunctionalUnit.buildingSites.length > 0 ?
                                                <div className="result-list">
                                                    {functionalUnit.selectedFunctionalUnit.buildingSites.map((buildingSite, index) =>
                                                        <BuildingSiteItem key={buildingSite.id} buildingSite={buildingSite} viewMode="FunctionUnitView"></BuildingSiteItem>
                                                    )}
                                                </div>
                                                :
                                                <div>
                                                    <div className="list-group-item d-flex">
                                                        <div className="me-3 fs-12px">
                                                            <i className="fa fa-info text-gray-500 fa-fw"></i>
                                                        </div>
                                                        <div className="mb-1 fs-12px">
                                                            <div className="flex-fill">
                                                                <div className="text-gray-600 flex-1">No se registran obras iniciadas</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </PanelBody>
                                    </Panel>
                                </div>
                            }
                        </div>

                        {(functionalUnit.selectedFunctionalUnit.workOrders || functionalUnit.selectedFunctionalUnit.maintenanceTasks) &&
                            <div className="col-xl-3">
                                {functionalUnit.selectedFunctionalUnit.maintenanceTasks && functionalUnit.selectedFunctionalUnit.maintenanceTasks.length != 0 &&
                                    <div className="col-xl-12">
                                        <Panel>
                                            <PanelHeader noButton={true}>
                                                <div className="d-flex align-items-center">
                                                    {t('task.scheduled_tasks_title')}
                                                </div>
                                            </PanelHeader>
                                            <PanelBody>
                                                {functionalUnit.selectedFunctionalUnit.maintenanceTasks.map((task, index) =>
                                                    <div key={index}>
                                                        <p><i className="fa fa-tasks"></i> {task.description}</p>
                                                        <p>
                                                            <Link to={"/task/detail?id=" + task.id} key={index} >
                                                                {moment(task.scheduledDate).format("DD/MM/YYYY")}&nbsp;<span className="badge bg-gray ms-5px">{t("task.state_" + task.stateNormalizedName)}</span>
                                                            </Link>
                                                        </p>
                                                    </div>
                                                )}
                                                {functionalUnit.selectedFunctionalUnit.maintenanceTasks.length === 0 &&
                                                    <p>{t('task.scheduled_tasks_title')} - {t('common.no_records_found')}</p>
                                                }
                                            </PanelBody>
                                        </Panel>
                                    </div>
                                }
                                {functionalUnit.selectedFunctionalUnit.workOrders && functionalUnit.selectedFunctionalUnit.workOrders.filter(w => w.stateId != WorkOrderStateFinishedId).length != 0 &&
                                    <div className="col-xl-12">
                                        <Panel>
                                            <PanelHeader noButton={true}>
                                                <div className="d-flex align-items-center">
                                                    {t('dashboard.title_tasks_active')}
                                                </div>
                                            </PanelHeader>
                                            <PanelBody>
                                                {functionalUnit.selectedFunctionalUnit.workOrders.filter(w => w.stateId != WorkOrderStateFinishedId).map((workorder, index) =>
                                                    <div key={index}>
                                                        <p><i className="fa fa-tasks"></i> {workorder.description}</p>
                                                        <p>
                                                            <Link to={"/workorder/detail?id=" + workorder.id} key={index} >
                                                                {moment(workorder.finishedOn ? workorder.finishedOn : workorder.startedOn).format("DD/MM/YYYY")}&nbsp;<span className="badge bg-gray ms-5px">{t("workOrder.state_" + workorder.stateNormalizedName)}</span>
                                                            </Link>
                                                        </p>
                                                    </div>
                                                )}
                                                {functionalUnit.selectedFunctionalUnit.workOrders.length === 0 &&
                                                    <p>{t('dashboard.title_tasks_active')} - {t('common.no_records_found')}</p>
                                                }
                                            </PanelBody>
                                        </Panel>
                                    </div>
                                }
                                {functionalUnit.selectedFunctionalUnit.workOrders && functionalUnit.selectedFunctionalUnit.workOrders.filter(w => w.stateId === WorkOrderStateFinishedId).length != 0 &&
                                    <div className="col-xl-12">
                                        <Panel>
                                            <PanelHeader noButton={true}>
                                                <div className="d-flex align-items-center">
                                                    {t('dashboard.title_tasks_finished')}
                                                </div>
                                            </PanelHeader>
                                            <PanelBody>
                                                {functionalUnit.selectedFunctionalUnit.workOrders.filter(w => w.stateId === WorkOrderStateFinishedId).map((workorder, index) =>
                                                    <div key={index}>
                                                        <p><i className="fa fa-tasks"></i> {workorder.description}</p>
                                                        <p>
                                                            <Link to={"/workorder/detail?id=" + workorder.id} key={index} >
                                                                {moment(workorder.finishedOn ? workorder.finishedOn : workorder.startedOn).format("DD/MM/YYYY")}&nbsp;<span className="badge bg-gray ms-5px">{t("workOrder.state_" + workorder.stateNormalizedName)}</span>
                                                            </Link>
                                                        </p>
                                                    </div>
                                                )}
                                                {functionalUnit.selectedFunctionalUnit.workOrders.length === 0 &&
                                                    <p>{t('dashboard.title_tasks_finished')} - {t('common.no_records_found')}</p>
                                                }
                                            </PanelBody>
                                        </Panel>
                                    </div>
                                }
                            </div>
                        }



                    </div>

                    <Viewer files={functionalUnit.selectedFunctionalUnit.files} />
                    <Upload onSubmit={onUploadSubmit} isOpen={modalAddFiles} toggleModal={() => toggleModal('modalAddFiles')}  ></Upload>

                    <Modal size="md" style={{ maxWidth: '450px', width: '100%' }} isOpen={modalViewDeleteFile} toggle={() => toggleModal('modalViewDeleteFile')} >
                        <ModalHeader toggle={() => toggleModal('modalViewDeleteFile')}>Eliminar Unidad Funcional</ModalHeader>
                        <ModalBody>
                            {!functionalUnit.error &&
                                <span>¿Esta seguro que desea eliminar la unidad funcional <b>{functionalUnit.selectedFunctionalUnit.number}</b>, creado por <b>{functionalUnit.selectedFunctionalUnit.createdBy}</b> el <b>{moment(functionalUnit.selectedFunctionalUnit.createdOn).format("DD/MM/YYYY")}</b> ?</span>
                            }
                            {functionalUnit.error && <div className='text-center'>
                                <span className='text-danger'>Ha ocurrido un problema: {functionalUnit.errorMessage}</span></div>}
                        </ModalBody>
                        {!functionalUnit.error &&
                            <ModalFooter>
                                <button className="btn btn-danger" onClick={() => deleteFunctionalUnit(functionalUnit.selectedFunctionalUnit.id)}>Si, borrar!</button>
                                <button className="btn btn-white" onClick={() => toggleModal('modalViewDeleteFile')}>No</button>
                            </ModalFooter>
                        }
                    </Modal>

                </div>
            }
        </div>
    )
};

export default FunctionalUnitDetail;
import React, { useContext, useState, useEffect } from 'react';
import { Panel, PanelHeader, PanelFooter, PanelBody } from '.././../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { GetAudit, SendAudit, CloseAudit, DeleteAudit } from '../../../services/redux/auditSlice';
import Upload from '../../../components/app/file/upload.js';
import Viewer from '../../../components/app/file/viewer.js';
import { postfileData } from '../../../services/redux/fileSlice';
import { FileTypes } from '../../../utils/generalConstants';
import { useTranslation } from "react-i18next";
import SweetAlert from 'react-bootstrap-sweetalert';

const AuditDetail = () => {
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const history = useHistory();
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const audit = useSelector(state => state.auditReducer);

    const query = new URLSearchParams(window.location.search);

    const [modalAddFiles, setModalAddFiles] = useState(false);
    const [modalViewDeleteFile, setModalViewDeleteFile] = useState(false);
    const [modalViewDeleteAudit, setModalViewDeleteAudit] = useState(false);
    const [sweetAlertSendAudit, setSweetAlertSendAudit] = useState(false);
    const [sweetAlertCloseAudit, setSweetAlertCloseAudit] = useState(false);

    const getAudit = async () => {
        const requestData = {
            "tenantId": user.selectedTenant.id,
            "id": query.get("id")
        };
        await dispatch(GetAudit({ authToken: user.authToken, params: requestData })).then((response) => {
        });
    };

    const sendAudit = async (id) => {
        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": id,
        }
        await dispatch(SendAudit({ authToken: user.authToken, body: requestData })).then((response) => {
            toggleSweetAlert("sweetAlertCloseAudit");
            response && response.payload &&
                history.push("/audit/list");
        });
    }

    const closeAudit = async (id) => {
        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": id,
        }
        await dispatch(CloseAudit({ authToken: user.authToken, body: requestData })).then((response) => {
            toggleSweetAlert("sweetAlertCloseAudit");
            response && response.payload &&
                history.push("/audit/list");
        });
    }

    const deleteAudit = async (id) => {
        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": id,
        }
        await dispatch(DeleteAudit({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload &&
                history.push("/audit/list");
        });
    }

    const onUploadSubmit = async (data) => {
        data.append("TenantId", user.selectedTenant.id);
        data.append("AgentId", user.selectedTenant.agentId);
        data.append("CreatedOn", "2015-08-05T19:33:26Z");
        data.append("ReferenceId", audit.selectedAudit.id);
        data.append("FileTypeId", FileTypes.filter(f => f.NormalizeName === 'AUDIT IMAGE')[0].id);

        await dispatch(postfileData({ authToken: user.authToken, body: data })).then((response) => {
            setModalAddFiles(false);
            getAudit();
        });
    };

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        getAudit();
    }, []);

    const toggleModal = (name) => {
        switch (name) {
            case 'modalAddFiles':
                setModalAddFiles(!modalAddFiles);
                break;
            case 'modalViewDeleteFile':
                setModalViewDeleteFile(!modalViewDeleteFile);
                break;
            case 'modalViewDeleteAudit':
                setModalViewDeleteAudit(!modalViewDeleteAudit);
                break;
            default:
                break;
        }
    }

    const toggleSweetAlert = (name) => {
        switch (name) {
            case 'sweetAlertSendAudit':
                setSweetAlertSendAudit(!sweetAlertSendAudit);
                break;
            case 'sweetAlertCloseAudit':
                setSweetAlertCloseAudit(!sweetAlertCloseAudit);
                break;
            default:
                break;
        }
    }

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (
        <div>
            {audit.selectedAudit &&
                <div>
                    <ol className="breadcrumb float-xl-end" hidden>
                        <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/audit/list">Auditorías</Link></li>
                        <li className="breadcrumb-item active">{audit.selectedAudit.name}</li>
                    </ol>
                    <h1 className="page-header">{audit.selectedAudit.name} <small>{/*{audit.selectedAudit.typeNormalizedName}*/}</small></h1>

                    <div className="d-flex align-items-center mb-2">
                        <div className="ms-auto">
                            <Link to="/audit/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                            {/* <Link to="/audit/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("common.add")}</Link> */}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-6">
                            <div className="col-xl-12">
                                <Panel>
                                    <PanelHeader noButton={true}>
                                        <div className="d-flex justify-content-between">
                                            General Data
                                            {(user.selectedTenant.agentId === audit.selectedAudit.createdById &&
                                                audit.selectedAudit.stateNormalizedName === "PENDING") &&
                                                <div>
                                                    <Link className="btn btn-success btn-sm btn-rounded px-3 rounded-pill" to={{
                                                        pathname: "/audit/edit",
                                                        state: {
                                                            audit: audit.selectedAudit
                                                        }
                                                    }}> {t("common.edit")}</Link>
                                                    <button
                                                        className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-2"
                                                        onClick={() => toggleModal('modalAddFiles')}>
                                                        {t("common.add_files")}
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </PanelHeader>
                                    <PanelBody>
                                        <p>Name: {audit.selectedAudit.name}</p>
                                        <p>Description: {audit.selectedAudit.description}</p>
                                        <p>Type: {audit.selectedAudit.typeNormalizedName}</p>
                                        <p>State: {audit.selectedAudit.stateNormalizedName}</p>
                                        <p>{t("common.created_by")}: {audit.selectedAudit.createdBy}</p>
                                        <p>{t("common.createdOn")}: {audit.selectedAudit.createdOn}</p>
                                    </PanelBody>
                                    <PanelFooter>
                                        <div className="d-flex align-items-center">
                                            <div className="ms-auto">
                                                {(user.selectedTenant.agentRoles && user.selectedTenant.agentRoles.toUpperCase() === "MANAGER" &&
                                                    audit.selectedAudit.stateNormalizedName === "SENT") &&
                                                    <>
                                                        <button className='btn btn-primary me-1' onClick={() => { toggleSweetAlert('sweetAlertCloseAudit') }}> Validar y Aceptar </button>
                                                    </>
                                                }
                                                {user.selectedTenant.agentId === audit.selectedAudit.createdById &&
                                                    audit.selectedAudit.stateNormalizedName === "PENDING" && <>
                                                        <button className='btn btn-primary me-1' onClick={() => { toggleSweetAlert('sweetAlertSendAudit') }}> Enviar </button>
                                                        <button className='btn btn-danger' onClick={() => { toggleModal('modalViewDeleteAudit') }}> Eliminar <i className="fa fa-trash"></i> </button>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </PanelFooter>
                                </Panel>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            {/* <div className="col-xl-12">
                                <Panel>
                                    <PanelHeader noButton={true}>
                                        <div className="d-flex align-items-center">
                                            Extra information
                                        </div>
                                    </PanelHeader>
                                    <PanelBody>
                                        <p>Panel Content Here</p>
                                    </PanelBody>
                                </Panel>
                            </div> */}
                            <Upload onSubmit={onUploadSubmit} isOpen={modalAddFiles} toggleModal={() => toggleModal('modalAddFiles')}  ></Upload>

                        </div>
                    </div>
                    <Viewer files={audit.selectedAudit.files} />

                    <Modal size="md" style={{ maxWidth: '300px', width: '100%' }} isOpen={modalViewDeleteAudit} toggle={() => toggleModal('modalViewDeleteAudit')} >
                        <ModalHeader toggle={() => toggleModal('modalViewDeleteAudit')}>Eliminar Auditoría</ModalHeader>
                        <ModalBody>
                            <span>¿Esta seguro que desea eliminar el auditoría <b>{audit.selectedAudit.name}</b>, creado por <b>{audit.selectedAudit.createdBy}</b> el <b>{moment(audit.selectedAudit.createdOn).format("DD/MM/YYYY")}</b> ?</span>
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-danger" onClick={() => deleteAudit(audit.selectedAudit.id)}>Si, borrar!</button>
                            <button className="btn btn-white" onClick={() => toggleModal('modalViewDeleteAudit')}>No</button>
                        </ModalFooter>
                    </Modal>

                    {sweetAlertSendAudit &&
                        <SweetAlert showCancel
                            confirmBtnText="Continuar"
                            confirmBtnBsStyle="primary"
                            cancelBtnText="Cancelar"
                            cancelBtnBsStyle="default"
                            title="Está seguro que desea realizar esta acción?"
                            onConfirm={() => sendAudit(audit.selectedAudit.id)}
                            onCancel={() => toggleSweetAlert("sweetAlertSendAudit")}
                        >
                            You will not be able to change this action!
                        </SweetAlert>
                    }

                    {sweetAlertCloseAudit &&
                        <SweetAlert showCancel
                            confirmBtnText="Continuar"
                            confirmBtnBsStyle="primary"
                            cancelBtnText="Cancelar"
                            cancelBtnBsStyle="default"
                            title="Está seguro que desea realizar esta acción?"
                            onConfirm={() => closeAudit(audit.selectedAudit.id)}
                            onCancel={() => toggleSweetAlert("sweetAlertCloseAudit")}
                        >
                            You will not be able to change this action!
                        </SweetAlert>
                    }

                </div>
            }
        </div>
    )
};

export default AuditDetail;
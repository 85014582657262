import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const UserItem = ({ user }) => {
    const { t } = useTranslation('common');
    return (
        <div className="result-item" key={user.id}>
            <Link to="/extra/search" className="result-image" style={{ backgroundImage: 'url(/assets/img/gallery/gallery-51.jpg)' }} hidden></Link>
            <div className="result-info">
            {user.isPendingInvitation &&
                <span className='badge bg-orange-200 me-5px'>
                   Invitación pendiente por confirmar</span>
               }
                <h4 className="title my-1"><Link to={{
                    pathname: "/user/edit",
                    state: {
                        userToEdit: user
                    }
                }}>{user.agent.fullName}</Link></h4>
                <p className="location">{user.userName} <span className="badge bg-blue me-5px">{t("roles.rol_" + user.role.normalizedName)}</span></p>
            </div>
            <div className="result-price" hidden>
                <Link to={{
                    pathname: "/user/edit",
                    state: {
                        userToEdit: user
                    }
                }} className="btn btn-gray-600 d-block w-100">{t("common.edit")}</Link>
            </div>
        </div>
    )
}

export default UserItem;
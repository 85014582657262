import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

const SupplierItem = ({ supplier }) => {
    const { t } = useTranslation('common');
    return (
        <div className="result-item" key={supplier.id}>
            <Link to="/extra/search" className="result-image" style={{ backgroundImage: 'url(/assets/img/gallery/gallery-51.jpg)' }} hidden></Link>
            <div className="result-info">
                <h4 className="title"><Link to={"/supplier/detail?id=" + supplier.id}>{supplier.name}</Link></h4>
                {supplier.supplierCategory &&
                    <p className="location"><span className="badge bg-default text-dark me-5px">{supplier.supplierCategory}</span></p>
                }
                <p className="location">
                    {supplier.street && supplier.streetNumber &&
                        <>{supplier.fullAddress}<br /></>
                    }
                    {supplier.email &&
                        <>{supplier.email}<br /></>
                    }
                    {supplier.phoneNumber &&
                        <>{supplier.phoneNumber}</>
                    }
                </p>
                <p className="desc" hidden>
                    {supplier.description}
                </p>
                {/* <div className="btn-row">
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Analytics"><i className="fa fa-fw fa-chart-bar"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="suppliers"><i className="fa fa-fw fa-tasks"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Configuration"><i className="fa fa-fw fa-cog"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Performance"><i className="fa fa-fw fa-tachometer"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Users"><i className="fa fa-fw fa-user"></i></Link>
                </div> */}
            </div>
            <div className="result-price" hidden>
                <Link to={"/supplier/detail?id=" + supplier.id} className="btn btn-gray-600 d-block w-100">{t("common.detail")}</Link>
            </div>
        </div>
    )
}

export default SupplierItem;
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import moment from 'moment';

const BuildingSiteItem = ({ buildingSite, viewMode }) => {
    const { t } = useTranslation('common');
    return (
        <>
            {viewMode === "FunctionUnitView" ?
                <div className="result-item" key={buildingSite.id}>
                    <Link to="/extra/search" className="result-image" style={{ backgroundImage: 'url(/assets/img/gallery/gallery-51.jpg)' }} hidden></Link>
                    <div className="result-info">
                    
                        <h4 className="title"><i className="fas fa-screwdriver-wrench me-7px"></i>
                        <Link to={"/buildingSite/detail?id=" + buildingSite.id}>{moment(buildingSite.startDate).format("DD/MM/YYYY")}</Link>
                        </h4>
                        <p className="desc" hidden>
                            {buildingSite.observations}
                        </p>
                    </div>
                    <div className="result-price" hidden>
                        <Link to={"/buildingSite/detail?id=" + buildingSite.id} className="btn btn-gray-600 d-block w-100">{t("common.detail")}</Link>
                    </div>
                </div>
                :
                <div className="result-item" key={buildingSite.id}>
                    <Link to="/extra/search" className="result-image" style={{ backgroundImage: 'url(/assets/img/gallery/gallery-51.jpg)' }} hidden></Link>
                    <div className="result-info">
                        <h4 className="title"><Link to={"/buildingSite/detail?id=" + buildingSite.id}>Obra Lote #{buildingSite.parcelNumber}</Link></h4>
                        <p className="location">{moment(buildingSite.startDate).format("DD/MM/YYYY")}</p>
                        <p className="desc" hidden>
                            {buildingSite.observations}
                        </p>
                    </div>
                    <div className="result-price" hidden>
                        <Link to={"/buildingSite/detail?id=" + buildingSite.id} className="btn btn-gray-600 d-block w-100">{t("common.detail")}</Link>
                    </div>
                </div>
            }
        </>
    )
}

export default BuildingSiteItem;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { PURGE } from "redux-persist";

const initialState = {
    list: null,
    dataCount: null,
    dataCountFiltered: null,
    selectedBuildingSite: null,
    lastSync: null,
    isFetching: false,
    error: false,
    errorMessage: "",
    status: "",
}

export const GetBuildingSite = createAsyncThunk('buildingSite/fetchGetBuildingSite', async ({ authToken, params }) => {
    let response = await api.getBuildingSite(authToken, params);
    return response;
});

export const ListBuildingSites = createAsyncThunk('buildingSite/fetchListBuildingSites', async ({authToken, body}) => {
    let response = await api.listBuildingSites(authToken, body);
    return response;
});

export const CreateBuildingSite = createAsyncThunk('buildingSite/fetchCreateBuildingSite', async ({ authToken, body }) => {
    let response = await api.createBuildingSite(authToken, body);
    return response;
});

export const EditBuildingSite = createAsyncThunk('buildingSite/fetchEditBuildingSite', async ({ authToken, body }) => {
    let response = await api.editBuildingSite(authToken, body);
    return response;
});

export const DeleteBuildingSite = createAsyncThunk('buildingSite/fetchDeleteBuildingSite', async ({ authToken, body }) => {
    let response = await api.deleteBuildingSite(authToken, body);
    return response;
});

const buildingSiteSlice = createSlice({
    name: "buildingSite",
    initialState,
    reducers: {

    },
    extraReducers: {

        [GetBuildingSite.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [GetBuildingSite.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedBuildingSite = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [GetBuildingSite.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [ListBuildingSites.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [ListBuildingSites.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.list = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [ListBuildingSites.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [CreateBuildingSite.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [CreateBuildingSite.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedBuildingSite = payload.data;
        },
        [CreateBuildingSite.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [EditBuildingSite.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [EditBuildingSite.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedBuildingSite = payload.data;
        },
        [EditBuildingSite.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [DeleteBuildingSite.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [DeleteBuildingSite.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedBuildingSite = null;
        },
        [DeleteBuildingSite.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },


        [PURGE]: (state) => {
            return initialState;
        },
    }
})

export default buildingSiteSlice.reducer; 
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { InputGroup, Input, Button, ButtonDropdown } from 'reactstrap';
import { AppSettings } from '../../../config/app-settings.js';
import { useDispatch, useSelector } from 'react-redux';
import { ListFunctionalUnits } from '../../../services/redux/functionalUnitSlice';
import Pagination from '../../../components/app/pager/pagination';
import { Loader } from '../../widget/loader';
import FunctionalUnitItem from '../../../components/app/functionalUnit/listItem';
import { useTranslation } from "react-i18next";

const FunctionalUnitList = () => {

    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const functionalUnits = useSelector(state => state.functionalUnitReducer);

    const [keyword, setkeyword] = useState("");
    const take = 10;

    const requestData = {
        "tenantId": user.selectedTenant.id,
        "createdById": null,
        "keyword": keyword,
        "sortBy": null,
        "sortDir": true,
        "skip": 0,
        "take": take
    };

    const toggleSplit = async (skip) => {
        requestData.skip = skip;
        await dispatch(ListFunctionalUnits({ authToken: user.authToken, body: requestData })).then((response) => {
            //console.log(response);
        });
    };

    const toggleSplitCallBack = useCallback(
        (skip) => {
            toggleSplit(skip);
        },
        [keyword],
    );

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        toggleSplit(0);
    }, []);


    return (
        <Loader isLoading={functionalUnits.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active">Unidades Funcionales</li>
                </ol>
                <h1 className="page-header">Unidades Funcionales
                 {/* {functionalUnits && <small>{functionalUnits.dataCountFiltered} {t('common.results_found_of')} {functionalUnits.dataCount}</small>} */}
                 </h1>
                
                <div className="d-flex align-items-center mb-2">
                    <div className="ms-auto">
                        <Link to="/functionalUnit/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("common.add")}</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <InputGroup size="lg" className="mb-3">
                            <Input placeholder={t("common.enter_keyword_here")} type="text"  onKeyPress={(e) => e.key === 'Enter' && toggleSplit(0)} className="input-white" onChange={(text) => setkeyword(text.target.value)} />
                            <Button color="primary" className="rounded-right btn-lg" onClick={() => toggleSplit(0)}><i className="fa fa-search fa-fw"></i> {t("common.search")}</Button>
                            {/*<ButtonDropdown toggle={toggleSplit} hidden>*/}
                            {/*	<DropdownToggle color="primary" className="btn-lg" split>*/}
                            {/*		<i className="fa fa-cog fa-fw"></i>*/}
                            {/*	</DropdownToggle>*/}
                            {/*	<DropdownMenu>*/}
                            {/*		<DropdownItem>Action</DropdownItem>*/}
                            {/*		<DropdownItem>Another Action</DropdownItem>*/}
                            {/*		<DropdownItem>Something else here</DropdownItem>*/}
                            {/*		<DropdownItem divider />*/}
                            {/*		<DropdownItem>Separated link</DropdownItem>*/}
                            {/*	</DropdownMenu>*/}
                            {/*</ButtonDropdown>*/}
                        </InputGroup>

                        {functionalUnits && functionalUnits.list &&
                            <div>
                                {/* <div className="d-block d-md-flex align-items-center mb-3">
                                    <div className="d-flex">
                                        <UncontrolledButtonDropdown className="me-2">
                                            <DropdownToggle color="white" caret>
                                                Filters by <b className="caret"></b>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem>Posted Date</DropdownItem>
                                                <DropdownItem>View Count</DropdownItem>
                                                <DropdownItem>Total View</DropdownItem>
                                                <DropdownItem divider />
                                                <DropdownItem>Location</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                        <div className="btn-group" hidden>
                                            <Link to="/extra/search" className="btn btn-white"><i className="fa fa-list"></i></Link>
                                            <Link to="/extra/search" className="btn btn-white"><i className="fa fa-th"></i></Link>
                                            <Link to="/extra/search" className="btn btn-white"><i className="fa fa-th-large"></i></Link>
                                        </div>
                                    </div>
                                </div> */}
                                <Pagination toggleSplitCallBack={toggleSplitCallBack} dataCount={functionalUnits.dataCountFiltered} take={take}>
                                    <div className="result-list">
                                        {functionalUnits.list.map((functionalUnit, index) => 
											<FunctionalUnitItem key={functionalUnit.id} functionalUnit={functionalUnit}></FunctionalUnitItem>
                                        )}
                                    </div>
                                </Pagination>
                                <div className="result-list">

                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </Loader>
    )
};

export default FunctionalUnitList;
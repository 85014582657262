import React, { useContext, useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppSettings } from "./../../config/app-settings.js";
import SidebarNavList from "./sidebar-nav-list.jsx";
import menu from "./menu.js";

const SidebarNav = () => {
  const user = useSelector((state) => state.userReducer);
  const context = useContext(AppSettings);
  const [stateActive, setStateActive] = useState(-1);
  const [stateClicked, setStateClicked] = useState(-1);
  var menus = menu();

  const handleExpand = (e, i, match) => {
    e.preventDefault();

    if (stateClicked === -1 && match) {
      setStateActive(-1);
      setStateClicked(1);
    } else {
      setStateActive(stateActive === i ? -1 : i);
      setStateClicked(1);
      // this.setState(state => ({
      // 	active: (this.state.active === i ? -1 : i),
      // 	clicked: 1
      // }));
    }
  };

  const checkAccess = (route) => {
    //console.log("####################" + user?.selectedTenant.id)
    // if (
    //   (
    //     user?.selectedTenant?.id != "7868D0D4-3012-48B8-8707-AC6DD392559A".toLowerCase() &&
    //     user?.selectedTenant?.id != "AB74DB4B-6A24-4749-8324-195C62B77D45".toLowerCase() &&
    //     user?.selectedTenant?.id != "BEFAAC29-406B-4CC3-96E9-06F6E2CEFB4A".toLowerCase()
    //   )
    //   && (
    //     route.path.includes("functionalUnit") ||
    //     route.path.includes("pettyCash"))
    // ) {
    //   return false;
    // } else {
    return (
      !route.roles ||
      (user.selectedTenant &&
        user.selectedTenant.agentRoles &&
        route.roles
          .toLowerCase()
          .includes(user.selectedTenant.agentRoles.toLowerCase()))
    );
    // }
  };

  const testAccess = () => {
    const role =
      user.selectedTenant && user.selectedTenant.agentRoles
        ? user.selectedTenant.agentRoles
        : "";

    menus.map(
      (route, index) =>
        route.roles &&
        console.log(
          "route.path: " +
            route.path +
            " route.roles: " +
            route.roles +
            " Rol: " +
            role +
            " match: " +
            route.roles.toLowerCase().includes(role.toLowerCase()) +
            " include: " +
            checkAccess(route)
        )
    );
  };

  const handleSidebarSearch = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.toLowerCase();

    this.setState((state) => {
      let newMenus = [];
      if (searchValue !== "") {
        newMenus = menus.filter((item) => {
          let title = item.title;
          title = title.toLowerCase();
          if (title.search(searchValue) > -1) {
            item.search = true;
            return true;
          } else {
            if (item.children) {
              for (var i = 0; i < item.children.length; i++) {
                let title2 = item.children[i]["title"];
                title2 = title2.toLowerCase();

                if (title2.search(searchValue) > -1) {
                  item.search = true;
                  return true;
                }
              }
            }
            return false;
          }
        });
      } else {
        newMenus = menus.filter((item) => {
          item.search = false;
          return true;
        });
      }
      return {
        menus: newMenus,
      };
    });
  };

  // useEffect(() => {
  //   //testAccess();
  // }, []);

  return (
    <div className="menu">
      {context.appSidebarSearch && (
        <div className="menu-search mb-n3">
          <input
            type="text"
            className="form-control"
            placeholder="Sidebar menu filter..."
            onKeyUp={handleSidebarSearch}
          />
        </div>
      )}
      <div className="menu-header">{/*Navigation*/}</div>
      {menus.map(
        (menu, i) =>
          menu.path &&
          checkAccess(menu) && (
            // <div
            //   key={"menudiv" + i}
            //   className={
            //     (i === 0 || i === 3) ?
            //     "border-secondary border-bottom 1px solid "
            //     : ""
            //   }
            // >
              <Route
                path={menu.path}
                exact={menu.exact}
                key={i}
                children={({ match }) => (
                  <SidebarNavList
                    data={menu}
                    key={i}
                    expand={(e) => handleExpand(e, i, match)}
                    active={i === stateActive}
                    clicked={stateClicked}
                  />
                )}
              />
            // </div>
          )
      )}
    </div>
  );
};

export default SidebarNav;

// class SidebarNav1 extends React.Component {
//   static contextType = AppSettings;

//   constructor(props) {
//     super(props);
//     this.state = {
//       active: -1,
//       clicked: -1,
//       menus: menu(),
//     };

//     this.handleSidebarSearch = this.handleSidebarSearch.bind(this);
//   }

//   handleExpand(e, i, match) {
//     e.preventDefault();

//     if (this.state.clicked === -1 && match) {
//       this.setState((state) => ({
//         active: -1,
//         clicked: 1,
//       }));
//     } else {
//       this.setState((state) => ({
//         active: this.state.active === i ? -1 : i,
//         clicked: 1,
//       }));
//     }
//   }

//   handleSidebarSearch(e) {
//     let searchValue = e.target.value;
//     searchValue = searchValue.toLowerCase();

//     this.setState((state) => {
//       let newMenus = [];
//       if (searchValue !== "") {
//         newMenus = menu().filter((item) => {
//           let title = item.title;
//           title = title.toLowerCase();
//           if (title.search(searchValue) > -1) {
//             item.search = true;
//             return true;
//           } else {
//             if (item.children) {
//               for (var i = 0; i < item.children.length; i++) {
//                 let title2 = item.children[i]["title"];
//                 title2 = title2.toLowerCase();

//                 if (title2.search(searchValue) > -1) {
//                   item.search = true;
//                   return true;
//                 }
//               }
//             }
//             return false;
//           }
//         });
//       } else {
//         newMenus = menu().filter((item) => {
//           item.search = false;
//           return true;
//         });
//       }
//       return {
//         menus: newMenus,
//       };
//     });
//   }

//   render() {
//     return (
//       <div className="menu">
//         {this.context.appSidebarSearch && (
//           <div className="menu-search mb-n3">
//             <input
//               type="text"
//               className="form-control"
//               placeholder="Sidebar menu filter..."
//               onKeyUp={this.handleSidebarSearch}
//             />
//           </div>
//         )}
//         <div className="menu-header">{/*Navigation*/}</div>
//         {this.state.menus.map((menu, i) => (
//           <Route
//             path={menu.path}
//             exact={menu.exact}
//             key={i}
//             children={({ match }) => (
//               <SidebarNavList
//                 data={menu}
//                 key={i}
//                 expand={(e) => this.handleExpand(e, i, match)}
//                 active={i === this.state.active}
//                 clicked={this.state.clicked}
//               />
//             )}
//           />
//         ))}
//       </div>
//     );
//   }
// }

//export default SidebarNav1;

import React, { useContext, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import { Panel, PanelHeader, PanelFooter, PanelBody } from '.././../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../widget/loader';
import { CreateAsset } from '../../../services/redux/assetSlice';
import { FullListAreas } from '../../../services/redux/areaSlice';
import { AssetTypes, AssetPriorityLevel } from '../../../utils/generalConstants';
import { useTranslation } from "react-i18next";
import { FullListRooms } from '../../../services/redux/roomSlice.js';
import { renderAreasOptions } from '../../../components/app/area/common.js';


const AssetCreate = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const asset = useSelector(state => state.assetReducer);
    const areas = useSelector(state => state.areaReducer)
    const rooms = useSelector(state => state.roomReducer)

    const [isOutOfService, setIsOutOfService] = useState(false);
    const [isBackup, setIsBackup] = useState(false);
    const [purchaseDate, setPurchaseDate] = useState(null);
    const [warrantyExpirationDate, setWarrantyExpirationDate] = useState(null);

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);

        !areas.fullList &&
            dispatch(FullListAreas({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

        !rooms.fullList &&
            dispatch(FullListRooms({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            })
    }, []);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {

        let requestData = {
            "tenantId": user.selectedTenant.id,
            "typeId": data.assetTypeId,
            "name": data.name,
            "brand": (data.brand != "") ? data.brand : null,
            "model": (data.model != "") ? data.model : null,
            "serialNumber": (data.serialNumber != "") ? data.serialNumber : null,
            "IsOutOfService": isOutOfService,
            "IsActive": true,
            "IsBackup": isBackup,
            "IsArchived": false,
            "PurchaseDate": purchaseDate,
            "warrantyExpirationDate": warrantyExpirationDate,
            "MoreInfo": data.moreInfo,
            "priorityLevelId": data.priorityLevelId,
            "areaId": (data.areaId != "") ? data.areaId : null,
            "roomId": (data.roomId != "") ? data.roomId : null,
            "createdById": user.selectedTenant.agentId,
        }

        //console.log(requestData);

        await dispatch(CreateAsset({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload && response.payload.data &&
                history.push("/asset/detail?id=" + response.payload.data.id);
            //return <Redirect to={"/asset/detail?id=" + response.payload.data.id } />;
        });
    };

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (
        <Loader isLoading={asset.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active">{t('common.new')} {t('common.asset')}</li>
                </ol>
                <h1 className="page-header">{t('common.new')} {t('common.asset')} <small>complete los campos obligatorios</small></h1>

                <div className="d-flex align-items-center mb-2">
                    <div className="ms-auto">
                        <Link to="/asset/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-7">
                        <div className="col-xl-12">
                            <Panel>
                                <PanelHeader noButton={true}>
                                    <div className="d-flex align-items-center">
                                        {t('common.general_data')}
                                    </div>
                                </PanelHeader>
                                <PanelBody>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-floating mb-15px">
                                            <input type="text" maxLength={70} className="form-control fs-15px" id="name" {...register("name", {
                                                required: true,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.name')}
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                        <input type="text" maxLength={90} className="form-control fs-15px" id="brand" {...register("brand", {
                                            required: false,
                                        })} />
                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                            {t('common.brand')}
                                        </label>
                                    </div>

                                        <div className="form-floating mb-15px">
                                            <input type="text" maxLength={90} className="form-control fs-15px" id="model" {...register("model", {
                                                required: false,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.model')}
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <input type="text" maxLength={90} className="form-control fs-15px" id="serialNumber" {...register("serialNumber", {
                                                required: false,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.serial_number')}
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">

                                            <select className="form-select" id="assetTypeId" {...register("assetTypeId", {
                                                required: true,
                                            })}>
                                                <option></option>
                                                {AssetTypes.map(o => <option key={o.id} value={o.id}>{t("asset.type_" + o.NormalizeName)}</option>)}
                                            </select>
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.type')}
                                            </label>
                                        </div>

                                        <div className="row mb-10px align-items-center">
                                            <div className="col-8 control-label text-dark fw-bold">{t("asset.out_of_service")}</div>
                                            <div className="col-4 d-flex">
                                                <div className="form-check form-switch ms-auto mb-0">
                                                    <input type="checkbox" className="form-check-input" name="IsOutOfService" onChange={(e) => setIsOutOfService(e.target.checked)} id="IsOutOfService" checked={isOutOfService} />
                                                    <label className="form-check-label" htmlFor="appHeaderInverse">&nbsp;</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-10px align-items-center">
                                            <div className="col-8 control-label text-dark fw-bold">{t("asset.backup")}</div>
                                            <div className="col-4 d-flex">
                                                <div className="form-check form-switch ms-auto mb-0">
                                                    <input type="checkbox" className="form-check-input" name="IsBackup" onChange={(e) => setIsBackup(e.target.checked)} id="IsBackup" checked={isBackup} />
                                                    <label className="form-check-label" htmlFor="appHeaderInverse">&nbsp;</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-floating mb-15px">

                                            <select className="form-select" id="priorityLevelId" {...register("priorityLevelId", {
                                                required: true,
                                            })}>
                                                <option></option>
                                                {AssetPriorityLevel.map(o => <option key={o.id} value={o.id}>{t("common.priority_level_" + o.NormalizeName)}</option>)}
                                            </select>
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.priority')}
                                            </label>
                                        </div>

                                        {areas.fullList &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="areaId" {...register("areaId", {
                                                    required: false,
                                                })}>
                                                    <option></option>
                                                    {/* {areas.fullList.map(a => <option key={a.id} value={a.id}>{a.name}</option>)} */}
                                                    {renderAreasOptions(areas.fullList, null)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    {t('common.area')}
                                                </label>
                                            </div>
                                        }

                                        {user?.selectedTenant?.settings?.assetsPerRoomm &&
                                            rooms.fullList &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="roomId" {...register("roomId", {
                                                    required: false,
                                                })}>
                                                    <option></option>
                                                    {rooms.fullList.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    {t('common.room')}
                                                </label>
                                            </div>
                                        }

                                        <div className="form-floating mb-15px">
                                            <DatePicker className="form-control fs-15px pt-25px" dateFormat="dd-MM-yyyy" selected={purchaseDate} onChange={(date) => setPurchaseDate(date)} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-11px pt-1px mb-10px pb-30px">
                                                {t('common.purchase_date')}
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <DatePicker className="form-control fs-15px pt-25px" dateFormat="dd-MM-yyyy" selected={warrantyExpirationDate} onChange={(date) => setWarrantyExpirationDate(date)} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-11px pt-1px mb-10px pb-30px">
                                                {t('asset.warranty_expiration_date')}
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <textarea rows="5" maxLength={2000} className="form-control fs-15px h-100"
                                                id="moreInfo" {...register("moreInfo", {
                                                    required: false
                                                })} />
                                            <label htmlFor="floatingInput" className="fs-13px">
                                                {t('common.aditional_info')}
                                            </label>
                                        </div>

                                        <div className="mb-20px">
                                            <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value={t("common.add")} />
                                            {asset.error && <div className='text-center'>
                                                <span className='text-danger'>Ha ocurrido un problema: {asset.errorMessage}</span></div>}
                                        </div>

                                    </form>

                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                </div>
            </div>
        </Loader>
    )
};

export default AssetCreate;
import React, { useContext, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../../widget/loader';
import { Panel, PanelHeader, PanelFooter, PanelBody } from '../../../../components/panel/panel.jsx';
import { AppSettings } from '../../../../config/app-settings.js';
import { CreateUser } from '../../../../services/redux/userSlice';
import { Roles } from '../../../../utils/generalConstants'
import { useTranslation } from "react-i18next";

const UserCreate = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);

    const [isEnabledUser, setIsEnabledUser] = useState(true);

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
    }, []);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {

        let requestData = {
            "tenantId": user.selectedTenant.id,
            "createdById": user.selectedTenant.agentId,
            "firstName": data.firstName,
            "lastName": data.lastName,
            "email": data.email,
            "isEnabledUser": isEnabledUser,
            "role": data.role,
            "phoneNumber": data.phoneNumber,
        }

        //console.log(requestData);

        await dispatch(CreateUser({ authToken: user.authToken, body: requestData })).then((response) => {
            if (!response.error)
                history.push("/user/list");
        });
    };

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (
        <Loader isLoading={user.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active">Nuevo Usuario</li>
                </ol>
                <h1 className="page-header">Nuevo Usuario <small>complete los campos obligatorios</small></h1>

                <div className="d-flex align-items-center mb-2">
                    <div className="ms-auto">
                        <Link to="/user/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-7">
                        <div className="col-xl-12">
                            <Panel>
                                <PanelHeader noButton={true}>
                                    <div className="d-flex align-items-center">
                                        {t('common.general_data')}
                                    </div>
                                </PanelHeader>
                                <PanelBody>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-floating mb-15px">
                                            <input type="text" className={"form-control fs-15px " + (errors.firstName ? 'is-invalid' : '')}
                                                id="firstName" {...register("firstName", {
                                                    required: true,
                                                })} />
                                            {errors.firstName?.type === 'required' && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.first_name')}
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <input type="text" className={"form-control fs-15px " + (errors.lastName ? 'is-invalid' : '')} id="lastName" {...register("lastName", {
                                                required: true,
                                            })} />
                                            {errors.lastName?.type === 'required' && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.last_name')}
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <input type="text" className={"form-control fs-15px " + (errors.email ? 'is-invalid' : '')} id="email" {...register("email", {
                                                required: true,
                                                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                            })} />
                                            {errors.email?.type === 'required' && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
                                            {errors.email?.type === 'pattern' && <span className='invalid-feedback'>{t('validation.invalid_email')}</span>}
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.email')}
                                            </label>
                                        </div>

                                        <div className="row mb-10px align-items-center">
                                            <div className="col-8 control-label text-dark fw-bold">¿Es usuario con acceso a Wartun?</div>
                                            <div className="col-4 d-flex">
                                                <div className="form-check form-switch ms-auto mb-0">
                                                    <input type="checkbox" className="form-check-input" name="IsEnabledUser" onChange={(e) => setIsEnabledUser(e.target.checked)} id="IsEnabledUser" checked={isEnabledUser} />
                                                    <label className="form-check-label" htmlFor="appHeaderInverse">&nbsp;</label>
                                                </div>
                                            </div>
                                        </div>

                                        {Roles &&
                                            <div className="form-floating mb-15px">
                                                <select className={"form-select " + (errors.role ? 'is-invalid' : '')} id="areaId" {...register("role", {
                                                    required: true,
                                                })}>
                                                    <option></option>
                                                    {Roles.map(r => <option key={r.id} value={r.NormalizeName}>{t("roles.rol_" + r.NormalizeName)}</option>)}
                                                </select>
                                                {errors.role?.type === 'required' && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    Rol
                                                </label>
                                            </div>
                                        }

                                        <div className="form-floating mb-15px">
                                            <input type="text" className={"form-control fs-15px " + (errors.phoneNumber ? 'is-invalid' : '')} id="phoneNumber" {...register("phoneNumber", {
                                                required: false,
                                            })} />
                                            {errors.phoneNumber?.type === 'required' && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.phone_number')}
                                            </label>
                                        </div>

                                        <div className="mb-20px">
                                            <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value={t("common.add")} />
                                            {user.error && <div className='text-center'>
                                                <span className='text-danger'>Ha ocurrido un problema: {user.errorMessage}</span></div>}
                                        </div>

                                    </form>

                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                </div>
            </div>
        </Loader>
    )
};

export default UserCreate;
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const AssetItem = ({ asset }) => {
    // console.log(asset);
    const { t } = useTranslation('common');
    return (
        <div className="result-item" key={asset.id}>
            <Link to="/extra/search" className="result-image" style={{ backgroundImage: 'url(/assets/img/gallery/gallery-51.jpg)' }} hidden></Link>
            <div className="result-info">
                <h4 className="title"><Link to={"/asset/detail?id=" + asset.id}>{asset.name}</Link></h4>
                <div>
                    <span className="badge bg-default text-gray-800 me-5px">{t("asset.type_" + asset.typeNormalizedName)}</span>
                    {!asset.isOutOfService && !asset.isBackup && !asset.isArchived && <span className="badge bg-green me-5px">{t("asset.in_service")}</span>}
                    {asset.isOutOfService && <span className="badge bg-red me-5px">{t("asset.out_of_service")}</span>}
                    {asset.isBackup && <span className="badge bg-blue me-5px">{t("asset.backup")}</span>}
                    {asset.isArchived && <span className="badge bg-red me-5px">{t("asset.archived")}</span>}
                    {asset.areaId && <span className="badge bg-default text-gray-800 me-5px">{t("common.area") + " " + asset.areaName}</span>}
                    {asset.maintenanceTasks === 0 &&
                        <div className="text-warning me-5px mt-10px">
                            <i className="fas fa-2x fa-fw fa-face-sad-tear me-5px"></i><strong>Sin mantenimiento programado</strong>
                        </div>
                    }
                    {/* face-sad-tear */}
                </div>
                {/* <p className="desc" hidden>
                    Nunc et ornare ligula. Aenean commodo lectus turpis, eu laoreet risus lobortis quis. Suspendisse vehicula mollis magna vel aliquet. Donec ac tempor neque, convallis euismod mauris. Integer dictum dictum ipsum quis viverra.
                </p> */}
                {/* <div className="btn-row" hidden>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Analytics"><i className="fa fa-fw fa-chart-bar"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="assets"><i className="fa fa-fw fa-list"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Configuration"><i className="fa fa-fw fa-cog"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Performance"><i className="fa fa-fw fa-tachometer"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Users"><i className="fa fa-fw fa-user"></i></Link>
                </div> */}
            </div>
            {/* <div className="result-price" hidden>
                $92,101 <small>PER MONTH</small>
                <Link to={"/asset/detail?id=" + asset.id} className="btn btn-gray-600 d-block w-100">{t("common.detail")}</Link>
            </div>  */}
        </div>
    )
}

export default AssetItem;
import React, { useEffect, useState } from 'react';
import moment from 'moment';
// import { Link } from 'react-router-dom';
import imgPdf from '../../../assets/img/pdf_icon.webp';
import imgFile from '../../../assets/img/file_icon.png';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from '.././../../components/panel/panel.jsx';
import PdfReader from '../../../pages/app/pdfreader.js';
import { useTranslation } from 'react-i18next';
import { EditFileName } from '../../../services/redux/fileSlice.js';
import { useDispatch, useSelector } from 'react-redux';
// import { use } from 'i18next';



const Viewer = ({ files, allowDelete, deleteAsyncCallback }) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);
    const fileReducer = useSelector(state => state.fileReducer);

    const [filesToRender, setFilesToRender] = useState(null);
    const [modalViewDetail, setModalViewDetail] = useState(false);
    const [modalViewDeleteFile, setModalViewDeleteFile] = useState(false);
    const [modalPdfViewer, setModalPdfViewer] = useState(false);
    const [selectedImage, setSeletedImage] = useState(null);
    const [editFileNameForm, setEditFileNameForm] = useState(null);
    const [newFileName, setNewFileName] = useState('');

    const saveNewFileName = (file) => {
        dispatch(EditFileName({ authToken: user.authToken, body: { tenantId: user.selectedTenant.id, fileId: file.result.id, newFileName: newFileName } })).then((response) => {
            console.log(file.result.id);
            console.log(newFileName);
            console.log(response);

            if (response.payload && response.payload.success) {
                setEditFileNameForm(null);
                let fileUpdated = { ...file, result: { ...file.result, name: newFileName } };
                console.log("fileUpdated", fileUpdated);
                let newFilesToRender = filesToRender.map((fileItem) => {
                    if (fileItem.result.id === file.result.id) {
                        return { ...fileItem, ...fileUpdated }
                    }
                    return fileItem;
                });
                console.log("newFilesToRender", newFilesToRender);
                setFilesToRender(newFilesToRender);
            }

        });


    }

    const renderSwitch = (file, index) => {
        switch (file.result.extension) {
            case '.jpg':
            case '.jpeg':
            case '.png':
                return (
                    <div className="image w-100">
                        <div className="image-inner d-flex justify-content-center" style={{ backgroundColor: '#eee' }}>
                            <Button color="link" style={{ width: '100%', padding: '0' }} onClick={() => { toggleModal('modalViewDetail'); setSeletedImage(file) }} >
                                <div className="image-inner" style={{
                                    cursor: 'pointer',
                                    backgroundImage: `url(${file.result.fileUrl})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPositionX: 'center',
                                    backgroundPositionY: 'center',
                                    backgroundSize: 'cover',
                                    height: '200px',
                                    width: '100%'
                                }}>&nbsp;</div>
                            </Button>
                            {!(editFileNameForm && file === editFileNameForm) ?
                                <p className="image-caption">
                                    {file.result.name}
                                    <span className="edit-icon ms-2" title='Editar nombre' onClick={() => { setNewFileName(file.result.name); setEditFileNameForm(file); }} style={{ cursor: 'pointer' }}>
                                        <i className="fa fa-pencil"></i>
                                    </span>
                                </p>
                                :
                                <p className="image-caption" style={{ width: '100%' }}>
                                    <>
                                        <input type="text" value={newFileName} style={{ width: '100%' }} onChange={(e) => setNewFileName(e.target.value)} />
                                        <br />
                                        <Button color="primary" size="sm" className="mt-2" title='Guardar' onClick={() => { saveNewFileName(file); }} style={{ cursor: 'pointer' }}>
                                            {t('common.save')}{/*  <i className="fa fa-save fa-lg"></i> */}
                                        </Button>

                                    </>
                                    <Button color="secondary" size="sm" className="mt-2 ms-2" title='Cancelar' onClick={() => setEditFileNameForm(null)} style={{ cursor: 'pointer' }}>
                                        {t('common.cancel')}{/*  <i className="fa fa-times fa-lg"></i> */}
                                    </Button>
                                    {(fileReducer.error && file === editFileNameForm) &&
                                        <>
                                            <br />
                                            <span className="text-danger bg-white p-1">Ocurrió un error: {fileReducer.errorMessage}</span>
                                        </>
                                    }
                                </p>
                            }
                        </div>

                        <div className="image-info p-1 pe-2">
                            <div className="d-flex align-items-center mb-2">
                                <div className="ms-auto">
                                    by {file.result.createdBy} - {moment(file.result.createdOn).format("DD/MM/YYYY")}
                                </div>
                            </div>
                            {allowDelete &&
                                <div className="d-flex align-items-center mb-2">
                                    <div className="ms-auto">

                                        <button className='btn btn-danger' onClick={() => { toggleModal('modalViewDeleteFile'); setSeletedImage(file) }}> Eliminar <i className="fa fa-trash"></i> </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                )
            case '.pdf':
                return (
                    <>
                        <div className="image w-100">
                            <div className="image-inner d-flex justify-content-center" style={{ backgroundColor: '#eee' }}>
                                {/* <a onClick={() => setSeletedImage(file)}
                                        href={{ pathname: file.result.fileUrl }}
                                        target="_blank"
                                        download={file.result.name}
                                    >
                                        <img src={imgPdf} alt="" />
                                    </a> */}
                                <Button color="link" onClick={() => { toggleModal('modalPdfViewer'); setSeletedImage(file) }} >
                                    <img src={imgPdf} alt="" />
                                </Button>

                                {!(editFileNameForm && file === editFileNameForm) ?
                                    <p className="image-caption">
                                        {file.result.name}
                                        <span className="edit-icon ms-2" title='Editar nombre' onClick={() => { setNewFileName(file.result.name); setEditFileNameForm(file); }} style={{ cursor: 'pointer' }}>
                                            <i className="fa fa-pencil"></i>
                                        </span>
                                    </p>
                                    :
                                    <p className="image-caption" style={{ width: '100%' }}>
                                        <>
                                            <input type="text" value={newFileName} style={{ width: '100%' }} onChange={(e) => setNewFileName(e.target.value)} />
                                            <br />
                                            <Button color="primary" size="sm" className="mt-2" title='Guardar' onClick={() => { saveNewFileName(file); }} style={{ cursor: 'pointer' }}>
                                                {t('common.save')}{/*  <i className="fa fa-save fa-lg"></i> */}
                                            </Button>

                                        </>
                                        <Button color="secondary" size="sm" className="mt-2 ms-2" title='Cancelar' onClick={() => setEditFileNameForm(null)} style={{ cursor: 'pointer' }}>
                                            {t('common.cancel')}{/*  <i className="fa fa-times fa-lg"></i> */}
                                        </Button>
                                        {(fileReducer.error && file === editFileNameForm) &&
                                            <>
                                                <br />
                                                <span className="text-danger bg-white p-1">Ocurrió un error: {fileReducer.errorMessage}</span>
                                            </>
                                        }
                                    </p>
                                }
                            </div>
                            <div className="image-info p-1 pe-2">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="ms-auto">
                                        by {file.result.createdBy} - {moment(file.result.createdOn).format("DD/MM/YYYY")}
                                    </div>
                                </div>
                                {allowDelete &&
                                    <div className="d-flex align-items-center mb-2">
                                        <div className="ms-auto">
                                            <button className='btn btn-danger' onClick={() => { toggleModal('modalViewDeleteFile'); setSeletedImage(file) }}> Eliminar <i className="fa fa-trash"></i> </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                )
            default:
                return (
                    <div className="image w-100">
                        <div className="image-inner d-flex justify-content-center">
                            <a
                                href={{ pathname: file.result.fileUrl }}
                                target="_blank"
                                rel="noreferrer"
                                download={file.result.name}
                            >
                                <img src={imgFile} alt="" />
                            </a>
                            {/* <Link to={{ pathname: file.result.fileUrl }} download={file.result.name} target="_blank">
                                    <img src={imgFile} alt="" />
                                </Link> */}
                            {!(editFileNameForm && file === editFileNameForm) ?
                                <p className="image-caption">
                                    {file.result.name}
                                    <span className="edit-icon ms-2" title='Editar nombre' onClick={() => { setNewFileName(file.result.name); setEditFileNameForm(file); }} style={{ cursor: 'pointer' }}>
                                        <i className="fa fa-pencil"></i>
                                    </span>
                                </p>
                                :
                                <p className="image-caption" style={{ width: '100%' }}>
                                    <>
                                        <input type="text" value={newFileName} style={{ width: '100%' }} onChange={(e) => setNewFileName(e.target.value)} />
                                        <br />
                                        <Button color="primary" size="sm" className="mt-2" title='Guardar' onClick={() => { saveNewFileName(file); }} style={{ cursor: 'pointer' }}>
                                            {t('common.save')}{/*  <i className="fa fa-save fa-lg"></i> */}
                                        </Button>

                                    </>
                                    <Button color="secondary" size="sm" className="mt-2 ms-2" title='Cancelar' onClick={() => setEditFileNameForm(null)} style={{ cursor: 'pointer' }}>
                                        {t('common.cancel')}{/*  <i className="fa fa-times fa-lg"></i> */}
                                    </Button>
                                    {(fileReducer.error && file === editFileNameForm) &&
                                        <>
                                            <br />
                                            <span className="text-danger bg-white p-1">Ocurrió un error: {fileReducer.errorMessage}</span>
                                        </>
                                    }
                                </p>
                            }
                            {/* <p className="image-caption">
                                    {file.result.name}
                                </p> */}
                        </div>
                        <div className="image-info p-1 pe-2">
                            <div className="d-flex align-items-center mb-2">
                                <div className="ms-auto">
                                    by {file.result.createdBy} - {moment(file.result.createdOn).format("DD/MM/YYYY")}
                                </div>
                            </div>
                            {allowDelete &&
                                <div className="d-flex align-items-center mb-2">
                                    <div className="ms-auto">
                                        <button className='btn btn-danger' onClick={() => { toggleModal('modalViewDeleteFile'); setSeletedImage(file) }}> Eliminar <i className="fa fa-trash"></i> </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                )
        }
    }

    const fileIcons = {
        ".xls": <i className="fa fa-file-excel fa-10x" style={{ color: 'green' }}></i>,
        ".doc": <i className="fa fa-file-word fa-10x" style={{ color: 'word' }}></i>,
        ".ppt": <i className="fa fa-file-powerpoint fa-10x" style={{ color: 'orange' }}></i>,
        ".pdf": <i className="fa fa-file-pdf fa-10x" style={{ color: 'red' }}></i>,
        ".jpg": <i className="fa fa-file-image fa-10x" style={{ color: 'goldenrod' }}></i>,
        ".zip": <i className="fa fa-file-zipper fa-10x" style={{ color: 'gold' }}></i>,
        ".mp4": <i className="fa fa-file-video fa-10x" style={{ color: 'violet' }}></i>,
        "none": <i className="far fa-file fa-10x" style={{ color: 'gray' }}></i>,
    };

    const renderLink = (file, index) => {
        return (
            <div className="image w-100">
                <div className="image-inner d-flex justify-content-center">
                    <a
                        href={file.result.fileUrl}
                        target="_blank"
                        rel="noreferrer"
                        download={file.result.name}
                        style={{ padding: '40px' }}
                    >
                        {/* <img src={imgFile} alt="" /> */}
                        {fileIcons[file.result.extension] || fileIcons["default"]}
                    </a>
                    {/* <Link to={{ pathname: file.result.fileUrl }} download={file.result.name} target="_blank">
                                    <img src={imgFile} alt="" />
                                </Link> */}
                    {!(editFileNameForm && file === editFileNameForm) ?
                        <p className="image-caption">
                            {file.result.name}
                            <span className="edit-icon ms-2" title='Editar nombre' onClick={() => { setNewFileName(file.result.name); setEditFileNameForm(file); }} style={{ cursor: 'pointer' }}>
                                <i className="fa fa-pencil"></i>
                            </span>
                        </p>
                        :
                        <p className="image-caption" style={{ width: '100%' }}>
                            <>
                                <input type="text" value={newFileName} style={{ width: '100%' }} onChange={(e) => setNewFileName(e.target.value)} />
                                <br />
                                <Button color="primary" size="sm" className="mt-2" title='Guardar' onClick={() => { saveNewFileName(file); }} style={{ cursor: 'pointer' }}>
                                    {t('common.save')}{/*  <i className="fa fa-save fa-lg"></i> */}
                                </Button>

                            </>
                            <Button color="secondary" size="sm" className="mt-2 ms-2" title='Cancelar' onClick={() => setEditFileNameForm(null)} style={{ cursor: 'pointer' }}>
                                {t('common.cancel')}{/*  <i className="fa fa-times fa-lg"></i> */}
                            </Button>
                            {(fileReducer.error && file === editFileNameForm) &&
                                <>
                                    <br />
                                    <span className="text-danger bg-white p-1">Ocurrió un error: {fileReducer.errorMessage}</span>
                                </>
                            }
                        </p>
                    }
                    {/* <p className="image-caption">
                                    {file.result.name}
                                </p> */}
                </div>
                <div className="image-info p-1 pe-2">
                    <div className="d-flex align-items-center mb-2">
                        <div className="ms-auto">
                            by {file.result.createdBy} - {moment(file.result.createdOn).format("DD/MM/YYYY")}
                        </div>
                    </div>
                    {allowDelete &&
                        <div className="d-flex align-items-center mb-2">
                            <div className="ms-auto">
                                <button className='btn btn-danger' onClick={() => { toggleModal('modalViewDeleteFile'); setSeletedImage(file) }}> Eliminar <i className="fa fa-trash"></i> </button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }


    const toggleModal = (name) => {
        switch (name) {
            case 'modalViewDetail':
                setModalViewDetail(!modalViewDetail);
                break;
            case 'modalViewDeleteFile':
                setModalViewDeleteFile(!modalViewDeleteFile);
                break;
            case 'modalPdfViewer':
                setModalPdfViewer(!modalPdfViewer);
                break;
            default:
                break;
        }
    }

    const deleteFile = async (fileId) => {
        try {
            //console.log(fileId);
            await deleteAsyncCallback(fileId);
            toggleModal('modalViewDeleteFile');
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        setFilesToRender(files);
    }, [files]);

    return (
        fileReducer.isFetching ?
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            :
            <>
                <div className='row'>
                    <div className="col-xl-12">
                        <div id="gallery" className="gallery row gx-0">
                            {filesToRender && filesToRender.map((file, index) =>
                                <div key={file.id} className='col-lg-3 col-md-8'>
                                    {
                                        file.result.originalName === "EXTERNAL_LINK" ?
                                            renderLink(file, index)
                                            :
                                            renderSwitch(file, index)
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {selectedImage &&
                    <Modal size="lg" style={{ maxWidth: '600px', width: '100%' }} isOpen={modalViewDetail} toggle={() => toggleModal('modalViewDetail')} >
                        <ModalHeader toggle={() => toggleModal('modalViewDetail')}>Detalle</ModalHeader>
                        <ModalBody>
                            <Panel>
                                <PanelHeader noButton={true}>
                                    {moment(selectedImage.result.createdOn).format("DD/MM/YYYY")} - {selectedImage.result.name}
                                </PanelHeader>
                                <PanelBody>
                                    <img src={selectedImage.result.fileUrl} className="w-100" alt="" />
                                </PanelBody>
                            </Panel>
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-white" onClick={() => toggleModal('modalViewDetail')}>Cerrar</button>
                        </ModalFooter>
                    </Modal>
                }
                {selectedImage &&
                    <Modal size="md" style={{ maxWidth: '300px', width: '100%' }} isOpen={modalViewDeleteFile} toggle={() => toggleModal('modalViewDeleteFile')} >
                        <ModalHeader toggle={() => toggleModal('modalViewDeleteFile')}>Eliminar archivo</ModalHeader>
                        <ModalBody>
                            <span>¿Esta seguro que desea eliminar el archivo <b>{selectedImage.result.name}</b>, creado por <b>{selectedImage.result.createdBy}</b> el <b>{moment(selectedImage.result.createdOn).format("DD/MM/YYYY")}</b> ?</span>
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-danger" onClick={() => deleteFile(selectedImage.result.id)}>Si, borrar!</button>
                            <button className="btn btn-white" onClick={() => toggleModal('modalViewDeleteFile')}>No</button>
                        </ModalFooter>
                    </Modal>
                }

                {modalPdfViewer &&
                    <Modal size="lg" style={{ maxWidth: '80vw', width: '100%' }} isOpen={modalPdfViewer} toggle={() => toggleModal('modalPdfViewer')} >
                        <ModalHeader toggle={() => toggleModal('modalPdfViewer')}>
                            {selectedImage.result.name}
                            <a className='btn btn-primary ms-3'
                                href={{ pathname: selectedImage.result.fileUrl }}
                                target="_blank"
                                rel="noreferrer"
                                download={selectedImage.result.name}
                            >
                                {t("common.download")} <i className="fa fa-download fa-lg"></i>
                            </a>
                        </ModalHeader>
                        <ModalBody>
                            <PdfReader file={selectedImage.result.fileUrl} />
                        </ModalBody>
                        <ModalFooter>
                            <a className='btn btn-primary'
                                href={{ pathname: selectedImage.result.fileUrl }}
                                target="_blank"
                                rel="noreferrer"
                                download={selectedImage.result.name}
                            >
                                {t("common.download")} <i className="fa fa-download fa-lg"></i>
                            </a>
                            <button className="btn btn-white" onClick={() => toggleModal('modalPdfViewer')}>{t("common.close")}</button>
                        </ModalFooter>
                    </Modal>
                }
            </>
    );
};

export default Viewer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { PURGE } from "redux-persist";
import { ChangeTenant } from "../redux/userSlice";

const initialState = {
    list: null,
    fullList: null,
    dataCount: null,
    dataCountFiltered: null,
    selectedAsset: null,
    lastSync: null,
    isFetching: false,
    error: false,
    errorMessage: "",
    status: "",
}

export const GetAsset = createAsyncThunk('assets/fetchGetAsset', async ({ authToken, params }) => {
    let response = await api.getAsset(authToken, params);
    return response;
});

export const ListAssets = createAsyncThunk('assets/fetchListAssets', async ({authToken, body}) => {
    let response = await api.listAssets(authToken, body);
    return response;
});

export const FullListAssets = createAsyncThunk('area/fetchFullListAssets', async ({ authToken, tenantId }) => {
    let body = {
        "tenantId": tenantId,
        "sortBy": null,
        "sortDir": true,
        "skip": 0,
        "take": null
    };
    let response = await api.listAssets(authToken, body);
    return response;
});

export const CreateAsset = createAsyncThunk('assets/fetchCreateAsset', async ({ authToken, body }) => {
    let response = await api.createAsset(authToken, body);
    return response;
});

export const EditAsset = createAsyncThunk('assets/fetchEditAsset', async ({ authToken, body }) => {
    let response = await api.editAsset(authToken, body);
    return response;
});

export const DeleteAsset = createAsyncThunk('assets/fetchDeleteAsset', async ({ authToken, body }) => {
    let response = await api.deleteAsset(authToken, body);
    return response;
});

export const SetHealthStatus = createAsyncThunk('assets/fetchSetHealthStatus', async ({ authToken, body }) => {
    let response = await api.setHealthStatus(authToken, body);
    return response;
});

const assetSlice = createSlice({
    name: "asset",
    initialState,
    reducers: {

    },
    extraReducers: {

        [GetAsset.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [GetAsset.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedAsset = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [GetAsset.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [ListAssets.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [ListAssets.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.list = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [ListAssets.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [FullListAssets.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [FullListAssets.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.fullList = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [FullListAssets.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [CreateAsset.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [CreateAsset.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedAsset = payload.data;
            state.fullList = null;
        },
        [CreateAsset.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [EditAsset.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [EditAsset.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedAsset = payload.data;
            state.fullList = null;
        },
        [EditAsset.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [DeleteAsset.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [DeleteAsset.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedAsset = null;
            state.fullList = null;
        },
        [DeleteAsset.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [SetHealthStatus.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [SetHealthStatus.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            if (payload.data.healthStatus != 0)
            state.selectedAsset = {...state.selectedAsset, healthStatus: payload.data};
            state.fullList = null;
        },
        [SetHealthStatus.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [ChangeTenant.fulfilled]: (state, { payload }) => {
            return initialState;
            // state.isFetching = false;
            // state.error = false;
            // state.errorMessage = '';
            // state.fullList = null;
            // state.dataCount = null;
            // state.dataCountFiltered = null;
        },


        [PURGE]: (state) => {
            return initialState;
        },
    }
})

export default assetSlice.reducer; 
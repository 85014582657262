import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { InputGroup, Input, Button, ButtonDropdown } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppSettings } from '../../../config/app-settings.js';
import { useTranslation } from "react-i18next";
import { GetTenant, GetTenantStatistics } from '../../../services/redux/backend/tenantSlice.js';
import { use } from 'i18next';
import { Panel, PanelHeader, PanelFooter, PanelBody } from '.././../../components/panel/panel.jsx';
import DatePicker, { registerLocale } from "react-datepicker";


const Dashboard = () => {
    const { t } = useTranslation('common');

    const context = useContext(AppSettings);

    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);
    const backendTenantReducer = useSelector(state => state.backendTenantReducer);
    // how to set new Date() minus 30 days?



    const today = new Date();
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - 30);
    const [startDate, setStartDate] = useState(thirtyDaysAgo);
    const [endDate, setEndDate] = useState(today);

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);

        getTenantStatistics();
    }, []);

    const getTenant = async () => {
        console.log("getTenant");

        if (user?.authToken != "") {
            const requestData = {
                "tenantId": user.selectedTenant.id,
            };

            await dispatch(GetTenant({ authToken: user.authToken, body: requestData })).then((response) => {
                getTenantStatistics();
            });
        }
    };

    const getTenantStatistics = async () => {
        console.log("getTenantStatistics");
        console.log("user.selectedTenant.id", user.selectedTenant.id);
        console.log("startDate", startDate);
        console.log("getTenantStatistics");
        if (user?.authToken != "") {
            const requestData = {
                "tenantId": user.selectedTenant.id,
                "startDate": startDate,
                "endDate": endDate,
            };

            await dispatch(GetTenantStatistics({ authToken: user.authToken, params: requestData })).then((response) => {
                console.log("response");
                console.log(response);
            });
        }
    };

    return (
        <div>
            <ol className="breadcrumb float-xl-end" hidden>
                <li className="breadcrumb-item"><Link to="/dashboard">Inicio</Link></li>
                <li className="breadcrumb-item active">{t('common.settings')}</li>
            </ol>
            <h1 className="page-header">
                Cuenta {user.selectedTenant.name}
                {/* {t('common.settings')} */}
            </h1>



            <div className="row">
                {backendTenantReducer.statistics && backendTenantReducer.statistics.length != 0 &&
                    <div className="row col-xl-12">
                        <div className="col-xl-6">
                            <Panel>
                                <PanelHeader noButton={true}>
                                    <div className="d-flex align-items-center">
                                        Estadísticas de Uso
                                    </div>
                                </PanelHeader>
                                <PanelBody>
                                    <div className="row col-xl-12">
                                        <div className="col-xs-12 col-sm-4">
                                            <div>
                                                <div className="form-floating mb-15px">
                                                    <DatePicker dateFormat="dd-MM-yyyy" className="form-control fs-15px pt-25px" selected={startDate} onChange={(date) => setStartDate(date)} />
                                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-11px pt-1px mb-10px pb-30px">
                                                        {t('common.from')} {/* {startDate.toString()} */}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col-xs-12 col-sm-4">
                                            <div>
                                                <div className="form-floating mb-15px ">
                                                    <DatePicker dateFormat="dd-MM-yyyy" className="form-control fs-15px pt-25px" selected={endDate} onChange={(date) => setEndDate(date)} />
                                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-11px pt-1px mb-10px pb-30px">
                                                        {t('common.to')} {/* {endDate.toString()} */}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-4">
                                            <div className="form-floating mb-15px ">
                                                <Button className='btn btn-blue d-block mb-10px' onClick={() => getTenantStatistics()}>Actualizar</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <h4 className='mt-2 mb-4'>Tareas y Órdenes de trabajo</h4>
                                    {backendTenantReducer.statistics.filter(i => i.type === 'TASKS').map((item, index) =>
                                        <div key={index}>
                                            <p>{item.name}: <b>{item.value}</b> </p>
                                        </div>
                                    )}
                                </PanelBody>
                            </Panel>
                        </div>
                        <div className="col-xl-6">
                            <Panel>
                                <PanelHeader noButton={true}>
                                    <div className="d-flex align-items-center">
                                        {t('common.general_data')}
                                    </div>
                                </PanelHeader>
                                <PanelBody>
                                    <h4 className='mt-2 mb-4'>{t('menu.assets')}</h4>
                                    {backendTenantReducer.statistics.filter(i => i.type === 'ASSETS').map((item, index) =>
                                        <div key={index}>
                                            <p>{item.name}: <b>{item.value}</b> </p>
                                        </div>
                                    )}
                                    <h4 className='mt-4 mb-4'>{t('common.users')}</h4>
                                    {backendTenantReducer.statistics.filter(i => i.type === 'USERS').map((item, index) =>
                                        <div key={index}>
                                            <p>{item.name}: <b>{item.value}</b> </p>
                                        </div>
                                    )}
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
};

export default Dashboard;
import React, { useContext, useState, useEffect } from 'react';
import { Panel, PanelHeader, PanelFooter, PanelBody } from '.././../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { GetAudit, SendAudit, DiscardAudit, CloseAudit, DeleteAudit } from '../../../services/redux/auditSlice';
import Upload from '../../../components/app/file/upload.js';
import Viewer from '../../../components/app/file/viewer.js';
import { postfileData } from '../../../services/redux/fileSlice';
import { FileTypes, HotelTenantType, PrivateNeighborhoodTenantType } from '../../../utils/generalConstants';
import { useTranslation } from "react-i18next";
import SweetAlert from 'react-bootstrap-sweetalert';

import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { CreateMaintenanceTask } from '../../../services/redux/maintenanceTaskSlice';
import { FullListFunctionalUnits } from '../../../services/redux/functionalUnitSlice';
import { FullListRooms } from '../../../services/redux/roomSlice';
import { FullListAssets } from '../../../services/redux/assetSlice';
import { FullListAreas } from '../../../services/redux/areaSlice';
import { FullListSuppliers } from '../../../services/redux/supplierSlice';
import { MaintenanceTaskTypes, MaintenanceTaskPriorityLevel, MaintenanceTasksIdPending } from '../../../utils/generalConstants';
import { Loader } from '../../widget/loader.js';
import { renderAreasOptions } from '../../../components/app/area/common.js';

const TaskReviewDetail = () => {
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const history = useHistory();
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const audit = useSelector(state => state.auditReducer);

    const query = new URLSearchParams(window.location.search);

    const [showCreateTaksForm, setShowCreateTaksForm] = useState(false);
    const [modalAddFiles, setModalAddFiles] = useState(false);
    const [modalViewDeleteFile, setModalViewDeleteFile] = useState(false);
    const [modalViewDeleteAudit, setModalViewDeleteAudit] = useState(false);
    const [sweetAlertSendAudit, setSweetAlertSendAudit] = useState(false);
    const [sweetAlertDiscardAudit, setSweetAlertDiscardAudit] = useState(false);
    // const [sweetAlertCloseAudit, setSweetAlertCloseAudit] = useState(false);

    const getAudit = async () => {
        const requestData = {
            "tenantId": user.selectedTenant.id,
            "id": query.get("id")
        };
        await dispatch(GetAudit({ authToken: user.authToken, params: requestData })).then((response) => {
        });
    };

    const sendAudit = async (id) => {
        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": id,
        }
        await dispatch(SendAudit({ authToken: user.authToken, body: requestData })).then((response) => {
            toggleSweetAlert("sweetAlertSendAudit");
            response && response.payload &&
                history.push("/taskreview/list");
        });
    }

    const discardAudit = async (id) => {
        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": id,
        }
        await dispatch(DiscardAudit({ authToken: user.authToken, body: requestData })).then((response) => {
            toggleSweetAlert("sweetAlertDiscardAudit");
            response && response.payload &&
                history.push("/taskreview/list");
        });
    }

    // const closeAudit = async (id) => {
    //     let requestData = {
    //         "tenantId": user.selectedTenant.id,
    //         "id": id,
    //     }
    //     await dispatch(CloseAudit({ authToken: user.authToken, body: requestData })).then((response) => {
    //         toggleSweetAlert("sweetAlertCloseAudit");
    //         response && response.payload &&
    //             history.push("/task/create");
    //     });
    // }

    const deleteAudit = async (id) => {
        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": id,
        }
        await dispatch(DeleteAudit({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload &&
                history.push("/taskreview/list");
        });
    }

    const onUploadSubmit = async (data) => {
        data.append("TenantId", user.selectedTenant.id);
        data.append("AgentId", user.selectedTenant.agentId);
        data.append("CreatedOn", "2015-08-05T19:33:26Z");
        data.append("ReferenceId", audit.selectedAudit.id);
        data.append("FileTypeId", FileTypes.filter(f => f.NormalizeName === 'TASKREVIEW IMAGE')[0].id);

        await dispatch(postfileData({ authToken: user.authToken, body: data })).then((response) => {
            setModalAddFiles(false);
            getAudit();
        });
    };

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        getAudit();
    }, []);

    const toggleModal = (name) => {
        switch (name) {
            case 'modalAddFiles':
                setModalAddFiles(!modalAddFiles);
                break;
            case 'modalViewDeleteFile':
                setModalViewDeleteFile(!modalViewDeleteFile);
                break;
            case 'modalViewDeleteAudit':
                setModalViewDeleteAudit(!modalViewDeleteAudit);
                break;
            default:
                break;
        }
    }

    const toggleSweetAlert = (name) => {
        switch (name) {
            case 'sweetAlertSendAudit':
                setSweetAlertSendAudit(!sweetAlertSendAudit);
                break;
            case 'sweetAlertDiscardAudit':
                setSweetAlertDiscardAudit(!sweetAlertDiscardAudit);
                break;
            // case 'sweetAlertCloseAudit':
            //     setSweetAlertCloseAudit(!sweetAlertCloseAudit);
            //     break;
            default:
                break;
        }
    }

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }
    return (
        <Loader isLoading={audit.isFetching}>
            <div>
                {audit.selectedAudit &&
                    <div>
                        <ol className="breadcrumb float-xl-end" hidden>
                            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/taskreview/list">Relevamiento de tareas</Link></li>
                            <li className="breadcrumb-item active">{audit.selectedAudit.name}</li>
                        </ol>
                        <h1 className="page-header">{audit.selectedAudit.name} <small>{/*{audit.selectedAudit.typeNormalizedName}*/}</small></h1>

                        <div className="d-flex align-items-center mb-2">
                            <div className="ms-auto">
                                <Link to="/taskreview/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                                {/* <Link to="/taskreview/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("common.add")}</Link> */}
                            </div>
                        </div>

                        {!showCreateTaksForm ?
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="col-xl-12">
                                        <Panel>
                                            <PanelHeader noButton={true}>
                                                <div className="d-flex justify-content-between">
                                                    {t("common.general_data")}
                                                    {(user.selectedTenant.agentId === audit.selectedAudit.createdById &&
                                                        audit.selectedAudit.stateNormalizedName === "PENDING") &&
                                                        <div>
                                                            <Link className="btn btn-success btn-sm btn-rounded px-3 rounded-pill" to={{
                                                                pathname: "/taskreview/edit",
                                                                state: {
                                                                    audit: audit.selectedAudit
                                                                }
                                                            }}> {t("common.edit")}</Link>
                                                            <button
                                                                className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-2"
                                                                onClick={() => toggleModal('modalAddFiles')}>
                                                                {t("common.add_files")}
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </PanelHeader>
                                            <PanelBody>
                                                {audit.selectedAudit.functionalUnit &&
                                                    <p>{t("common.functional_unit")}: {audit.selectedAudit.functionalUnit.number}
                                                        {/* &nbsp;<Link to={"/functionalunit/detail?id=" + audit.selectedAudit.functionalUnit.id + "&taskid=" + audit.selectedAudit.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.functional_unit")}</Link> */}
                                                    </p>
                                                }
                                                {/* TODO */}
                                                {audit.selectedAudit.room &&
                                                    <p>{t("common.room")}: {audit.selectedAudit.room.name}
                                                        {/* &nbsp;<Link to={"/room/detail?id=" + audit.selectedAudit.room.id + "&taskid=" + audit.selectedAudit.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.room")}</Link> */}
                                                    </p>
                                                }
                                                {/* <p>{t("common.description")}: {audit.selectedAudit.name}</p> */}
                                                <p><b>{t("common.description")}:</b></p>
                                                <p className='fs-20px'>{audit.selectedAudit.name}</p>
                                                {audit.selectedAudit.description &&
                                                    <p>{t("common.detail")}: {audit.selectedAudit.description}</p>
                                                }
                                                {audit.selectedAudit.areaName &&
                                                    <p>{t("common.area")}: {audit.selectedAudit.areaName}</p>
                                                }
                                                {audit.selectedAudit.functionalUnitNumber &&
                                                    <p>{t("common.functional_unit")}: {audit.selectedAudit.functionalUnitNumber}&nbsp;<Link to={"/functionalunit/detail?id=" + audit.selectedAudit.functionalUnitId + "&taskreviewid=" + audit.selectedAudit.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.functional_unit")}</Link></p>
                                                }{/* TODO */}
                                                {audit.selectedAudit.roomName &&
                                                    <p>{t("common.room")}: {audit.selectedAudit.roomName}&nbsp;<Link to={"/room/detail?id=" + audit.selectedAudit.roomId + "&taskreviewid=" + audit.selectedAudit.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.room")}</Link></p>
                                                }
                                                <p>{t("common.type")}: {t("identifyTask.type_" + audit.selectedAudit.typeNormalizedName)}</p>
                                                <p>{t("common.state")}: {t("identifyTask.state_" + audit.selectedAudit.stateNormalizedName)}</p>
                                                <p>{t("common.created_by")}: {audit.selectedAudit.createdBy}</p>
                                                <p>{t("common.createdOn")}: {moment(audit.selectedAudit.createdOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm:ss")}</p>
                                            </PanelBody>
                                            <PanelFooter>
                                                <div className="d-flex align-items-center">
                                                    <div className="ms-auto">
                                                        {(user.selectedTenant.agentRoles && user.selectedTenant.agentRoles.toUpperCase() === "MANAGER" &&
                                                            audit.selectedAudit.stateNormalizedName === "SENT") &&
                                                            <>
                                                                <button className='btn btn-warning me-1' onClick={() => { toggleSweetAlert('sweetAlertDiscardAudit') }}> {t("common.discard")}  </button>
                                                                {/* <button className='btn btn-primary me-1' onClick={() => { toggleSweetAlert('sweetAlertCloseAudit') }}> {t("common.createTask")}  </button> */}
                                                                <button className='btn btn-primary me-1' onClick={() => { setShowCreateTaksForm(true) }}> {t("common.createTask")}  </button>
                                                            </>
                                                        }
                                                        {user.selectedTenant.agentId === audit.selectedAudit.createdById &&
                                                            audit.selectedAudit.stateNormalizedName === "PENDING" && <>
                                                                <button className='btn btn-primary me-1' onClick={() => { toggleSweetAlert('sweetAlertSendAudit') }}> {t("common.send")} </button>
                                                                <button className='btn btn-danger' onClick={() => { toggleModal('modalViewDeleteAudit') }}> {t("common.delete")} <i className="fa fa-trash"></i> </button>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div>
                                                    {audit.error && <div className='text-center mt-10px'>
                                                        <span className='text-danger'>Ha ocurrido un problema: {audit.errorMessage}</span></div>}
                                                </div>
                                            </PanelFooter>
                                        </Panel>
                                    </div>
                                </div>
                                <div className="col-xl-3">
                                    <Upload onSubmit={onUploadSubmit} isOpen={modalAddFiles} toggleModal={() => toggleModal('modalAddFiles')}  ></Upload>
                                </div>
                                <Viewer files={audit.selectedAudit.files} />
                            </div>
                            :
                            <div className="row">
                                <TaskForm taskReview={audit} />
                            </div>
                        }

                        <Modal size="md" style={{ maxWidth: '300px', width: '100%' }} isOpen={modalViewDeleteAudit} toggle={() => toggleModal('modalViewDeleteAudit')} >
                            <ModalHeader toggle={() => toggleModal('modalViewDeleteAudit')}>Eliminar Relevamiento</ModalHeader>
                            <ModalBody>
                                <span>¿Esta seguro que desea eliminar el relevamiento <b>{audit.selectedAudit.name}</b>, creado por <b>{audit.selectedAudit.createdBy}</b> el <b>{moment(audit.selectedAudit.createdOn).format("DD/MM/YYYY")}</b> ?</span>
                            </ModalBody>
                            <ModalFooter>
                                <button className="btn btn-danger" onClick={() => deleteAudit(audit.selectedAudit.id)}>Si, borrar!</button>
                                <button className="btn btn-white" onClick={() => toggleModal('modalViewDeleteAudit')}>No</button>
                            </ModalFooter>
                        </Modal>

                        {sweetAlertSendAudit &&
                            <SweetAlert showCancel
                                confirmBtnText="Continuar"
                                confirmBtnBsStyle="primary"
                                cancelBtnText="Cancelar"
                                cancelBtnBsStyle="default"
                                title="¿Está seguro?"
                                onConfirm={() => {
                                    setSweetAlertSendAudit(false);
                                    sendAudit(audit.selectedAudit.id);
                                }}
                                onCancel={() => toggleSweetAlert("sweetAlertSendAudit")}
                            >
                                Esta acción no puede ser revertida!
                            </SweetAlert>
                        }

                        {sweetAlertDiscardAudit &&
                            <SweetAlert showCancel
                                confirmBtnText="Continuar"
                                confirmBtnBsStyle="primary"
                                cancelBtnText="Cancelar"
                                cancelBtnBsStyle="default"
                                title="¿Está seguro?"
                                onConfirm={() => {
                                    setSweetAlertDiscardAudit(false);
                                    discardAudit(audit.selectedAudit.id);
                                }}
                                onCancel={() => toggleSweetAlert("sweetAlertDiscardAudit")}
                            >
                                Esta acción no puede ser revertida!
                            </SweetAlert>
                        }

                        {/* {sweetAlertCloseAudit &&
                            <SweetAlert showCancel
                                confirmBtnText="Continuar"
                                confirmBtnBsStyle="primary"
                                cancelBtnText="Cancelar"
                                cancelBtnBsStyle="default"
                                title="¿Está seguro?"
                                // onConfirm={() => closeAudit(audit.selectedAudit.id)}
                                onConfirm={() => {
                                    setSweetAlertCloseAudit(false);
                                    setShowCreateTaksForm(true);
                                }}

                                onCancel={() => toggleSweetAlert("sweetAlertCloseAudit")}
                            >
                                Esta acción no puede ser revertida!
                            </SweetAlert>
                        } */}

                    </div>
                }
            </div >
        </Loader>
    )
};

export default TaskReviewDetail;



export const TaskForm = ({ taskReview }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const task = useSelector(state => state.maintenanceTaskReducer)
    const assets = useSelector(state => state.assetReducer)
    const functionalUnits = useSelector(state => state.functionalUnitReducer)
    const rooms = useSelector(state => state.roomReducer)
    const areas = useSelector(state => state.areaReducer)
    const suppliers = useSelector(state => state.supplierReducer)

    const [startDate, setStartDate] = useState(new Date());
    const [requireValidation, setRequireValidation] = useState(false);

    useEffect(() => {

        !functionalUnits.fullList &&
            dispatch(FullListFunctionalUnits({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            })

        !rooms.fullList &&
            dispatch(FullListRooms({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            })

        !assets.fullList &&
            dispatch(FullListAssets({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            })

        !areas.fullList &&
            dispatch(FullListAreas({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

        !suppliers.fullList &&
            dispatch(FullListSuppliers({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

    }, []);

    const { register, handleSubmit, formState: { errors, isValid }, trigger } = useForm();

    const handleInputChange = (event) => {
        trigger(event.target.name); // Validar el campo correspondiente
        //trigger();
    };

    useEffect(() => {
        trigger();
    }, [trigger]);

    const onSubmit = async (data) => {

        let requestData = {
            "tenantId": user.selectedTenant.id,
            "typeId": data.taskTypeId,
            "description": data.description,
            "detail": data.detail,
            "responsibleId": user.selectedTenant.agentId,
            "priorityLevelId": data.priorityLevelId,
            "requireValidation": requireValidation,
            "areaId": (data.areaId != "") ? data.areaId : null,
            "assetId": (data.assetId != "") ? data.assetId : null,
            "functionalUnitId": (data.functionalUnitId != "") ? data.functionalUnitId : null,
            "roomId": (data.roomId != "") ? data.roomId : null,
            "supplierId": (data.supplierId != "") ? data.supplierId : null,
            "estimatedCost": (data.estimatedCost != "") ? data.estimatedCost : null,
            "estimatedTime": (data.estimatedTime != "") ? data.estimatedTime : null,
            "maintenanceTaskStateId": MaintenanceTasksIdPending,
            "schedule": {
                "scheduleTypeId": 1,
                "interval": 1,
                "startDate": startDate,
                "endDate": startDate,
                "createdById": user.selectedTenant.agentId,
            },
            "createdById": user.selectedTenant.agentId,
            "taskReviewId": taskReview.selectedAudit.id
        }
        //console.log(requestData);
        await dispatch(CreateMaintenanceTask({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload && response.payload.data &&
                history.push("/task/detail?id=" + response.payload.data.id);
        });
    };
    // console.log(errors);

    return (
        <Loader isLoading={task.isFetching}>
            <div className="col-xl-7">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader noButton={true}>
                            <div className="d-flex align-items-center">
                                {t("common.createTask")}
                            </div>
                        </PanelHeader>
                        <PanelBody>
                            <form onSubmit={handleSubmit(onSubmit)}>

                                {user?.selectedTenant?.tenantTypeId == PrivateNeighborhoodTenantType &&
                                    functionalUnits.fullList &&
                                    <div className="form-floating mb-15px">
                                        <select className={"form-select " + (errors.functionalUnitId?.type === 'required' ? 'is-invalid' : '')}
                                            id="functionalUnitId" {...register("functionalUnitId", {
                                                value: taskReview.selectedAudit.functionalUnitId
                                            })}
                                            onClick={handleInputChange}>
                                            <option></option>
                                            {functionalUnits.fullList.map(a => <option key={a.id} value={a.id}>{a.number}</option>)}
                                        </select>
                                        {errors.functionalUnitId?.type === 'required' && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                            {t('common.functional_unit')}
                                        </label>
                                    </div>
                                }

                                {user?.selectedTenant?.tenantTypeId == HotelTenantType &&
                                    rooms.fullList &&
                                    <div className="form-floating mb-15px">
                                        <select className={"form-select " + (errors.roomId?.type === 'required' ? 'is-invalid' : '')}
                                            id="roomId" {...register("roomId", {
                                                value: taskReview.selectedAudit.roomId
                                            })}
                                            onClick={handleInputChange}>
                                            <option></option>
                                            {rooms.fullList.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
                                        </select>
                                        {errors.roomId?.type === 'required' && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                            {t('common.room')}
                                        </label>
                                    </div>
                                }

                                <div className="form-floating mb-15px">
                                    <input type="text" maxLength={90} className="form-control fs-15px" id="description" {...register("description", {
                                        required: true,
                                        value: taskReview.selectedAudit.name
                                    })} />
                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                        {t('common.description')}
                                    </label>
                                </div>

                                <div className="form-floating mb-15px">
                                    <textarea rows="5" maxLength={2000} className="form-control fs-15px h-100"
                                        id="detail" {...register("detail", {
                                            required: false,
                                            value: taskReview.selectedAudit.description
                                        })} />
                                    <label htmlFor="floatingInput" className="fs-13px">
                                        {t('common.detail')}
                                    </label>
                                </div>

                                <div className="form-floating mb-15px">
                                    <DatePicker className="form-control fs-15px pt-25px" dateFormat="dd-MM-yyyy" selected={startDate} onChange={(date) => setStartDate(date)} required={true} />
                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-11px pt-1px mb-10px pb-30px">
                                        {t('common.date')}
                                    </label>
                                </div>

                                <div className="form-floating mb-15px">
                                    <select className={"form-select " + (errors.taskTypeId?.type === 'required' ? 'is-invalid' : '')}
                                        id="taskTypeId" {...register("taskTypeId", {
                                            required: true,
                                        })}
                                        onClick={handleInputChange}>
                                        <option></option>
                                        {MaintenanceTaskTypes.map(o => <option key={o.id} value={o.id}>{t("task.type_" + o.NormalizeName)}</option>)}
                                    </select>
                                    {errors.taskTypeId?.type === 'required' && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                        {t("common.type")}
                                    </label>
                                </div>

                                <div className="form-floating mb-15px">
                                    <select className={"form-select " + (errors.priorityLevelId?.type === 'required' ? 'is-invalid' : '')}
                                        id="priorityLevelId" {...register("priorityLevelId", {
                                            required: true,
                                        })}
                                        onClick={handleInputChange}>
                                        <option></option>
                                        {MaintenanceTaskPriorityLevel.map(o => <option key={o.id} value={o.id}>{t("common.priority_level_" + o.NormalizeName)}</option>)}
                                    </select>
                                    {errors.priorityLevelId?.type === 'required' && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                        {t("common.priority")}
                                    </label>
                                </div>

                                <div className="row mb-10px align-items-center">
                                    <div className="col-8 control-label text-dark fw-bold">Requiere control de validación</div>
                                    <div className="col-4 d-flex">
                                        <div className="form-check form-switch ms-auto mb-0">
                                            <input type="checkbox" className="form-check-input" name="RequireValidation" onChange={(e) => setRequireValidation(e.target.checked)} id="RequireValidation" checked={requireValidation} />
                                            <label className="form-check-label" htmlFor="appHeaderInverse">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>

                                {areas.fullList &&
                                    <div className="form-floating mb-15px">
                                        <select className="form-select" id="areaId" {...register("areaId", {
                                            required: false,
                                        })}>
                                            <option></option>
                                            {/* {areas.fullList.map(a => <option key={a.id} value={a.id}>{a.name}</option>)} */}
                                            {renderAreasOptions(areas.fullList, null)}
                                        </select>
                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                            {t("common.area")}
                                        </label>
                                    </div>
                                }

                                {assets.fullList &&
                                    <div className="form-floating mb-15px">
                                        <select className="form-select" id="assetId" {...register("assetId", {
                                            required: false,
                                        })}>
                                            <option></option>
                                            {assets.fullList.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
                                        </select>
                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                            {t("common.asset")}
                                        </label>
                                    </div>
                                }

                                {suppliers.fullList &&
                                    <div className="form-floating mb-15px">
                                        <select className="form-select" id="supplierId" {...register("supplierId", {
                                            required: false,
                                        })}>
                                            <option></option>
                                            {suppliers.fullList.map(a => <option key={a.id} value={a.id}>{a.fullName}</option>)}
                                        </select>
                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                            {t("common.supplier")}
                                        </label>
                                    </div>
                                }

                                <div className="form-floating mb-15px">
                                    <input type="text" pattern="[+-]?\d+(?:[.]\d+)?" className="form-control fs-15px" id="estimatedCost" {...register("estimatedCost", {
                                        required: false,
                                    })} />
                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                        {t('common.estimated_cost')}
                                    </label>
                                </div>

                                <div className="form-floating mb-15px">
                                    <input type="number" pattern="[0-999]*" className={"form-control fs-15px" + (errors.estimatedTime ? ' is-invalid' : '')}
                                        id="estimatedTime" {...register("estimatedTime", {
                                            required: false,
                                        })} />
                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                        {t('common.estimated_time')} (horas)
                                    </label>
                                </div>

                                <div className="mb-20px">
                                    <input type="submit" disabled={!isValid} className="btn btn-primary d-block w-100 h-45px btn-lg" value={t("common.save")} />
                                    {task.error && <div className='text-center'>
                                        <span className='text-danger'>Ha ocurrido un problema: {task.errorMessage}</span></div>}
                                    {/* {!isValid && <div className='text-center'>
                                    <span className='text-danger'>Ha ocurrido un problema: {errors}</span></div>} */}
                                </div>

                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </Loader>
    )
}
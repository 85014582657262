import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { InputGroup, Input, Button, ButtonDropdown } from 'reactstrap';
import { AppSettings } from '../../../config/app-settings.js';
import { useDispatch, useSelector } from 'react-redux';
import { ListAssets } from '../../../services/redux/assetSlice';
import Pagination from '../../../components/app/pager/pagination';
import { Loader } from '../../widget/loader';
import AssetItem from '../../../components/app/asset/listItem';
import { useTranslation } from "react-i18next";
import { AssetPriorityLevel, AssetTypes } from "../../../utils/generalConstants";
import { FullListAreas } from '../../../services/redux/areaSlice';
import { FullListRooms } from '../../../services/redux/roomSlice.js';
import { renderAreasDropdownItems } from '../../../components/app/area/common.js';

const AssetsList = () => {

    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');

    const query = new URLSearchParams(window.location.search)

    const user = useSelector(state => state.userReducer);
    const assets = useSelector(state => state.assetReducer);
    const areas = useSelector(state => state.areaReducer)
    const rooms = useSelector(state => state.roomReducer)

    const [keyword, setkeyword] = useState("");
    const [areaId, setAreaId] = useState([]);
    const [roomId, setRoomId] = useState([]);
    const [assetTypeId, setAssetTypeId] = useState([]);
    const [priorityLevelId, setPriorityLevelId] = useState([]);
    const [isOutOfService, setIsOutOfService] = useState(query.get('OutOfService') ? true : query.get('InService') ? false : null);
    const [isBackup, setIsBackup] = useState(query.get('IsBackup') ? true : query.get('InService') ? false : null);
    const [isArchived, setIsArchived] = useState(query.get('IsArchived') ? true : query.get('InService') ? false : false);

    const take = 15;

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        //console.log(user?.selectedTenant?.settings);
        //toggleSplit(0);

        !areas.fullList &&
            dispatch(FullListAreas({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

        !rooms.fullList &&
            dispatch(FullListRooms({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            })

    }, []);

    useEffect(() => {
        toggleSplit(0)
    }, [assetTypeId, priorityLevelId, areaId, roomId, isOutOfService, isBackup, isArchived]);

    const toggleSplit = async (skip) => {

        const requestData = {
            "tenantId": user.selectedTenant.id,
            "areaId": areaId[0],
            "roomId": roomId[0],
            "assetTypeId": assetTypeId[0],
            "isOutOfService": isOutOfService,
            "isBackup": isBackup,
            "isArchived": isArchived,
            "responsibleId": null,
            "priorityLevelId": priorityLevelId[0],
            "assetStateId": null,
            "createdById": null,
            "keyword": keyword,
            "sortBy": null,
            "sortDir": true,
            "skip": skip,
            "take": take
        };

        //console.log(requestData);

        //requestData.skip = skip;
        await dispatch(ListAssets({ authToken: user.authToken, body: requestData })).then((response) => {
            //console.log(response);
        });
    };

    const toggleSplitCallBack = useCallback(
        (skip) => {
            toggleSplit(skip);
        },
        [keyword, assetTypeId, priorityLevelId, areaId, roomId, isOutOfService, isBackup, isArchived]
    );

    //console.log("user");
    //console.log(user);
    if (!user) {
        return <Redirect to='/user/login' />;
    }

    return (
        <Loader isLoading={assets.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active">{t('menu.assets')}</li>
                </ol>
                <h1
                // className="page-header"
                >{t('menu.assets')}
                    {/* {assets && <small>{assets.dataCountFiltered} {t('common.results_found_of')} {assets.dataCount}</small>} */}
                    {assets && <small className='ms-10px'>{assets.dataCountFiltered} {t('common.results_found_of')} {assets.dataCount}</small>}
                </h1>

                <div className="d-flex align-items-center mb-2">
                    <div className="ms-auto">
                        <Link to="/asset/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("common.add")}</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <InputGroup size="lg" className="mb-3">
                            <Input placeholder={t("common.enter_keyword_here")} type="text" className="input-white" onChange={(text) => setkeyword(text.target.value)} onKeyPress={(e) => e.key === 'Enter' && toggleSplit(0)} />
                            <Button color="primary" className="rounded-right btn-lg" onClick={() => toggleSplit(0)}><i className="fa fa-search fa-fw"></i> {t("common.search")}</Button>
                            {/*<ButtonDropdown toggle={toggleSplit} hidden>*/}
                            {/*	<DropdownToggle color="primary" className="btn-lg" split>*/}
                            {/*		<i className="fa fa-cog fa-fw"></i>*/}
                            {/*	</DropdownToggle>*/}
                            {/*	<DropdownMenu>*/}
                            {/*		<DropdownItem>Action</DropdownItem>*/}
                            {/*		<DropdownItem>Another Action</DropdownItem>*/}
                            {/*		<DropdownItem>Something else here</DropdownItem>*/}
                            {/*		<DropdownItem divider />*/}
                            {/*		<DropdownItem>Separated link</DropdownItem>*/}
                            {/*	</DropdownMenu>*/}
                            {/*</ButtonDropdown>*/}
                        </InputGroup>

                        {assets && assets.list &&
                            <div>
                                {/* <div className="d-block d-md-flex align-items-center mb-3"> */}
                                <div className="d-block align-items-center mb-1 mb-sm-3">
                                    <div className="flex-column">

                                        {areas.fullList && areas.fullList.length > 0 &&
                                            <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                                                <DropdownToggle color="white" caret>
                                                    {t('common.filters_by')} {t('common.area')} <b className="caret"></b><span className="badge bg-default text-dark ms-5px">{areaId[1]}</span>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {/* {areas.fullList.map((area, index) =>
                                                        <DropdownItem onClick={() => setAreaId([area.id, area.name])} key={area.id}>{area.name}</DropdownItem>
                                                    )} */}
                                                    {renderAreasDropdownItems(areas.fullList, setAreaId, null)}
                                                    <DropdownItem divider />
                                                    <DropdownItem onClick={() => setAreaId([])} key="ALL">Todas</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        }

                                        <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                                            <DropdownToggle color="white" caret>
                                                {t('common.filters_by')} {t('common.type')} <b className="caret"></b><span className="badge bg-default text-dark ms-5px">{assetTypeId[1]}</span>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {AssetTypes.map((type, index) =>
                                                    <DropdownItem onClick={() => setAssetTypeId([type.id, t("asset.type_" + type.NormalizeName)])} key={type.id}>{t("asset.type_" + type.NormalizeName)}</DropdownItem>
                                                )}
                                                <DropdownItem divider />
                                                <DropdownItem onClick={() => setAssetTypeId([])} key="ALL">Todos</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>

                                        <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                                            <DropdownToggle color="white" caret>
                                                {t('common.filters_by')} prioridad <b className="caret"></b><span className="badge bg-default text-dark ms-5px">{priorityLevelId[1]}</span>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {AssetPriorityLevel.map((type, index) =>
                                                    <DropdownItem onClick={() => setPriorityLevelId([type.id, t("common.priority_level_" + type.NormalizeName)])} key={type.id}>{t("common.priority_level_" + type.NormalizeName)}</DropdownItem>
                                                )}
                                                <DropdownItem divider />
                                                <DropdownItem onClick={() => setPriorityLevelId([])} key="ALL">Todos</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>

                                        <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                                            <DropdownToggle color="white" caret>
                                                {t('common.filters_by')} {t('common.state')} <b className="caret"></b>
                                                {isOutOfService && <span className="badge bg-red ms-5px">{t("asset.out_of_service")}</span>}
                                                {isBackup && <span className="badge bg-blue ms-5px">{t("asset.backup")}</span>}
                                                {isArchived && <span className="badge bg-red ms-5px">{t("asset.archived")}</span>}
                                                {isOutOfService == false && isBackup == null && isArchived == false && <span className="badge bg-green ms-5px">{t("asset.in_service")}</span>}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {/* <DropdownItem onClick={() => { setIsOutOfService(!isOutOfService ? true : null); isBackup == false && setIsBackup(null); }} key="IsOutOfService"><input type="checkbox" className="form-check-input" name="IsOutOfService" id="IsOutOfService" checked={isOutOfService} /> {t("asset.out_of_service")}</DropdownItem> */}
                                                {/* <DropdownItem onClick={() => { setIsBackup(!isBackup ? true : null); isOutOfService == false && setIsOutOfService(null); }} key="IsBackup"><input type="checkbox" className="form-check-input" name="IsBackup" id="IsBackup" checked={isBackup} /> {t("asset.backup")}</DropdownItem> */}
                                                <DropdownItem onClick={() => { setIsOutOfService(false); setIsBackup(null); setIsArchived(false); }} key="in_service"> {t("asset.in_service")}</DropdownItem>
                                                <DropdownItem onClick={() => { setIsOutOfService(true); setIsBackup(null); setIsArchived(false); }} key="IsOutOfService"> {t("asset.out_of_service")}</DropdownItem>
                                                <DropdownItem onClick={() => { setIsOutOfService(null); setIsBackup(true); setIsArchived(false); }} key="IsBackup"> {t("asset.backup")}</DropdownItem>
                                                <DropdownItem onClick={() => { setIsOutOfService(null); setIsBackup(null); setIsArchived(true); }} key="archived"> {t("asset.archived")}</DropdownItem>
                                                <DropdownItem divider />
                                                <DropdownItem onClick={() => { setIsOutOfService(null); setIsBackup(null); setIsArchived(false); }} key="ALL">Todos</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>

                                        {user?.selectedTenant?.settings?.assetsPerRoomm &&
                                            rooms.fullList &&
                                            <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                                                <DropdownToggle color="white" caret>
                                                    {t('common.filters_by')} {t('common.room')} <b className="caret"></b><span className="badge bg-default text-dark ms-5px">{roomId[1]}</span>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {roomId != "" &&
                                                        <DropdownItem onClick={() => setRoomId([])} key="ALL">Todas</DropdownItem>
                                                    }
                                                    {rooms.fullList.map((room, index) =>
                                                        <DropdownItem onClick={() => setRoomId([room.id, room.name])} key={room.id}>{room.name}</DropdownItem>
                                                    )}
                                                    <DropdownItem divider />
                                                    <DropdownItem onClick={() => setRoomId([])} key="ALL">Todas</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        }

                                        <div className="btn-group" hidden>
                                            <Link to="/extra/search" className="btn btn-white"><i className="fa fa-list"></i></Link>
                                            <Link to="/extra/search" className="btn btn-white"><i className="fa fa-th"></i></Link>
                                            <Link to="/extra/search" className="btn btn-white"><i className="fa fa-th-large"></i></Link>
                                        </div>

                                    </div>
                                </div>

                                {assets.list.length != 0 ?
                                    <Pagination toggleSplitCallBack={toggleSplitCallBack} dataCount={assets.dataCountFiltered} take={take}>
                                        <div className="result-list">
                                            {assets.list.filter(a=> !a.isArchived || isArchived).map((asset, index) =>
                                                <AssetItem key={asset.id} asset={asset}></AssetItem>
                                            )}
                                        </div>
                                    </Pagination>
                                    :
                                    <div className="alert alert-info" role="alert">
                                        {t("common.empty_list")}
                                    </div>
                                }

                            </div>
                        }

                    </div>
                </div>
            </div>
        </Loader>
    )
};

export default AssetsList;
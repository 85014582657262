import React, { useContext, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import { Panel, PanelHeader, PanelFooter, PanelBody } from '.././../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../widget/loader';
import { CreateRoom } from '../../../services/redux/roomSlice';
import { useTranslation } from "react-i18next";


const RoomCreate = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const room = useSelector(state => state.roomReducer);

    const [isActive, setIsActive] = useState(true);

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
    }, []);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {

        let requestData = {
            "tenantId": user.selectedTenant.id,
            "name": data.name,
            "IsActive": isActive,
            "IsArchived": false,
            "MoreInfo": data.moreInfo,
            "createdById": user.selectedTenant.agentId,
        }

        //console.log(requestData);

        await dispatch(CreateRoom({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload && response.payload.data &&
                history.push("/room/detail?id=" + response.payload.data.id);
            //return <Redirect to={"/room/detail?id=" + response.payload.data.id } />;
        });
    };

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (
        <Loader isLoading={room.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active">Nueva Habitación</li>
                </ol>
                <h1 className="page-header">Nueva Habitación <small>complete los campos obligatorios</small></h1>

                <div className="d-flex align-items-center mb-2">
                    <div className="ms-auto">
                        <Link to="/room/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-7">
                        <div className="col-xl-12">
                            <Panel>
                                <PanelHeader noButton={true}>
                                    <div className="d-flex align-items-center">
                                        {t('common.general_data')}
                                    </div>
                                </PanelHeader>
                                <PanelBody>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-floating mb-15px">
                                            <input type="text" className="form-control fs-15px" id="name" {...register("name", {
                                                required: true,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.name')}
                                            </label>
                                        </div>

                                        <div className="row mb-10px align-items-center">
                                            <div className="col-8 control-label text-dark fw-bold">¿Se encuentra Disponible?</div>
                                            <div className="col-4 d-flex">
                                                <div className="form-check form-switch ms-auto mb-0">
                                                    <input type="checkbox" className="form-check-input" name="IsActive" onChange={(e) => setIsActive(e.target.checked)} id="IsActive" checked={isActive} />
                                                    <label className="form-check-label" htmlFor="appHeaderInverse">&nbsp;</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <textarea rows="5" maxLength={2000} className="form-control fs-15px h-100"
                                                id="moreInfo" {...register("moreInfo", {
                                                    required: false
                                                })} />
                                            <label htmlFor="floatingInput" className="fs-13px">
                                                {t('common.aditional_info')}
                                            </label>
                                        </div>

                                        <div className="mb-20px">
                                            <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value={t("common.add")} />
                                            {room.error && <div className='text-center'>
                                                <span className='text-danger'>Ha ocurrido un problema: {room.errorMessage}</span></div>}
                                        </div>

                                    </form>

                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                </div>
            </div>
        </Loader>
    )
};

export default RoomCreate;
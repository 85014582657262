import React, { useContext, useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import { useDispatch, useSelector } from 'react-redux';
import { persistor } from '../../services/store';
import { logOut } from '../../services/redux/userSlice.js';

export default function Logout() {
	const context = useContext(AppSettings);
	const dispatch = useDispatch();
	const user = useSelector(state => state.userReducer);
	let history = useHistory();

	const activeBackground = '/assets/img/login-bg/login-bg-15.jpg';

	const purge = () => {
		//console.log("############################################");
		//console.log("############## LOG OUT ###############");
		//console.log("############################################");
		persistor.purge().then(() => {
			//return dispatch(logOut());
			// console.log("REDIRECT persistor.purge()");
			// window.location = "/user/login";
		});

	}

	const goBack = () => {
		history.push("/dashboard");
	}

	useEffect(() => {
		context.handleSetAppSidebarNone(true);
		context.handleSetAppHeaderNone(true);
		context.handleSetAppContentClass('p-4');
	}, []);

	// if (user.authToken == "") {
	// 	console.log("REDIRECT LOGOUT");
	// 	window.location = "/user/login";
	// 	//return <Redirect to='/user/login' />;
	// }
	return (
		<div className="login login-v2 fw-bold">
			<div className="login-cover">
				<div className="login-cover-img" style={{ backgroundImage: 'url(' + activeBackground + ')' }}></div>
				<div className="login-cover-bg"></div>
			</div>

			<div className="login-container">
				<div className="login-header">
					<div className="brand">
						<div className="d-flex align-items-center">
							<span className="logo"></span> <b>Wartun</b>
						</div>
						<small>Smart & Collaborative Community</small>
					</div>
					<div className="icon">
						<i className="fa fa-sign-out"></i>
					</div>
				</div>

				<div className="form-floating mb-20px align-items-center">
					<big>Are you sure you want to log out?</big>
				</div>

				<div className="form-floating mb-20px">
					<div className="mb-20px">
						<button onClick={() => purge()} className="btn btn-success d-block w-100 h-45px btn-lg">Yes, I'm sure</button>
					</div>
				</div>
				<div className="form-floating mb-20px">
					<div className="mb-20px">
						<button onClick={() => goBack()} className="btn btn-warning d-block w-100 h-45px btn-lg">No, Go back</button>
					</div>
				</div>

			</div>

		</div>
	)
}

import React, { useContext, useState, useEffect, useCallback } from 'react';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { InputGroup, Input, Button } from 'reactstrap';
import { AppSettings } from '../../../config/app-settings.js';
import { useDispatch, useSelector } from 'react-redux';
import { ListFiles, DeleteFile, AddLink } from '../../../services/redux/fileSlice';
import Pagination from '../../../components/app/pager/pagination';
import { Loader } from '../../widget/loader';
import Viewer from '../../../components/app/file/viewer.js';
import Upload from '../../../components/app/file/upload.js';
import { postfileData } from '../../../services/redux/fileSlice';
import { FileTypes } from '../../../utils/generalConstants';
import { useTranslation } from "react-i18next";

const LibraryList = () => {

    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const files = useSelector(state => state.fileReducer);

    const [keyword, setkeyword] = useState("");
    const [fileTypeId, setFileTypeId] = useState([]);
    const [isPrivate, setIsPrivate] = useState(false);
    const take = 12;

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        //toggleSplit(0);
    }, []);

    useEffect(() => {
        toggleSplit(0)
        // console.log("fileTypeId")
    }, [fileTypeId]);

    useEffect(() => {
        toggleSplit(0)
        // console.log("isPrivate")
    }, [isPrivate]);

    const toggleSplit = async (skip) => {
        const requestData = {
            "tenantId": user.selectedTenant.id,
            "fileTypeId": fileTypeId[0],
            "isPrivate": isPrivate,
            "createdById": null,
            "keyword": keyword,
            "sortBy": null,
            "sortDir": true,
            "skip": skip,
            "take": take
        };
        //console.log("toggleSplit")
        //requestData.skip = skip;
        await dispatch(ListFiles({ authToken: user.authToken, body: requestData })).then((response) => {
            //console.log(response);
        });
    };

    const deleteFile = async (fileId) => {
        const requestDataDeleteFile = {
            "tenantId": user.selectedTenant.id,
            "id": fileId
        };

        await dispatch(DeleteFile({ authToken: user.authToken, body: requestDataDeleteFile })).then((response) => {
            toggleSplit(0);
        });
    };

    const toggleSplitCallBack = useCallback(
        (skip) => {
            toggleSplit(skip);
        },
        [keyword],
    );

    const [modalAddFiles, setModalAddFiles] = useState(false);

    const onUploadSubmit = async (data) => {
        data.append("TenantId", user.selectedTenant.id);
        data.append("AgentId", user.selectedTenant.agentId);
        data.append("CreatedOn", "2015-08-05T19:33:26Z");
        data.append("FileTypeId", FileTypes.filter(f => f.NormalizeName === 'GENERAL FILE')[0].id);

        await dispatch(postfileData({ authToken: user.authToken, body: data })).then((response) => {
            setModalAddFiles(false);
            toggleSplit(0);
        });
    };



    const toggleModal = (name) => {
        switch (name) {
            case 'modalAddFiles':
                setModalAddFiles(!modalAddFiles);
                break;
            default:
                break;
        }
    }

    const onClose = () => {
        setModalAddFiles(false);
        toggleSplit(0);
    }

    return (
        <Loader isLoading={files.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active">Biblioteca</li>
                </ol>
                <h1 className="page-header">Biblioteca
                    {/* {files && <small>{files.dataCountFiltered} {t('common.results_found_of')} {files.dataCount}</small>} */}
                </h1>

                <div className="d-flex align-items-center mb-2">
                    <div className="ms-auto">
                        <Button onClick={() => toggleModal('modalAddFiles')} className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("common.add")}</Button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <InputGroup size="lg" className="mb-3">
                            <Input placeholder={t("common.enter_keyword_here")} onKeyPress={(e) => e.key === 'Enter' && toggleSplit(0)} type="text" className="input-white" onChange={(text) => setkeyword(text.target.value)} />
                            <Button color="primary" className="rounded-right btn-lg" onClick={() => toggleSplit(0)}><i className="fa fa-search fa-fw"></i> {t("common.search")}</Button>
                        </InputGroup>
                        {files && files.list &&
                            <div>
                                <div className="d-block d-md-flex align-items-center mb-3">
                                    <div className="d-flex">
                                        <UncontrolledButtonDropdown className="me-2">
                                            <DropdownToggle color="white" caret>
                                                {t('common.filters_by')} tipo <b className="caret"></b><span className="badge bg-indigo ms-5px">{fileTypeId[1]}</span>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {FileTypes.map((type, index) =>
                                                    <DropdownItem onClick={() => setFileTypeId([type.id, type.Name])} key={type.id}>{type.Name}</DropdownItem>
                                                )}
                                                <DropdownItem divider />
                                                <DropdownItem onClick={() => setFileTypeId([])} key="ALL">Todos</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                        {/* <div className="btn-group" hidden>
                                            <Link to="/extra/search" className="btn btn-white"><i className="fa fa-list"></i></Link>
                                            <Link to="/extra/search" className="btn btn-white"><i className="fa fa-th"></i></Link>
                                            <Link to="/extra/search" className="btn btn-white"><i className="fa fa-th-large"></i></Link>
                                        </div> */}
                                    </div>
                                    <div className="d-flex">
                                        <div className="form-check form-switch ms-auto mb-0">
                                            <input type="checkbox" className="form-check-input" name="app-header-inverse" onChange={(e) => setIsPrivate(e.target.checked)} id="appHeaderInverse" checked={isPrivate} />
                                            <label className="form-check-label" htmlFor="appHeaderInverse"><b>Solo archivos privados</b></label>
                                        </div>
                                    </div>
                                </div>
                                <Pagination toggleSplitCallBack={toggleSplitCallBack} dataCount={files.dataCountFiltered} take={take}>
                                    <div className="result-list">
                                        <Viewer files={files.list} allowDelete={true} deleteAsyncCallback={deleteFile} />
                                    </div>
                                </Pagination>
                            </div>
                        }
                        <Upload isLibrary={true} onSubmit={onUploadSubmit} onClose={onClose} fileTypeId={FileTypes.filter(f => f.NormalizeName === 'GENERAL FILE')[0].id} isOpen={modalAddFiles} toggleModal={() => toggleModal('modalAddFiles')}  ></Upload>
                    </div>
                </div>
            </div>
        </Loader>
    )
};

export default LibraryList;
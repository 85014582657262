import React, { useContext, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Panel, PanelHeader, PanelFooter, PanelBody } from '../../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EditAudit } from '../../../services/redux/auditSlice';
import { AuditTypes } from '../../../utils/generalConstants';
import { useTranslation } from "react-i18next";

const AuditEdit = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const location = useLocation();
    const { audit } = location.state
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const auditReducer = useSelector(state => state.auditReducer);

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
    }, []);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {

        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": audit.id,
            "name": data.name,
            "typeId": data.typeId,
            "description": data.description ? data.description : null,
        }

        await dispatch(EditAudit({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload && response.payload.data &&
                history.push("/audit/detail?id=" + response.payload.data.id);
        });
    };

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (

        <div>
            <ol className="breadcrumb float-xl-end" hidden>
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/audit/list">Auditorías</Link></li>
                <li className="breadcrumb-item active">Editar Auditoría</li>
            </ol>
            <h1 className="page-header">Editar Audit ({audit.name})  <small>complete los campos obligatorios</small></h1>

            <div className="d-flex align-items-center mb-2">
                <div className="ms-auto">
                    <Link to="/audit/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                    <Link to={"/audit/detail?id=" + audit.id} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-7">
                    <div className="col-xl-12">
                        <Panel>
                            <PanelHeader noButton={true}>
                                <div className="d-flex align-items-center">
                                    {t("common.general_data")}
                                </div>
                            </PanelHeader>
                            <PanelBody>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-floating mb-15px">
                                        <input type="text" className={"form-control fs-15px" + (errors.name ? ' is-invalid' : '')} id="name" {...register("name", {
                                            required: true,
                                            value: audit.name
                                        })} />
                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                            {t('common.description')}
                                        </label>
                                        {errors.name && <span className='invalid-feedback'>This field is required</span>}
                                    </div>

                                    <div className="form-floating mb-15px">
                                        <textarea rows="5" maxLength={255} className={"form-control fs-15px h-100" + (errors.description ? ' is-invalid' : '')}
                                            id="description" {...register("description", {
                                                required: false,
                                                value: audit.description,
                                            })} />
                                        <label htmlFor="floatingInput" className=" fs-13px">
                                            {t('common.detail')}
                                        </label>
                                        {errors.description && <span className='invalid-feedback'>This field is required</span>}
                                    </div>

                                    {AuditTypes &&
                                        <div className="form-floating mb-15px">
                                            <select className="form-select" id="typeId" {...register("typeId", {
                                                required: true,
                                                value: audit.auditTypeId,
                                            })}>
                                                {/* {(user.selectedTenant.agentRoles === "Auditor" || user.selectedTenant.agentRoles === "Manager") && */}
                                                <option key={AuditTypes[0].id} value={AuditTypes[0].id}>{AuditTypes[0].Name}</option>
                                                {/* } */}
                                                <option key={AuditTypes[1].id} value={AuditTypes[1].id}>{AuditTypes[1].Name}</option>
                                            </select>
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.type')}
                                            </label>
                                        </div>
                                    }

                                    <div>
                                        <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value={t("common.save")} />
                                        {auditReducer.error && <div className='text-center mt-10px'>
                                            <span className='text-danger'>Ha ocurrido un problema: {auditReducer.errorMessage}</span></div>}
                                    </div>

                                </form>

                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AuditEdit;
import React, { useContext, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, PanelHeader, PanelFooter, PanelBody } from '../../../../components/panel/panel.jsx';
import { AppSettings } from '../../../../config/app-settings.js';
import { EditUser, DeleteUser, ResendInvitation } from '../../../../services/redux/userSlice';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Loader } from '../../../widget/loader.js';
import { Roles } from '../../../../utils/generalConstants.js';

const UserEdit = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    // console.log("user", JSON.stringify(user));
    const query = new URLSearchParams(window.location.search);

    const location = useLocation();
    const { userToEdit } = location.state
    //console.log("userToEdit", userToEdit);
    const isWartunAdmin =
        user?.selectedTenant.agentRoles === "ADMINISTRATOR" ||
        user?.selectedTenant?.userName === "emenoguera@gmail.com" ||
        user?.selectedTenant?.userName === "asistente.wartun@gmail.com";

    const [isEnabledUser, setIsEnabledUser] = useState(userToEdit.agent.isEnabledUser ? true : false);
    const [showMsgUpdateSuccess, setShowMsgUpdateSuccess] = useState("");
    //const [userToEdit, setUserToEdit] = useState(null);
    const [modalViewDeleteUser, setModalViewDeleteUser] = useState(false);
    const [sweetAlertResendInvitation, setSweetAlertResendInvitation] = useState(false);

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
    }, []);

    const { register, handleSubmit, formState: { errors } } = useForm();

    // const getUser = async () => {
    //     const requestData = {
    //         "tenantId": user.selectedTenant.id,
    //         "id": query.get("id")
    //     };
    //     await dispatch(GetUser({ authToken: user.authToken, params: requestData })).then((response) => {
    //         setUserToEdit(response.payload.data);
    //     });
    // };

    const onSubmit = async (data) => {

        let requestData = {
            "id": user.id,
            "agentId": userToEdit.agent.id,
            "tenantId": user.selectedTenant.id,
            "firstName": data.firstName,
            "lastName": data.lastName,
            "email": isWartunAdmin ? data.userName : userToEdit.userName,
            "isEnabledUser": isEnabledUser,
            "phoneNumber": data.phoneNumber,
            "role": isWartunAdmin ? data.role : null,
            // "role": userToEdit.role.normalizedName != data.role ? data.role : null, //Check if the role has changed
        }

        // console.log("requestData", requestData);

        await dispatch(EditUser({ authToken: user.authToken, body: requestData })).then((response) => {
            if (response && response.payload && response.payload.data) {
                //history.push("/user/list");
                //userToEdit.agent.isEnabledUser = response.payload.data.isEnabledUser;
                // console.log("response.payload.data.isEnabledUser")
                // console.log(response.payload.data.isEnabledUser)
                setIsEnabledUser(response.payload.data.isEnabledUser);
                setShowMsgUpdateSuccess("Usuario actualizado correctamente - " + moment().format('YYYY-MM-DD HH:mm:ss'));
            }
        });
    };

    const deleteUser = async (id) => {
        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": id,
        }
        await dispatch(DeleteUser({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload &&
                history.push("/user/list");
        });
    }

    const resendInvitation = async () => {
        setSweetAlertResendInvitation(false);
        let requestData = {
            "tenantId": user.selectedTenant.id,
            "agentId": userToEdit.agent.id,
            "email": userToEdit.userName,
        }
        await dispatch(ResendInvitation({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload &&
                setShowMsgUpdateSuccess("Invitación reenviada correctamente - " + moment().format('YYYY-MM-DD HH:mm:ss'));
        });
    }

    const toggleModal = (name) => {
        switch (name) {
            case 'modalViewDeleteUser':
                setModalViewDeleteUser(!modalViewDeleteUser);
                break;
            default:
                break;
        }
    }

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (
        <Loader isLoading={user.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active">Editar Usuario</li>
                </ol>
                <h1 className="page-header">Editar Usuario <small>complete los campos obligatorios</small></h1>

                <div className="d-flex align-items-center mb-2">
                    <div className="ms-auto">
                        <Link to="/user/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                    </div>
                </div>

                {userToEdit &&
                    <>
                        <div className="row">
                            <div className="col-xl-7">
                                <div className="col-xl-12">
                                    <Panel>
                                        <PanelHeader noButton={true}>
                                            <div className="d-flex align-items-center">
                                                {t("common.general_data")}
                                            </div>
                                        </PanelHeader>
                                        <PanelBody>
                                            <form onSubmit={handleSubmit(onSubmit)}>

                                                <div className="form-floating mb-15px">
                                                    <input type="text" className={"form-control fs-15px" + (errors.name ? ' is-invalid' : '')} id="firstName" {...register("firstName", {
                                                        required: true,
                                                        value: userToEdit.agent.firstName
                                                    })} />
                                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                        {t('common.first_name')}
                                                    </label>
                                                    {errors.name && <span className='invalid-feedback'>This field is required</span>}
                                                </div>

                                                <div className="form-floating mb-15px">
                                                    <input type="text" className={"form-control fs-15px" + (errors.name ? ' is-invalid' : '')} id="lastName" {...register("lastName", {
                                                        required: true,
                                                        value: userToEdit.agent.lastName
                                                    })} />
                                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                        {t('common.last_name')}
                                                    </label>
                                                    {errors.name && <span className='invalid-feedback'>This field is required</span>}
                                                </div>

                                                <div className="form-floating mb-10px">
                                                    <input type="text" className={"form-control fs-15px" + (errors.description ? ' is-invalid' : '')} id="userName" {...register("userName", {
                                                        required: true,
                                                        value: userToEdit.userName,
                                                    })}
                                                        disabled={!isWartunAdmin} />
                                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                        {t('common.email')}
                                                    </label>
                                                    {errors.description && <span className='invalid-feedback'>This field is required</span>}
                                                </div>

                                                <div className="row mb-10px align-items-center">
                                                    <div className="col-8 control-label text-dark fw-bold">¿Es usuario con acceso a Wartun?</div>
                                                    <div className="col-4 d-flex">
                                                        <div className="form-check form-switch ms-auto mb-0">
                                                            <input type="checkbox" className="form-check-input" name="IsEnabledUser" onChange={(e) => setIsEnabledUser(e.target.checked)} id="IsEnabledUser" checked={isEnabledUser} />
                                                            <label className="form-check-label" htmlFor="appHeaderInverse">&nbsp;</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {Roles &&
                                                    <div className="form-floating mb-15px">
                                                        <select className={"form-select " + (errors.role?.type === 'required' ? 'is-invalid' : '')} id="role" {...register("role", {
                                                            required: true,
                                                            value: userToEdit.role.normalizedName
                                                        })}
                                                            disabled={!isWartunAdmin}>
                                                            <option></option>
                                                            {Roles.map(r => <option key={r.id} value={r.NormalizeName} className='text-dark'>{t("roles.rol_" + r.NormalizeName)}</option>)}
                                                        </select>
                                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                            Rol
                                                        </label>
                                                        {/* {errors.role?.type === 'required' && <span className='invalid-feedback'>{t('validation.required_field')}</span>} */}
                                                        {errors.role && <span className='invalid-feedback'>{t('validation.required_field')}  </span>}
                                                    </div>
                                                }

                                                <div className="form-floating mb-15px">
                                                    <input type="text" className={"form-control fs-15px" + (errors.name ? ' is-invalid' : '')} id="phoneNumber" {...register("phoneNumber", {
                                                        required: false,
                                                        value: userToEdit.agent.phoneNumber
                                                    })} />
                                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                        {t('common.phone_number')}
                                                    </label>
                                                </div>

                                                <div className="mb-20px">
                                                    <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value={t("common.save")} />
                                                    {user.error && <div className='text-center'>
                                                        <span className='text-danger'>Ha ocurrido un problema: {user.errorMessage}</span></div>}

                                                </div>


                                            </form>

                                        </PanelBody>
                                        <PanelFooter>
                                            <div className="d-flex align-items-center">
                                                <div className="ms-auto">
                                                    <button className='btn btn-warning' disabled={!isEnabledUser} onClick={() => { setSweetAlertResendInvitation(true) }}> Reenviar invitación <i className="fa fa-reply"></i> </button>
                                                    <button className='btn btn-danger ms-10px' onClick={() => { toggleModal('modalViewDeleteUser') }}> Eliminar <i className="fa fa-trash"></i> </button>
                                                </div>
                                            </div>
                                        </PanelFooter>
                                    </Panel>
                                    {showMsgUpdateSuccess &&
                                        <Alert color="success" isOpen={true}>
                                            {showMsgUpdateSuccess}<strong></strong>
                                        </Alert>
                                    }

                                </div>
                            </div>
                        </div>
                        <Modal size="md" style={{ maxWidth: '300px', width: '100%' }} isOpen={modalViewDeleteUser} toggle={() => toggleModal('modalViewDeleteUser')} >
                            <ModalHeader toggle={() => toggleModal('modalViewDeleteUser')}>Eliminar Usuario</ModalHeader>
                            <ModalBody>
                                {!user.error ?
                                    <span>¿Esta seguro que desea eliminar el usuario <b>{userToEdit.agent.fullName}</b>, creado por <b>{userToEdit.agent.createdBy}</b> el <b>{moment(userToEdit.createdOn).format("DD/MM/YYYY")}</b> ?</span>
                                    :
                                    <div className='text-center'>
                                        <span className='text-danger'>Ha ocurrido un problema: {user.errorMessage}</span></div>}
                            </ModalBody>
                            {!user.error &&
                                <ModalFooter>
                                    <button className="btn btn-danger" onClick={() => deleteUser(userToEdit.id)}>Si, borrar!</button>
                                    <button className="btn btn-white" onClick={() => toggleModal('modalViewDeleteUser')}>No</button>
                                </ModalFooter>
                            }
                        </Modal>

                        {sweetAlertResendInvitation &&
                            <SweetAlert showCancel
                                confirmBtnText="Continuar"
                                confirmBtnBsStyle="primary"
                                cancelBtnText="Cancelar"
                                cancelBtnBsStyle="default"
                                title="Está seguro que desea realizar esta acción?"
                                onConfirm={() => resendInvitation()}
                                onCancel={() => setSweetAlertResendInvitation(false)}
                            >
                                You will not be able to change this action!
                            </SweetAlert>
                        }
                    </>
                }

            </div>
        </Loader>
    )
};

export default UserEdit;
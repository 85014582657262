import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import DropdownNotification from './dropdown/notification.jsx';
// import SearchForm from './search/form.jsx';
import logo from "../../assets/img/logo_100.png";
import { AppSettings } from "./../../config/app-settings.js";
import DropdownLanguage from "./dropdown/language.jsx";
import DropdownMegaMenu from "./dropdown/mega.jsx";
import DropdownProfile from "./dropdown/profile.jsx";
import { useDispatch, useSelector } from "react-redux";
import { setSettings } from "../../services/redux/userSlice.js";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const context = useContext(AppSettings);
  const user = useSelector((state) => state.userReducer);

  const isWartunAdmin =
    user?.selectedTenant?.userName === "emenoguera@gmail.com" ||
    user?.selectedTenant?.userName === "asistente.wartun@gmail.com";

  const [appHeaderMegaMenuMobile, setAppHeaderMegaMenuMobile] = useState(false);
  // const [appHeaderLanguageBar, setAppHeaderLanguageBar] = useState(true);

  useEffect(() => {
    context.handleSetAppDarkMode(user.settings?.darkMode);
  }, []);

  const handleDarkMode = (e) => {
    if (user.settings == undefined) {
      let initialSettings = {
        darkMode: false,
        language: "en",
      };
      dispatch(setSettings(initialSettings));
    }

    if (e.target.checked) {
      context.handleSetAppDarkMode(true);
      let newSettings = { ...user.settings, darkMode: true };
      console.log("newSettings", newSettings);
      dispatch(setSettings(newSettings));
    } else {
      context.handleSetAppDarkMode(false);
      let newSettings = { ...user.settings, darkMode: false };
      console.log("newSettings", newSettings);
      dispatch(setSettings(newSettings));
    }
  };

  const toggleAppHeaderMegaMenuMobile = () => {
    setAppHeaderMegaMenuMobile(!this.state.appHeaderMegaMenuMobile);
  };
  return (
    <AppSettings.Consumer>
      {({
        toggleAppSidebarMobile,
        toggleAppSidebarEnd,
        toggleAppSidebarEndMobile,
        toggleAppTopMenuMobile,
        appHeaderLanguageBar,
        appHeaderMegaMenu,
        appHeaderInverse,
        appSidebarTwo,
        appTopMenu,
        appSidebarNone,
      }) => (
        <div
          id="header"
          className={
            "app-header " + (appHeaderInverse ? "app-header-inverse" : "")
          }
        >
          <div className="navbar-header">
            {appSidebarTwo && (
              <button
                type="button"
                className="navbar-mobile-toggler"
                onClick={toggleAppSidebarEndMobile()}
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            )}
            <Link to="/" className="navbar-brand">
              {/* <span className="navbar-logo"></span> <b>Wartun</b> */}
              <img src={logo} />
            </Link>

            {appHeaderMegaMenu && (
              <button
                type="button"
                className="navbar-mobile-toggler"
                onClick={toggleAppHeaderMegaMenuMobile()}
              >
                <span className="fa-stack fa-lg text-inverse">
                  <i className="far fa-square fa-stack-2x"></i>
                  <i className="fa fa-cog fa-stack-1x"></i>
                </span>
              </button>
            )}
            {appTopMenu && !appSidebarNone && (
              <button
                type="button"
                className="navbar-mobile-toggler"
                onClick={toggleAppTopMenuMobile}
              >
                <span className="fa-stack fa-lg text-inverse">
                  <i className="far fa-square fa-stack-2x"></i>
                  <i className="fa fa-cog fa-stack-1x"></i>
                </span>
              </button>
            )}
            {appSidebarNone && appTopMenu && (
              <button
                type="button"
                className="navbar-mobile-toggler"
                onClick={toggleAppTopMenuMobile}
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            )}
            {!appSidebarNone && (
              <button
                type="button"
                className="navbar-mobile-toggler"
                onClick={toggleAppSidebarMobile}
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            )}
          </div>

          {appHeaderMegaMenu && (
            <DropdownMegaMenu collapse={this.state.appHeaderMegaMenuMobile} />
          )}

          <div className="navbar-nav">
            {/* <SearchForm /> */}
            {/* <DropdownNotification /> */}

            {isWartunAdmin && (
              <div className="form-check form-switch ms-auto me-20px">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="app-theme-dark-mode"
                  onChange={handleDarkMode}
                  id="appThemeDarkMode"
                  value="1"
                  checked={user.settings?.darkMode}
                />
                <label className="form-check-label" htmlFor="appThemeDarkMode">
                  {t('common.dark_mode')}&nbsp;
                </label>
              </div>
            )}

            <DropdownLanguage />

            <DropdownProfile />

            {appSidebarTwo && (
              <div className="navbar-divider d-none d-md-block"></div>
            )}

            {appSidebarTwo && (
              <div className="navbar-item d-none d-md-block">
                <Link
                  to="/"
                  onClick={toggleAppSidebarEnd}
                  className="navbar-link icon"
                >
                  <i className="fa fa-th"></i>
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </AppSettings.Consumer>
  );
};

export default Header;

import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { InputGroup, Input, Button, ButtonDropdown } from 'reactstrap';
import { AppSettings } from '../../../config/app-settings.js';
import { useDispatch, useSelector } from 'react-redux';
import { ListSuppliers } from '../../../services/redux/supplierSlice';
import Pagination from '../../../components/app/pager/pagination';
import { Loader } from '../../widget/loader';
import SupplierItem from '../../../components/app/supplier/listItem';
import { useTranslation } from "react-i18next";

const SupplierList = () => {

    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');

    const globalData = useSelector(state => state.appReducer);
    const user = useSelector(state => state.userReducer);
    const suppliers = useSelector(state => state.supplierReducer);

    const [keyword, setkeyword] = useState("");
    const [supplierCategoryId, setSupplierCategoryId] = useState([]);
    const take = 10;


    const toggleSplit = async (skip) => {
        const requestData = {
            "tenantId": user.selectedTenant.id,
            "supplierCategoryId": supplierCategoryId[0],
            "responsibleId": null,
            "supplierStateId": null,
            "createdById": null,
            "keyword": keyword,
            "sortBy": null,
            "sortDir": true,
            "skip": skip,
            "take": take
        };
        //requestData.skip = skip;
        await dispatch(ListSuppliers({ authToken: user.authToken, body: requestData })).then((response) => {
            //console.log(response);
        });
    };

    const toggleSplitCallBack = useCallback(
        (skip) => {
            toggleSplit(skip);
        },
        [keyword],
    );

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        toggleSplit(0);
    }, []);

    useEffect(() => {
        toggleSplit(0)
    }, [supplierCategoryId]);


    return (
        <Loader isLoading={suppliers.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/dashboard">{t("menu.dashboard")}</Link></li>
                    <li className="breadcrumb-item active">Proveedores</li>
                </ol>
                <h1 className="page-header">Proveedores</h1>
                {/* {suppliers && <small>{suppliers.dataCountFiltered} {t('common.results_found_of')} {suppliers.dataCount}</small>} */}
                <div className="d-flex align-items-center mb-2">
                    <div className="ms-auto">
                        <Link to="/supplier/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("common.add")}</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <InputGroup size="lg" className="mb-3">
                            <Input placeholder={t("common.enter_keyword_here")} type="text" onKeyPress={(e) => e.key === 'Enter' && toggleSplit(0)} className="input-white" onChange={(text) => setkeyword(text.target.value)} />
                            <Button color="primary" className="rounded-right btn-lg" onClick={() => toggleSplit(0)}><i className="fa fa-search fa-fw"></i> {t("common.search")}</Button>
                            {/*<ButtonDropdown toggle={toggleSplit} hidden>*/}
                            {/*	<DropdownToggle color="primary" className="btn-lg" split>*/}
                            {/*		<i className="fa fa-cog fa-fw"></i>*/}
                            {/*	</DropdownToggle>*/}
                            {/*	<DropdownMenu>*/}
                            {/*		<DropdownItem>Action</DropdownItem>*/}
                            {/*		<DropdownItem>Another Action</DropdownItem>*/}
                            {/*		<DropdownItem>Something else here</DropdownItem>*/}
                            {/*		<DropdownItem divider />*/}
                            {/*		<DropdownItem>Separated link</DropdownItem>*/}
                            {/*	</DropdownMenu>*/}
                            {/*</ButtonDropdown>*/}
                        </InputGroup>

                        {suppliers && suppliers.list &&
                            <div>
                                <div className="d-block d-md-flex align-items-center mb-3">
                                    <div className="d-flex">
                                        <UncontrolledButtonDropdown className="me-2">
                                            <DropdownToggle color="white" caret>
                                                {t('common.filters_by')} categoría <b className="caret"></b><span className="badge bg-default text-dark ms-5px">{supplierCategoryId[1]}</span>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {globalData.supplierCategories.map((type, index) =>
                                                    <DropdownItem onClick={() => setSupplierCategoryId([type.id, type.NormalizeName])} key={type.id}>{type.NormalizeName}</DropdownItem>
                                                )}
                                                <DropdownItem divider />
                                                <DropdownItem onClick={() => setSupplierCategoryId([])} key="ALL">Todos</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                        <div className="btn-group" hidden>
                                            <Link to="/extra/search" className="btn btn-white"><i className="fa fa-list"></i></Link>
                                            <Link to="/extra/search" className="btn btn-white"><i className="fa fa-th"></i></Link>
                                            <Link to="/extra/search" className="btn btn-white"><i className="fa fa-th-large"></i></Link>
                                        </div>
                                    </div>
                                </div>
                                <Pagination toggleSplitCallBack={toggleSplitCallBack} dataCount={suppliers.dataCountFiltered} take={take}>
                                    <div className="result-list">
                                        {suppliers.list.map((supplier, index) =>
                                            <SupplierItem key={supplier.id} supplier={supplier}></SupplierItem>
                                        )}
                                    </div>
                                </Pagination>
                                <div className="result-list">

                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </Loader>
    )
};

export default SupplierList;
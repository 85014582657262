import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { PURGE } from "redux-persist";

const initialState = {
    routineTasks: null,
    dataCount: null,
    dataCountFiltered: null,
    selectedRoutineTask: null,
    lastSync: null,
    isFetching: false,
    error: false,
    errorMessage: "",
    status: ""
}

export const GetRoutineTask = createAsyncThunk('routineTask/fetchGetRoutineTask', async ({ authToken, params }) => {
    let response = await api.getRoutineTask(authToken, params);
    return response;
});

export const ListRoutineTasks = createAsyncThunk('routineTask/fetchListRoutineTasks', async ({authToken, body}) => {
    let response = await api.listRoutineTasks(authToken, body);
    return response;
});

export const CreateRoutineTask = createAsyncThunk('routineTask/fetchCreateRoutineTask', async ({ authToken, body }) => {
    let response = await api.createRoutineTask(authToken, body);
    return response;
});

export const EditRoutineTask = createAsyncThunk('routineTask/fetchEditRoutineTask', async ({ authToken, body }) => {
    //console.log(body);
    let response = await api.editRoutineTask(authToken, body);
    return response;
});

export const DeleteRoutineTask = createAsyncThunk('tasks/fetchDeleteTask', async ({ authToken, body }) => {
    let response = await api.deleteRoutineTask(authToken, body);
    return response;
});

const routineTaskSlice = createSlice({
    name: "routineTask",
    initialState,
    reducers: {

    },
    extraReducers: {
        [ListRoutineTasks.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [ListRoutineTasks.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.routineTasks = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [ListRoutineTasks.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [GetRoutineTask.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [GetRoutineTask.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedRoutineTask = payload.data;
        },
        [GetRoutineTask.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [CreateRoutineTask.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [CreateRoutineTask.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
        },
        [CreateRoutineTask.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [EditRoutineTask.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [EditRoutineTask.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
        },
        [EditRoutineTask.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [DeleteRoutineTask.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [DeleteRoutineTask.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedRoutineTask = null;
            state.fullList = null;
        },
        [DeleteRoutineTask.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [PURGE]: (state) => {
            return initialState;
        },
    }
})

export default routineTaskSlice.reducer; 
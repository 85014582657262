import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { PURGE } from "redux-persist";

const initialState = {
    list: null,
    dataCount: null,
    dataCountFiltered: null,
    selectedRoutineWorkOrder: null,
    lastSync: null,
    isFetching: false,
    error: false,
    errorMessage: "",
    status: "",
}

export const GetRoutineWorkOrder = createAsyncThunk('routineWorkOrder/fetchGetRoutineWorkOrder', async ({ authToken, params }) => {
    let response = await api.getRoutineWorkOrder(authToken, params);
    return response;
});

export const ListRoutineWorkOrders = createAsyncThunk('routineWorkOrder/fetchListRoutineWorkOrders', async ({authToken, body}) => {
    let response = await api.listRoutineWorkOrders(authToken, body);
    return response;
});

export const CreateRoutineWorkOrder = createAsyncThunk('routineWorkOrder/fetchCreateRoutineWorkOrder', async ({ authToken, body }) => {
    let response = await api.createRoutineWorkOrder(authToken, body);
    return response;
});

export const FinishRoutineWorkOrder = createAsyncThunk('routineWorkOrder/fetchFinishRoutineWorkOrder', async ({ authToken, body }) => {
    let response = await api.finishRoutineWorkOrder(authToken, body);
    return response;
});

export const AddRoutineWorkOrderDetail = createAsyncThunk('routineWorkOrder/fetchAddRoutineWorkOrderDetail', async ({ authToken, body }) => {
    let response = await api.addRoutineWorkOrderDetail(authToken, body);
    return response;
});


const routineWorkOrderSlice = createSlice({
    name: "routineWorkOrder",
    initialState,
    reducers: {

    },
    extraReducers: {

        [GetRoutineWorkOrder.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [GetRoutineWorkOrder.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedRoutineWorkOrder = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [GetRoutineWorkOrder.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [ListRoutineWorkOrders.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [ListRoutineWorkOrders.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.list = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [ListRoutineWorkOrders.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [CreateRoutineWorkOrder.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [CreateRoutineWorkOrder.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedRoutineWorkOrder = payload.data;
        },
        [CreateRoutineWorkOrder.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [FinishRoutineWorkOrder.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [FinishRoutineWorkOrder.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedRoutineWorkOrder = payload.data;
        },
        [FinishRoutineWorkOrder.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [AddRoutineWorkOrderDetail.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [AddRoutineWorkOrderDetail.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedRoutineWorkOrder = payload.data;
        },
        [AddRoutineWorkOrderDetail.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },


        [PURGE]: (state) => {
            return initialState;
        },
    }
})

export default routineWorkOrderSlice.reducer; 
import { DropdownItem } from "reactstrap";

export const renderAreasOptions = (areas, parentId = null, level = 0, maxLevel = 10) => {
    // console.log(maxLevel)
    const spaces = '\u00A0\u00A0\u00A0\u00A0'.repeat(level);
    return (
        <>
            {areas
                .filter(area => area.parentAreaId === parentId)
                .map(area => (
                    <>
                        <option key={area.id} value={area.id} className='text-dark'>{parentId && spaces}{area.name}</option>
                        {level < maxLevel - 1 && renderAreasOptions(areas, area.id, level + 1, maxLevel)}
                    </>
                ))}
        </>
    )
};

export const renderAreasDropdownItems = (areas, setAreaId, parentId = null, level = 0) => {
    const spaces = '\u00A0\u00A0\u00A0\u00A0'.repeat(level);
    return (
        <>
            {areas
                .filter(area => area.parentAreaId === parentId)
                .map(area => (
                    <div key={'div' + area.id}>
                        <DropdownItem key={area.id} onClick={() => setAreaId([area.id, area.name])}>{parentId && spaces}{area.name}</DropdownItem>
                        {renderAreasDropdownItems(areas, setAreaId, area.id, level + 1)}
                    </div>
                ))}
        </>
    )
};


import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { PURGE } from "redux-persist";

const initialState = {
    selectedTenant: null,
    lastSync: null,
    isFetching: false,
    error: false,
    errorMessage: "",
    status: "",
}

export const GetTenant = createAsyncThunk('tenant/fetchGetTenant', async ({ authToken, params }) => {
    // console.log("params.id")
    // console.log(params.id)
    let response = await api.getTenant(authToken, params);
    return response;
});

export const EditTenant = createAsyncThunk('tenant/fetchEditTenant', async ({ authToken, body }) => {
    let response = await api.editTenant(authToken, body);
    return response;
});

const tenantSlice = createSlice({
    name: "tenant",
    initialState,
    reducers: {

    },
    extraReducers: {
        [GetTenant.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [GetTenant.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedTenant = payload.data;
        },
        [GetTenant.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [EditTenant.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [EditTenant.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedTenant = null;
        },
        [EditTenant.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [PURGE]: (state) => {
            return initialState;
        },
    }
})

export default tenantSlice.reducer; 
import ReactGA from "react-ga4";
import React, { Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './app.jsx';
// css
import 'bootstrap/dist/css/bootstrap.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-calendar/dist/Calendar.css';
// import 'react-quill/dist/quill.snow.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap-social/bootstrap-social.css';
import 'flag-icon-css/css/flag-icons.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'simple-line-icons/css/simple-line-icons.css';
import './index.css';
import './scss/react.scss';
import { persistor, store } from './services/store';

import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import './config/i18n';

import { registerLocale, setDefaultLocale } from "react-datepicker";
import { es } from "date-fns/locale";
registerLocale("es", es);
setDefaultLocale("es");


ReactGA.initialize("G-CGMERV03H5");
ReactGA._gaCommandSendPageview(window.location.pathname + window.location.search);
ReactGA._gaCommandSendEvent("userClickPath", window.location.pathname + window.location.search);
// console.log("G-CGMERV03H5");
// console.log(window.location.pathname + window.location.search);

// i18next.init({
//   interpolation: { escapeValue: false },  // React already does escaping
// });
// ========================================
// checks whether an element is even

function AppWithCallbackAfterRender() {
  useEffect(() => {
    // console.log('rendered');
    //console.log(window.location)
    if (window.location.hostname !== "localhost" && window.location.protocol == "http:")
      window.location.href = "https://app.wartun.com";

      

    return () => {
      // console.log('######################## unmount ########################');
      // console.log('######################## unmount ########################');
      // console.log('######################## unmount ########################');
      // console.log('######################## unmount ########################');
      // console.log('######################## unmount ########################');
    }
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <I18nextProvider i18n={i18next}>
            <Suspense fallback="loading">
              <App />
            </Suspense>
          </I18nextProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<AppWithCallbackAfterRender />);

// ReactDOM.render(
//   <Provider store={store}>
//     <PersistGate loading={null} persistor={persistor}>
//       <BrowserRouter>
//         <I18nextProvider i18n={i18next}>
//           <Suspense fallback="loading">
//             <App />
//           </Suspense>
//         </I18nextProvider>
//       </BrowserRouter>
//     </PersistGate>
//   </Provider>,
//   document.getElementById('root')
// );

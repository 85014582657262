import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { PURGE } from "redux-persist";

const initialState = {
    lastSync: null,
    isFetching: false,
    error: false,
    errorMessage: "",
    status: "",
    list: null,
    dataCount: null,
    dataCountFiltered: null,
}

export const postfileData = createAsyncThunk('file/UploadFile', async ({ authToken, body }) => {
    let response = await api.uploadFile(authToken, body);
    return response;
});

export const AddLink = createAsyncThunk('file/fetchAddLink', async ({ authToken, body }) => {
    let response = await api.addLink(authToken, body);
    return response;
});

export const ListFiles = createAsyncThunk('file/fetchListFiles', async ({authToken, body}) => {
    let response = await api.listFiles(authToken, body);
    return response;
});


export const DeleteFile = createAsyncThunk('file/fetchDeleteFile', async ({authToken, body}) => {
    let response = await api.deleteFile(authToken, body);
    return response;
});

export const EditFileName = createAsyncThunk('assets/fetchEditFileName', async ({ authToken, body }) => {
    let response = await api.editFileName(authToken, body);
    return response;
});

const fileSlice = createSlice({
    name: "file",
    initialState,
    reducers: {

    },
    extraReducers: {
        [postfileData.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [postfileData.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
        },
        [postfileData.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [AddLink.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [AddLink.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
        },
        [AddLink.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [ListFiles.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [ListFiles.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.list = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [ListFiles.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [DeleteFile.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [DeleteFile.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.list = state.list?.filter(function( file ) {
                return file.result.id !== payload.id;
            });;
            state.dataCount = state.dataCount - 1;
            state.dataCountFiltered =  state.dataCountFiltered - 1;
        },
        [DeleteFile.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [EditFileName.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [EditFileName.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
        },
        [EditFileName.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [PURGE]: (state) => {
            return initialState;
        },
    }
})

export default fileSlice.reducer; 
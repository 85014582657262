import React, { useContext, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Panel, PanelHeader, PanelFooter, PanelBody } from '.././../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../widget/loader';
import { CreateAudit } from '../../../services/redux/auditSlice';
import { AuditTypes } from '../../../utils/generalConstants';
import { useTranslation } from "react-i18next";

const AuditCreate = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const audit = useSelector(state => state.auditReducer);

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
    }, []);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {

        let requestData = {
            "tenantId": user.selectedTenant.id,
            "name": data.name,
            "typeId": data.typeId,
            "description": (data.description != "") ? data.description : null,
            "createdById": user.selectedTenant.agentId,
        }

        //console.log(requestData);

        await dispatch(CreateAudit({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload && response.payload.data &&
                history.push("/audit/detail?id=" + response.payload.data.id);
        });
    };

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (
        <Loader isLoading={audit.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/audit/list">Auditorías</Link></li>
                    <li className="breadcrumb-item active">Nueva Auditoría</li>
                </ol>
                <h1 className="page-header">Nueva Auditoría <small>complete los campos obligatorios</small></h1>

                <div className="d-flex align-items-center mb-2">
                    <div className="ms-auto">
                        <Link to="/audit/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-7">
                        <div className="col-xl-12">
                            <Panel>
                                <PanelHeader noButton={true}>
                                    <div className="d-flex align-items-center">
                                        General Data
                                    </div>
                                </PanelHeader>
                                <PanelBody>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-floating mb-15px">
                                            <input type="text" className="form-control fs-15px" id="name" {...register("name", {
                                                required: true,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.description')}
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <textarea rows="5" maxLength={255} className="form-control fs-15px h-100"
                                                id="description" {...register("description", {
                                                    required: false,
                                                })} />
                                            <label htmlFor="floatingInput" className=" fs-13px">
                                                {t('common.detail')}
                                            </label>
                                        </div>

                                        {AuditTypes &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="typeId" {...register("typeId", {
                                                    required: true,
                                                })}>
                                                    {/* {(user.selectedTenant.agentRoles === "Auditor" || user.selectedTenant.agentRoles === "Manager") && */}
                                                    <option key={AuditTypes[0].id} value={AuditTypes[0].id}>{AuditTypes[0].Name}</option>
                                                    {/* } */}
                                                    <option key={AuditTypes[1].id} value={AuditTypes[1].id}>{AuditTypes[1].Name}</option>
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    {t('common.type')}
                                                </label>
                                            </div>
                                        }

                                        <div className="mb-20px">
                                            <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value={t("common.save")} />
                                            {audit.error && <div className='text-center'>
                                                <span className='text-danger'>Ha ocurrido un problema: {audit.errorMessage}</span></div>}
                                        </div>

                                    </form>

                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                </div>
            </div>
        </Loader>
    )
};

export default AuditCreate;
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import moment from 'moment';

const TaskReviewItem = ({ audit }) => {
    const { t } = useTranslation('common');
    const user = useSelector(state => state.userReducer);

    return (
        <div className="result-item" key={audit.id}>
            <Link to="/extra/search" className="result-image" style={{ backgroundImage: 'url(/assets/img/gallery/gallery-51.jpg)' }} hidden></Link>
            <div className="result-info">
                <h4 className="title"><Link to={"/taskreview/detail?id=" + audit.id}>{audit.name}</Link></h4>
                <p className="location">{audit.description}</p>
                <p>
                    <span className="badge bg-gray me-5px">{t("audit.state_" + audit.stateNormalizedName)}</span>
                    {audit.functionalUnitNumber &&
                        <span className="badge bg-info me-5px">{t("common.functional_unit") + ": " + audit.functionalUnitNumber}</span>}
                    {audit.roomName &&
                        <span className="badge bg-info me-5px">{t("common.room") + ": " + audit.roomName}</span>}
                    {audit.areaName &&
                        <span className="badge bg-info me-5px">{t("common.area") + ": " + audit.areaName}</span>}

                </p>
                <p>
                    <span className="ms-5px">{t("common.createdOn")} {moment(audit.createdOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm:ss")}</span>
                    <span className="ms-5px">|</span>
                    <span className="ms-5px">{t("common.created_by")} {audit.createdBy}</span>
                </p>
            </div>
            <div className="result-price" hidden>
                {/*$92,101 <small>PER MONTH</small>*/}
                <Link to={"/taskreview/detail?id=" + audit.id} className="btn btn-gray-600 d-block w-100">{t("common.detail")}</Link>
            </div>
        </div>
    )
}

export default TaskReviewItem;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/api";
import { PURGE } from "redux-persist";

const initialState = {
    selectedTenant: null,
    statistics: null,
    lastSync: null,
    isFetching: false,
    error: false,
    errorMessage: "",
    status: "",
}

export const GetTenantStatistics = createAsyncThunk('tenant/fetchGetTenantStatistics', async ({ authToken, params }) => {
    // console.log("params.id")
    // console.log(params.id)
    let response = await api.getTenantStatistics(authToken, params);
    return response;
});

export const GetTenant = createAsyncThunk('tenant/fetchGetTenant', async ({ authToken, params }) => {
    // console.log("params.id")
    // console.log(params.id)
    let response = await api.getTenant(authToken, params);
    return response;
});

export const EditTenant = createAsyncThunk('tenant/fetchEditTenant', async ({ authToken, body }) => {
    let response = await api.editTenant(authToken, body);
    return response;
});

const backendtenantSlice = createSlice({
    name: "backendtenant",
    initialState,
    reducers: {

    },
    extraReducers: {
        [GetTenantStatistics.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [GetTenantStatistics.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            console.log("payload.data");
            state.statistics = payload.data;
        },
        [GetTenantStatistics.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },
        [GetTenant.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [GetTenant.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedTenant = payload.data;
        },
        [GetTenant.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [EditTenant.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [EditTenant.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedTenant = null;
        },
        [EditTenant.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [PURGE]: (state) => {
            return initialState;
        },
    }
})

export default backendtenantSlice.reducer; 
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, UncontrolledButtonDropdown } from 'reactstrap';
import Pagination from '../../../components/app/pager/pagination';
import TaskReviewItem from '../../../components/app/taskReview/listItem';
import { AppSettings } from '../../../config/app-settings.js';
import { ListAudits } from '../../../services/redux/auditSlice';
import { HotelTenantType, PrivateNeighborhoodTenantType, TaskReviewStates } from "../../../utils/generalConstants";
import { Loader } from '../../widget/loader';
import { FullListFunctionalUnits } from '../../../services/redux/functionalUnitSlice';
import { FullListAreas } from '../../../services/redux/areaSlice';
import { FullListRooms } from '../../../services/redux/roomSlice';
import { renderAreasDropdownItems } from '../../../components/app/area/common';

const TaskReviewList = () => {

    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');

    const query = new URLSearchParams(window.location.search)
    const state = query.get('state')

    const user = useSelector(state => state.userReducer);
    const audits = useSelector(state => state.auditReducer);
    const areas = useSelector(state => state.areaReducer)
    const functionalUnits = useSelector(state => state.functionalUnitReducer);
    const rooms = useSelector(state => state.roomReducer)

    const isHotelTenantType = user?.selectedTenant?.tenantTypeId == HotelTenantType;
    const isPrivateNeighborhoodTenantType = user?.selectedTenant?.tenantTypeId == PrivateNeighborhoodTenantType;

    const [keyword, setkeyword] = useState("");
    const [areaId, setAreaId] = useState([]);
    const [functionalUnitId, setFunctionalUnitId] = useState([]);
    const [roomId, setRoomId] = useState([]);

    let initialState = ["00000000-0000-0000-0000-000000000000", "Pendiente o Enviado"]; //PENDING AND SENT
    switch (state) {
        case "pending":
            initialState = [TaskReviewStates[0].id, t("identifyTask.state_" + TaskReviewStates[0].NormalizeName)] //PENDING
            break;
        case "sent":
            initialState = [TaskReviewStates[1].id, t("identifyTask.state_" + TaskReviewStates[1].NormalizeName)] //SENT
            break;
        default:
            break;
    }
    const [auditStateId, setAuditStateId] = useState(initialState);
    //const [auditStateId, setAuditStateId] = useState([TaskReviewStates[1].id, t("identifyTask.state_" + TaskReviewStates[1].NormalizeName)]); //SENT
    //const [auditStateId, setAuditStateId] = useState(["00000000-0000-0000-0000-000000000000", "Pendiente o Enviado"]);

    const take = 10;

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        // toggleSplit(0);

        !areas.fullList &&
            dispatch(FullListAreas({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

        isPrivateNeighborhoodTenantType && !functionalUnits.fullList &&
            dispatch(FullListFunctionalUnits({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

        isHotelTenantType && !rooms.fullList &&
            dispatch(FullListRooms({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            })

    }, []);

    useEffect(() => {
        toggleSplit(0)
    }, [auditStateId, areaId, functionalUnitId, roomId]);

    const toggleSplit = async (skip) => {

        const requestData = {
            "tenantId": user.selectedTenant.id,
            "areaId": areaId[0],
            "functionalUnitId": functionalUnitId[0],
            "roomId": roomId[0],
            "auditStateId": auditStateId[0],
            "responsibleId": null,
            "priorityLevelId": null,
            "createdById": null,
            "keyword": keyword,
            "sortBy": null,
            "sortDir": true,
            "skip": skip,
            "take": take,
            "IsReview": true,
        };
        //console.log(requestData)
        //requestData.skip = skip;
        await dispatch(ListAudits({ authToken: user.authToken, body: requestData })).then((response) => {
            //console.log(response);
        });
    };

    const toggleSplitCallBack = useCallback(
        (skip) => {
            toggleSplit(skip);
        },
        [keyword, auditStateId, areaId, functionalUnitId, roomId],
    );

    return (
        <Loader isLoading={audits.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active">Relevamiento de tareas</li>
                </ol>
                <h1 className="page-header">Relevamiento de tareas
                    {/* {audits && <small>{audits.dataCountFiltered} {t('common.results_found_of')} {audits.dataCount}</small>} */}
                </h1>

                <div className="d-flex align-items-center mb-2">
                    <div className="ms-auto">
                        <Link to="/taskreview/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("identifyTask.identifyTask")}</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <InputGroup size="lg" className="mb-3">
                            <Input placeholder={t("common.enter_keyword_here")} onKeyPress={(e) => e.key === 'Enter' && toggleSplit(0)} type="text" className="input-white" onChange={(text) => setkeyword(text.target.value)} />
                            <Button color="primary" className="rounded-right btn-lg" onClick={() => toggleSplit(0)}><i className="fa fa-search fa-fw"></i> {t("common.search")}</Button>
                            {/*<ButtonDropdown toggle={toggleSplit} hidden>*/}
                            {/*	<DropdownToggle color="primary" className="btn-lg" split>*/}
                            {/*		<i className="fa fa-cog fa-fw"></i>*/}
                            {/*	</DropdownToggle>*/}
                            {/*	<DropdownMenu>*/}
                            {/*		<DropdownItem>Action</DropdownItem>*/}
                            {/*		<DropdownItem>Another Action</DropdownItem>*/}
                            {/*		<DropdownItem>Something else here</DropdownItem>*/}
                            {/*		<DropdownItem divider />*/}
                            {/*		<DropdownItem>Separated link</DropdownItem>*/}
                            {/*	</DropdownMenu>*/}
                            {/*</ButtonDropdown>*/}
                        </InputGroup>

                        {audits && audits.list &&
                            <div>
                                <div>
                                    {/* <div className="d-block d-md-flex align-items-center mb-3" >
                                        <div className="d-flex"> */}
                                    <div className="d-block align-items-center mb-1 mb-sm-3">
                                        <div className="flex-column">

                                            <UncontrolledButtonDropdown className="me-2">
                                                <DropdownToggle color="white" caret>
                                                    {t('common.filters_by')} {t("common.state").toLowerCase()} <b className="caret"></b><span className="badge bg-gray ms-5px">{auditStateId[1]}</span>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {TaskReviewStates.map((state, index) =>
                                                        <DropdownItem onClick={() => setAuditStateId([state.id, t("identifyTask.state_" + state.NormalizeName)])} key={state.id}>{t("identifyTask.state_" + state.NormalizeName)}</DropdownItem>
                                                    )}
                                                    <DropdownItem divider />
                                                    <DropdownItem onClick={() => setAuditStateId(["00000000-0000-0000-0000-000000000000", "Pendiente o Enviado"])} key="PENDINGORSENT">Pendiente o Enviado</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem onClick={() => setAuditStateId([])} key="ALL">Todos</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>

                                            {isPrivateNeighborhoodTenantType &&
                                                functionalUnits.fullList &&
                                                <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                                                    <DropdownToggle color="white" caret>
                                                        {t('common.filters_by')} {t('common.functional_unit')} <b className="caret"></b><span className="badge bg-info ms-5px">{functionalUnitId[1]}</span>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {functionalUnitId != "" &&
                                                            <DropdownItem onClick={() => setFunctionalUnitId([])} key="ALL">Todas</DropdownItem>
                                                        }
                                                        {functionalUnits.fullList.map((functionalUnit, index) =>
                                                            <DropdownItem onClick={() => setFunctionalUnitId([functionalUnit.id, functionalUnit.number])} key={functionalUnit.id}>{functionalUnit.number}</DropdownItem>
                                                        )}
                                                        <DropdownItem divider />
                                                        <DropdownItem onClick={() => setFunctionalUnitId([])} key="ALL">Todas</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            }

                                            {isHotelTenantType &&
                                                rooms.fullList &&
                                                <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                                                    <DropdownToggle color="white" caret>
                                                        {t('common.filters_by')} {t('common.room')} <b className="caret"></b><span className="badge bg-info ms-5px">{roomId[1]}</span>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {roomId != "" &&
                                                            <DropdownItem onClick={() => setRoomId([])} key="ALL">Todas</DropdownItem>
                                                        }
                                                        {rooms.fullList.map((room, index) =>
                                                            <DropdownItem onClick={() => setRoomId([room.id, room.name])} key={room.id}>{room.name}</DropdownItem>
                                                        )}
                                                        <DropdownItem divider />
                                                        <DropdownItem onClick={() => setRoomId([])} key="ALL">Todas</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            }

                                            {user?.selectedTenant?.settings?.assetsPerRoomm &&
                                                rooms.fullList &&
                                                <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                                                    <DropdownToggle color="white" caret>
                                                        {t('common.filters_by')} {t('common.room')} <b className="caret"></b><span className="badge bg-info ms-5px">{roomId[1]}</span>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {roomId != "" &&
                                                            <DropdownItem onClick={() => setRoomId([])} key="ALL">Todas</DropdownItem>
                                                        }
                                                        {rooms.fullList.map((room, index) =>
                                                            <DropdownItem onClick={() => setRoomId([room.id, room.name])} key={room.id}>{room.name}</DropdownItem>
                                                        )}
                                                        <DropdownItem divider />
                                                        <DropdownItem onClick={() => setRoomId([])} key="ALL">Todas</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            }

                                            {areas.fullList && areas.fullList.length > 0 &&
                                                <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                                                    <DropdownToggle color="white" caret>
                                                        {t('common.filters_by')} {t('common.area')} <b className="caret"></b><span className="badge bg-info ms-5px">{areaId[1]}</span>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {areaId != "" &&
                                                            <DropdownItem onClick={() => setAreaId([])} key="ALL">Todas</DropdownItem>
                                                        }
                                                        {/* {areas.fullList.map((area, index) =>
                                                            <DropdownItem onClick={() => setAreaId([area.id, area.name])} key={area.id}>{area.name}</DropdownItem>
                                                        )} */}
                                                        {renderAreasDropdownItems(areas.fullList, setAreaId, null)}
                                                        <DropdownItem divider />
                                                        <DropdownItem onClick={() => setAreaId([])} key="ALL">Todas</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            }

                                            <div className="btn-group" hidden>
                                                <Link to="/extra/search" className="btn btn-white"><i className="fa fa-list"></i></Link>
                                                <Link to="/extra/search" className="btn btn-white"><i className="fa fa-th"></i></Link>
                                                <Link to="/extra/search" className="btn btn-white"><i className="fa fa-th-large"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {audits.list.length != 0 ?
                                    <Pagination toggleSplitCallBack={toggleSplitCallBack} dataCount={audits.dataCountFiltered} take={take}>
                                        <div className="result-list">
                                            {audits.list.map((audit, index) =>
                                                <TaskReviewItem key={audit.id} audit={audit}></TaskReviewItem>
                                            )}
                                        </div>
                                    </Pagination>
                                    :
                                    <div className="alert alert-info" role="alert">
                                        {t("common.empty_list")}
                                    </div>
                                }
                            </div>
                        }

                    </div>
                </div>
            </div>
        </Loader>
    )
};

export default TaskReviewList;
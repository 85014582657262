//import { string } from "prop-types";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, useHistory, withRouter, useLocation } from "react-router-dom";
import routes from "./../../config/app-route.jsx";
import { AppSettings } from "./../../config/app-settings.js";

const Content = () => {
  const history = useHistory();
  const user = useSelector((state) => state.userReducer);
  const location = useLocation();

  useEffect(() => {
    // console.log("useEffect Context")
    // console.log(location.pathname);

    if (user.authToken === "" &&
      location.pathname !== "/user/register" &&
      location.pathname !== "/user/forgotPassword" &&
      location.pathname !== "/user/resetPassword") {
      // console.log("SECURITY REDIRECT")
      window.history.replaceState({}, document.title)
      return history.replace("/user/login");
    }

    return () => {
      // componentWillUnmount()
      // console.log('##################### Componente desmontado x user or history #####################');
    };

    //testAccess();
  }, [history, user, location.pathname]);

  useEffect(() => {

    return () => {
      // componentWillUnmount()
      // console.log('##################### Componente desmontado #####################');
    };

  }, []);

  function setTitle(path, routeArray) {
    var appTitle;
    for (var i = 0; i < routeArray.length; i++) {
      if (routeArray[i].path === path) {
        appTitle = "Wartun | " + routeArray[i].title;
      }
    }
    document.title = appTitle ? appTitle : "Wartun | App";
  }

  setTitle(history.location.pathname, routes);


  const checkAccess = (route) => {

    //return route.roles && (route.roles.includes("ALL") || (user.selectedTenant && route.roles.toLowerCase().includes(user.selectedTenant.agentRoles.toLowerCase())))
    return !route.roles || (user.selectedTenant && user.selectedTenant.agentRoles && route.roles.toLowerCase().includes(user.selectedTenant.agentRoles.toLowerCase()))
  };

  // const testAccess = () => {

  //   const role = user.selectedTenant ? user.selectedTenant.agentRoles : "";
  //   // console.log("EME route.roles: " + role)

  //   // console.log("user.selectedTenant.agentRoles[0]");
  //   // user.selectedTenant &&
  //   //   console.log(user.selectedTenant.agentRoles);

  //   routes.map((route, index) =>
  //     route.roles &&
  //     console.log("route.path: " + route.path + " route.roles: " + route.roles + " Rol: " + role + " match: " + (route.roles === role) + " include: " + checkAccess(route))
  //   )

  // };

  return (
    <AppSettings.Consumer>
      {({ appContentClass }) => (
        <div className={"app-content " + appContentClass}>
          {routes.map((route, index) =>

            checkAccess(route) &&
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />

            // /*Logica para pintar la route audit*/
            // route.path.includes("audit") &&
            // (user.selectedTenant && (user.selectedTenant.agentRoles.includes("Auditor") ||
            //   user.selectedTenant.agentRoles.includes("Administrator"))) ? (
            //   <Route
            //     key={index}
            //     path={route.path}
            //     exact={route.exact}
            //     component={route.component}
            //   />
            // ) : !route.path.includes("audit") &&
            // (user.selectedTenant && !user.selectedTenant.agentRoles.includes("Auditor")) ? (
            //   <Route
            //     key={index}
            //     path={route.path}
            //     exact={route.exact}
            //     component={route.component}
            //   />
            // ) : (route.path.includes("login") || route.path.includes("logout")) ? (
            //   <Route
            //     key={index}
            //     path={route.path}
            //     exact={route.exact}
            //     component={route.component}
            //   />
            // ):<></>

          )}
        </div>
      )}
    </AppSettings.Consumer>
  );
};

export default withRouter(Content);

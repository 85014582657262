import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { PURGE } from "redux-persist";
import { ChangeTenant } from "../redux/userSlice";

const initialState = {
    list: null,
    fullList: null,
    dataCount: null,
    dataCountFiltered: null,
    selectedSupplier: null,
    lastSync: null,
    isFetching: false,
    error: false,
    errorMessage: "",
    status: "",
}

export const GetSupplier = createAsyncThunk('supplier/fetchGetSupplier', async ({ authToken, params }) => {
    let response = await api.getSupplier(authToken, params);
    return response;
});

export const ListSuppliers = createAsyncThunk('supplier/fetchListSuppliers', async ({authToken, body}) => {
    let response = await api.listSuppliers(authToken, body);
    return response;
});

export const FullListSuppliers = createAsyncThunk('area/fetchFullListSuppliers', async ({ authToken, tenantId }) => {
    let body = {
        "tenantId": tenantId,
        "sortBy": null,
        "sortDir": true,
        "skip": 0,
        "take": null
    };
    let response = await api.listSuppliers(authToken, body);
    return response;
});

export const CreateSupplier = createAsyncThunk('supplier/fetchCreateSupplier', async ({ authToken, body }) => {
    let response = await api.createSupplier(authToken, body);
    return response;
});

export const EditSupplier = createAsyncThunk('supplier/fetchEditSupplier', async ({ authToken, body }) => {
    let response = await api.editSupplier(authToken, body);
    return response;
});

export const DeleteSupplier = createAsyncThunk('supplier/fetchDeleteSupplier', async ({ authToken, body }) => {
    let response = await api.deleteSupplier(authToken, body);
    return response;
});

const supplierSlice = createSlice({
    name: "supplier",
    initialState,
    reducers: {

    },
    extraReducers: {

        [GetSupplier.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [GetSupplier.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedSupplier = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [GetSupplier.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [ListSuppliers.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [ListSuppliers.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.list = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [ListSuppliers.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [FullListSuppliers.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [FullListSuppliers.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.fullList = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [FullListSuppliers.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [CreateSupplier.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [CreateSupplier.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedSupplier = payload.data;
            state.fullList = null;
        },
        [CreateSupplier.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [EditSupplier.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [EditSupplier.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedSupplier = payload.data;
            state.fullList = null;
        },
        [EditSupplier.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [DeleteSupplier.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [DeleteSupplier.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedSupplier = null;
            state.fullList = null;
        },
        [DeleteSupplier.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [ChangeTenant.fulfilled]: (state, { payload }) => {
            return initialState;
            // state.isFetching = false;
            // state.error = false;
            // state.errorMessage = '';
            // state.fullList = null;
            // state.dataCount = null;
            // state.dataCountFiltered = null;
        },

        [PURGE]: (state) => {
            return initialState;
        },
    }
})

export default supplierSlice.reducer; 
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { InputGroup, Input, Button, ButtonDropdown } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppSettings } from '../../../config/app-settings.js';
import { useTranslation } from "react-i18next";


const AccountIndex = () => {
	const { t } = useTranslation('common');

    const context = useContext(AppSettings);

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
    }, []);


    return (
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/dashboard">Inicio</Link></li>
                    <li className="breadcrumb-item active">{t('common.settings')}</li>
                </ol>
                <h1 className="page-header">{t('common.settings')}</h1>
              
                <div className="row">
                    <div className="col-xs-12 col-sm-8 col-md-7 col-lg-5 col-xl-5">
                        <Link to="/user/list" className="btn btn-primary d-block mb-10px">{t('common.users')}</Link>
                        <Link to="/area/list" className="btn btn-primary d-block mb-10px">{t('common.areas')}</Link>
                        <Link to="/settings/tenantinfo" className="btn btn-primary d-block mb-10px">{t('common.tenant_info')}</Link>
                    </div>
                </div>
            </div>
    )
};

export default AccountIndex;
import React, { useContext, useState, useEffect } from 'react';
import { Panel, PanelHeader, PanelFooter, PanelBody } from '.././../../components/panel/panel.jsx';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { AppSettings } from '../../../config/app-settings.js';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetSupplier, DeleteSupplier } from '../../../services/redux/supplierSlice';
import { useTranslation } from "react-i18next";

const SupplierDetail = () => {
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const history = useHistory();
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const supplier = useSelector(state => state.supplierReducer)

    const query = new URLSearchParams(window.location.search)

    const [modalViewDeleteSupplier, setModalViewDeleteSupplier] = useState(false);


    const getSupplier = async () => {

        const requestData = {
            "tenantId": user.selectedTenant.id,
            "id": query.get("id")
        };

        await dispatch(GetSupplier({ authToken: user.authToken, params: requestData })).then((response) => {
        });
    };

    const deleteSupplier = async (id) => {
        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": id,
        }
        await dispatch(DeleteSupplier({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload &&
                history.push("/supplier/list");
        });
    }

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        getSupplier();
    }, []);

    const toggleModal = (name) => {
        switch (name) {
            case 'modalViewDeleteSupplier':
                setModalViewDeleteSupplier(!modalViewDeleteSupplier);
                break;
            default:
                break;
        }
    }

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (
        <div>
            {supplier.selectedSupplier &&
                <div>
                    <ol className="breadcrumb float-xl-end" hidden>
                        <li className="breadcrumb-item"><Link to="/dashboard">{t("menu.dashboard")}</Link></li>
                        <li className="breadcrumb-item"><Link to="/supplier/list">Proveedores</Link></li>
                        <li className="breadcrumb-item active">{supplier.selectedSupplier.name}</li>
                    </ol>
                    <h1 className="page-header">{supplier.selectedSupplier.name} <small>{/*{supplier.selectedSupplier.typeNormalizedName}*/}</small></h1>

                    <div className="d-flex align-items-center mb-2">
                        <div className="ms-auto">
                            <Link to="/supplier/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                            {/* <Link to="/supplier/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("common.add")}</Link> */}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-6">
                            <div className="col-xl-12">
                                <Panel>
                                    <PanelHeader noButton={true}>
                                        <div className="d-flex justify-content-between">
                                            {t("common.general_data")}
                                            <div>
                                                <Link className="btn btn-success btn-sm btn-rounded px-3 rounded-pill" to={{
                                                    pathname: "/supplier/edit",
                                                    state: {
                                                        supplier: supplier.selectedSupplier
                                                    }
                                                }}> Editar</Link>
                                            </div>
                                        </div>
                                    </PanelHeader>
                                    <PanelBody>
                                        <p>Nombre: {supplier.selectedSupplier.name}</p>
                                        {supplier.selectedSupplier.description &&
                                            <p>Información adicional: {supplier.selectedSupplier.description}</p>
                                        }
                                        <p>Identificador Fiscal: {supplier.selectedSupplier.fiscalIdentifier}</p>
                                        <p>Dirección: {supplier.selectedSupplier.fullAddress}</p>
                                        <p>Código Postal: {supplier.selectedSupplier.zipCode}</p>
                                        <p>País: {supplier.selectedSupplier.country}</p>
                                        <p>Email: {supplier.selectedSupplier.email}</p>
                                        <p>Teléfono: {supplier.selectedSupplier.phoneNumber}</p>
                                        <p>Teléfono Alternativo: {supplier.selectedSupplier.phoneNumber2}</p>
                                        <p>Categoría: {supplier.selectedSupplier.supplierCategory}</p>
                                    </PanelBody>
                                    <PanelFooter>
                                        <div className="d-flex align-items-center">
                                            <div className="ms-auto">
                                                {query.get("taskid") &&
                                                    <Link to={"/task/detail?id=" + query.get("taskid")} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill me-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                                }
                                                {query.get("workorderid") &&
                                                    <Link to={"/workorder/detail?id=" + query.get("workorderid")} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill me-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                                }
                                                {(user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
                                                    user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF") &&
                                                    <button className='btn btn-danger' onClick={() => { toggleModal('modalViewDeleteSupplier') }}> Eliminar <i className="fa fa-trash"></i> </button>
                                                }
                                            </div>
                                        </div>
                                    </PanelFooter>
                                </Panel>
                            </div>

                        </div>
                        <div className="col-xl-3" hidden>
                            <div className="col-xl-12">
                                <Panel>
                                    <PanelHeader noButton={true}>
                                        <div className="d-flex align-items-center">
                                            Extra information
                                        </div>
                                    </PanelHeader>
                                    <PanelBody>
                                        <p>Panel Content Here</p>
                                    </PanelBody>
                                </Panel>
                            </div>
                            
                        </div>
                    </div>
                    <Modal size="md" style={{ maxWidth: '300px', width: '100%' }} isOpen={modalViewDeleteSupplier} toggle={() => toggleModal('modalViewDeleteSupplier')} >
                        <ModalHeader toggle={() => toggleModal('modalViewDeleteSupplier')}>Eliminar Activo</ModalHeader>
                        <ModalBody>
                            <span>¿Esta seguro que desea eliminar el activo <b>{supplier.selectedSupplier.name}</b>, creado por <b>{supplier.selectedSupplier.createdBy}</b> el <b>{moment(supplier.selectedSupplier.createdOn).format("DD/MM/YYYY")}</b> ?</span>
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-danger" onClick={() => deleteSupplier(supplier.selectedSupplier.id)}>Si, borrar!</button>
                            <button className="btn btn-white" onClick={() => toggleModal('modalViewDeleteSupplier')}>No</button>
                        </ModalFooter>
                    </Modal>
                </div>
            }
        </div>
    )
};

export default SupplierDetail;
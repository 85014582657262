//NOTA> Todos los Ids, deben estar representados en minusculas, ya que asi los devuelve el API.
export const CommunityTenantType = "67552370-5cf1-42ba-807d-00bc00f81de3";
export const PrivateNeighborhoodTenantType = "2da46d8c-c866-4129-a954-c3f51749fb0e";
export const HotelTenantType = "6b7bdb7f-b9ce-416d-b381-be7b272b1661";
export const EducationalInstitutionTenantType = "7573ce8f-949b-4c20-a287-88a37289227a";
export const HealthInstitutionTenantType = "40f5fa7e-1e5d-45f3-9ff5-2d315d343f99";
export const HouseboatsTenantType = "aed87d6f-1c92-4ac8-9daa-a1f62190b96a";


export const MaintenanceTasksIdPending = 'e9c76a30-1792-4800-b6ed-68381849bfaa';
export const MaintenanceTasksIdExecution = 'b2308b58-ed8a-4779-a34a-13f947f53f3a';
export const MaintenanceTasksIdFinished = 'f4fc06a0-8b67-4b0f-9c36-af3b84e16a72';

export const MaintenanceTaskTypes = [
    { id: '1397bda2-b503-493e-b9ba-19b20695053e', NormalizeName: 'CONTROL', Name: 'Control', Priority: 1 },
    { id: '3fa85f64-5717-4562-b3fc-2c963f66afa6', NormalizeName: 'REPAIR', Name: 'Reparación', Priority: 2 },
    { id: '76cc9713-67d4-4a7d-bb94-b06d2997ebcd', NormalizeName: 'EXECUTION', Name: 'Ejecución', Priority: 3 },
    { id: '91caed58-414a-4690-a9d7-ac28eb681298', NormalizeName: 'PURCHASE', Name: 'Compra', Priority: 4 }
]

export const MaintenanceTaskStates = [
    { id: 'e9c76a30-1792-4800-b6ed-68381849bfaa', NormalizeName: 'PENDING', Name: 'Pendiente', Priority: 2 },
    { id: 'b2308b58-ed8a-4779-a34a-13f947f53f3a', NormalizeName: 'STARTED', Name: 'Iniciada', Priority: 1 },
    { id: 'f4fc06a0-8b67-4b0f-9c36-af3b84e16a72', NormalizeName: 'FINISHED', Name: 'Finalizada', Priority: 3 },
]

export const MaintenanceTaskPriorityLevel = [
    { id: '519ee47d-8c4e-430c-9465-c4478561c815', NormalizeName: 'MEDIUM', Priority: 1 },
    { id: '6d8d178a-ac05-4382-93d8-0f31ee9c0be4', NormalizeName: 'LOW', Priority: 2 },
    { id: 'eadb964d-2c7d-4246-8005-f17ce2635508', NormalizeName: 'HIGH', Priority: 3 }
]

//#region ServiceTask

export const ServiceTasksIdPending = 'e9c76a30-1792-4800-b6ed-68381849bfaa';
export const ServiceTasksIdExecution = 'b2308b58-ed8a-4779-a34a-13f947f53f3a';
export const ServiceTasksIdFinished = 'f4fc06a0-8b67-4b0f-9c36-af3b84e16a72';

export const ServiceTaskTypes = [ //NO SERIA NECESIARIO
    { id: '1397bda2-b503-493e-b9ba-19b20695053e', NormalizeName: 'CONTROL', Name: 'Control', Priority: 1 },
    { id: '3fa85f64-5717-4562-b3fc-2c963f66afa6', NormalizeName: 'REPAIR', Name: 'Reparación', Priority: 2 },
    { id: '76cc9713-67d4-4a7d-bb94-b06d2997ebcd', NormalizeName: 'EXECUTION', Name: 'Ejecución', Priority: 3 },
    { id: '91caed58-414a-4690-a9d7-ac28eb681298', NormalizeName: 'PURCHASE', Name: 'Compra', Priority: 4 }
]

export const ServiceTaskStates = [
    { id: 'e9c76a30-1792-4800-b6ed-68381849bfaa', NormalizeName: 'PENDING', Name: 'Pendiente', Priority: 2 },
    { id: 'b2308b58-ed8a-4779-a34a-13f947f53f3a', NormalizeName: 'STARTED', Name: 'Iniciada', Priority: 1 },
    { id: 'f4fc06a0-8b67-4b0f-9c36-af3b84e16a72', NormalizeName: 'FINISHED', Name: 'Finalizada', Priority: 3 },
]

export const ServiceTaskPriorityLevel = [
    { id: '519ee47d-8c4e-430c-9465-c4478561c815', NormalizeName: 'MEDIUM', Priority: 1 },
    { id: '6d8d178a-ac05-4382-93d8-0f31ee9c0be4', NormalizeName: 'LOW', Priority: 2 },
    { id: 'eadb964d-2c7d-4246-8005-f17ce2635508', NormalizeName: 'HIGH', Priority: 3 }
]

//#endregion

//#region RoutineTask

export const RoutineTasksIdPending = 'e9c76a30-1792-4800-b6ed-68381849bfaa';
export const RoutineTasksIdExecution = 'b2308b58-ed8a-4779-a34a-13f947f53f3a';
export const RoutineTasksIdFinished = 'f4fc06a0-8b67-4b0f-9c36-af3b84e16a72';

export const RoutineTaskTypes = [ //NO SERIA NECESIARIO
    { id: '1397bda2-b503-493e-b9ba-19b20695053e', NormalizeName: 'CONTROL', Name: 'Control', Priority: 1 },
    { id: '3fa85f64-5717-4562-b3fc-2c963f66afa6', NormalizeName: 'REPAIR', Name: 'Reparación', Priority: 2 },
    { id: '76cc9713-67d4-4a7d-bb94-b06d2997ebcd', NormalizeName: 'EXECUTION', Name: 'Ejecución', Priority: 3 },
    { id: '91caed58-414a-4690-a9d7-ac28eb681298', NormalizeName: 'PURCHASE', Name: 'Compra', Priority: 4 }
]

export const RoutineTaskStates = [
    { id: 'e9c76a30-1792-4800-b6ed-68381849bfaa', NormalizeName: 'PENDING', Name: 'Pendiente', Priority: 2 },
    { id: 'b2308b58-ed8a-4779-a34a-13f947f53f3a', NormalizeName: 'STARTED', Name: 'Iniciada', Priority: 1 },
    { id: 'f4fc06a0-8b67-4b0f-9c36-af3b84e16a72', NormalizeName: 'FINISHED', Name: 'Finalizada', Priority: 3 },
]

export const RoutineTaskPriorityLevel = [
    { id: '519ee47d-8c4e-430c-9465-c4478561c815', NormalizeName: 'MEDIUM', Priority: 1 },
    { id: '6d8d178a-ac05-4382-93d8-0f31ee9c0be4', NormalizeName: 'LOW', Priority: 2 },
    { id: 'eadb964d-2c7d-4246-8005-f17ce2635508', NormalizeName: 'HIGH', Priority: 3 }
]

//#endregion

export const AssetTypes = [
    { id: '3af5b05e-6261-4312-a5c8-44efb014324d', NormalizeName: 'EQUIPMENT', Priority: 1, Name: 'Equipamiento' },
    { id: '115048c0-6992-44f1-9c5d-f0e36897709c', NormalizeName: 'TOOL', Priority: 2, Name: 'Herramienta' },
    { id: '0c5affc1-b227-47f3-a198-b794b4772ec1', NormalizeName: 'ITEM', Priority: 3, Name: 'Artículo' },
    { id: '08e8ecd6-9045-434b-a344-0247c0b50862', NormalizeName: 'VEHICLE', Priority: 3, Name: 'Vehículo' },
]

export const AssetPriorityLevel = [
    { id: '519ee47d-8c4e-430c-9465-c4478561c815', NormalizeName: 'MEDIUM', Priority: 1 },
    { id: '6d8d178a-ac05-4382-93d8-0f31ee9c0be4', NormalizeName: 'LOW', Priority: 2 },
    { id: 'eadb964d-2c7d-4246-8005-f17ce2635508', NormalizeName: 'HIGH', Priority: 3 }
]

export const WorkOrderStateStartedId = '11d2b1a7-6299-45de-ae79-15050ba7174c';
export const WorkOrderStateToValidateId = 'ea1a0d6d-5d49-4a9f-b7cb-dbf074f83ea4';
export const WorkOrderStateFinishedId = '322abb49-beed-483f-a82a-09655e98b5c4';

export const WorkOrderStates = [
    { id: '11d2b1a7-6299-45de-ae79-15050ba7174c', NormalizeName: 'STARTED', Name: 'Iniciada', Priority: 2 },
    { id: 'ea1a0d6d-5d49-4a9f-b7cb-dbf074f83ea4', NormalizeName: 'TOVALIDATE', Name: 'Enviada para validar', Priority: 1 },
    { id: '322abb49-beed-483f-a82a-09655e98b5c4', NormalizeName: 'FINISHED', Name: 'Finalizada', Priority: 3 },
]

export const SupplierCategories = [
    { id: '030cd153-56be-4b1a-b23a-a9cf97439559', NormalizeName: 'Aberturas', Priority: 1 },
    { id: '68bb001a-ba8f-4d71-a3b6-b02f70c9c3e9', NormalizeName: 'Ambulancia', Priority: 1 },
    { id: '6a70f146-3f86-46cc-8214-e1aa0c1d4460', NormalizeName: 'Bomberos', Priority: 1 },
    { id: '2f3bf7c8-21fb-4b14-8b0c-580d2501a629', NormalizeName: 'Cartelería', Priority: 1 },
    { id: '564d3bcb-227e-4b75-8e7b-c5684623399d', NormalizeName: 'Cerrajería', Priority: 1 },
    { id: '9d1efa5d-c072-474f-900d-927431f953e7', NormalizeName: 'Deportes', Priority: 1 },
    { id: '37223cad-9198-47fa-afc9-c891bec89897', NormalizeName: 'Electrodomésticos', Priority: 1 },
    { id: '9e46d2f7-3321-4f30-a867-d75f57101a8e', NormalizeName: 'Ferreteria', Priority: 1 },
    { id: '197b3df0-91ba-48e7-88cb-c2fdb82245cd', NormalizeName: 'Higiene', Priority: 1 },
    { id: '4741bd34-801b-4c3b-bb6c-385bcb56a48d', NormalizeName: 'Librería', Priority: 1 },
    { id: '1e000464-db98-49ab-910b-d137a1d845ab', NormalizeName: 'Maderas/Carpintería', Priority: 1 },
    { id: 'a4d7cf28-01e0-4d60-978c-22c45c126e18', NormalizeName: 'Máquinas Herramientas', Priority: 1 },
    { id: '807259d8-ac1e-4582-b6f1-45f0aa4fa667', NormalizeName: 'Materiales de Construcción', Priority: 1 },
    { id: '8d1487dc-b6fa-4582-96d3-2f11dc62449e', NormalizeName: 'Materiales Eléctricos', Priority: 1 },
    { id: 'bc213844-ece0-443c-af11-f8f4cf4e18a3', NormalizeName: 'Metalurgia', Priority: 1 },
    { id: '6fffc381-0e94-4ca1-9383-ad3b372696ce', NormalizeName: 'Mobiliario', Priority: 1 },
    { id: '9d849bce-53c8-4ff8-b155-eea1c6eb5068', NormalizeName: 'Navales', Priority: 1 },
    { id: 'dcc5ef8c-4f06-415c-ab98-f5aeab4881a6', NormalizeName: 'Pinturería', Priority: 1 },
    { id: 'f71c46f0-b356-471a-a783-1275bddc0d52', NormalizeName: 'Policía', Priority: 1 },
    { id: '2d085f39-b9da-472a-bfbb-76b990bfc905', NormalizeName: 'Productos Químicos', Priority: 1 },
    { id: '1a82c649-99d9-471b-8542-b572335dda8d', NormalizeName: 'Salud', Priority: 1 },
    { id: '6ec92d6c-59cc-4b33-a0d2-99ea1cfa0910', NormalizeName: 'Sanitarios', Priority: 1 },
    { id: 'f5fd8804-ad9b-4b19-9751-4ed2da4dc8c2', NormalizeName: 'Seguridad', Priority: 1 },
    { id: '4c12f56b-1862-4bbc-8519-27bc744940a2', NormalizeName: 'Techista', Priority: 1 },
    { id: 'cfd351b1-d05a-4fd2-a3bf-9a03060ea968', NormalizeName: 'Vehiculos', Priority: 1 },
    { id: '169fb69f-a2c8-4b05-ac20-80c7ea702db4', NormalizeName: 'Zinguería', Priority: 1 },
    { id: '8d60fa1c-308f-49fa-8b1c-49ae9444d2e0', NormalizeName: 'Otro', Priority: 1 }
]

export const FileTypes = [
    { id: '8f196439-5bf0-42df-8eb0-29c57e311eca', NormalizeName: 'GENERAL FILE', Name: 'General', Priority: 0 },
    { id: '64e6c9bc-1d71-45de-9e40-594b0e5fcf48', NormalizeName: 'AREA IMAGE', Name: 'Areas', Priority: 0 },
    { id: '3ea1e751-cab5-4a41-adea-8ca17abb00bb', NormalizeName: 'ASSET IMAGE', Name: 'Activos', Priority: 0 },
    { id: 'bb9148a7-3a00-4e03-8360-c75fd6447caa', NormalizeName: 'MANTENANCETASK IMAGE', Name: 'Tareas de Mantenimiento', Priority: 0 },
    { id: '3350e3ad-97fe-4b2f-9f9e-b54c9d5796e1', NormalizeName: 'AUDIT IMAGE', Name: 'Auditoria', Priority: 0 },
    { id: '8ef49498-ae6b-49bb-aa28-e76ca5eca2dc', NormalizeName: 'BUILDINGSITE IMAGE', Name: 'Control de Obra', Priority: 0 },
    { id: 'c033ed2f-44bd-44b3-bc21-6ceb1c14be5d', NormalizeName: 'TASKREVIEW IMAGE', Name: 'Relevamiento', Priority: 0 },
    { id: '345a860a-f8ed-4e72-8e9d-255864abf7d5', NormalizeName: 'WORKORDER FILE', Name: 'Orden de trabajo', Priority: 0 },
    { id: '6ce223b1-f488-4f8d-b0b8-908cb9a555d4', NormalizeName: 'FUNCTIONALUNIT IMAGE', Name: 'Unidad funcional', Priority: 0 },
    { id: '09939e25-c6c0-4cf2-b3b4-b65537c37d2a', NormalizeName: 'ROOM IMAGE', Name: 'Habitación', Priority: 0 },
    { id: '1ba948ba-a3c8-4164-95f8-b5da59115cc0', NormalizeName: 'SERVICETASK FILE', Name: 'Tareas de servicio', Priority: 0 },
    { id: '7ffc8640-a73d-494f-8f7f-33a339cc784b', NormalizeName: 'SERVICEWORKORDER FILE', Name: 'Order de trabajo de servicio', Priority: 0 },
    { id: '674c46cf-0c66-4091-9137-16ba24f080c5', NormalizeName: 'ROUTINETASK_FILE', Name: 'Tareas de rutina', Priority: 0 },
    { id: '7d4d37d3-e53c-42f8-b00f-fbe385647525', NormalizeName: 'ROUTINEWORKORDER_FILE', Name: 'Order de trabajo de rutina', Priority: 0 },
]

export const Roles = [
    { id: '013170c9-81fc-4914-b218-5742e29270d4', NormalizeName: 'ADMINISTRATOR', Priority: 1 },
    { id: '2d356ba9-a5f0-4253-a12c-6d641a922400', NormalizeName: 'MANAGER', Priority: 2 },
    { id: '38b0213d-8881-4eea-9456-2415a38004a1', NormalizeName: 'MAINTENANCECHIEF', Priority: 3 },
    { id: '0e7b4f3e-c6cf-414d-9c49-112d06102424', NormalizeName: 'MAINTENANCEOPERATOR', Priority: 3 },
    { id: '3f427509-f5b8-41a4-9d73-730529d70062', NormalizeName: 'COUNCILOR', Priority: 3 },
    { id: '850f83e6-7899-4f78-abb7-e913dbb034fa', NormalizeName: 'AUDITOR', Priority: 3 },
    { id: '293d0a58-deaa-4d85-8740-5bc30967e892', NormalizeName: 'TASKIDENTIFIER', Priority: 4 }
]

export const AuditTypes = [
    { id: '2f6bf0f7-434f-4433-b155-3143c945f810', NormalizeName: 'EXTERNALAUDIT', Priority: 0, Name: 'Auditoría Externa' },
    { id: '64331fd7-786b-43f0-a9b8-1e73e2b553b3', NormalizeName: 'INTERNALAUDIT', Priority: 0, Name: 'Auditoría Interna' },
]

export const AuditStates = [
    { id: 'e4613714-d8f1-498a-bb2c-0a414f490754', NormalizeName: 'PENDING', Priority: 0, Name: 'Pendiente' },
    { id: '9e7a07af-701f-4a6c-ae6d-cfbc5e3c25e0', NormalizeName: 'SENT', Priority: 0, Name: 'Enviada' },
    { id: '19ab7c34-05e2-4716-9c48-992ad87debb1', NormalizeName: 'CLOSED', Priority: 0, Name: 'Cerrada' },
]

export const TaskReviewStates = [
    { id: 'e4613714-d8f1-498a-bb2c-0a414f490754', NormalizeName: 'PENDING', Priority: 0, Name: 'Pendiente' },
    { id: '9e7a07af-701f-4a6c-ae6d-cfbc5e3c25e0', NormalizeName: 'SENT', Priority: 0, Name: 'Enviada' },
    { id: 'c94b2765-d35f-42a0-a8c1-91d4c7602deb', NormalizeName: 'DISCARD', Priority: 0, Name: 'Descartada' },
    { id: '19ab7c34-05e2-4716-9c48-992ad87debb1', NormalizeName: 'CLOSED', Priority: 0, Name: 'Cerrada' },
]

export const BuildingSiteStates = [
    { id: 'E00BC19A-2F5D-42F0-8395-BBC7A77B8431', NormalizeName: 'STARTED', Priority: 0, Name: 'Iniciada' },
    { id: '310472B3-7C83-43A8-B926-1CBB8866893D', NormalizeName: 'STANDBY', Priority: 0, Name: 'Suspendida' },
    { id: 'c580c36f-ac10-423f-83b8-930fcc793d0e', NormalizeName: 'FINISHED', Priority: 0, Name: 'Finalizada' },
]

export const Countries = [
    { id: 'c75606e6-59d9-4491-bc74-d9bd2e66377b', NormalizeName: 'Argentina', Priority: 1 },
    { id: 'f52ac25e-12a5-45a4-afdb-80d99a27b6aa', NormalizeName: 'Bolivia', Priority: 2 },
    { id: 'b053e0f1-403b-4c6c-8dd3-415ec82b26ad', NormalizeName: 'Brasil', Priority: 3 },
    { id: 'cf92ea45-048c-47b7-8344-75442f69dd68', NormalizeName: 'Chile', Priority: 4 },
    { id: '072f8bd2-010e-4f5f-9ac4-ed274566f533', NormalizeName: 'Colombia', Priority: 5 },
    { id: 'bfe49e87-a9ef-467e-84b7-a5e5f83586a4', NormalizeName: 'Ecuador', Priority: 6 },
    { id: 'aa74c76b-8c25-4be8-a382-4ceac099340d', NormalizeName: 'España', Priority: 7 },
    { id: '30131303-eec9-4e86-8977-5f1afe6d3255', NormalizeName: 'México', Priority: 8 },
    { id: '8f63693b-5553-4129-99dc-b605579884ed', NormalizeName: 'Paraguay', Priority: 9 },
    { id: '88e64e0e-7ce6-466d-881a-48f725e464b8', NormalizeName: 'Peru', Priority: 10 },
    { id: '3cab1bcb-fcc4-4b32-8aa1-d14cafaf6b5e', NormalizeName: 'Uruguay', Priority: 11 },
    { id: 'd3aa1655-8bc7-4b77-92d2-22df9472a68c', NormalizeName: 'Venezuela', Priority: 12 },
]
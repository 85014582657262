import React, { useContext, useState, useEffect } from 'react';
import { Panel, PanelHeader, PanelFooter, PanelBody } from '.././../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { GetArea, DeleteArea } from '../../../services/redux/areaSlice';
import Upload from '../../../components/app/file/upload.js';
import Viewer from '../../../components/app/file/viewer.js';
import { postfileData } from '../../../services/redux/fileSlice';
import { FileTypes, WorkOrderStateFinishedId } from '../../../utils/generalConstants';
import { useTranslation } from "react-i18next";
import AssetItem from '../../../components/app/asset/listItem.js';
import AreaItem from '../../../components/app/area/listItem';
import Pagination from '../../../components/app/pager/pagination.js';
import { Loader } from '../../widget/loader.js';

const AreaDetail = () => {
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const history = useHistory();
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const area = useSelector(state => state.areaReducer);

    const query = new URLSearchParams(window.location.search);
    const areaId = query.get("id");

    const [modalAddFiles, setModalAddFiles] = useState(false);
    const [modalViewDeleteFile, setModalViewDeleteFile] = useState(false);
    const [modalViewDeleteArea, setModalViewDeleteArea] = useState(false);

    const getArea = async () => {
        const requestData = {
            "tenantId": user.selectedTenant.id,
            "id": areaId
        };
        await dispatch(GetArea({ authToken: user.authToken, params: requestData })).then((response) => {
        });
    };

    const deleteArea = async (id) => {
        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": id,
        }
        await dispatch(DeleteArea({ authToken: user.authToken, body: requestData })).then((response) => {
            toggleModal('modalViewDeleteArea');
            response && response.payload &&
                history.push("/area/list");
        });
    }

    const onUploadSubmit = async (data) => {
        data.append("TenantId", user.selectedTenant.id);
        data.append("AgentId", user.selectedTenant.agentId);
        data.append("CreatedOn", "2015-08-05T19:33:26Z");
        data.append("ReferenceId", area.selectedArea.id);
        data.append("FileTypeId", FileTypes.filter(f => f.NormalizeName === 'AREA IMAGE')[0].id);

        await dispatch(postfileData({ authToken: user.authToken, body: data })).then((response) => {
            setModalAddFiles(false);
            getArea();
        });
    };

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        getArea();
    }, [areaId]);

    const toggleModal = (name) => {
        switch (name) {
            case 'modalAddFiles':
                setModalAddFiles(!modalAddFiles);
                break;
            case 'modalViewDeleteFile':
                setModalViewDeleteFile(!modalViewDeleteFile);
                break;
            case 'modalViewDeleteArea':
                setModalViewDeleteArea(!modalViewDeleteArea);
                break;
            default:
                break;
        }
    }

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (
        <Loader isLoading={area.isFetching}>
            <div>
                {area.selectedArea &&
                    <div>
                        <ol className="breadcrumb float-xl-end" hidden>
                            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/area/list">Areas</Link></li>
                            <li className="breadcrumb-item active">{area.selectedArea.name}</li>
                        </ol>
                        <h1 className="page-header">{area.selectedArea.name} <small>{/*{area.selectedArea.typeNormalizedName}*/}</small></h1>

                        <div className="d-flex align-items-center mb-2">
                            <div className="ms-auto">
                                <Link to="/area/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                                {/* <Link to="/area/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("common.add")}</Link> */}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-6">
                                <div className="col-xl-12">
                                    <Panel>
                                        <PanelHeader noButton={true}>
                                            <div className="d-flex justify-content-between">
                                                {t("common.general_data")}
                                                {(user.selectedTenant.agentRoles.toUpperCase() == "ADMINISTRATOR" ||
                                                    user.selectedTenant.agentRoles.toUpperCase() == "MANAGER") &&
                                                    <div>
                                                        <Link className="btn btn-success btn-sm btn-rounded px-3 rounded-pill" to={{
                                                            pathname: "/area/edit",
                                                            state: {
                                                                area: area.selectedArea
                                                            }
                                                        }}> {t("common.edit")}</Link>
                                                        <button
                                                            className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-2"
                                                            onClick={() => toggleModal('modalAddFiles')}>
                                                            {t("common.add_files")}
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </PanelHeader>
                                        <PanelBody>
                                            <p>{t("common.name")}: {area.selectedArea.name}</p>
                                            {area.selectedArea.description &&
                                                <p>{t("common.description")}: {area.selectedArea.description}</p>
                                            }
                                            {area.selectedArea.parentArea &&
                                                <p>{t("common.parent_area")}: &nbsp;<Link to={"/area/detail?id=" + area.selectedArea.parentArea.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.area")}&nbsp;{area.selectedArea.parentArea.name}</Link></p>
                                            }
                                        </PanelBody>
                                        {(area.selectedArea.workOrders && area.selectedArea.workOrders.length === 0 && area.selectedArea.maintenanceTasks && area.selectedArea.maintenanceTasks.length === 0 && area.selectedArea.assets && area.selectedArea.assets.length === 0) &&
                                            <PanelFooter>
                                                <div className="d-flex align-items-center">
                                                    <div className="ms-auto">
                                                        <button className='btn btn-danger' onClick={() => { toggleModal('modalViewDeleteArea') }}> {t("common.delete")} <i className="fa fa-trash"></i> </button>

                                                    </div>
                                                </div>
                                                {area.error && <div className='text-center'>
                                                    <span className='text-danger'>Ha ocurrido un problema: {area.errorMessage}</span></div>}
                                            </PanelFooter>
                                        }
                                    </Panel>
                                </div>
                                {area.selectedArea.subAreas && area.selectedArea.subAreas.length != 0 &&
                                    <div className="col-xl-12">
                                        <Panel>
                                            <PanelHeader noButton={true}>
                                                <div className="d-flex justify-content-between">
                                                    {t("common.subareas")}
                                                </div>
                                            </PanelHeader>
                                            <PanelBody>
                                                <Pagination dataCount={area.selectedArea.subAreas.length} take={10}>
                                                    <div className="result-list">
                                                        {area.selectedArea.subAreas.map((subArea, index) =>
                                                            <AreaItem key={subArea.id + index} area={subArea}></AreaItem>
                                                            // <>{subArea.id}</>
                                                        )}
                                                    </div>
                                                </Pagination>
                                                {/* :
                                            <div className="alert alert-info" role="alert">
                                                {t("common.empty_list")}
                                            </div> */}
                                            </PanelBody>
                                        </Panel>
                                    </div>
                                }
                                {area.selectedArea.assets && area.selectedArea.assets.length != 0 &&
                                    <div className="col-xl-12">
                                        <Panel>
                                            <PanelHeader noButton={true}>
                                                <div className="d-flex justify-content-between">
                                                    {t("menu.assets")}
                                                </div>
                                            </PanelHeader>
                                            <PanelBody>
                                                <Pagination dataCount={area.selectedArea.assets.length} take={10}>
                                                    <div className="result-list">
                                                        {area.selectedArea.assets.map((asset, index) =>
                                                            <AssetItem key={asset.id} asset={asset}></AssetItem>
                                                        )}
                                                    </div>
                                                </Pagination>
                                                {/* :
                                            <div className="alert alert-info" role="alert">
                                                {t("common.empty_list")}
                                            </div> */}
                                            </PanelBody>
                                        </Panel>
                                    </div>
                                }
                            </div>
                            {(area.selectedArea.workOrders || area.selectedArea.maintenanceTasks) &&
                                <div className="col-xl-3">
                                    {area.selectedArea.maintenanceTasks && area.selectedArea.maintenanceTasks.length != 0 &&
                                        <div className="col-xl-12">
                                            <Panel>
                                                <PanelHeader noButton={true}>
                                                    <div className="d-flex align-items-center">
                                                        {t('task.scheduled_tasks_title')}
                                                    </div>
                                                </PanelHeader>
                                                <PanelBody>
                                                    {area.selectedArea.maintenanceTasks.map((task, index) =>
                                                        <div key={index}>
                                                            <p><i className="fa fa-tasks"></i> {task.description}</p>
                                                            <p>
                                                                <Link to={"/task/detail?id=" + task.id} key={index}>
                                                                    {moment(task.scheduledDate).format("DD/MM/YYYY")}<span className="badge bg-gray ms-8px">{t("task.state_" + task.stateNormalizedName)}</span>
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    )}
                                                    {area.selectedArea.maintenanceTasks.length === 0 &&
                                                        <p>{t('task.scheduled_tasks_title')} - {t('common.no_records_found')}</p>
                                                    }
                                                </PanelBody>
                                            </Panel>
                                        </div>
                                    }
                                    {area.selectedArea.workOrders && area.selectedArea.workOrders.filter(w => w.stateId != WorkOrderStateFinishedId).length != 0 &&
                                        <div className="col-xl-12">
                                            <Panel>
                                                <PanelHeader noButton={true}>
                                                    <div className="d-flex align-items-center">
                                                        {t('dashboard.title_tasks_active')}
                                                    </div>
                                                </PanelHeader>
                                                <PanelBody>
                                                    {area.selectedArea.workOrders.filter(w => w.stateId != WorkOrderStateFinishedId).map((workorder, index) =>
                                                        <div key={index}>
                                                            <p><i className="fa fa-tasks"></i> {workorder.description}</p>
                                                            <p>
                                                                <Link to={"/workorder/detail?id=" + workorder.id} key={index} >
                                                                    {moment(workorder.finishedOn ? workorder.finishedOn : workorder.startedOn).format("DD/MM/YYYY")}<span className="badge bg-gray ms-8px">{t("workOrder.state_" + workorder.stateNormalizedName)}</span>
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    )}
                                                    {area.selectedArea.workOrders.length === 0 &&
                                                        <p>{t('dashboard.title_tasks_active')} - {t('common.no_records_found')}</p>
                                                    }
                                                </PanelBody>
                                            </Panel>
                                        </div>
                                    }
                                    {area.selectedArea.workOrders && area.selectedArea.workOrders.filter(w => w.stateId === WorkOrderStateFinishedId).length != 0 &&
                                        <div className="col-xl-12">
                                            <Panel>
                                                <PanelHeader noButton={true}>
                                                    <div className="d-flex align-items-center">
                                                        {t('dashboard.title_tasks_finished')}
                                                    </div>
                                                </PanelHeader>
                                                <PanelBody>
                                                    {area.selectedArea.workOrders.filter(w => w.stateId === WorkOrderStateFinishedId).map((workorder, index) =>
                                                        <div key={index}>
                                                            <p><i className="fa fa-tasks"></i> {workorder.description}</p>
                                                            <p>
                                                                <Link to={"/workorder/detail?id=" + workorder.id} key={index} >
                                                                    {moment(workorder.finishedOn ? workorder.finishedOn : workorder.startedOn).format("DD/MM/YYYY")}<span className="badge bg-gray ms-8px">{t("workOrder.state_" + workorder.stateNormalizedName)}</span>
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    )}
                                                    {area.selectedArea.workOrders.length === 0 &&
                                                        <p>{t('dashboard.title_tasks_finished')} - {t('common.no_records_found')}</p>
                                                    }
                                                </PanelBody>
                                            </Panel>
                                        </div>
                                    }
                                </div>
                            }
                            <div className="col-xl-3" hidden>
                                <div className="col-xl-12">
                                    <Panel>
                                        <PanelHeader noButton={true}>
                                            <div className="d-flex align-items-center">
                                                Extra information
                                            </div>
                                        </PanelHeader>
                                        <PanelBody>
                                            <p>Panel Content Here</p>
                                        </PanelBody>
                                    </Panel>
                                </div>
                            </div>
                        </div>

                        <Upload onSubmit={onUploadSubmit} isOpen={modalAddFiles} toggleModal={() => toggleModal('modalAddFiles')}  ></Upload>

                        <Viewer files={area.selectedArea.files} />

                        <Modal size="md" style={{ maxWidth: '300px', width: '100%' }} isOpen={modalViewDeleteArea} toggle={() => toggleModal('modalViewDeleteArea')} >
                            <ModalHeader toggle={() => toggleModal('modalViewDeleteArea')}>{t("common.delete")} {t("common.area")}</ModalHeader>
                            <ModalBody>
                                <span>¿Esta seguro que desea eliminar el area <b>{area.selectedArea.name}</b>, creado por <b>{area.selectedArea.createdBy}</b> el <b>{moment(area.selectedArea.createdOn).format("DD/MM/YYYY")}</b> ?</span>
                            </ModalBody>
                            <ModalFooter>
                                <button className="btn btn-danger" onClick={() => deleteArea(area.selectedArea.id)}>Si, borrar!</button>
                                <button className="btn btn-white" onClick={() => toggleModal('modalViewDeleteArea')}>No</button>
                            </ModalFooter>
                        </Modal>

                    </div>
                }
            </div>
        </Loader>
    )
};

export default AreaDetail;
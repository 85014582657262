import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';


export const renderLinesBreak = (text) => {
    return text.split(/\r\n|\r|\n/).map((linea, index) => (
        <div key={index}>{linea}</div>
    ))
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState({
      width: window.innerWidth,
    });
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions({
          width: window.innerWidth,
        });
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }

export const GetDarkMode = () => {
  const user = useSelector((state) => state.userReducer);
  return user.settings.darkMode;
}

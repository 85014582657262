import React, { useContext, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Panel, PanelHeader, PanelFooter, PanelBody } from '.././../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../widget/loader';
import { CreateArea, FullListAreas } from '../../../services/redux/areaSlice';
import { useTranslation } from "react-i18next";
import { renderAreasOptions } from '../../../components/app/area/common.js';

const AreaCreate = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const areas = useSelector(state => state.areaReducer);

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);

        !areas.fullList &&
            dispatch(FullListAreas({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });
    }, []);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {

        let requestData = {
            "tenantId": user.selectedTenant.id,
            "name": data.name,
            "description": (data.description != "") ? data.description : null,
            "parentAreaId": data.parentAreaId ? data.parentAreaId : null,
            "createdById": user.selectedTenant.agentId,
        }

        //console.log(requestData);

        await dispatch(CreateArea({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload && response.payload.data &&
                history.push("/area/detail?id=" + response.payload.data.id);
        });
    };

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (
        <Loader isLoading={areas.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active">Nueva Area</li>
                </ol>
                <h1 className="page-header">Nuevo Area <small>complete los campos obligatorios</small></h1>

                <div className="d-flex align-items-center mb-2">
                    <div className="ms-auto">
                        <Link to="/area/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-7">
                        <div className="col-xl-12">
                            <Panel>
                                <PanelHeader noButton={true}>
                                    <div className="d-flex align-items-center">
                                        General Data
                                    </div>
                                </PanelHeader>
                                <PanelBody>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-floating mb-15px">
                                            <input type="text" className="form-control fs-15px" id="name" {...register("name", {
                                                required: true,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                Name
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <input type="text" className="form-control fs-15px" id="model" {...register("description", {
                                                required: false,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                Description
                                            </label>
                                        </div>

                                        {areas.fullList && areas.fullList.length > 0 &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="parentAreaId" {...register("parentAreaId", {
                                                    required: false
                                                })}>
                                                    <option></option>
                                                    {/* {areas.fullList.map(a => <option key={a.id} value={a.id}>{a.name}</option>)} */}
                                                    {renderAreasOptions(areas.fullList, null, 0, user?.selectedTenant?.settings?.areaMaxLevel)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    {t('common.parent_area')} {/* MaxLevel: {user?.selectedTenant?.settings?.areaMaxLevel} */}
                                                </label>
                                            </div>
                                        }

                                        <div className="mb-20px">
                                            <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value={t("common.save")} />
                                            {areas.error && <div className='text-center'>
                                                <span className='text-danger'>Ha ocurrido un problema: {areas.errorMessage}</span></div>}
                                        </div>

                                    </form>

                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                </div>
            </div>
        </Loader>
    )
};

export default AreaCreate;
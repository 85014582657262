import ReactPDF, { BlobProvider, Document, PDFViewer, Page, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import { t } from 'i18next';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Button } from 'reactstrap';
import { renderLinesBreak } from '../../../utils/common';
import { useTranslation } from 'react-i18next';

const WorkOrderPdf = ({ workOrder, fileName }) => {
  const user = useSelector((state) => state.userReducer);
  const [pdfBlob, setPdfBlob] = useState(null);

  return (
    <div style={styles.container}>
      <BlobProvider document={
        <RenderDocument user={user} workOrder={workOrder} />
      }>
        {({ blob, url, loading, error }) => {
          setPdfBlob(blob);

          return (
            <PDFViewer style={{ flexGrow: 1 }}>
              <RenderDocument user={user} workOrder={workOrder} />
            </PDFViewer>
          );
        }}
      </BlobProvider>

      {pdfBlob && (
        <a
          href={URL.createObjectURL(pdfBlob)}
          download={fileName || 'WorkOrder.pdf'}
          style={styles.downloadButton}
        >
          Descargar PDF
        </a>
      )}
    </div>
  );
};

export default WorkOrderPdf;

//extract Document in a component to use in the BlobProvider
export const RenderDocument = ({ user, workOrder }) => {
  // console.log('user', user);
  console.log('RenderPdfDocument');
  console.log(JSON.stringify(workOrder));
  console.log(workOrder.operatorFullName);
  const { t } = useTranslation('common');

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {RenderPdfHeader(user)}
        <View style={styles.body}>
          {/* <View style={styles.section}>
            <Text>Section #1</Text>
          </View> */}
          {/* <View style={styles.section}> */}
          {/* </View> */}
          <View style={{ marginTop: 10 }} >
          {RenderWorkOrder(workOrder, user)}
          </View>
        </View>
        {/* {RenderPdfFootter(user)} */}
      </Page>

    </Document >
  )
};

export const ExportToPdfWorkOrders = ({ workOrders, fileName }) => {
  const user = useSelector((state) => state.userReducer);
  const [pdfBlob, setPdfBlob] = useState(null);

  return (
    <div style={styles.container}>
      <BlobProvider document={
        <RenderDocumentWorkOrderList user={user} workOrders={workOrders} />
      }>
        {({ blob, url, loading, error }) => {
          setPdfBlob(blob);

          return (
            <PDFViewer style={{ flexGrow: 1 }}>
              <RenderDocumentWorkOrderList user={user} workOrders={workOrders} />
            </PDFViewer>
          );
        }}
      </BlobProvider>

      {pdfBlob && (
        <a
          href={URL.createObjectURL(pdfBlob)}
          download={fileName || 'WorkOrders.pdf'}
          style={styles.downloadButton}
        >
          Descargar PDF
        </a>
      )}
    </div>
  );
};

export const RenderDocumentWorkOrderList = ({ user, workOrders }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {RenderPdfHeader(user)}
        <View style={styles.body}>
          {workOrders.map((workOrder, index) =>
            <View wrap={false} style={{ marginTop: 10 }} >
              {RenderWorkOrder(workOrder, user, index)}
            </View>
          )}
        </View>
        {/* {RenderPdfFootter(user)} */}
      </Page>
    </Document >
  )
};

export const RenderDocumentWorkOrderListOld = ({ user, workOrders }) => {
  return (
    <Document>
      {workOrders.map((workOrder, index) =>
        <Page size="A4" style={styles.page}>
          {index === 0 &&
            RenderPdfHeader(user)}
          <View style={styles.body}>
            <View style={{ marginTop: 10 }}>
              {RenderWorkOrder(workOrder, user, index)}
            </View>
          </View>
          {RenderPdfFootter(user)}
        </Page>
      )}
    </Document >
  )
};

const styles = {
  container: {
    height: '70vh',
    // width: '80vw',
    display: 'flex',
    flexDirection: 'column',
  },
  page: {
    flexDirection: 'column',
    // backgroundColor: '#E4E4E4',
    backgroundColor: '#fff',
    fontSize: 10,
  },
  header: {
    margin: 10,
    padding: 10,
    // flexGrow: 1,
    flex: .4,
    textAlign: 'center',
    backgroundColor: '#bbb',
    borderRadius: '5px',
    borderWidth: '2px',
    borderColor: '#aaa',
    //backgroundColor: 'red',
  },
  body: {
    padding: 20,
    paddingTop: 0,
    flex: 11.4,
    // backgroundColor: 'green',
  },
  footer: {
    margin: 10,
    padding: 10,
    // flexGrow: 1,
    flex: .2,
    textAlign: 'center',
    borderWidth: '1px',
    borderRight: 'none',
    borderBottom: 'none',
    borderLeft: 'none',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    // backgroundColor: 'red',
  },
  downloadButton: {
    alignSelf: 'flex-end',
    margin: '10px',
    padding: '5px 10px',
    cursor: 'pointer',
    backgroundColor: 'blue',
    textDecoration: 'none',
    color: 'white',
    borderRadius: '5px',
  },
};


{/* <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text>Header</Text>
        </View>
        <View style={styles.body}>
          <View style={styles.section}>
            <Text>Section #1</Text>
          </View>
          <View style={styles.section}>
            <Text>Section #2</Text>
          </View>
          <View style={styles.section}>
            <Text>Section #3</Text>
          </View>
          <View style={styles.section}>
            <Text>Section #4</Text>
          </View>
          <View style={styles.section}>
            <Text>Section #5</Text>
          </View>
          <View style={styles.section}>
            <Text>Section #6</Text>
          </View>
        </View>
        <View style={styles.footer}>
          <Text>Footer</Text>
        </View>
      </Page> */}

const RenderWorkOrder = (workOrder, user, key = 0) => {
  const { t } = useTranslation('common');
  return (
    <View key={key} style={{ break: 'avoid', flexWrap: 'nowrap', padding: 5, border: '2px solid #999', borderRadius: 4, backgroundColor: '#fff' }}>
      <View>
        <Text style={{ padding: 5, marginVertical: 0, backgroundColor: '#444', color: '#fff' }}>{workOrder.description.toUpperCase()}</Text>
        <Text style={{ padding: 5, marginVertical: 5, backgroundColor: '#bbb', color: '#000' }}>{t("common.work_order").toUpperCase()}</Text>
        <Text>Asignada a: {workOrder.operatorFullName}</Text>
        <Text>{t("common.started_on")}: {moment(workOrder.startedOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm")}</Text>
        {workOrder.finishedOn &&
          <Text>{t("common.finished_on")}: {moment(workOrder.finishedOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm")}</Text>
        }
        <Text>Estado de la Orden de trabajo: {t("workOrder.state_" + workOrder.stateNormalizedName)}</Text>
        <Text>{t("common.created_by")}: {workOrder.createdBy}</Text>
      </View>
      {workOrder.detail &&
        <View>
          <Text style={{ padding: 5, marginVertical: 5, backgroundColor: '#ccc' }}>{t("common.actions_taken").toUpperCase()}</Text>
          <Text>
            {workOrder.detail}
          </Text>
        </View>
      }
      {workOrder.movements && workOrder.movements.length > 0 &&
        <View>
          <Text style={{ padding: 5, marginVertical: 5, backgroundColor: '#ccc' }}>{t("common.expenses").toUpperCase()}</Text>
          {workOrder.movements.map((movement, index) =>
            <View style={{ flexDirection: 'row', flexGrow: 1, alignContent: 'space-between', color: '#000' }}>
              <Text style={{ flex: 2 }}>{moment(movement.movementDate).format("DD/MM/YYYY")}</Text>
              <Text style={{ flex: 2, textAlign: 'center' }}>{movement.typeNormalizedName}</Text>
              <Text style={{ flex: 7 }}>{movement.description}</Text>
              <Text style={{ flex: 2, textAlign: 'right' }} >{movement.amount} {movement.currencyCode}</Text>
            </View>
          )}
        </View>
      }
      <View>
        <Text style={{ padding: 5, marginVertical: 5, backgroundColor: '#ccc' }}>{t("common.task").toUpperCase()}</Text>
        <Text>{t("common.description")}: {workOrder.description}</Text>
        {workOrder.maintenanceTaskDetail &&
          <>
            <Text>{t("common.detail")}:</Text>
            <Text style={{ marginVertical: 5 }}>{workOrder.maintenanceTaskDetail}</Text>
          </>
        }
        <Text>{t("common.type")}: {t("task.type_" + workOrder.typeNormalizedName)}</Text>
        <Text>{t("common.priority")}: {t("common.priority_level_" + workOrder.priorityLevelNormalizedName)}</Text>
        <Text>Requiere Validación: {workOrder.requireValidation ? "Sí" : "No"}</Text>
        {workOrder.area &&
          <Text>{t("common.area")}: {workOrder.area.name}</Text>
        }
        {workOrder.asset &&
          <Text>{t("common.asset")}: {workOrder.asset.name}</Text>
        }
        {workOrder.functionalUnit &&
          <Text>{t("common.functional_unit")}: {workOrder.functionalUnit.number}</Text>
        }
        {workOrder.room &&
          <Text>{t("common.room")}: {workOrder.room.name}</Text>
        }
        {workOrder.supplier &&
          <Text>{t("common.supplier")}: {workOrder.supplier.name}</Text>
        }
      </View>
    </View>
  )
}


function RenderPdfHeader(user) {
  return (
    <View style={{ margin: 20, marginBottom: 0, padding: 7, backgroundColor: '#bbb', borderRadius: '5px' /*, borderWidth: '2px', borderColor: '#bbb' */ }}>
      <View style={{ flexDirection: 'row', alignContent: 'space-between', color: '#000' }}>
        <Text style={{ flex: 1, textAlign: 'left', fontSize: 20 }}>{user.selectedTenant.name}</Text>
        <Text style={{ flex: 1, textAlign: 'right', fontSize: 20, fontWeight: 'extrabold' }}>WARTUN</Text>
        {/* <Image
          src="https://react-pdf.org/static/images/luke.jpg"
          style={styles.image}
        /> */}
      </View>
      <View style={{ fontSize: 10, marginTop: 2, paddingVertical: 3, paddingLeft: 3, backgroundColor: '#fff', borderRadius: 2 }}>
        <Text>Generado por {user.selectedTenant.fullName} | {moment(new Date()).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm")} </Text>
      </View>
    </View>
  );
}


function RenderPdfFootter(user) {
  return <View style={styles.footer}>
    <View style={{ flexDirection: 'row', flex: 1, alignContent: 'space-between', color: '#000' }}>
      <Text style={{ flex: 1, textAlign: 'left', fontSize: 10 }}>{user.selectedTenant.name}</Text>
      <Text style={{ flex: 1, textAlign: 'center', fontSize: 10 }}>Generado por: {user.selectedTenant.fullName} {moment(new Date()).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm")} </Text>
      <Text style={{ flex: 1, textAlign: 'right', fontSize: 10, fontWeight: 'extrabold' }}>WARTUN</Text>
    </View>
  </View>;
}

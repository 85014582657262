import React from "react";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AppSettings } from "./../../config/app-settings.js";
import SidebarProfile from "./sidebar-profile.jsx";
import SidebarNav from "./sidebar-nav.jsx";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const { t } = useTranslation("common");
  const user = useSelector((state) => state.userReducer);
  return (
    <AppSettings.Consumer>
      {({
        appSidebarMinify,
        toggleAppSidebarMinify,
        toggleAppSidebarMobile,
        appSidebarTransparent,
        appSidebarGrid,
      }) => (
        <React.Fragment>
          <div
            id="sidebar"
            className={
              "app-sidebar " +
              (appSidebarTransparent ? "app-sidebar-transparent" : "") +
              (appSidebarGrid ? "app-sidebar-grid" : "")
            }
          >
            <PerfectScrollbar
              className="app-sidebar-content"
              options={{ suppressScrollX: true }}
            >
              <div className="menu">
                {/*{!this.context.appSidebarSearch && (*/}
                <SidebarProfile />
                {/*)}*/}
              </div>
              <SidebarNav />
              {user.selectedTenant &&
              user.selectedTenant.agentRoles &&
              user.selectedTenant.agentRoles.toUpperCase() != "AUDITOR" &&
              appSidebarMinify ? (
                <div className="d-flex align-items-center mt-20px mb-10px">
                  <div className="m-auto pe-5px">
                    <Link
                      to="/taskreview/create"
                      className="btn btn-green btn-sm btn-rounded px-3 rounded-pill"
                    >
                      <i className="fa fa-plus me-1"></i>{" "}
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center mt-20px mb-10px">
                  <div className="m-auto pe-5px">
                    <Link
                      to="/taskreview/create"
                      className="btn btn-green btn-sm btn-rounded px-3 rounded-pill"
                    >
                      <i className="fa fa-plus me-1"></i>{" "}
                      {t("identifyTask.identifyTask")}
                    </Link>
                  </div>
                </div>
              )}
              <div className="menu">
                <div className="menu-item d-flex">
                  <Link
                    to="/"
                    className="app-sidebar-minify-btn ms-auto"
                    onClick={toggleAppSidebarMinify}
                  >
                    <i className="fa fa-angle-double-left"></i>
                  </Link>
                </div>
              </div>
            </PerfectScrollbar>
          </div>
          <div className="app-sidebar-bg"></div>
          <div className="app-sidebar-mobile-backdrop">
            <Link
              to="/"
              onClick={toggleAppSidebarMobile}
              className="stretched-link"
            ></Link>
          </div>
        </React.Fragment>
      )}
    </AppSettings.Consumer>
  );
};

// class Sidebar1 extends React.Component {
// 	static contextType = AppSettings;

// 	render() {
// 		return (
// 			<AppSettings.Consumer>
// 				{({ toggleAppSidebarMinify, toggleAppSidebarMobile, appSidebarTransparent, appSidebarGrid }) => (
// 					<React.Fragment>
// 						<div id="sidebar" className={'app-sidebar ' + (appSidebarTransparent ? 'app-sidebar-transparent' : '') + (appSidebarGrid ? 'app-sidebar-grid' : '')}>
// 							<PerfectScrollbar className="app-sidebar-content" options={{ suppressScrollX: true }}>
// 								<div className="menu">
// 									{/*{!this.context.appSidebarSearch && (*/}
// 									<SidebarProfile />
// 									{/*)}*/}
// 								</div>
// 								<SidebarNav />
// 								{/* {user.selectedTenant.agentRoles.toUpperCase() != "AUDITOR" && */}
// 									<div className="d-flex align-items-center mt-20px mb-10px">
// 										<div className="m-auto pe-5px">
// 											<Link to="/taskreview/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill"><i className="fa fa-plus me-1"></i> Relevar Tarea</Link>
// 										</div>
// 									</div>
// 								{/* } */}
// 								<div className="menu">
// 									<div className="menu-item d-flex">
// 										<Link to="/" className="app-sidebar-minify-btn ms-auto" onClick={toggleAppSidebarMinify}>
// 											<i className="fa fa-angle-double-left"></i>
// 										</Link>
// 									</div>
// 								</div>

// 							</PerfectScrollbar>
// 						</div>
// 						<div className="app-sidebar-bg"></div>
// 						<div className="app-sidebar-mobile-backdrop"><Link to="/" onClick={toggleAppSidebarMobile} className="stretched-link"></Link></div>
// 					</React.Fragment>
// 				)}
// 			</AppSettings.Consumer>
// 		)
// 	}
// }

export default Sidebar;

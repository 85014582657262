import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { PURGE } from "redux-persist";
import { ChangeTenant } from "../redux/userSlice";

const initialState = {
    list: null,
    fullList: null,
    dataCount: null,
    dataCountFiltered: null,
    selectedRoom: null,
    lastSync: null,
    isFetching: false,
    error: false,
    errorMessage: "",
    status: "",
}

export const GetRoom = createAsyncThunk('rooms/fetchGetRoom', async ({ authToken, params }) => {
    let response = await api.getRoom(authToken, params);
    return response;
});

export const ListRooms = createAsyncThunk('rooms/fetchListRooms', async ({authToken, body}) => {
    let response = await api.listRooms(authToken, body);
    return response;
});

export const FullListRooms = createAsyncThunk('area/fetchFullListRooms', async ({ authToken, tenantId }) => {
    let body = {
        "tenantId": tenantId,
        "sortBy": null,
        "sortDir": true,
        "skip": 0,
        "take": null
    };
    let response = await api.listRooms(authToken, body);
    return response;
});

export const CreateRoom = createAsyncThunk('rooms/fetchCreateRoom', async ({ authToken, body }) => {
    let response = await api.createRoom(authToken, body);
    return response;
});

export const EditRoom = createAsyncThunk('rooms/fetchEditRoom', async ({ authToken, body }) => {
    let response = await api.editRoom(authToken, body);
    return response;
});

export const DeleteRoom = createAsyncThunk('rooms/fetchDeleteRoom', async ({ authToken, body }) => {
    let response = await api.deleteRoom(authToken, body);
    return response;
});

const roomSlice = createSlice({
    name: "room",
    initialState,
    reducers: {

    },
    extraReducers: {

        [GetRoom.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [GetRoom.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedRoom = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [GetRoom.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [ListRooms.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [ListRooms.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.list = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [ListRooms.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [FullListRooms.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [FullListRooms.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.fullList = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [FullListRooms.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [CreateRoom.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [CreateRoom.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedRoom = payload.data;
            state.fullList = null;
        },
        [CreateRoom.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [EditRoom.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [EditRoom.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedRoom = payload.data;
            state.fullList = null;
        },
        [EditRoom.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [DeleteRoom.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [DeleteRoom.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedRoom = null;
            state.fullList = null;
        },
        [DeleteRoom.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [ChangeTenant.fulfilled]: (state, { payload }) => {
            return initialState;
            // state.isFetching = false;
            // state.error = false;
            // state.errorMessage = '';
            // state.fullList = null;
            // state.dataCount = null;
            // state.dataCountFiltered = null;
        },

        [PURGE]: (state) => {
            return initialState;
        },
    }
})

export default roomSlice.reducer; 
import React, { useContext, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import { Panel, PanelHeader, PanelBody } from '.././../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EditRoutineTask } from '../../../services/redux/routineTaskSlice';
import { postfileData } from '../../../services/redux/fileSlice';
import { FullListAssets } from '../../../services/redux/assetSlice';
import { FullListFunctionalUnits } from '../../../services/redux/functionalUnitSlice';
import { FullListRooms } from '../../../services/redux/roomSlice';
import { FullListAreas } from '../../../services/redux/areaSlice';
import { FullListSuppliers } from '../../../services/redux/supplierSlice';
import { RoutineTaskTypes, RoutineTaskPriorityLevel, HotelTenantType, PrivateNeighborhoodTenantType } from '../../../utils/generalConstants'
import { Loader } from '../../widget/loader'
import Upload from '../../../components/app/file/upload.js';
import { FileTypes } from '../../../utils/generalConstants';
import { useTranslation } from "react-i18next";
import { renderAreasOptions } from '../../../components/app/area/common.js';
import { FullListUsers } from '../../../services/redux/userSlice.js';
import moment from 'moment';
import { Alert } from 'reactstrap';

const RoutineTaskEdit = () => {

    const location = useLocation();
    const { routineTask } = location.state

    const dispatch = useDispatch();
    const history = useHistory();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const task = useSelector(state => state.routineTaskReducer)
    const assets = useSelector(state => state.assetReducer)
    const functionalUnits = useSelector(state => state.functionalUnitReducer)
    const rooms = useSelector(state => state.roomReducer)
    const areas = useSelector(state => state.areaReducer)
    const suppliers = useSelector(state => state.supplierReducer)

    //console.log(routineTask);
    const [startDate, setStartDate] = useState(new Date(routineTask.startDate));
    const [endDate, setEndDate] = useState(new Date(routineTask.endDate ?? routineTask.endDate));
    const [selectedScheduleTypeId, setSelectedScheduleTypeId] = useState(routineTask.scheduleTypeId.toString());

    const [modalAddFiles, setModalAddFiles] = useState(false);

    const [autoStartDisabled, setAutoStartDisabled] = useState(routineTask.responsibleId === null);
    const [autoStart, setAutoStart] = useState(routineTask.autoStart);

    const [selectAreaId, setSelectAreaId] = useState(routineTask.areaId);
    const [assetsRelatedToSelectedArea, setAssetsRelatedToSelectedArea] = useState(null);
    const [selectedAssetId, setSelectedAssetId] = useState(routineTask.assetId);

    const [daysOfWeek, setDaysOfWeek] = useState(routineTask.daysOfWeek ? routineTask.daysOfWeek.split(',') : []);
    const [dayOfMonth, setDayOfMonth] = useState(routineTask.dayOfMonth || '');
    const [omitWeekends, setOmitWeekends] = useState(routineTask.omitWeekends || false);
    const [omitHolidays, setOmitHolidays] = useState(routineTask.omitHolidays || false);
    const [taskTime, setTaskTime] = useState(routineTask.taskTime || '');

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);

        !user.fullList &&
            dispatch(FullListUsers({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

        !assets.fullList &&
            dispatch(FullListAssets({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

        !functionalUnits.fullList &&
            dispatch(FullListFunctionalUnits({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

        !rooms.fullList &&
            dispatch(FullListRooms({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            })
        !areas.fullList &&
            dispatch(FullListAreas({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

    }, []);

    useEffect(() => {

        if (selectAreaId && assets.fullList) {
            const assetsRelatedToSelectedArea = assets.fullList.filter(a => a.areaId === selectAreaId);
            if (assetsRelatedToSelectedArea.length > 0) {
                setAssetsRelatedToSelectedArea(assetsRelatedToSelectedArea);
                // console.log("selectAreaId");
                // console.log(selectAreaId);
            } else {
                setAssetsRelatedToSelectedArea(null);
                // console.log("selectAreaId else");
                // console.log(selectAreaId);
            }
        }

    }, [selectAreaId, assets.fullList]);

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async (data) => {
        let requestData = {
            "id": routineTask.id,
            "tenantId": user.selectedTenant.id,
            "description": data.description,
            "detail": data.detail,
            "responsibleId": (data.operatorId != "") ? data.operatorId : null,
            "autoStart": autoStart,
            "priorityLevelId": data.priorityLevelId,
            "areaId": (data.areaId != "") ? data.areaId : null,
            "assetId": (data.assetId != "") ? data.assetId : null,
            "functionalUnitId": (data.functionalUnitId != "") ? data.functionalUnitId : null,
            "roomId": (data.roomId != "") ? data.roomId : null,
            "estimatedTime": (data.estimatedTime != "") ? data.estimatedTime : null,
            "maintenanceTaskStateId": routineTask.stateId,
            "routineSchedule": {
                "scheduleTypeId": (data.scheduleTypeId != undefined && data.scheduleTypeId != "") ? data.scheduleTypeId : 1,
                "interval": (data.interval != undefined && data.interval != "") ? data.interval : 1,
                "startDate": moment(startDate).format("YYYY-MM-DD"),
                "endDate": (endDate != null) ? moment(endDate).format("YYYY-MM-DD") : moment(startDate).format("YYYY-MM-DD"),
                "daysOfWeek": (data.scheduleTypeId === "3" && daysOfWeek.length > 0) ? daysOfWeek.join(",") : null,  // Para frecuencia semanal
                "dayOfMonth": (data.scheduleTypeId === "4" && dayOfMonth !== '') ? parseInt(dayOfMonth) : null,  // Para frecuencia mensual
                "omitWeekends": omitWeekends,  // Omitir fines de semana
                "omitHolidays": omitHolidays,  // Omitir feriados
                "taskTime": taskTime !== '' ? taskTime : null,  // Hora específica de la tarea
                "createdById": user.selectedTenant.agentId,
            },
            "createdById": user.selectedTenant.agentId,
        }
        //console.log(startDate);
        // console.log(requestData);
        await dispatch(EditRoutineTask({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload && response.payload.data &&
                history.push("/routinetask/detail?id=" + routineTask.id);
        });
    };

    const onUploadSubmit = async (data) => {

        data.append("TenantId", user.selectedTenant.id);
        data.append("AgentId", user.selectedTenant.agentId);
        data.append("CreatedOn", "2015-08-05T19:33:26Z");
        data.append("ReferenceId", routineTask.id);
        data.append("FileTypeId", FileTypes.filter(f => f.NormalizeName === 'ROUTINETASK_FILE')[0].id);

        await dispatch(postfileData({ authToken: user.authToken, body: data })).then((response) => {
            setModalAddFiles(false);
            response && response.payload && response.payload.data &&
                history.push("/routinetask/detail?id=" + response.payload.data.referenceId);
        });
    };

    const toggleModal = (name) => {
        switch (name) {
            case 'modalAddFiles':
                setModalAddFiles(!modalAddFiles);
                break;
            default:
                break;
        }
    }



    if (!user.authToken) {
        return <Redirect to='/user/login' />;
    }

    return (
        <Loader isLoading={task.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/routinetask/dashboard">{t('menu.tasks_dashboard')}</Link></li>
                    <li className="breadcrumb-item"><Link to={"/routinetask/detail?id=" + routineTask.id}>{routineTask.description}</Link></li>
                    <li className="breadcrumb-item active">{t('common.edit')} {t('menu.tasks')}</li>
                </ol>
                <h1 className="page-header">{t('common.edit')} {t('menu.tasks')} <small>{t('common.complete_required_fields')}</small></h1>

                <div className="d-flex align-items-center mb-2">
                    <div className="ms-auto">
                        <Link to="/routinetask/dashboard" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-tasks me-1"></i> {t("menu.tasks_dashboard")}</Link>
                        <Link to="/routinetask/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                        <Link hidden to="/routinetask/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("common.add")}</Link>
                        <Link to={"/routinetask/detail?id=" + routineTask.id} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-7">
                        <div className="col-xl-12">
                            <Panel>
                                <PanelHeader noButton={true}>
                                    <div className="d-flex justify-content-between">
                                        {routineTask.description}
                                        <button hidden
                                            className="btn btn-green btn-sm btn-rounded px-3 rounded-pill"
                                            onClick={() => toggleModal('modalAddFiles')}>
                                            {t('common.add_files')}
                                        </button>
                                    </div>
                                </PanelHeader>
                                <PanelBody>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-floating mb-15px">
                                            <input type="text" maxLength={90} className={"form-control fs-15px" + (errors.description ? ' is-invalid' : '')}
                                                id="description" {...register("description", {
                                                    required: true,
                                                    value: routineTask.description
                                                })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.description')}
                                            </label>
                                            {errors.description && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <textarea rows="5" maxLength={2000} className={"form-control fs-15px h-100" + (errors.detail ? ' is-invalid' : '')}
                                                id="detail" {...register("detail", {
                                                    required: false,
                                                    value: routineTask.detail,
                                                })} />
                                            <label htmlFor="floatingInput" className="fs-13px">
                                                {t('common.detail')}
                                            </label>
                                            {errors.detail && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
                                        </div>

                                        {user.fullList &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="operatorId" {...register("operatorId", {
                                                    required: false,
                                                    value: routineTask.responsibleId,
                                                })}
                                                    onChange={(e) =>
                                                    (
                                                        // console.log(e.target.value),
                                                        (e.target.value === "") ?
                                                            (setAutoStartDisabled(true), setAutoStart(false))
                                                            :
                                                            (setAutoStartDisabled(false))
                                                    )
                                                    }
                                                >
                                                    <option></option>
                                                    {user.fullList.map((user, index) =>
                                                        (user.role.normalizedName.includes("MANAGER") ||
                                                            user.role.normalizedName.includes("MAINTENANCECHIEF") ||
                                                            user.role.normalizedName.includes("MAINTENANCEOPERATOR")) &&
                                                        <option key={user.agent.id} value={user.agent.id}>{user.agent.fullName} ({t("roles.rol_" + user.role.normalizedName)})</option>
                                                    )
                                                    }
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    Asignar a{/* {t("workOrder.operator")} */}
                                                </label>
                                            </div>
                                        }

                                        {/* <div className="row mb-10px align-items-center">
                                            <div className="col-8 control-label text-dark fw-bold">Iniciar automáticamente en la fecha programada</div>
                                            <div className="col-4 d-flex">
                                                <div className="form-check form-switch ms-auto mb-0">
                                                    <input type="checkbox" className="form-check-input" name="app-header-inverse" onChange={(e) => setAutoStart(e.target.checked)} id="appHeaderInverse" checked={autoStart} disabled={autoStartDisabled} />
                                                    <label className="form-check-label" htmlFor="appHeaderInverse">&nbsp;</label>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div>
                                            <div className="bg-blue-100 mb-15px p-15px">
                                                <div className="form-floating mb-15px">
                                                    <DatePicker dateFormat="dd-MM-yyyy" className="form-control fs-15px pt-25px" selected={startDate} onChange={(date) => setStartDate(date)} required />
                                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-11px pt-1px mb-10px pb-30px">
                                                        {t('common.from')} {/* {startDate.toString()} */}
                                                    </label>
                                                </div>
                                                <div className="form-floating mb-15px">
                                                    <DatePicker dateFormat="dd-MM-yyyy" className="form-control fs-15px pt-25px" selected={endDate} onChange={(date) => setEndDate(date)} required />
                                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-11px pt-1px mb-10px pb-30px">
                                                        {t('common.to')} {/* {endDate.toString()} */}
                                                    </label>
                                                </div>
                                                <div className="form-floating mb-15px">

                                                    <select className="form-select" id="scheduleTypeId" {...register("scheduleTypeId", {
                                                        required: true,
                                                    })}
                                                        onChange={(e) => {
                                                            // Reset the specific fields when the schedule type changes
                                                            setDaysOfWeek([]);
                                                            setDayOfMonth('');
                                                            setOmitWeekends(false);
                                                            setOmitHolidays(false);
                                                            setTaskTime('');
                                                            setSelectedScheduleTypeId(e.target.value);
                                                        }}>
                                                        <option value="2" key="2" selected={routineTask.scheduleTypeId === 2}>{t('common.daily')} </option>
                                                        <option value="3" key="3" selected={routineTask.scheduleTypeId === 3}>{t('common.weekly')} </option>
                                                        <option value="4" key="4" selected={routineTask.scheduleTypeId === 4}>{t('common.monthly')} </option>
                                                    </select>
                                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                        {t('common.frequency')}
                                                    </label>
                                                </div>

                                                {selectedScheduleTypeId === "3" && (
                                                    <div className="mb-15px">
                                                        <label className="d-flex align-items-center fs-13px mb-2">Días de la semana</label>
                                                        <div className="form-row">
                                                            {[...Array(7)].map((_, i) => (
                                                                <div key={i} className="form-check form-check-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        value={i + 1}
                                                                        checked={daysOfWeek.includes((i + 1).toString())}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            setDaysOfWeek(prev => prev.includes(value)
                                                                                ? prev.filter(day => day !== value)
                                                                                : [...prev, value]);
                                                                        }}
                                                                    /> {['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'][i]}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}

                                                {selectedScheduleTypeId === "4" && (
                                                    <div className="mb-15px">
                                                        <label className="d-flex align-items-center fs-13px mb-2">Día del mes</label>
                                                        <select
                                                            className="form-select"
                                                            value={dayOfMonth}
                                                            onChange={(e) => setDayOfMonth(e.target.value)}
                                                        >
                                                            <option value="">Selecciona un día</option>
                                                            {[...Array(31)].map((_, i) => (
                                                                <option key={i} value={i + 1}>{i + 1}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                )}

                                                <div className="form-floating mb-15px">
                                                    <input
                                                        type="time"
                                                        className="form-control"
                                                        value={taskTime}
                                                        onChange={(e) => setTaskTime(e.target.value)}
                                                    />
                                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                        {t('common.task_time')}
                                                    </label>
                                                </div>

                                                <div className="form-floating">
                                                    <input type="number" pattern="[0-365]*" className="form-control fs-15px" id="interval" defaultValue="1" {...register("interval", {
                                                        required: true,
                                                        value: routineTask.interval
                                                    })} />
                                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                        {t('common.interval')}
                                                    </label>
                                                </div>

                                                <div className="form-floating mt-15px">
                                                    {selectedScheduleTypeId !== "3" &&
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={omitWeekends}
                                                                onChange={(e) => setOmitWeekends(e.target.checked)}
                                                            />
                                                            <label className="form-check-label">
                                                                Omitir fines de semana
                                                            </label>
                                                        </div>
                                                    }
                                                    <div className="form-check" hidden>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={omitHolidays}
                                                            onChange={(e) => setOmitHolidays(e.target.checked)}
                                                        />
                                                        <label className="form-check-label">
                                                            Omitir feriados
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <select className={"form-select" + (errors.priorityLevelId ? ' is-invalid' : '')} id="priorityLevelId" {...register("priorityLevelId", {
                                                required: true,
                                                value: routineTask.priorityLevelId
                                            })}>
                                                <option></option>
                                                {RoutineTaskPriorityLevel.map(o => <option key={o.id} value={o.id}>{t("common.priority_level_" + o.NormalizeName)}</option>)}
                                            </select>
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.priority')}
                                            </label>
                                            {errors.priorityLevelId && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
                                        </div>

                                        {areas.fullList &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="areaId" {...register("areaId", {
                                                    required: false,
                                                    value: routineTask.areaId
                                                })}
                                                    onChange={(e) => setSelectAreaId(e.target.value)}
                                                >
                                                    <option></option>
                                                    {/* {areas.fullList.map(a => <option key={a.id} value={a.id}>{a.name}</option>)} */}
                                                    {renderAreasOptions(areas.fullList, null)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    {t('common.area')}
                                                </label>
                                            </div>
                                        }

                                        {user?.selectedTenant?.tenantTypeId == PrivateNeighborhoodTenantType &&
                                            functionalUnits.fullList &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="functionalUnitId" {...register("functionalUnitId", {
                                                    required: false,
                                                    value: routineTask.functionalUnitId
                                                })}>
                                                    <option></option>
                                                    {functionalUnits.fullList.map(a => <option key={a.id} value={a.id}>{a.number}</option>)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    {t('common.functional_unit')}
                                                </label>
                                            </div>
                                        }

                                        {user?.selectedTenant?.tenantTypeId == HotelTenantType &&
                                            rooms.fullList &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="roomId" {...register("roomId", {
                                                    required: false,
                                                    value: routineTask.roomId
                                                })}>
                                                    <option></option>
                                                    {rooms.fullList.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    {t('common.room')}
                                                </label>
                                            </div>
                                        }

                                        {assets.fullList &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="assetId" {...register("assetId", {
                                                    required: false,
                                                    value: routineTask.assetId
                                                })}
                                                    onChange={(e) => setSelectedAssetId(e.target.value)}
                                                    value={selectedAssetId}
                                                >
                                                    <option key="select" value=""></option>
                                                    {assetsRelatedToSelectedArea &&
                                                        <option key="related" value=""># Relacionados al area #</option>
                                                    }
                                                    {assetsRelatedToSelectedArea && assetsRelatedToSelectedArea.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
                                                    {assetsRelatedToSelectedArea &&
                                                        <option key="none" value=""></option>
                                                    }
                                                    {assetsRelatedToSelectedArea &&
                                                        <option key="all" value=""># Todos #</option>
                                                    }
                                                    {assets.fullList.map(a => <option key={"all" + a.id} value={a.id}>{a.name}</option>)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    {t('common.asset')}
                                                </label>
                                            </div>
                                        }

                                        <div className="form-floating mb-15px">
                                            <input type="number" pattern="[0-999]*" className={"form-control fs-15px" + (errors.estimatedTime ? ' is-invalid' : '')}
                                                id="estimatedTime" {...register("estimatedTime", {
                                                    required: false,
                                                    value: routineTask.estimatedTime && routineTask.estimatedTime
                                                })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.estimated_time')} (horas)
                                            </label>
                                        </div>

                                        <div className="mb-20px">
                                            <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value={t("common.save")} />
                                            {task.error && <div className='text-center'>
                                                <span className='text-danger'>{t("common.error", { message: task.errorMessage })}</span></div>}
                                        </div>

                                    </form>

                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                    <div className="col-xl-3" hidden>
                        <div className="col-xl-12" hidden>
                            <Panel>
                                <PanelHeader noButton={true}>
                                    <div className="d-flex align-items-center">
                                        {t('common.aditional_info')}
                                    </div>
                                </PanelHeader>
                                <PanelBody>
                                    <p>Content</p>
                                </PanelBody>
                            </Panel>
                        </div>
                        <Upload onSubmit={onUploadSubmit} isOpen={modalAddFiles} toggleModal={() => toggleModal('modalAddFiles')}  ></Upload>
                    </div>
                </div>
            </div>
        </Loader >
    )
};

export default RoutineTaskEdit;
import React, { useContext, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import { Link, Redirect, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Panel, PanelHeader, PanelFooter, PanelBody } from '.././../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { useDispatch, useSelector } from 'react-redux';
import { CreateServiceTask } from '../../../services/redux/serviceTaskSlice';
import { FullListAssets } from '../../../services/redux/assetSlice';
import { FullListFunctionalUnits } from '../../../services/redux/functionalUnitSlice';
import { FullListRooms } from '../../../services/redux/roomSlice';
import { FullListAreas } from '../../../services/redux/areaSlice';
import { FullListSuppliers } from '../../../services/redux/supplierSlice';
import { ServiceTaskTypes, ServiceTaskPriorityLevel, ServiceTasksIdPending, HotelTenantType, PrivateNeighborhoodTenantType } from '../../../utils/generalConstants';
import { Loader } from '../../widget/loader';
import { useTranslation } from "react-i18next";
import { renderAreasOptions } from '../../../components/app/area/common.js';
import { FullListUsers } from '../../../services/redux/userSlice.js';

const ServiceTaskCreate = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');
    const query = new URLSearchParams(window.location.search)

    const user = useSelector(state => state.userReducer);
    const task = useSelector(state => state.serviceTaskReducer)
    const assets = useSelector(state => state.assetReducer)
    const functionalUnits = useSelector(state => state.functionalUnitReducer)
    const rooms = useSelector(state => state.roomReducer)
    const areas = useSelector(state => state.areaReducer)
    const queryAssetId = query.get("assetId"); // VERIFICAR SI ESTO ES NECESARIO

    const [startDate, setStartDate] = useState(new Date());
    const [selectAreaId, setSelectAreaId] = useState(null);
    const [assetsRelatedToSelectedArea, setAssetsRelatedToSelectedArea] = useState(null);
    const [selectedAssetId, setSelectedAssetId] = useState(query.get("assetId") ? query.get("assetId") : null);

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        //context.handleSetAppContentClass('p-10');

        !user.fullList &&
            dispatch(FullListUsers({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

        !assets.fullList &&
            dispatch(FullListAssets({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            })

        !functionalUnits.fullList &&
            dispatch(FullListFunctionalUnits({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

        !rooms.fullList &&
            dispatch(FullListRooms({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            })

        !areas.fullList &&
            dispatch(FullListAreas({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

    }, []);

    useEffect(() => {

        if (selectAreaId && assets.fullList) {
            const assetsRelatedToSelectedArea = assets.fullList.filter(a => a.areaId === selectAreaId);
            if (assetsRelatedToSelectedArea.length > 0) {
                setAssetsRelatedToSelectedArea(assetsRelatedToSelectedArea);
                // console.log("selectAreaId");
                // console.log(selectAreaId);
            } else {
                setAssetsRelatedToSelectedArea(null);
                // console.log("selectAreaId else");
                // console.log(selectAreaId);
            }
        }

    }, [selectAreaId, assets.fullList]);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {

        let requestData = {
            "tenantId": user.selectedTenant.id,
            "description": data.description,
            "detail": data.detail,
            "responsibleId": (data.operatorId != "") ? data.operatorId : null,
            "priorityLevelId": data.priorityLevelId,
            "areaId": (data.areaId != "") ? data.areaId : null,
            "assetId": (data.assetId != "") ? data.assetId : null,
            "functionalUnitId": (data.functionalUnitId != "") ? data.functionalUnitId : null,
            "roomId": (data.roomId != "") ? data.roomId : null,
            "supplierId": (data.supplierId != "") ? data.supplierId : null,
            "serviceTaskStateId": ServiceTasksIdPending,
            "ScheduledDate": startDate.toISOString(),
            "createdById": user.selectedTenant.agentId,
        }
        // console.log(requestData);
        await dispatch(CreateServiceTask({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload && response.payload.data &&
                history.push("/servicetask/detail?id=" + response.payload.data.id);
            //return <Redirect to={"/servicetask/detail?id=" + response.payload.data.id } />;
        });
    };

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (
        <Loader isLoading={task.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/servicetask/dashboard">{t("menu.tasks_dashboard")}</Link></li>
                    <li className="breadcrumb-item active">{t("task.add_task")}</li>
                </ol>
                <h1 className="page-header">Nueva Tarea de servicio <small>complete los campos obligatorios</small></h1>

                <div className="d-flex align-items-center mb-2">
                    <div className="ms-auto">
                        <Link to="/servicetask/dashboard" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill"><i className="fa fa-tasks me-1"></i> {t("menu.tasks_dashboard")}</Link>
                        <Link hidden to="/servicetask/dashboard" className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                        <Link to="/servicetask/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-7">
                        <div className="col-xl-12">
                            <Panel>
                                <PanelHeader noButton={true}>
                                    <div className="d-flex align-items-center">
                                        {t('common.general_data')}
                                    </div>
                                </PanelHeader>
                                <PanelBody>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-floating mb-15px">
                                            <input type="text" maxLength={90} className="form-control fs-15px" id="description" {...register("description", {
                                                required: true,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.description')}
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <textarea rows="5" maxLength={2000} className="form-control fs-15px h-750"
                                                id="detail" {...register("detail", {
                                                    required: false,
                                                })} />
                                            <label htmlFor="floatingInput" className="fs-13px">
                                                {t('common.detail')}
                                            </label>
                                        </div>

                                        {/* {user.fullList &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="operatorId" {...register("operatorId", {
                                                    required: false,
                                                })}
                                                >
                                                    <option></option>
                                                    {user.fullList.map((user, index) =>
                                                        (user.role.normalizedName.includes("MANAGER") ||
                                                            user.role.normalizedName.includes("MAINTENANCECHIEF") ||
                                                            user.role.normalizedName.includes("MAINTENANCEOPERATOR")) &&
                                                        <option key={user.agent.id} value={user.agent.id}>{user.agent.fullName} ({t("roles.rol_" + user.role.normalizedName)})</option>
                                                    )
                                                    }
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    Asignar a
                                                </label>
                                            </div>
                                        } */}

                                        <div className="form-floating mb-15px">
                                            <DatePicker className="form-control fs-15px pt-25px" dateFormat="dd-MM-yyyy" selected={startDate} onChange={(date) => setStartDate(date)} required />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-11px pt-1px mb-10px pb-30px">
                                                {t('common.date')} 
                                                {/* {new Date().toISOString()} */}
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <select className="form-select" id="priorityLevelId" {...register("priorityLevelId", {
                                                required: true,
                                                value: ServiceTaskPriorityLevel[0].id
                                            })}>
                                                <option></option>
                                                {ServiceTaskPriorityLevel.map(o => <option key={o.id} value={o.id}>{t("common.priority_level_" + o.NormalizeName)}</option>)}
                                            </select>
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.priority')}
                                            </label>
                                        </div>

                                        {areas.fullList &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="areaId" {...register("areaId", {
                                                    required: false,
                                                })}
                                                    onChange={(e) => setSelectAreaId(e.target.value)}
                                                >
                                                    <option></option>
                                                    {/* {areas.fullList.map(a => <option key={a.id} value={a.id}>{a.name}</option>)} */}
                                                    {renderAreasOptions(areas.fullList, null)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    {t('common.area')}
                                                </label>
                                            </div>
                                        }

                                        {user?.selectedTenant?.tenantTypeId == PrivateNeighborhoodTenantType &&
                                            functionalUnits.fullList &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="functionalUnitId" {...register("functionalUnitId", {
                                                    required: false,
                                                })}>
                                                    <option></option>
                                                    {functionalUnits.fullList.map(a => <option key={a.id} value={a.id} className='text-dark'>#{a.number}</option>)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    {t('common.functional_unit')}
                                                </label>
                                            </div>
                                        }

                                        {user?.selectedTenant?.tenantTypeId == HotelTenantType &&
                                            rooms.fullList &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="roomId" {...register("roomId", {
                                                    required: false,
                                                })}>
                                                    <option></option>
                                                    {rooms.fullList.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    {t('common.room')}
                                                </label>
                                            </div>
                                        }

                                        {assets.fullList &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="assetId" {...register("assetId", {
                                                    required: false,
                                                })}
                                                    onChange={(e) => setSelectedAssetId(e.target.value)}
                                                    value={selectedAssetId}
                                                >
                                                    <option key="select" value=""></option>
                                                    {assetsRelatedToSelectedArea &&
                                                        <option key="related" value=""># Relacionados al area #</option>
                                                    }
                                                    {assetsRelatedToSelectedArea && assetsRelatedToSelectedArea.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
                                                    {assetsRelatedToSelectedArea &&
                                                        <option key="none" value=""></option>
                                                    }
                                                    {assetsRelatedToSelectedArea &&
                                                        <option key="all" value=""># Todos #</option>
                                                    }
                                                    {assets.fullList.map(a => <option key={"all" + a.id} value={a.id}>{a.name}</option>)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    {t('common.asset')}
                                                </label>
                                            </div>
                                        }

                                        <div className="mb-20px">
                                            <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value={t("common.save")} />
                                            {task.error && <div className='text-center'>
                                                <span className='text-danger'>Ha ocurrido un problema: {task.errorMessage}</span></div>}
                                        </div>

                                    </form>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                    <div className="col-xl-3" hidden>
                        <div className="col-xl-12">
                            <Panel>
                                <PanelHeader noButton={true}>
                                    <div className="d-flex align-items-center">
                                        Extra information
                                    </div>
                                </PanelHeader>
                                <PanelBody>
                                    <p>Panel Content Here</p>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                </div>
            </div>
        </Loader>
    )
};

export default ServiceTaskCreate;
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { InputGroup, Input, Button, ButtonDropdown } from 'reactstrap';
import { AppSettings } from '../../../config/app-settings.js';
import { useDispatch, useSelector } from 'react-redux';
import { ListAudits } from '../../../services/redux/auditSlice';
import Pagination from '../../../components/app/pager/pagination';
import { Loader } from '../../widget/loader';
import AuditItem from '../../../components/app/audit/listItem';
import { useTranslation } from "react-i18next";
import { AuditTypes, AuditStates } from "../../../utils/generalConstants";

const AuditList = () => {

    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const audits = useSelector(state => state.auditReducer);
    
    const [keyword, setkeyword] = useState("");
    const [auditStateId, setAuditStateId] = useState([AuditStates[1].id, t("audit.state_" + AuditStates[1].NormalizeName)]); //SENT
	const [auditTypeId, setAuditTypeId] = useState([]);
    const take = 10;

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        toggleSplit(0);
    }, []);

    useEffect(() => {
		toggleSplit(0)
	}, [auditTypeId]);

	useEffect(() => {
		toggleSplit(0)
	}, [auditStateId]);
    
    const toggleSplit = async (skip) => {

        const requestData = {
            "tenantId": user.selectedTenant.id,
            "auditTypeId": auditTypeId[0],
            "auditStateId": auditStateId[0],
            "responsibleId": null,
            "priorityLevelId": null,
            "createdById": null,
            "keyword": keyword,
            "sortBy": null,
            "sortDir": true,
            "skip": skip,
            "take": take,
            "IsReview": false,
        };

        //requestData.skip = skip;
        await dispatch(ListAudits({ authToken: user.authToken, body: requestData })).then((response) => {
            //console.log(response);
        });
    };

    const toggleSplitCallBack = useCallback(
        (skip) => {
            toggleSplit(skip);
        },
        [keyword],
    );

    return (
        <Loader isLoading={audits.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active">Auditorías</li>
                </ol>
                <h1 className="page-header">Auditorías
                 {/* {audits && <small>{audits.dataCountFiltered} {t('common.results_found_of')} {audits.dataCount}</small>} */}
                 </h1>

                <div className="d-flex align-items-center mb-2">
                    <div className="ms-auto">
                        <Link to="/audit/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("common.add")}</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <InputGroup size="lg" className="mb-3">
                            <Input placeholder={t("common.enter_keyword_here")}  onKeyPress={(e) => e.key === 'Enter' && toggleSplit(0)} type="text" className="input-white" onChange={(text) => setkeyword(text.target.value)} />
                            <Button color="primary" className="rounded-right btn-lg" onClick={() => toggleSplit(0)}><i className="fa fa-search fa-fw"></i> {t("common.search")}</Button>
                            {/*<ButtonDropdown toggle={toggleSplit} hidden>*/}
                            {/*	<DropdownToggle color="primary" className="btn-lg" split>*/}
                            {/*		<i className="fa fa-cog fa-fw"></i>*/}
                            {/*	</DropdownToggle>*/}
                            {/*	<DropdownMenu>*/}
                            {/*		<DropdownItem>Action</DropdownItem>*/}
                            {/*		<DropdownItem>Another Action</DropdownItem>*/}
                            {/*		<DropdownItem>Something else here</DropdownItem>*/}
                            {/*		<DropdownItem divider />*/}
                            {/*		<DropdownItem>Separated link</DropdownItem>*/}
                            {/*	</DropdownMenu>*/}
                            {/*</ButtonDropdown>*/}
                        </InputGroup>

                        {audits && audits.list &&
                            <div>
                                <div className="d-block d-md-flex align-items-center mb-3">
									<div className="d-flex">
										<UncontrolledButtonDropdown className="me-2">
											<DropdownToggle color="white" caret>
												{t('common.filters_by')} {t("common.type").toLowerCase()} <b className="caret"></b><span className="badge bg-default text-dark ms-5px">{auditTypeId[1]}</span>
											</DropdownToggle>
											<DropdownMenu>
												{AuditTypes.map((type, index) =>
													<DropdownItem onClick={() => setAuditTypeId([type.id, t("audit.type_" + type.NormalizeName)])} key={type.id}>{t("audit.type_" + type.NormalizeName)}</DropdownItem>
												)}
												<DropdownItem divider />
												<DropdownItem onClick={() => setAuditTypeId([])} key="ALL">Todos</DropdownItem>
											</DropdownMenu>
										</UncontrolledButtonDropdown>
										<UncontrolledButtonDropdown className="me-2">
											<DropdownToggle color="white" caret>
												{t('common.filters_by')} {t("common.state").toLowerCase()} <b className="caret"></b><span className="badge bg-gray ms-5px">{auditStateId[1]}</span>
											</DropdownToggle>
											<DropdownMenu>
												{AuditStates.map((state, index) =>
													<DropdownItem onClick={() => setAuditStateId([state.id, t("audit.state_" + state.NormalizeName)])} key={state.id}>{t("audit.state_" + state.NormalizeName)}</DropdownItem>
												)}
												<DropdownItem divider />
												<DropdownItem onClick={() => setAuditStateId([])} key="ALL">Todos</DropdownItem>
											</DropdownMenu>
										</UncontrolledButtonDropdown>
										<div className="btn-group" hidden>
											<Link to="/extra/search" className="btn btn-white"><i className="fa fa-list"></i></Link>
											<Link to="/extra/search" className="btn btn-white"><i className="fa fa-th"></i></Link>
											<Link to="/extra/search" className="btn btn-white"><i className="fa fa-th-large"></i></Link>
										</div>
									</div>
								</div>
                                <Pagination toggleSplitCallBack={toggleSplitCallBack} dataCount={audits.dataCountFiltered} take={take}>
                                    <div className="result-list">
                                        {audits.list.map((audit, index) => 
											<AuditItem key={audit.id} audit={audit}></AuditItem>
                                        )}
                                    </div>
                                </Pagination>
                                <div className="result-list">

                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </Loader>
    )
};

export default AuditList;
import React, { useContext, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Panel, PanelBody, PanelHeader } from '../../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { EditAudit } from '../../../services/redux/auditSlice';
//import { AuditTypes } from '../../../utils/generalConstants';
import { useTranslation } from "react-i18next";
import { FullListFunctionalUnits } from '../../../services/redux/functionalUnitSlice';
import { FullListRooms } from '../../../services/redux/roomSlice';
import { FullListAreas } from '../../../services/redux/areaSlice.js';
import { renderAreasOptions } from '../../../components/app/area/common.js';
import { HotelTenantType, PrivateNeighborhoodTenantType } from '../../../utils/generalConstants.js';

const TaskReviewEdit = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const location = useLocation();
    const { audit } = location.state
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const auditReducer = useSelector(state => state.auditReducer);
    const areas = useSelector(state => state.areaReducer);
    const functionalUnits = useSelector(state => state.functionalUnitReducer);
    const rooms = useSelector(state => state.roomReducer)

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);

        !areas.fullList &&
            dispatch(FullListAreas({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

        !functionalUnits.fullList &&
            dispatch(FullListFunctionalUnits({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => { 
                //console.log(response);
            });

        !rooms.fullList &&
            dispatch(FullListRooms({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            })

    }, []);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {

        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": audit.id,
            "name": data.name,
            "typeId": "c29e8f9b-2785-47a0-9fc0-d5b713b0910c", //data.typeId,
            "areaId": (data.areaId != "") ? data.areaId : null,
            "functionalUnitId": (data.functionalUnitId != "") ? data.functionalUnitId : null,
            "roomId": (data.roomId != "") ? data.roomId : null,
            "description": data.description ? data.description : null,
        }

        await dispatch(EditAudit({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload && response.payload.data &&
                history.push("/taskreview/detail?id=" + response.payload.data.id);
        });
    };

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (

        <div>
            <ol className="breadcrumb float-xl-end" hidden>
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/taskreview/list">Relevamiento de tareas</Link></li>
                <li className="breadcrumb-item active">Editar Relevamiento</li>
            </ol>
            <h1 className="page-header">Editar Relevamiento ({audit.name})  <small>complete los campos obligatorios</small></h1>

            <div className="d-flex align-items-center mb-2">
                <div className="ms-auto">
                    <Link to="/taskreview/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                    <Link to={"/taskreview/detail?id=" + audit.id} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-7">
                    <div className="col-xl-12">
                        <Panel>
                            <PanelHeader noButton={true}>
                                <div className="d-flex align-items-center">
                                    {t("common.general_data")}
                                </div>
                            </PanelHeader>
                            <PanelBody>
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    {user?.selectedTenant?.tenantTypeId == PrivateNeighborhoodTenantType &&
                                        functionalUnits.fullList && functionalUnits.fullList.length > 0 &&
                                        <div className="form-floating mb-15px">
                                            <select className="form-select" id="functionalUnitId" {...register("functionalUnitId", {
                                                required: false,
                                                value: audit.functionalUnitId
                                            })}>
                                                <option></option>
                                                {functionalUnits.fullList.map(a => <option key={a.id} value={a.id} className='text-dark'>#{a.number}</option>)}
                                            </select>
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.functional_unit')}
                                            </label>
                                        </div>
                                    }

                                    {user?.selectedTenant?.tenantTypeId == HotelTenantType &&
                                        rooms.fullList &&
                                        <div className="form-floating mb-15px">
                                            <select className="form-select" id="roomId" {...register("roomId", {
                                                required: false,
                                                value: audit.roomId
                                            })}>
                                                <option></option>
                                                {rooms.fullList.map(a => <option key={a.id} value={a.id} className='text-dark'>{a.name}</option>)}
                                            </select>
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.room')}
                                            </label>
                                        </div>
                                    }
                                    {areas.fullList && areas.fullList.length > 0 &&
                                        <div className="form-floating mb-15px">
                                            <select className="form-select" id="areaId" {...register("areaId", {
                                                required: false,
                                                value: audit.areaId
                                            })}>
                                                <option></option>
                                                {/* {areas.fullList.map(a => <option key={a.id} value={a.id}>{a.name}</option>)} */}
                                                {renderAreasOptions(areas.fullList, null)}
                                            </select>
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.area')}
                                            </label>
                                        </div>
                                    }
                                    <div className="form-floating mb-15px">
                                        <input type="text" maxLength={90} className={"form-control fs-15px" + (errors.name ? ' is-invalid' : '')} id="name" {...register("name", {
                                            required: true,
                                            value: audit.name
                                        })} />
                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                            {t("common.description")}
                                        </label>
                                        {errors.name && <span className='invalid-feedback'>This field is required</span>}
                                    </div>

                                    <div className="form-floating mb-15px">
                                        <textarea rows="5" maxLength={255} className={"form-control fs-15px h-100" + (errors.description ? ' is-invalid' : '')} id="model" {...register("description", {
                                            required: false,
                                            value: audit.description,
                                        })} />
                                        <label htmlFor="floatingInput" className=" fs-13px">
                                            {t("common.detail")}
                                        </label>
                                        {errors.description && <span className='invalid-feedback'>This field is required</span>}
                                    </div>

                                    {/* {AuditTypes &&
                                        <div className="form-floating mb-15px">
                                            <select className="form-select" id="typeId" {...register("typeId", {
                                                required: true,
                                                value: audit.auditTypeId,
                                            })}>
                                                {user.selectedTenant.agentRoles === "Auditor" &&
                                                        <option key={AuditTypes[0].id} value={AuditTypes[0].id}>{AuditTypes[0].Name}</option>
                                                    }
                                                    {user.selectedTenant.agentRoles === "Auditor" &&
                                                        <option key={AuditTypes[1].id} value={AuditTypes[1].id}>{AuditTypes[1].Name}</option>
                                                    }
                                                    {user.selectedTenant.agentRoles !== "Auditor" &&
                                                        <option key={AuditTypes[2].id} value={AuditTypes[2].id}>{AuditTypes[2].Name}</option>
                                                    }
                                            </select>
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                Type
                                            </label>
                                        </div>
                                    } */}

                                    <div>
                                        <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value={t("common.save")} />
                                        {auditReducer.error && <div className='text-center mt-10px'>
                                            <span className='text-danger'>Ha ocurrido un problema: {auditReducer.errorMessage}</span></div>}
                                    </div>

                                </form>

                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TaskReviewEdit;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { PURGE } from "redux-persist";
import { Countries, SupplierCategories } from '../../utils/generalConstants';

const initialState = {
    countries: Countries,
    supplierCategories: SupplierCategories,
    isFetching: false,
    error: false,
    errorMessage: "",
}

export const GetGlobalData = createAsyncThunk('user/fetchGetGlobalData', async (requestData) => {
    let response = await api.getGlobalData(requestData);
    return response;
});

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {

    },
    extraReducers: {
         [GetGlobalData.pending]: (state, action) => {
             state.isFetching = true;
             state.error = false;
             state.errorMessage = '';
         },
         [GetGlobalData.fulfilled]: (state, { payload }) => {
             state.isFetching = false;
             state.error = false;
             state.errorMessage = '';
             state.countries = payload.data.countries;
             state.supplierCategories = payload.data.supplierCategories;
         },
         [GetGlobalData.rejected]: (state, action) => {
             state.isFetching = false;
             state.error = true;
             state.errorMessage = action.error.message;
         },

        [PURGE]: (state) => {
            return initialState;
        },
    }
})

export default appSlice.reducer; 
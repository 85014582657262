import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { PURGE } from "redux-persist";
import { set } from "date-fns";

const initialState = {
    authToken: "",
    tenants: [],
    selectedTenant: null,
    //userData: null,

    list: null,
    fullList: null,
    dataCount: null,
    dataCountFiltered: null,
    selectedUser: null,

    settings: {
        darkMode: false,
        language: "es",
    },

    lastSync: null,
    isFetching: false,
    error: false,
    errorMessage: "",
    status: "",
    invitationRegister: null
}

export const PostLogin = createAsyncThunk('user/fetchLogin', async (requestData) => {
    let response = await api.postLogin(requestData);
    return response;
});

export const GetInvitation = createAsyncThunk('user/fetchGetInvitation', async ({ params }) => {
    let response = await api.getInvitation(params);
    return response;
});

export const PostConfirmInvitation = createAsyncThunk('user/fetchConfirmInvitation', async (requestData) => {
    let response = await api.postConfirmInvitation(requestData);
    return response;
});

export const PostForgotPassword = createAsyncThunk('user/fetchForgotPassword', async (requestData) => {
    let response = await api.postForgotPassword(requestData);
    return response;
});

export const PostResetPassword = createAsyncThunk('user/fetchResetPassword', async (requestData) => {
    let response = await api.postResetPassword(requestData);
    return response;
});

export const ResendInvitation = createAsyncThunk('user/fetchResendInvitation', async ({ authToken, body }) => {
    let response = await api.resendInvitation(authToken, body);
    return response;
});

export const GetUser = createAsyncThunk('user/fetchGetUser', async ({ authToken, params }) => {
    let response = await api.getUser(authToken, params);
    return response;
});

export const ListUsers = createAsyncThunk('user/fetchListUsers', async ({ authToken, body }) => {
    let response = await api.listUsers(authToken, body);
    return response;
});

export const FullListUsers = createAsyncThunk('user/fetchFullListUsers', async ({ authToken, tenantId }) => {
    let body = {
        "tenantId": tenantId,
        "sortBy": null,
        "sortDir": true,
        "skip": 0,
        "take": null
    };
    let response = await api.listUsers(authToken, body);
    return response;
});

export const CreateUser = createAsyncThunk('users/fetchCreateUser', async ({ authToken, body }) => {
    let response = await api.createUser(authToken, body);
    return response;
});

export const EditUser = createAsyncThunk('users/fetchEditUser', async ({ authToken, body }) => {
    let response = await api.editUser(authToken, body);
    return response;
});

export const DeleteUser = createAsyncThunk('users/fetchDeleteUser', async ({ authToken, body }) => {
    let response = await api.deleteUser(authToken, body);
    return response;
});

export const ChangeTenant = createAsyncThunk('user/ChangeTenant', async (id) => {
    //console.log("ChangeTenant" + id);
    return {
        id
    }
});



const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setSettings(state, action) {
            console.log(action.payload);
            state.settings = action.payload;
        },
        setTenant(state, action) {
            let newName = action.payload.name;
            let tenantTypeId = action.payload.tenantTypeId;
            if (action && action.payload && action.payload.name) {
                let objIndex = state.tenants.findIndex((obj => obj.id === action.payload.id));
                // state.tenants[objIndex] = { ...state.tenants[objIndex], name: newName};
                state.tenants[objIndex] = { ...state.tenants[objIndex], name: newName, tenantTypeId: tenantTypeId };
                // state.selectedTenant.name = newName;
                state.selectedTenant = { ...state.selectedTenant, name: newName, tenantTypeId: tenantTypeId };
            }
        },
        logOut(state, action) {
            state.authToken = "";
            state.tenants = [];
            state.selectedTenant = null;
            state.list = null;
            state.fullList = null;
            state.dataCount = null;
            state.dataCountFiltered = null;
            state.selectedUser = null;
            state.lastSync = null;
            state.isFetching = false;
            state.error = false;
            state.errorMessage = "";
            state.status = "";
            state.invitationRegister = null;
            // console.log("userSlice - logOut");
        }
    },
    extraReducers: {
        [PostConfirmInvitation.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
            state.invitationRegister = null;
        },
        [PostConfirmInvitation.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.invitationRegister = null; // state.userData = payload.data.userData;
        },
        [PostConfirmInvitation.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
            state.invitationRegister = null;
        },

        [PostForgotPassword.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
            state.invitationRegister = null;
        },
        [PostForgotPassword.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
        },
        [PostForgotPassword.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
            state.invitationRegister = null;
        },

        [PostResetPassword.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
            state.invitationRegister = null;
        },
        [PostResetPassword.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
        },
        [PostResetPassword.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
            state.invitationRegister = null;
        },

        [ResendInvitation.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
            state.invitationRegister = null;
        },
        [ResendInvitation.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
        },
        [ResendInvitation.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
            state.invitationRegister = null;
        },


        [GetInvitation.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
            state.invitationRegister = null;
        },
        [GetInvitation.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.invitationRegister = payload.data;
        },
        [GetInvitation.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
            state.invitationRegister = null;
        },

        [PostLogin.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [PostLogin.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.authToken = payload.data.token;
            state.selectedTenant = payload.data.activeTenant;
            state.tenants = payload.data.tenants;
            // state.userData = payload.data.userData;
        },
        [PostLogin.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },


        [GetUser.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [GetUser.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedUser = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [GetUser.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [ListUsers.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [ListUsers.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedUser = null;
            state.list = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [ListUsers.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [FullListUsers.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [FullListUsers.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.fullList = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [FullListUsers.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [CreateUser.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [CreateUser.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedUser = payload.data;
            state.fullList = null;
        },
        [CreateUser.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [EditUser.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [EditUser.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.fullList = null;
        },
        [EditUser.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [DeleteUser.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [DeleteUser.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.fullList = null;
        },
        [DeleteUser.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [ChangeTenant.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [ChangeTenant.fulfilled]: (state, { payload }) => {
            state.list = null;
            state.fullList = null;
            state.dataCount = null;
            state.dataCountFiltered = null;
            state.selectedUser = null;
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedTenant = JSON.parse(JSON.stringify(state.tenants)).filter(t => t.id === payload.id)[0];
        },
        [ChangeTenant.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [PURGE]: (state) => {
            let darkMode = state.settings?.darkMode;
            let language = state.settings?.language;
            let serviceTaskModule = state.settings?.serviceTaskModule;
            let routineTaskModule = state.settings?.routineTaskModule;

            const settings = { ...state.settings, darkMode, language, serviceTaskModule, routineTaskModule}
            const purgeState = { ...initialState, settings };
            console.log("purgeState", JSON.stringify(purgeState, null, 2));
            return purgeState;
        },
    }
})

export const { setTenant, setSettings, logOut } = userSlice.actions;

export default userSlice.reducer; 
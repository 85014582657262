import moment from 'moment';
import React from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const MaintenanceTaskItem = ({ maintenanceTask }) => {
    const { t } = useTranslation('common');
    const user = useSelector((state) => state.userReducer);

    return (
        <div className="result-item">
            <Link to="/extra/search" className="result-image" style={{ backgroundImage: 'url(/assets/img/gallery/gallery-51.jpg)' }} hidden></Link>
            <div className="result-info">
                {maintenanceTask.stateNormalizedName === "PENDING" &&
                    <p className="location">{t('common.scheduled_date')}: {moment(maintenanceTask.scheduledDate).format("DD/MM/YYYY")}</p>
                }
                {maintenanceTask.stateNormalizedName === "STARTED" && maintenanceTask.workOrder &&
                    <p className="location">{t('common.started_on')}: {moment(maintenanceTask.workOrder.startedOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm:ss")}</p>
                }
                {/* {maintenanceTask.stateNormalizedName === "FINISHED" &&
                    <p className="location">{t('common.finished_on')}: {moment(maintenanceTask.finishedOn).format("DD/MM/YYYY")}</p>
                } */}
                <h4 className="title"><Link to={"/task/detail?id=" + maintenanceTask.id}>{maintenanceTask.description}</Link></h4>
                <p>
                    <span className="badge bg-default text-dark me-5px">{t("task.type_" + maintenanceTask.typeNormalizedName)}</span>
                    <span className="badge bg-gray me-5px">{t("task.state_" + maintenanceTask.stateNormalizedName)}</span>
                    {/* Creada por: {maintenanceTask.createdBy} */}
                </p>
                <p className="desc" hidden>
                    Nunc et ornare ligula. Aenean commodo lectus turpis, eu laoreet risus lobortis quis. Suspendisse vehicula mollis magna vel aliquet. Donec ac tempor neque, convallis euismod mauris. Integer dictum dictum ipsum quis viverra.
                </p>
                <div className="btn-row" hidden>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Analytics"><i className="fa fa-fw fa-chart-bar"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Tasks"><i className="fa fa-fw fa-tasks"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Configuration"><i className="fa fa-fw fa-cog"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Performance"><i className="fa fa-fw fa-tachometer"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Users"><i className="fa fa-fw fa-user"></i></Link>
                </div>
            </div>
            <div className="result-price" hidden>
                <Link to={"/task/detail?id=" + maintenanceTask.id} className="btn btn-gray-600 d-block w-100">{t("common.detail")}</Link>
                {maintenanceTask.workOrder == null ?
                    <Link to={{
                        pathname: "/workorder/create",
                        state: {
                            maintenanceTask: maintenanceTask
                        }
                    }} className="btn btn-blue d-block w-100 mt-5px">{t('task.create_workOrder')}</Link>
                    :
                    <Link to={"/workorder/detail?id=" + maintenanceTask.workOrder.id} className="btn btn-green d-block w-100 mt-5px">{t('task.view_workOrder')}</Link>
                }

            </div>
        </div>
    )
}

export default MaintenanceTaskItem;
import React, { useContext, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import { Panel, PanelHeader, PanelFooter, PanelBody } from '.././../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CreateWorkOrder } from '../../../services/redux/workOrderSlice';
import { FullListUsers } from '../../../services/redux/userSlice';
import { Loader } from '../../widget/loader'
import { useTranslation } from "react-i18next";

const WorkOrderCreate = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const task = useSelector(state => state.maintenanceTaskReducer)

    const location = useLocation();
    const { maintenanceTask } = location.state
    const [startDate, setStartDate] = useState(new Date(maintenanceTask.scheduledDate));

    useEffect(() => {

        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        //context.handleSetAppContentClass('p-10');

        !user.fullList &&
            dispatch(FullListUsers({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

    }, []);

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async (data) => {

        let requestData = {
            "maintenanceTaskId": maintenanceTask.id,
            "scheduleId": maintenanceTask.scheduleId,
            "scheduledDate": maintenanceTask.scheduledDate,
            "operatorId": data.operatorId,
            "createdById": user.selectedTenant.agentId,
            "tenantId": user.selectedTenant.id,
        }
        await dispatch(CreateWorkOrder({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload && response.payload.data &&
                history.push("/workorder/detail?id=" + response.payload.data.id);
        });
    };

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (
        <Loader isLoading={task.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active">Editar Tarea</li>
                </ol>
                <h1 className="page-header">Create Work Order ({maintenanceTask.description}) <small>complete los campos obligatorios</small></h1>
                <div className="row">
                    <div className="col-xl-7">
                        <div className="col-xl-12">
                            <Panel>
                                <PanelHeader noButton={true}>
                                    <div className="d-flex align-items-center">
                                        {maintenanceTask.description}
                                    </div>
                                </PanelHeader>
                                <PanelBody>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        {/*<div className="form-floating mb-15px">*/}
                                        {/*    <input type="text" className={"form-control fs-15px" + (errors.description ? ' is-invalid' : '')}*/}
                                        {/*        id="description" {...register("description", {*/}
                                        {/*            required: true,*/}
                                        {/*            value: maintenanceTask.description*/}
                                        {/*        })} />*/}
                                        {/*    <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">*/}
                                        {/*        Description*/}
                                        {/*    </label>*/}
                                        {/*    {errors.description && <span className='invalid-feedback'>This field is required</span>}*/}
                                        {/*</div>*/}

                                        <div className="form-floating mb-15px">
                                            <DatePicker className="form-control fs-15px pt-25px" dateFormat="dd-MM-yyyy" selected={startDate} onChange={(date) => setStartDate(date)} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-11px pt-1px mb-10px pb-30px">
                                                Date
                                            </label>
                                        </div>

                                        {user.fullList &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="operatorId" {...register("operatorId", {
                                                    required: false,
                                                })}>
                                                    <option></option>
                                                    {user.fullList.map((user, index) =>
                                                        (user.role.normalizedName.includes("MANAGER") ||
                                                            user.role.normalizedName.includes("MAINTENANCECHIEF") ||
                                                            user.role.normalizedName.includes("MAINTENANCEOPERATOR")) &&
                                                        <option key={user.agent.id} value={user.agent.id}>{user.agent.fullName} ({t("roles.rol_" + user.role.normalizedName)})</option>
                                                    )
                                                    }
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    Operador
                                                </label>
                                            </div>
                                        }

                                        <div className="mb-20px">
                                            <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value="Crear Work Order" />
                                            {task.error && <div className='text-center'>
                                                <span className='text-danger'>Ha ocurrido un problema: {task.errorMessage}</span></div>}
                                        </div>

                                    </form>

                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                    <div className="col-xl-3" hidden>
                        <div className="col-xl-12">
                            <Panel>
                                <PanelHeader noButton={true}>
                                    <div className="d-flex align-items-center">
                                        Extra information
                                    </div>
                                </PanelHeader>
                                <PanelBody>
                                    <p>Panel Content Here</p>
                                </PanelBody>
                            </Panel>
                        </div>

                    </div>
                </div>
            </div>
        </Loader>
    )
};

export default WorkOrderCreate;
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const FunctionalUnitItem = ({ functionalUnit }) => {
    const { t } = useTranslation('common');
    return (
        <div className="result-item" key={functionalUnit.id}>
            <Link to="/extra/search" className="result-image" style={{ backgroundImage: 'url(/assets/img/gallery/gallery-51.jpg)' }} hidden></Link>
            <div className="result-info">
                <h4 className="title"><Link to={"/functionalUnit/detail?id=" + functionalUnit.id}>#{functionalUnit.number}</Link></h4>
                <p className="location">{functionalUnit.ownerContactInfo}</p>
                <p className="desc" hidden>
                    {functionalUnit.observations}
                </p>
            </div>
            <div className="result-price" hidden>
                <Link to={"/functionalUnit/detail?id=" + functionalUnit.id} className="btn btn-gray-600 d-block w-100">{t("common.detail")}</Link>
            </div>
        </div>
    )
}

export default FunctionalUnitItem;
import moment from 'moment';
import React from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const WorkOrderItem = ({ workOrder }) => {
    const { t } = useTranslation('common');
    const user = useSelector((state) => state.userReducer);

    return (
        <div className="result-item">
            <Link to="/extra/search" className="result-image" style={{ backgroundImage: 'url(/assets/img/gallery/gallery-51.jpg)' }} hidden></Link>
            <div className="result-info">
                {workOrder.stateNormalizedName === "PENDING" &&
                    <p className="location">{t('common.scheduled_date')}: {moment(workOrder.scheduledDate).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY")}</p>
                }
                {workOrder.stateNormalizedName === "STARTED" &&
                    <p className="location">{t('common.started_on')}: {moment(workOrder.startedOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm:ss")}</p>
                }
                {workOrder.stateNormalizedName === "TOVALIDATE" &&
                    <p className="location">{t('common.started_on')}: {moment(workOrder.startedOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm:ss")}</p>
                }
                {workOrder.stateNormalizedName === "FINISHED" &&
                    <p className="location">{t('common.started_on')}: {moment(workOrder.startedOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm:ss")} | {t('common.finished_on')}: {moment(workOrder.finishedOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm:ss")}</p>
                }
                <h4 className="title"><Link to={"/workorder/detail?id=" + workOrder.id}>{workOrder.description}</Link></h4>
                <p>
                    <span className="badge bg-default text-gray-800 me-5px">{t("task.type_" + workOrder.typeNormalizedName)}</span>
                    <span className="badge bg-gray me-5px">{t("workOrder.state_" + workOrder.stateNormalizedName)}</span>
                    {/* <span className="badge bg-gray me-5px">{workOrder.stateNormalizedName}</span> */}
                    {/* Creada por: {workOrder.createdBy} */}
                </p>
                <p className="desc" hidden>
                    Nunc et ornare ligula. Aenean commodo lectus turpis, eu laoreet risus lobortis quis. Suspendisse vehicula mollis magna vel aliquet. Donec ac tempor neque, convallis euismod mauris. Integer dictum dictum ipsum quis viverra.
                </p>
                <div className="btn-row" hidden>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Analytics"><i className="fa fa-fw fa-chart-bar"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Tasks"><i className="fa fa-fw fa-tasks"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Configuration"><i className="fa fa-fw fa-cog"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Performance"><i className="fa fa-fw fa-tachometer"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Users"><i className="fa fa-fw fa-user"></i></Link>
                </div>
            </div>
        </div>
    )
}

export default WorkOrderItem;
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { InputGroup, Input, Button, ButtonDropdown } from 'reactstrap';
import { AppSettings } from '../../../config/app-settings.js';
import { useDispatch, useSelector } from 'react-redux';
import { ListBuildingSites } from '../../../services/redux/buildingSiteSlice';
import Pagination from '../../../components/app/pager/pagination';
import { Loader } from '../../widget/loader';
import BuildingSiteItem from '../../../components/app/buildingSite/listItem';
import { useTranslation } from "react-i18next";

const BuildingSiteList = () => {

    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const buildingSites = useSelector(state => state.buildingSiteReducer);

    const [keyword, setkeyword] = useState("");
    const take = 10;

    const requestData = {
        "tenantId": user.selectedTenant.id,
        "buildingSiteStateId": null,
        "createdById": null,
        "keyword": keyword,
        "sortBy": null,
        "sortDir": true,
        "skip": 0,
        "take": take
    };

    const toggleSplit = async (skip) => {
        requestData.skip = skip;
        await dispatch(ListBuildingSites({ authToken: user.authToken, body: requestData })).then((response) => {
            //console.log(response);
        });
    };

    const toggleSplitCallBack = useCallback(
        (skip) => {
            toggleSplit(skip);
        },
        [keyword],
    );

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        toggleSplit(0);
    }, []);


    return (
        <Loader isLoading={buildingSites.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active">Control de Obras</li>
                </ol>
                <h1 className="page-header">Control de Obras 
                {/* {buildingSites && <small>{buildingSites.dataCountFiltered} {t('common.results_found_of')} {buildingSites.dataCount}</small>} */}
                </h1>
                
                {/* <div className="d-flex align-items-center mb-2">
                    <div className="ms-auto">
                        <Link to="/buildingSite/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("common.add")}</Link>
                    </div>
                </div> */}

                <div className="row">
                    <div className="col-md-12">
                        <InputGroup size="lg" className="mb-3">
                            <Input placeholder={t("common.enter_keyword_here")} type="text"  onKeyPress={(e) => e.key === 'Enter' && toggleSplit(0)} className="input-white" onChange={(text) => setkeyword(text.target.value)} />
                            <Button color="primary" className="rounded-right btn-lg" onClick={() => toggleSplit(0)}><i className="fa fa-search fa-fw"></i> {t("common.search")}</Button>
                        </InputGroup>

                        {buildingSites && buildingSites.list &&
                            <div>
                                <Pagination toggleSplitCallBack={toggleSplitCallBack} dataCount={buildingSites.dataCountFiltered} take={take}>
                                    <div className="result-list">
                                        {buildingSites.list.map((buildingSite, index) => 
											<BuildingSiteItem key={buildingSite.id} buildingSite={buildingSite} viewMode=""></BuildingSiteItem>
                                        )}
                                    </div>
                                </Pagination>
                                <div className="result-list">

                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Loader>
    )
};

export default BuildingSiteList;
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { useSelector } from 'react-redux';

const RoomItem = ({ room }) => {
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);

    return (
        <div className="result-item" key={room.id}>
            <Link to="/extra/search" className="result-image" style={{ backgroundImage: 'url(/assets/img/gallery/gallery-51.jpg)' }} hidden></Link>
            <div className="result-info">
                <h4 className="title"><Link to={"/room/detail?id=" + room.id}>{room.name}</Link></h4>
                <p>
                    {/* <span className="badge bg-default text-dark me-5px">{t("room.type_" + room.typeNormalizedName)}</span>
                    {room.isOutOfService && <span className="badge bg-red me-5px">{t("room.out_of_service")}</span>}
                    {room.isBackup && <span className="badge bg-green me-5px">{t("room.backup")}</span>} */}
                    {t("common.createdOn")} {moment(room.createdOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm")}
                    </p>
                <p className="desc" hidden>
                    Nunc et ornare ligula. Aenean commodo lectus turpis, eu laoreet risus lobortis quis. Suspendisse vehicula mollis magna vel aliquet. Donec ac tempor neque, convallis euismod mauris. Integer dictum dictum ipsum quis viverra.
                </p>
                <div className="btn-row" hidden>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Analytics"><i className="fa fa-fw fa-chart-bar"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="assets"><i className="fa fa-fw fa-assets"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Configuration"><i className="fa fa-fw fa-cog"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Performance"><i className="fa fa-fw fa-tachometer"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Users"><i className="fa fa-fw fa-user"></i></Link>
                </div>
            </div>
            <div className="result-price" hidden>
                {/*$92,101 <small>PER MONTH</small>*/}
                <Link to={"/room/detail?id=" + room.id} className="btn btn-gray-600 d-block w-100">{t("common.detail")}</Link>
            </div>
        </div>
    )
}

export default RoomItem;
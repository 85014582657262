import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { InputGroup, Input, Button } from 'reactstrap';
import { AppSettings } from '../../../config/app-settings.js';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../components/app/pager/pagination'
import { Loader } from '../../widget/loader';
import { useTranslation } from "react-i18next";
import { MaintenanceTaskTypes, MaintenanceTaskPriorityLevel, HotelTenantType, PrivateNeighborhoodTenantType, WorkOrderStates } from "../../../utils/generalConstants";
import { FullListAreas } from '../../../services/redux/areaSlice.js';
import { FullListFunctionalUnits } from '../../../services/redux/functionalUnitSlice';
import { FullListRooms } from '../../../services/redux/roomSlice.js';
import { renderAreasDropdownItems } from '../../../components/app/area/common.js';
import { ListWorkOrders } from '../../../services/redux/workOrderSlice.js';
import WorkOrderItem from '../../../components/app/workOrders/listItem.js';
import { ExportToPdfWorkOrders } from './pdf.js';
import { setMonth } from 'date-fns';
import * as XLSX from 'xlsx';

const WorkOrderList = () => {
  const dispatch = useDispatch();
  const context = useContext(AppSettings);
  const { t } = useTranslation('common');

  const query = new URLSearchParams(window.location.search)

  const user = useSelector(state => state.userReducer);
  const workorders = useSelector(state => state.workOrderReducer);
  const areas = useSelector(state => state.areaReducer)
  const functionalUnits = useSelector(state => state.functionalUnitReducer)
  const rooms = useSelector(state => state.roomReducer)

  const [keyword, setkeyword] = useState("");
  const [areaId, setAreaId] = useState([]);
  const [functionalUnitId, setFunctionalUnitId] = useState([]);
  const [roomId, setRoomId] = useState([]);
  const [workOrderStateId, setWorkOrderStateId] = useState(query.get('stateid') ? [query.get('stateid'), t("workOrder.state_" + WorkOrderStates.filter(state => state.id == query.get('stateid'))[0].NormalizeName)] : []);
  const [maintenanceTaskTypeId, setMaintenanceTaskTypeId] = useState([]);
  const [priorityLevelId, setPriorityLevelId] = useState([]);
  const [agentId, setAgentId] = useState([]);
  const [monthsFilter, setmonthsFilter] = useState([]);
  const [month, setMonth] = useState([]);

  const [modalWorkOrderPdf, setModalWorkOrderPdf] = useState(false);

  const take = 15;

  if (monthsFilter?.length === 0) {
    // console.log("buil months")
    // crear un array con los ultimos 12 meses expresando el mes en primer día del mes y el ultimo dia de cada mes
    for (let i = 0; i < 12; i++) {
      let date = new Date();
      date.setMonth(date.getMonth() - i);
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      let monthName = firstDay.toLocaleString('en', { month: 'short' });
      monthsFilter.push({
        firstDay: firstDay.toISOString().split('T')[0],
        lastDay: lastDay.toISOString().split('T')[0],
        monthName: t('month_names.' + monthName) + " " + date.getFullYear()
      });
    }
  }

  useEffect(() => {
    context.handleSetAppSidebarNone(false);
    context.handleSetAppHeaderNone(false);
    //toggleSplit(0);

    !areas.fullList &&
      dispatch(FullListAreas({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
        //console.log(response);
      });

    !functionalUnits.fullList &&
      dispatch(FullListFunctionalUnits({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
        //console.log(response);
      });

    !rooms.fullList &&
      dispatch(FullListRooms({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
        //console.log(response);
      })

  }, []);

  useEffect(() => {
    toggleSplit(0)
  }, [workOrderStateId, maintenanceTaskTypeId, priorityLevelId, areaId, functionalUnitId, roomId, agentId, month]);

  const toggleSplit = async (skip) => {

    const requestData = {
      "tenantId": user.selectedTenant.id,
      "areaId": areaId[0],
      "functionalUnitId": functionalUnitId[0],
      "roomId": roomId[0],
      "maintenanceTaskTypeId": maintenanceTaskTypeId[0],
      "responsibleId": agentId[0],
      "priorityLevelId": priorityLevelId[0],
      "workOrderStateId": workOrderStateId[0],
      "createdById": agentId[0],
      "keyword": keyword,
      "fromDate": month[0] ? month[0].firstDay : null,
      "toDate": month[0] ? month[0].lastDay : null,
      "sortBy": null,
      "sortDir": true,
      "skip": skip,
      "take": take
    };

    //requestData.skip = skip;

    // console.log(requestData);

    await dispatch(ListWorkOrders({ authToken: user.authToken, body: requestData })).then((response) => {
      //console.log(response);
    });
  };

  const toggleSplitCallBack = useCallback(
    (skip) => {
      toggleSplit(skip);
    },
    [keyword, workOrderStateId, maintenanceTaskTypeId, priorityLevelId, areaId, functionalUnitId, roomId, agentId, month],
  );

  const toggleModal = (name) => {
    switch (name) {
      case 'modalWorkOrderPdf':
        setModalWorkOrderPdf(!modalWorkOrderPdf);
        break;
      default:
        break;
    }
  }

  const prepareDataForExport = (workorders) => {
    return workorders.map(({ id, tenantId, createdById, operatorId, ...rest }) => rest);
  };

  const exportToExcel = (workorders, fileName) => {

    // Preparar los datos para exportar
    workorders = prepareDataForExport(workorders);

    // Crear un nuevo libro de trabajo y una hoja con los datos
    const ws = XLSX.utils.json_to_sheet(workorders);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "WorkOrders");

    // Escribir el libro de trabajo en un archivo y descargarlo
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  return (
    <Loader isLoading={workorders.isFetching}>
      <div>
        <ol className="breadcrumb float-xl-end" hidden>
          <li className="breadcrumb-item"><Link to="/task/dashboard">{t('menu.tasks_dashboard')}</Link></li>
          <li className="breadcrumb-item active">{t('common.work_orders')}</li>
        </ol>
        <h1>{t('common.work_orders')}
          {/* {workorders && <small>{workorders.dataCountFiltered} {t('common.results_found_of')} {workorders.dataCount}</small>} */}
          {workorders && <small className='ms-10px'>{workorders.dataCountFiltered} {t('common.results_found_of')} {workorders.dataCount}</small>}
        </h1>

        <div className="d-flex align-items-center mb-2">
          <div className="ms-auto">
            <button className="btn btn-white m-3px" onClick={() => exportToExcel(workorders.list, 'Wartun_ordenes_de_trabajo')}><span className='d-none d-sm-block'>Exportar a <i className="fa fa-file-excel fa-fw fa-2x" style={{ color: 'green', fontSize: 18 }} /></span><i className="d-block d-sm-none fa fa-file-excel fa-fw fa-2x" style={{ color: 'green', fontSize: 18 }} /></button>
            {/* <button className="btn btn-danger m-3px" onClick={() => exportToExcel(workorders.list, 'Wartun_ordenes_de_trabajo')}>Exportar a Excel</button> */}
            <button className="btn btn-white m-3px" onClick={() => toggleModal('modalWorkOrderPdf')}><span className='d-none d-sm-block'>Exportar a <i className="fa fa-file-pdf fa-fw fa-2x" style={{ color: 'red', fontSize: 18 }} /></span><i className="d-block d-sm-none fa fa-file-pdf fa-fw fa-2x" style={{ color: 'red', fontSize: 18 }} /></button>
            {/* <button className="btn btn-danger m-3px" onClick={() => toggleModal('modalWorkOrderPdf')}>Exportar a PDF</button> */}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <InputGroup size="lg" className="mb-3">
              <Input placeholder={t("common.enter_keyword_here")} type="text" className="input-white" onKeyPress={(e) => e.key === 'Enter' && toggleSplit(0)} onChange={(text) => setkeyword(text.target.value)} />
              <Button color="primary" className="rounded-right btn-lg" onClick={() => toggleSplit(0)}><i className="fa fa-search fa-fw"></i> {t('common.search')}</Button>
              {/*<ButtonDropdown toggle={toggleSplit} hidden>*/}
              {/*	<DropdownToggle color="primary" className="btn-lg" split>*/}
              {/*		<i className="fa fa-cog fa-fw"></i>*/}
              {/*	</DropdownToggle>*/}
              {/*	<DropdownMenu>*/}
              {/*		<DropdownItem>Action</DropdownItem>*/}
              {/*		<DropdownItem>Another Action</DropdownItem>*/}
              {/*		<DropdownItem>Something else here</DropdownItem>*/}
              {/*		<DropdownItem divider />*/}
              {/*		<DropdownItem>Separated link</DropdownItem>*/}
              {/*	</DropdownMenu>*/}
              {/*</ButtonDropdown>*/}
            </InputGroup>

            {workorders.list &&
              <div>
                {/* <div className="d-block d-md-flex align-items-center mb-3">
									<div className="d-flex"> */}
                <div className="d-block align-items-center mb-1 mb-sm-3">
                  <div className="flex-column">

                    {areas.fullList && areas.fullList.length > 0 &&
                      <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                        <DropdownToggle color="white" caret>
                          {t('common.filters_by')} {t('common.area')} <b className="caret"></b><span className="badge bg-default text-dark ms-5px">{areaId[1]}</span>
                        </DropdownToggle>
                        <DropdownMenu>
                          {/* {areas.fullList.map((area, index) =>
														<DropdownItem onClick={() => setAreaId([area.id, area.name])} key={area.id}>{area.name}</DropdownItem>
													)} */}
                          {renderAreasDropdownItems(areas.fullList, setAreaId, null)}
                          <DropdownItem divider />
                          <DropdownItem onClick={() => setAreaId([])} key="ALL">Todas</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    }

                    <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                      <DropdownToggle color="white" caret>
                        {t('common.filters_by')} tipo <b className="caret"></b><span className="badge bg-default text-dark ms-5px">{maintenanceTaskTypeId[1]}</span>
                      </DropdownToggle>
                      <DropdownMenu>
                        {MaintenanceTaskTypes.map((type, index) =>
                          <DropdownItem onClick={() => setMaintenanceTaskTypeId([type.id, t("task.type_" + type.NormalizeName)])} key={type.id}>{t("task.type_" + type.NormalizeName)}</DropdownItem>
                        )}
                        <DropdownItem divider />
                        <DropdownItem onClick={() => setMaintenanceTaskTypeId([])} key="ALL">Todos</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>

                    <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                      <DropdownToggle color="white" caret>
                        {t('common.filters_by')} prioridad <b className="caret"></b><span className="badge bg-default text-dark ms-5px">{priorityLevelId[1]}</span>
                      </DropdownToggle>
                      <DropdownMenu>
                        {MaintenanceTaskPriorityLevel.map((type, index) =>
                          <DropdownItem onClick={() => setPriorityLevelId([type.id, t("common.priority_level_" + type.NormalizeName)])} key={type.id}>{t("common.priority_level_" + type.NormalizeName)}</DropdownItem>
                        )}
                        <DropdownItem divider />
                        <DropdownItem onClick={() => setPriorityLevelId([])} key="ALL">Todos</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>

                    {(user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
                      user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF") &&
                      user.fullList &&
                      <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                        <DropdownToggle color="white" caret>
                          {t('common.filters_by')} usuario <b className="caret"></b><span className="badge bg-gray ms-5px">{agentId[1]}</span>
                        </DropdownToggle>
                        <DropdownMenu>
                          {user.fullList.map((user, index) =>
                            (user.role.normalizedName.includes("MANAGER") ||
                              user.role.normalizedName.includes("MAINTENANCECHIEF") ||
                              user.role.normalizedName.includes("MAINTENANCEOPERATOR")) &&
                            <DropdownItem onClick={() => setAgentId([user.agent.id, user.agent.fullName + " (" + t("roles.rol_" + user.role.normalizedName) + ")"])} key={user.agent.id}>{user.agent.fullName + " (" + t("roles.rol_" + user.role.normalizedName) + ")"}</DropdownItem>
                          )}
                          <DropdownItem divider />
                          <DropdownItem onClick={() => setAgentId([])} key="ALL">Todos</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    }

                    <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                      <DropdownToggle color="white" caret>
                        {t('common.filters_by')} estado <b className="caret"></b><span className="badge bg-gray ms-5px">{workOrderStateId[1]}</span>
                      </DropdownToggle>
                      <DropdownMenu>
                        {WorkOrderStates.map((state, index) =>
                          <DropdownItem onClick={() => setWorkOrderStateId([state.id, t("workOrder.state_" + state.NormalizeName)])} key={state.id}>{t("workOrder.state_" + state.NormalizeName)}</DropdownItem>
                        )}
                        <DropdownItem divider />
                        <DropdownItem onClick={() => setWorkOrderStateId([])} key="ALL">Todos</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>

                    {user?.selectedTenant?.tenantTypeId == PrivateNeighborhoodTenantType &&
                      functionalUnits.fullList &&
                      <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                        <DropdownToggle color="white" caret>
                          {t('common.filters_by')} {t('common.functional_unit')} <b className="caret"></b><span className="badge bg-default text-dark ms-5px">{functionalUnitId[1]}</span>
                        </DropdownToggle>
                        <DropdownMenu>
                          {functionalUnitId != "" &&
                            <DropdownItem onClick={() => setFunctionalUnitId([])} key="ALL">Todas</DropdownItem>
                          }
                          {functionalUnits.fullList.map((functionalUnit, index) =>
                            <DropdownItem onClick={() => setFunctionalUnitId([functionalUnit.id, functionalUnit.number])} key={functionalUnit.id}>{functionalUnit.number}</DropdownItem>
                          )}
                          <DropdownItem divider />
                          <DropdownItem onClick={() => setFunctionalUnitId([])} key="ALL">Todas</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    }

                    {user?.selectedTenant?.tenantTypeId == HotelTenantType &&
                      rooms.fullList &&
                      <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                        <DropdownToggle color="white" caret>
                          {t('common.filters_by')} {t('common.room')} <b className="caret"></b><span className="badge bg-default text-dark ms-5px">{roomId[1]}</span>
                        </DropdownToggle>
                        <DropdownMenu>
                          {roomId != "" &&
                            <DropdownItem onClick={() => setRoomId([])} key="ALL">Todas</DropdownItem>
                          }
                          {rooms.fullList.map((room, index) =>
                            <DropdownItem onClick={() => setRoomId([room.id, room.name])} key={room.id}>{room.name}</DropdownItem>
                          )}
                          <DropdownItem divider />
                          <DropdownItem onClick={() => setRoomId([])} key="ALL">Todas</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    }

                    <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                      <DropdownToggle color="white" caret>
                        {t('common.filters_by')} periodo <b className="caret"></b><span className="badge bg-gray ms-5px">{month[1]}</span>
                      </DropdownToggle>
                      <DropdownMenu>
                        {monthsFilter.map((month, index) =>
                          <DropdownItem onClick={() => setMonth([month, month.monthName])} key={month.firstDay}>{month.monthName}</DropdownItem>

                        )}
                        {/* {WorkOrderStates.map((state, index) =>
                          <DropdownItem onClick={() => setWorkOrderStateId([state.id, t("workOrder.state_" + state.NormalizeName)])} key={state.id}>{t("workOrder.state_" + state.NormalizeName)}</DropdownItem>
                        )} */}
                        <DropdownItem divider />
                        <DropdownItem onClick={() => setMonth([])} key="ALL">Todos</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>

                    <div className="btn-group" hidden>
                      <Link to="/extra/search" className="btn btn-white"><i className="fa fa-list"></i></Link>
                      <Link to="/extra/search" className="btn btn-white"><i className="fa fa-th"></i></Link>
                      <Link to="/extra/search" className="btn btn-white"><i className="fa fa-th-large"></i></Link>
                    </div>
                  </div>
                </div>

                {workorders.list.length != 0 ?
                  <Pagination toggleSplitCallBack={toggleSplitCallBack} dataCount={workorders.dataCountFiltered} take={take}>
                    <div className="result-list">
                      {workorders.list.map((workOrder, index) =>
                        // <WorkOrderItem key={workOrder.id} {...workOrder}></WorkOrderItem>
                        <WorkOrderItem key={workOrder.id} workOrder={workOrder}></WorkOrderItem>
                      )}
                    </div>
                  </Pagination>
                  :
                  <div className="alert alert-info" role="alert">
                    {t("common.empty_list")}
                  </div>
                }

                <Modal size="lg" style={{ maxWidth: '80vw', width: '100%' }} isOpen={modalWorkOrderPdf} toggle={() => toggleModal('modalWorkOrderPdf')} >
                  <ModalHeader toggle={() => toggleModal('modalWorkOrderPdf')}>Orden de trabajo</ModalHeader>
                  <ModalBody>
                    <ExportToPdfWorkOrders workOrders={workorders.list} fileName={"Month_WorkOrders.pdf"} />
                  </ModalBody>
                  <ModalFooter>
                    <button className="btn btn-white" onClick={() => toggleModal('modalWorkOrderPdf')}>Cerrar</button>
                  </ModalFooter>
                </Modal>

              </div>
            }

          </div>
        </div>
      </div>
    </Loader>
  )
};


export default WorkOrderList;

import '../../assets/css/site.css';

export const Loader = ({ isLoading, children }) => {
    return (
        <>
            {isLoading && <div className="loading">Loading&#8230;</div>}
            {children}
        </>
    )
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { PURGE } from "redux-persist";

const initialState = {
    list: null,
    fullList: null,
    dataCount: null,
    dataCountFiltered: null,
    movements: null,
    movementsDataCount: null,
    movementsDataCountFiltered: null,
    selectedPettyCash: null,
    lastSync: null,
    isFetching: false,
    error: false,
    errorMessage: "",
    status: "",
}

export const GetPettyCash = createAsyncThunk('pettycash/fetchGetPettyCash', async ({ authToken, params }) => {
    let response = await api.getPettyCash(authToken, params);
    return response;
});

export const ListPettyCashes = createAsyncThunk('pettycash/fetchListPettyCashes', async ({ authToken, body }) => {
    let response = await api.listPettyCashes(authToken, body);
    return response;
});

export const FullListPettyCashes = createAsyncThunk('pettycash/fetchFullListPettyCashes', async ({ authToken, tenantId }) => {
    let body = {
        "tenantId": tenantId,
        "sortBy": null,
        "sortDir": true,
        "skip": 0,
        "take": null
    };
    let response = await api.listPettyCashes(authToken, body);
    return response;
});

export const CreatePettyCash = createAsyncThunk('pettycash/fetchCreatePettyCash', async ({ authToken, body }) => {
    let response = await api.createPettyCash(authToken, body);
    return response;
});

export const EditPettyCash = createAsyncThunk('pettycash/fetchEditPettyCash', async ({ authToken, body }) => {
    let response = await api.editPettyCash(authToken, body);
    return response;
});

export const DeletePettyCash = createAsyncThunk('pettycashs/fetchDeletePettyCash', async ({ authToken, body }) => {
    let response = await api.deletePettyCash(authToken, body);
    return response;
});

export const ListMovements = createAsyncThunk('pettycash/fetchListMovements', async ({ authToken, body }) => {
    let response = await api.listMovements(authToken, body);
    return response;
});

export const AddMovement = createAsyncThunk('pettycash/fetchAddMovement', async ({ authToken, body }) => {
    let response = await api.addMovement(authToken, body);
    return response;
});

export const DeleteMovement = createAsyncThunk('pettycash/fetchDeleteMovement', async ({ authToken, body }) => {
    let response = await api.deleteMovement(authToken, body);
    return response;
});

const pettyCashSlice = createSlice({
    name: "pettycash",
    initialState,
    reducers: {

    },
    extraReducers: {

        [GetPettyCash.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [GetPettyCash.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedPettyCash = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [GetPettyCash.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [ListPettyCashes.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [ListPettyCashes.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.list = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [ListPettyCashes.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [FullListPettyCashes.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [FullListPettyCashes.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.fullList = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [FullListPettyCashes.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [CreatePettyCash.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [CreatePettyCash.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedPettyCash = payload.data;
            state.fullList = null;
        },
        [CreatePettyCash.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [EditPettyCash.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },

        [EditPettyCash.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedPettyCash = payload.data;
            state.fullList = null;
        },
        [EditPettyCash.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [DeletePettyCash.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [DeletePettyCash.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedPettyCash = null;
            state.fullList = null;
        },
        [DeletePettyCash.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [ListMovements.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [ListMovements.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.movements = payload.data;
            state.movementsDataCount = payload.count;
            state.movementsDataCountFiltered = payload.countFiltered;
        },
        [ListMovements.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [AddMovement.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [AddMovement.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
        },
        [AddMovement.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [DeleteMovement.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [DeleteMovement.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
        },
        [DeleteMovement.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [PURGE]: (state) => {
            return initialState;
        },
    }
})

export default pettyCashSlice.reducer; 
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const AreaItem = ({ area, level }) => {
    const { t } = useTranslation('common');
    return (
        <div key={"area" + area.id} className={"result-item ms-" + level * 20 + "px"}>
            <Link to="/extra/search" className="result-image" style={{ backgroundImage: 'url(/assets/img/gallery/gallery-51.jpg)' }} hidden></Link>
            <div className="result-info">
                <h4 className="title"><Link to={"/area/detail?id=" + area.id}><div className="menu-icon"><i className="fa fa-layer-group"></i>&nbsp;{area.name}</div></Link></h4>
                <p className="location">{area.description}</p>
                <p className="desc" hidden>
                    {area.description}
                </p>
                <div className="btn-row" hidden>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Analytics"><i className="fa fa-fw fa-chart-bar"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="areas"><i className="fa fa-fw fa-tasks"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Configuration"><i className="fa fa-fw fa-cog"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Performance"><i className="fa fa-fw fa-tachometer"></i></Link>
                    <Link to="/extra/search" data-toggle="tooltip" data-container="body" data-title="Users"><i className="fa fa-fw fa-user"></i></Link>
                </div>
            </div>
            <div className="result-price" hidden>
                {/*$92,101 <small>PER MONTH</small>*/}
                <Link to={"/area/detail?id=" + area.id} className="btn btn-gray-600 d-block w-100">{t("common.detail")}</Link>
            </div>
        </div>
    )
}

export default AreaItem;

/* <div class="menu-item active expand "><a class="menu-link" href="/area/list"> <div class="menu-icon"><i class="fa fa-layer-group"></i></div>  <div class="menu-text">Areas  </div></a></div> */
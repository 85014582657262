import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { PURGE } from "redux-persist";
import { ChangeTenant } from "./userSlice";

const initialState = {
    list: null,
    fullList: null,
    dataCount: null,
    dataCountFiltered: null,
    selectedFunctionalUnit: null,
    lastSync: null,
    isFetching: false,
    error: false,
    errorMessage: "",
    status: "",
}

export const GetFunctionalUnit = createAsyncThunk('functionalUnit/fetchGetFunctionalUnit', async ({ authToken, params }) => {
    let response = await api.getFunctionalUnit(authToken, params);
    return response;
});

export const ListFunctionalUnits = createAsyncThunk('functionalUnit/fetchListFunctionalUnits', async ({authToken, body}) => {
    let response = await api.listFunctionalUnits(authToken, body);
    return response;
});

export const FullListFunctionalUnits = createAsyncThunk('area/fetchFullListFunctionalUnits', async ({ authToken, tenantId }) => {
    let body = {
        "tenantId": tenantId,
        "sortBy": null,
        "sortDir": true,
        "skip": 0,
        "take": null
    };
    let response = await api.listFunctionalUnits(authToken, body);
    return response;
});

export const CreateFunctionalUnit = createAsyncThunk('functionalUnit/fetchCreateFunctionalUnit', async ({ authToken, body }) => {
    let response = await api.createFunctionalUnit(authToken, body);
    return response;
});

export const EditFunctionalUnit = createAsyncThunk('functionalUnit/fetchEditFunctionalUnit', async ({ authToken, body }) => {
    let response = await api.editFunctionalUnit(authToken, body);
    return response;
});

export const DeleteFunctionalUnit = createAsyncThunk('functionalUnit/fetchDeleteFunctionalUnit', async ({ authToken, body }) => {
    let response = await api.deleteFunctionalUnit(authToken, body);
    return response;
});

const functionalUnitSlice = createSlice({
    name: "functionalUnit",
    initialState,
    reducers: {

    },
    extraReducers: {

        [GetFunctionalUnit.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [GetFunctionalUnit.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedFunctionalUnit = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [GetFunctionalUnit.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [ListFunctionalUnits.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [ListFunctionalUnits.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.list = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [ListFunctionalUnits.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [FullListFunctionalUnits.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [FullListFunctionalUnits.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.fullList = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [FullListFunctionalUnits.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [CreateFunctionalUnit.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [CreateFunctionalUnit.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedFunctionalUnit = payload.data;
            state.fullList = null;
        },
        [CreateFunctionalUnit.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [EditFunctionalUnit.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [EditFunctionalUnit.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedFunctionalUnit = payload.data;
            state.fullList = null;
        },
        [EditFunctionalUnit.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [DeleteFunctionalUnit.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [DeleteFunctionalUnit.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedFunctionalUnit = null;
            state.fullList = null;
        },
        [DeleteFunctionalUnit.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [ChangeTenant.fulfilled]: (state, { payload }) => {
            return initialState;
        },


        [PURGE]: (state) => {
            return initialState;
        },
    }
})

export default functionalUnitSlice.reducer; 
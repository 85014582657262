import { configureStore } from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'

import appReducer from './redux/appSlice';
import userReducer from './redux/userSlice';
import tenantReducer from './redux/tenantSlice';
import maintenanceTaskReducer from './redux/maintenanceTaskSlice';
import workOrderReducer from './redux/workOrderSlice';
import serviceTaskReducer from './redux/serviceTaskSlice';
import serviceWorkOrderReducer from './redux/serviceWorkOrderSlice';
import routineTaskReducer from './redux/routineTaskSlice';
import routineWorkOrderReducer from './redux/routineWorkOrderSlice';
import assetReducer from './redux/assetSlice';
import areaReducer from './redux/areaSlice';
import auditReducer from './redux/auditSlice';
import pettyCashReducer from './redux/pettyCashSlice';
import functionalUnitReducer from './redux/functionalUnitSlice';
import buildingSiteReducer from './redux/buildingSiteSlice';
import roomReducer from './redux/roomSlice';
import supplierReducer from './redux/supplierSlice';
import dashboardReducer from './redux/dashboardSlice';
import fileReducer from './redux/fileSlice';
import backendTenantReducer from './redux/backend/tenantSlice';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['tenantReducer', 'maintenanceTaskReducer', 'workOrderReducer', 'serviceTaskReducer', 'serviceWorkOrderReducer', 'routineTaskReducer', 'routineWorkOrderReducer', 'assetReducer', 'roomReducer', 'areaReducer', 'auditReducer', 'pettyCashReducer', 'functionalUnitReducer', 'buildingSiteReducer', 'supplierReducer', 'dashboardReducer', 'fileReducer', 'backendTenantReducer'],
    whitelist: ['appReducer', 'userReducer'],
}

const rootReducers = combineReducers({
    appReducer: appReducer,
    userReducer: userReducer,
    tenantReducer: tenantReducer,
    maintenanceTaskReducer: maintenanceTaskReducer,
    workOrderReducer: workOrderReducer,
    serviceTaskReducer: serviceTaskReducer,
    serviceWorkOrderReducer: serviceWorkOrderReducer,
    routineTaskReducer: routineTaskReducer,
    routineWorkOrderReducer: routineWorkOrderReducer,
    assetReducer: assetReducer,
    roomReducer : roomReducer,
    areaReducer: areaReducer,
    auditReducer: auditReducer,
    pettyCashReducer: pettyCashReducer,
    functionalUnitReducer : functionalUnitReducer,
    buildingSiteReducer: buildingSiteReducer,
    supplierReducer: supplierReducer,
    dashboardReducer: dashboardReducer,
    fileReducer: fileReducer,
    backendTenantReducer: backendTenantReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducers)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})

export const persistor = persistStore(store)
import React, { useContext, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Panel, PanelHeader, PanelFooter, PanelBody } from '.././../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../widget/loader';
import { CreateSupplier } from '../../../services/redux/supplierSlice';
//import { GetGlobalData } from '../../../services/redux/appSlice';
import { useTranslation } from "react-i18next";

const SupplierCreate = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');

    const globalData = useSelector(state => state.appReducer);
    const user = useSelector(state => state.userReducer);
    const supplier = useSelector(state => state.supplierReducer);

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
    }, []);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {

        let requestData = {
            "tenantId": user.selectedTenant.id,
            "name": data.name,
            "description": (data.description != "") ? data.description : null,
            "fiscalIdentifier": (data.fiscalIdentifier != "") ? data.fiscalIdentifier : null,
            "street": (data.street != "") ? data.street : null,
            "streetNumber": (data.streetNumber != "") ? data.streetNumber : null,
            "stateOrRegion": (data.stateOrRegion != "") ? data.stateOrRegion : null,
            "countryId": (data.countryId != "") ? data.countryId : null,
            "zipCode": (data.zipCode != "") ? data.zipCode : null,
            "email": (data.email != "") ? data.email : null,
            "phoneNumber": (data.phoneNumber != "") ? data.phoneNumber : null,
            "phoneNumber2": (data.phoneNumber2 != "") ? data.phoneNumber2 : null,
            "SupplierCategoryId": data.supplierCategoryId,
            "createdById": user.selectedTenant.agentId,
        }

        //console.log(requestData);

        await dispatch(CreateSupplier({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload && response.payload.data &&
                history.push("/supplier/detail?id=" + response.payload.data.id);
        });
    };

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (
        <Loader isLoading={supplier.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active">Nueva Proveedor</li>
                </ol>
                <h1 className="page-header">Nuevo Proveedor <small>complete los campos obligatorios</small></h1>

                <div className="d-flex align-items-center mb-2">
                    <div className="ms-auto">
                        <Link to="/supplier/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-7">
                        <div className="col-xl-12">
                            <Panel>
                                <PanelHeader noButton={true}>
                                    <div className="d-flex align-items-center">
                                        {t("common.general_data")}
                                    </div>
                                </PanelHeader>
                                <PanelBody>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-floating mb-15px">
                                            <input type="text" className="form-control fs-15px" id="name" {...register("name", {
                                                required: true,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                Nombre
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <input type="text" className="form-control fs-15px" id="model" {...register("fiscalIdentifier", {
                                                required: false,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                Identificador Fiscal
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <input type="text" className="form-control fs-15px" id="model" {...register("street", {
                                                required: false,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                Calle
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <input type="text" className="form-control fs-15px" id="model" {...register("streetNumber", {
                                                required: false,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                Calle Número
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <input type="text" className="form-control fs-15px" id="stateOrRegion" {...register("stateOrRegion", {
                                                required: false,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                Localidad/Partido
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <input type="text" className="form-control fs-15px" id="zipCode" {...register("zipCode", {
                                                required: false,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                Código Postal
                                            </label>
                                        </div>

                                        {globalData.countries &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="countryId" {...register("countryId", {
                                                    required: true,
                                                })}>
                                                    <option></option>
                                                    {globalData.countries.map(r => <option key={r.id} value={r.id}>{r.NormalizeName}</option>)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    País
                                                </label>
                                            </div>
                                        }

                                        <div className="form-floating mb-15px">
                                            <input type="text" className="form-control fs-15px" id="model" {...register("email", {
                                                required: false,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                Email
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <input type="text" className="form-control fs-15px" id="model" {...register("phoneNumber", {
                                                required: false,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                Teléfono
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <input type="text" className="form-control fs-15px" id="model" {...register("phoneNumber2", {
                                                required: false,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                Teléfono Alternativo
                                            </label>
                                        </div>

                                        {globalData.supplierCategories &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="areaId" {...register("supplierCategoryId", {
                                                    required: true,
                                                })}>
                                                    <option></option>
                                                    {globalData.supplierCategories.map(a => <option key={a.id} value={a.id}>{a.NormalizeName}</option>)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    Categoría
                                                </label>
                                            </div>
                                        }

                                        <div className="form-floating mb-15px">
                                            {/* <input type="text" className={"form-control fs-15px" + (errors.description ? ' is-invalid' : '')} id="model" {...register("description", {
                                            required: false,
                                            value: supplier.description,
                                        })} /> */}
                                            <textarea rows="5" maxLength={500} className={"form-control fs-15px h-100" + (errors.description ? ' is-invalid' : '')}
                                                id="description" {...register("description", {
                                                    required: false,
                                                    value: supplier.description,
                                                })} />
                                            <label htmlFor="floatingInput" className="fs-13px">
                                                Información adicional
                                            </label>
                                            {errors.description && <span className='invalid-feedback'>This field is required</span>}
                                        </div>

                                        <div className="mb-20px">
                                            <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value={t("common.add")} />
                                            {user.error && <div className='text-center'>
                                                <span className='text-danger'>Ha ocurrido un problema: {supplier.errorMessage}</span></div>}
                                        </div>

                                    </form>

                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                </div>
            </div>
        </Loader>
    )
};

export default SupplierCreate;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { PURGE } from "redux-persist";

const initialState = {
    list: null,
    dataCount: null,
    dataCountFiltered: null,
    selectedWorkOrder: null,
    lastSync: null,
    isFetching: false,
    error: false,
    errorMessage: "",
    status: "",
}

export const GetWorkOrder = createAsyncThunk('workOrder/fetchGetWorkOrder', async ({ authToken, params }) => {
    let response = await api.getWorkOrder(authToken, params);
    return response;
});

export const ListWorkOrders = createAsyncThunk('workOrder/fetchListWorkOrders', async ({authToken, body}) => {
    let response = await api.listWorkOrders(authToken, body);
    return response;
});

export const CreateWorkOrder = createAsyncThunk('workOrder/fetchCreateWorkOrder', async ({ authToken, body }) => {
    let response = await api.createWorkOrder(authToken, body);
    return response;
});

export const SendToValidateWorkOrder = createAsyncThunk('workOrder/fetchSendToValidateWorkOrder', async ({ authToken, body }) => {
    let response = await api.sendToValidateWorkOrder(authToken, body);
    return response;
});

export const FinishWorkOrder = createAsyncThunk('workOrder/fetchFinishWorkOrder', async ({ authToken, body }) => {
    let response = await api.finishWorkOrder(authToken, body);
    return response;
});

export const AddWorkOrderDetail = createAsyncThunk('workOrder/fetchAddWorkOrderDetail', async ({ authToken, body }) => {
    let response = await api.addWorkOrderDetail(authToken, body);
    return response;
});


const workOrderSlice = createSlice({
    name: "workOrder",
    initialState,
    reducers: {

    },
    extraReducers: {

        [GetWorkOrder.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [GetWorkOrder.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedWorkOrder = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [GetWorkOrder.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [ListWorkOrders.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [ListWorkOrders.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.list = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [ListWorkOrders.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [CreateWorkOrder.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [CreateWorkOrder.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedWorkOrder = payload.data;
        },
        [CreateWorkOrder.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [SendToValidateWorkOrder.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [SendToValidateWorkOrder.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedWorkOrder = payload.data;
        },
        [SendToValidateWorkOrder.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [FinishWorkOrder.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [FinishWorkOrder.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedWorkOrder = payload.data;
        },
        [FinishWorkOrder.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [AddWorkOrderDetail.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [AddWorkOrderDetail.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedWorkOrder = payload.data;
        },
        [AddWorkOrderDetail.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },


        [PURGE]: (state) => {
            return initialState;
        },
    }
})

export default workOrderSlice.reducer; 
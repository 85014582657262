import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import moment from 'moment';

const AuditItem = ({ audit }) => {
    const { t } = useTranslation('common');
    return (
        <div className="result-item" key={audit.id}>
            <Link to="/extra/search" className="result-image" style={{ backgroundImage: 'url(/assets/img/gallery/gallery-51.jpg)' }} hidden></Link>
            <div className="result-info">
                <p className="location">{moment(audit.createdOn).format("DD/MM/YYYY")}</p>
                <h4 className="title"><Link to={"/audit/detail?id=" + audit.id}>{audit.name}</Link></h4>
                <p className="location">{audit.description}</p>
                <p>
                    <span className="badge bg-default text-dark me-5px">{t("audit.type_" + audit.typeNormalizedName)}</span>
                    <span className="badge bg-gray me-5px">{t("audit.state_" + audit.stateNormalizedName)}</span>
                    Creada por: {audit.createdBy}
                </p>
            </div>
            <div className="result-price" hidden>
                {/*$92,101 <small>PER MONTH</small>*/}
                <Link to={"/audit/detail?id=" + audit.id} className="btn btn-gray-600 d-block w-100">{t("common.detail")}</Link>
            </div>
        </div>
    )
}

export default AuditItem;
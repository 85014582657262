import React, { useContext, useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { renderAreasOptions } from '../../../components/app/area/common.js';
import { Panel, PanelBody, PanelHeader } from '../../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { FullListAreas } from '../../../services/redux/areaSlice';
import { EditAsset } from '../../../services/redux/assetSlice';
import { FullListRooms } from '../../../services/redux/roomSlice.js';
import { AssetPriorityLevel, AssetTypes } from '../../../utils/generalConstants';

const AssetEdit = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const location = useLocation();
    const { t } = useTranslation('common');

    const { asset } = location.state

    const user = useSelector(state => state.userReducer);
    const areas = useSelector(state => state.areaReducer);
    const rooms = useSelector(state => state.roomReducer)
    const assetReducer = useSelector(state => state.assetReducer);

    const [isActive, setIsActive] = useState(asset.isActive ? true : false);
    const [isOutOfService, setIsOutOfService] = useState(asset.isOutOfService ? true : false);
    const [isBackup, setIsBackup] = useState(asset.isBackup ? true : false);
    const [isArchived, setIsArchived] = useState(asset.isArchived ? true : false);
    const [purchaseDate, setPurchaseDate] = useState(asset.purchaseDate ? new Date(asset.purchaseDate) : null);
    const [warrantyExpirationDate, setWarrantyExpirationDate] = useState(asset.warrantyExpirationDate ? new Date(asset.warrantyExpirationDate) : null);

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);

        !areas.fullList &&
            dispatch(FullListAreas({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

        !rooms.fullList &&
            dispatch(FullListRooms({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            })
    }, []);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {

        let requestData = {
            "tenantId": user.selectedTenant.id,
            "Id": asset.id,
            "typeId": data.assetTypeId,
            "name": data.name,
            "brand": (data.brand != "") ? data.brand : null,
            "model": (data.model != "") ? data.model : null,
            "serialNumber": (data.serialNumber != "") ? data.serialNumber : null,
            "IsOutOfService": isOutOfService,
            "IsActive": true,
            "IsBackup": isBackup,
            "IsArchived": isArchived,
            "PurchaseDate": purchaseDate,
            "warrantyExpirationDate": warrantyExpirationDate,
            "MoreInfo": data.moreInfo,
            "priorityLevelId": data.priorityLevelId,
            "areaId": (data.areaId != "") ? data.areaId : null,
            "roomId": (data.roomId != "") ? data.roomId : null,
            "createdById": user.selectedTenant.agentId,
        }

        dispatch(EditAsset({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload && response.payload.data &&
                history.push("/asset/detail?id=" + response.payload.data.id);
        });
    };

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (

        <div>
            <ol className="breadcrumb float-xl-end" hidden>
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/asset/list">Activos</Link></li>
                <li className="breadcrumb-item active">{t('common.edit')} Activo</li>
            </ol>
            <h1 className="page-header">{t('common.edit')} {t('common.asset')}  <small>complete los campos obligatorios</small></h1>

            <div className="d-flex align-items-center mb-2">
                <div className="ms-auto">
                    <Link to="/asset/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                    <Link to={"/asset/detail?id=" + asset.id} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-7">
                    <div className="col-xl-12">
                        <Panel>
                            <PanelHeader noButton={true}>
                                <div className="d-flex align-items-center">
                                    {t('common.general_data')}
                                </div>
                            </PanelHeader>
                            <PanelBody>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-floating mb-15px">
                                        <input type="text" maxLength={70} className={"form-control fs-15px" + (errors.name ? ' is-invalid' : '')} id="name" {...register("name", {
                                            required: true,
                                            value: asset.name
                                        })} />
                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                            {t('common.name')}
                                        </label>
                                        {errors.name && <span className='invalid-feedback'>This field is required</span>}
                                    </div>

                                    <div className="form-floating mb-15px">
                                        <input type="text" maxLength={90} className="form-control fs-15px" id="brand" {...register("brand", {
                                            required: false,
                                            value: asset.brand
                                        })} />
                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                            {t('common.brand')}
                                        </label>
                                    </div>

                                    <div className="form-floating mb-15px">
                                        <input type="text" maxLength={90} className="form-control fs-15px" id="model" {...register("model", {
                                            required: false,
                                            value: asset.model
                                        })} />
                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                            {t('common.model')}
                                        </label>
                                    </div>

                                    <div className="form-floating mb-15px">
                                        <input type="text" maxLength={90} className="form-control fs-15px" id="serialNumber" {...register("serialNumber", {
                                            required: false,
                                            value: asset.serialNumber
                                        })} />
                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                            {t('common.serial_number')}
                                        </label>
                                    </div>

                                    {AssetTypes &&
                                        <div className="form-floating mb-15px">
                                            <select className="form-select" id="assetTypeId" {...register("assetTypeId", {
                                                required: true,
                                                value: asset.assetTypeId,
                                            })}>
                                                <option></option>
                                                {AssetTypes.map(r => <option key={r.id} value={r.id}>{t("asset.type_" + r.NormalizeName)}</option>)}
                                            </select>
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.type')}
                                            </label>
                                        </div>
                                    }

                                    {/* <div className="row mb-10px align-items-center">
                                        <div className="col-8 control-label text-dark fw-bold">¿Se encuentra Activo?</div>
                                        <div className="col-4 d-flex">
                                            <div className="form-check form-switch ms-auto mb-0">
                                                <input type="checkbox" className="form-check-input" name="IsActive" onChange={(e) => setIsActive(e.target.checked)} id="IsActive" checked={isActive} />
                                                <label className="form-check-label" htmlFor="appHeaderInverse">&nbsp;</label>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="row mb-10px align-items-center">
                                        <div className="col-8 control-label text-dark fw-bold">{t("asset.out_of_service")}</div>
                                        <div className="col-4 d-flex">
                                            <div className="form-check form-switch ms-auto mb-0">
                                                <input type="checkbox" className="form-check-input" name="IsOutOfService" onChange={(e) => setIsOutOfService(e.target.checked)} id="IsOutOfService" checked={isOutOfService} />
                                                <label className="form-check-label" htmlFor="appHeaderInverse">&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-10px align-items-center">
                                        <div className="col-8 control-label text-dark fw-bold">{t("asset.backup")}</div>
                                        <div className="col-4 d-flex">
                                            <div className="form-check form-switch ms-auto mb-0">
                                                <input type="checkbox" className="form-check-input" name="IsBackup" onChange={(e) => setIsBackup(e.target.checked)} id="IsBackup" checked={isBackup} />
                                                <label className="form-check-label" htmlFor="appHeaderInverse">&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-10px align-items-center">
                                        <div className="col-8 control-label text-dark fw-bold">{t("asset.archived")}</div>
                                        <div className="col-4 d-flex">
                                            <div className="form-check form-switch ms-auto mb-0">
                                                <input type="checkbox" className="form-check-input"
                                                    style={isArchived ? {
                                                        backgroundColor: '#dc3545',
                                                        borderColor: '#dc3545',
                                                        boxShadow: '0 0 0 1px #dc3545',
                                                    } : {
                                                        backgroundColor: '#fff',
                                                        color: '#fff',
                                                        boxShadow: '0 0 0 1px #eee',
                                                    }}
                                                    name="IsArchived" onChange={(e) => setIsArchived(e.target.checked)} id="IsArchived" checked={isArchived} />
                                                <label className="form-check-label" htmlFor="appHeaderInverse">&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-floating mb-15px">

                                        <select className="form-select" id="priorityLevelId" {...register("priorityLevelId", {
                                            required: true,
                                            value: asset.priorityLevelId
                                        })}>
                                            <option></option>
                                            {AssetPriorityLevel.map(o => <option key={o.id} value={o.id}>{t("common.priority_level_" + o.NormalizeName)}</option>)}
                                        </select>
                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                            {t('common.priority')}
                                        </label>
                                    </div>

                                    {areas.fullList &&
                                        <div className="form-floating mb-15px">
                                            <select className="form-select" id="areaId" {...register("areaId", {
                                                required: false,
                                                value: asset.areaId
                                            })}>
                                                <option></option>
                                                {/* {areas.fullList.map(a => 
                                                    <option key={a.id} value={a.id}>{a.parentAreaId && '  - '}{a.name}</option>)} */}
                                                {renderAreasOptions(areas.fullList, null)}
                                            </select>
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.area')}
                                            </label>
                                        </div>
                                    }

                                    {user?.selectedTenant?.settings?.assetsPerRoomm &&
                                        rooms.fullList &&
                                        <div className="form-floating mb-15px">
                                            <select className="form-select" id="roomId" {...register("roomId", {
                                                required: false,
                                                value: asset.roomId
                                            })}>
                                                <option></option>
                                                {rooms.fullList.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
                                            </select>
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.room')}
                                            </label>
                                        </div>
                                    }

                                    <div className="form-floating mb-15px">
                                        <DatePicker className="form-control fs-15px pt-25px" dateFormat="dd-MM-yyyy" selected={purchaseDate} onChange={(date) => setPurchaseDate(date)} />
                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-11px pt-1px mb-10px pb-30px">
                                            {t('common.purchase_date')}
                                        </label>
                                    </div>

                                    <div className="form-floating mb-15px">
                                        <DatePicker className="form-control fs-15px pt-25px" dateFormat="dd-MM-yyyy" selected={warrantyExpirationDate} onChange={(date) => setWarrantyExpirationDate(date)} />
                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-11px pt-1px mb-10px pb-30px">
                                            {t('asset.warranty_expiration_date')}
                                        </label>
                                    </div>

                                    <div className="form-floating mb-15px">
                                        <textarea rows="5" maxLength={2000} className="form-control fs-15px h-100"
                                            id="moreInfo" {...register("moreInfo", {
                                                required: false,
                                                value: asset.moreInfo,
                                            })} />
                                        <label htmlFor="floatingInput" className="fs-13px">
                                            {t('common.aditional_info')}
                                        </label>
                                    </div>

                                    <div>
                                        <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value={t("common.save")} />
                                        {assetReducer.error && <div className='text-center mt-10px'>
                                            <span className='text-danger'>Ha ocurrido un problema: {assetReducer.errorMessage}</span></div>}
                                    </div>

                                </form>

                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AssetEdit;
import React, { useContext, useState, useEffect } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { Panel, PanelHeader, PanelFooter, PanelBody } from '.././../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { GetBuildingSite, DeleteBuildingSite } from '../../../services/redux/buildingSiteSlice';
import Upload from '../../../components/app/file/upload.js';
import Viewer from '../../../components/app/file/viewer.js';
import { postfileData } from '../../../services/redux/fileSlice';
import { FileTypes } from '../../../utils/generalConstants';
import { useTranslation } from "react-i18next";

const BuildingSiteDetail = () => {
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const history = useHistory();
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const buildingSite = useSelector(state => state.buildingSiteReducer);

    const query = new URLSearchParams(window.location.search);

    const [modalAddFiles, setModalAddFiles] = useState(false);
    const [modalViewDeleteFile, setModalViewDeleteFile] = useState(false);

    const getBuildingSite = async () => {
        const requestData = {
            "tenantId": user.selectedTenant.id,
            "id": query.get("id")
        };
        await dispatch(GetBuildingSite({ authToken: user.authToken, params: requestData })).then((response) => {
        });
    };

    const deleteBuildingSite = async (id) => {
        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": id,
        }
        //console.log(requestData);
        await dispatch(DeleteBuildingSite({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload &&
                history.push("/buildingSite/list");
        });
    }

    const onUploadSubmit = async (data) => {
        data.append("TenantId", user.selectedTenant.id);
        data.append("AgentId", user.selectedTenant.agentId);
        data.append("CreatedOn", "2015-08-05T19:33:26Z");
        data.append("ReferenceId", buildingSite.selectedBuildingSite.id);
        data.append("FileTypeId", FileTypes.filter(f => f.NormalizeName === 'BUILDINGSITE IMAGE')[0].id);

        await dispatch(postfileData({ authToken: user.authToken, body: data })).then((response) => {
            setModalAddFiles(false);
            getBuildingSite();
        });
    };

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        getBuildingSite();
    }, []);

    const toggleModal = (name) => {
        switch (name) {
            case 'modalAddFiles':
                setModalAddFiles(!modalAddFiles);
                break;
            case 'modalViewDeleteFile':
                setModalViewDeleteFile(!modalViewDeleteFile);
                break;
            default:
                break;
        }
    }

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (
        <div>
            {buildingSite.selectedBuildingSite &&
                <div>
                    <ol className="breadcrumb float-xl-end" hidden>
                        <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/buildingSite/list">Control de Obra</Link></li>
                        <li className="breadcrumb-item active">{buildingSite.selectedBuildingSite.name}</li>
                    </ol>
                    <h1 className="page-header">Obra Lote #{buildingSite.selectedBuildingSite.parcelNumber} <small>{/*{buildingSite.selectedBuildingSite.typeNormalizedName}*/}</small></h1>

                    <div className="d-flex align-items-center mb-2">
                        <div className="ms-auto">
                            <Link to="/buildingSite/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                            <Link to={"/functionalUnit/detail?id=" + buildingSite.selectedBuildingSite.functionalUnitId} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-6">
                            <div className="col-xl-12">
                                <Panel>
                                    <PanelHeader noButton={true}>
                                        <div className="d-flex justify-content-between">
                                            {t("common.general_data")}
                                            <div>
                                                <Link className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill" to={{
                                                    pathname: "/buildingSite/edit",
                                                    state: {
                                                        buildingSite: buildingSite.selectedBuildingSite
                                                    }
                                                }}> {t("common.edit")}</Link>
                                                <button
                                                    className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-2"
                                                    onClick={() => toggleModal('modalAddFiles')}>
                                                    <i className="fa fa-plus me-1"></i> Agregar Archivos
                                                </button>
                                            </div>
                                        </div>
                                    </PanelHeader>
                                    <PanelBody>
                                        <p>Lote #: {buildingSite.selectedBuildingSite.parcelNumber}</p>
                                        <p>{t("common.owner")}: {buildingSite.selectedBuildingSite.owner}</p>
                                        {buildingSite.selectedBuildingSite.ownerContactInfo &&
                                            <p>{t("common.contact_indo")}: {buildingSite.selectedBuildingSite.ownerContactInfo}</p>
                                        }
                                        <p>Fecha de inicio: {moment(buildingSite.selectedBuildingSite.startDate).format("DD/MM/YYYY")}</p>
                                        {buildingSite.selectedBuildingSite.finishDate &&
                                            <p>Fecha de finalización: {moment(buildingSite.selectedBuildingSite.finishDate).format("DD/MM/YYYY")}</p>
                                        }
                                        {buildingSite.selectedBuildingSite.estimatedFinishDate &&
                                            <p>Fecha de finalización estimada: {moment(buildingSite.selectedBuildingSite.estimatedFinishDate).format("DD/MM/YYYY")}</p>
                                        }
                                        <p>{t("common.state")}: <b>{buildingSite.selectedBuildingSite.stateName}</b></p>
                                        {buildingSite.selectedBuildingSite.geolocation &&
                                            <p>{t("common.geolocation")}: {buildingSite.selectedBuildingSite.geolocation}</p>
                                        }
                                        {buildingSite.selectedBuildingSite.observations &&
                                            <p>{t("common.aditional_info")}: {buildingSite.selectedBuildingSite.observations}</p>
                                        }
                                        <p>{t("common.created_by")}: {buildingSite.selectedBuildingSite.createdBy}</p>
                                        <p>{t("common.createdOn")}: {moment(buildingSite.selectedBuildingSite.createdOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm")}</p>
                                    </PanelBody>
                                    <PanelFooter>
                                        <div className="d-flex align-items-center">
                                            <div className="ms-auto">
                                                <button className='btn btn-danger' onClick={() => { toggleModal('modalViewDeleteFile') }}> Eliminar <i className="fa fa-trash"></i> </button>
                                            </div>
                                        </div>
                                    </PanelFooter>
                                </Panel>
                            </div>

                        </div>
                        <div className="col-xl-3">
                            {/* <div className="col-xl-12">
                                <Panel>
                                    <PanelHeader noButton={true}>
                                        <div className="d-flex align-items-center">
                                            Extra information
                                        </div>
                                    </PanelHeader>
                                    <PanelBody>
                                        <p>Panel Content Here</p>
                                    </PanelBody>
                                </Panel>
                            </div> */}
                            <Upload onSubmit={onUploadSubmit} isOpen={modalAddFiles} toggleModal={() => toggleModal('modalAddFiles')}  ></Upload>
                            
                        </div>
                    </div>
                    <Viewer files={buildingSite.selectedBuildingSite.files} />

                    <Modal size="md" style={{ maxWidth: '450px', width: '100%' }} isOpen={modalViewDeleteFile} toggle={() => toggleModal('modalViewDeleteFile')} >
                        <ModalHeader toggle={() => toggleModal('modalViewDeleteFile')}>Eliminar Control de Obra</ModalHeader>
                        <ModalBody>
                            {!buildingSite.error &&
                                <span>¿Esta seguro que desea eliminar el activo <b>{buildingSite.selectedBuildingSite.parcelNumber}</b>, creado por <b>{buildingSite.selectedBuildingSite.createdBy}</b> el <b>{moment(buildingSite.selectedBuildingSite.createdOn).format("DD/MM/YYYY")}</b> ?</span>
                            }
                            {buildingSite.error && <div className='text-center'>
                                <span className='text-danger'>Ha ocurrido un problema: {buildingSite.errorMessage}</span></div>}
                        </ModalBody>
                        {!buildingSite.error &&
                            <ModalFooter>
                                <button className="btn btn-danger" onClick={() => deleteBuildingSite(buildingSite.selectedBuildingSite.id)}>Si, borrar!</button>
                                <button className="btn btn-white" onClick={() => toggleModal('modalViewDeleteFile')}>No</button>
                            </ModalFooter>
                        }
                    </Modal>

                </div>
            }
        </div>
    )
};

export default BuildingSiteDetail;
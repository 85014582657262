import React, { useContext, useState, useEffect } from 'react';
import { Panel, PanelHeader, PanelFooter, PanelBody } from '.././../../components/panel/panel.jsx';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import { AppSettings } from '../../../config/app-settings.js';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetServiceWorkOrder, FinishServiceWorkOrder, AddServiceWorkOrderDetail } from '../../../services/redux/serviceWorkOrderSlice';
import { AddMovement } from '../../../services/redux/pettyCashSlice';
import { postfileData } from '../../../services/redux/fileSlice';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { WorkOrderStateStartedId, WorkOrderStateToValidateId, WorkOrderStateFinishedId } from '../../../utils/generalConstants';
import SweetAlert from 'react-bootstrap-sweetalert';
import Upload from '../../../components/app/file/upload.js';
import Viewer from '../../../components/app/file/viewer.js';
import { FileTypes } from '../../../utils/generalConstants';
import { Loader } from '../../widget/loader';
import WorkOrderPdf from './pdf.js';
import { renderLinesBreak } from '../../../utils/common.js';


const ServiceWorkOrderDetail = () => {

    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const user = useSelector(state => state.userReducer);
    const serviceWorkOrder = useSelector(state => state.serviceWorkOrderReducer)
    const pettyCash = useSelector(state => state.pettyCashReducer)
    const query = new URLSearchParams(window.location.search)
    const serviceWorkOrderId = query.get("id");
    const { t } = useTranslation('common');

    const [sweetAlertFinish, setSweetAlertFinish] = useState(false);

    const [modalAddFiles, setModalAddFiles] = useState(false);
    const [modalAddExpense, setModalAddExpense] = useState(false);
    const [movementDate, setMovementDate] = useState(null);
    const [modalAddServiceWorkOrderDetail, setModalAddServiceWorkOrderDetail] = useState(false);
    const [modalWorkOrderPdf, setModalWorkOrderPdf] = useState(false);

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        getServiceWorkOrder();
    }, []);

    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const { register: registerDetail, handleSubmit: handleSubmitDetail, formState: { errors: errorsDetail }, reset: resetDetail } = useForm();

    const getServiceWorkOrder = async () => {

        const requestData = {
            "tenantId": user.selectedTenant.id,
            "id": query.get("id")
        };

        await dispatch(GetServiceWorkOrder({ authToken: user.authToken, params: requestData })).then((response) => {
            //console.log(response);
        });
    };

    const finishServiceWorkOrder = async (serviceWorkOrder) => {
        let requestData = {
            "id": serviceWorkOrder.id,
            "tenantId": user.selectedTenant.id,
            "LogTrackBody": "Orden de trabajo finalizada",//messageModal,
            "AgentId": user.selectedTenant.agentId,
        }

        //console.log(requestData);
        toggleSweetAlert("sweetAlertFinish");
        await dispatch(FinishServiceWorkOrder({ authToken: user.authToken, body: requestData })).then((response) => {
            getServiceWorkOrder();
        });
    }

    const addMovement = async (data) => {
        const requestData = {
            "tenantId": user.selectedTenant.id,
            "serviceWorkOrderId": serviceWorkOrderId,
            "pettyCashId": data.pettyCashId,
            "typeId": data.typeId,
            "description": data.description,
            "movementDate": movementDate,
            "amount": data.amount,
            "createdById": user.selectedTenant.agentId
        };
        //console.log(requestData);
        await dispatch(AddMovement({ authToken: user.authToken, body: requestData })).then((response) => {
            if (!response.error) {
                toggleModal('modalAddExpense');
                reset();
                getServiceWorkOrder();
            }
        });
    };

    const addServiceWorkOrderDetail = async (data) => {
        const requestData = {
            "tenantId": user.selectedTenant.id,
            "id": serviceWorkOrderId,
            "detail": data.serviceWorkOrderDetail
        };
        //console.log(requestData);
        await dispatch(AddServiceWorkOrderDetail({ authToken: user.authToken, body: requestData })).then((response) => {
            if (!response.error) {
                toggleModal('modalAddServiceWorkOrderDetail');
                reset();
                getServiceWorkOrder();
            }
        });
    }

    const onUploadSubmit = async (data) => {
        data.append("TenantId", user.selectedTenant.id);
        data.append("AgentId", user.selectedTenant.agentId);
        data.append("CreatedOn", "2015-08-05T19:33:26Z");
        data.append("ReferenceId", serviceWorkOrder.selectedServiceWorkOrder.id);
        data.append("FileTypeId", FileTypes.filter(f => f.NormalizeName === 'SERVICEWORKORDER FILE')[0].id);

        await dispatch(postfileData({ authToken: user.authToken, body: data })).then((response) => {
            setModalAddFiles(false);
            getServiceWorkOrder();
        });
    };

    const toggleSweetAlert = (name) => {
        switch (name) {
            case 'sweetAlertFinish':
                setSweetAlertFinish(!sweetAlertFinish);
                break;
            default:
                break;
        }
    }

    const toggleModal = (name) => {
        switch (name) {
            case 'modalAddFiles':
                setModalAddFiles(!modalAddFiles);
                break;
            case 'modalAddExpense':
                setModalAddExpense(!modalAddExpense);
                break;
            case 'modalAddServiceWorkOrderDetail':
                setModalAddServiceWorkOrderDetail(!modalAddServiceWorkOrderDetail);
                break;
            case 'modalWorkOrderPdf':
                setModalWorkOrderPdf(!modalWorkOrderPdf);
                break;
            default:
                break;
        }
    }

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (
        <Loader isLoading={serviceWorkOrder.isFetching || pettyCash.isFetching || serviceWorkOrderId != serviceWorkOrder?.selectedServiceWorkOrder?.id}>
            <div>
                {serviceWorkOrder.selectedServiceWorkOrder && serviceWorkOrderId === serviceWorkOrder?.selectedServiceWorkOrder?.id &&
                    <div>
                        <ol className="breadcrumb float-xl-end" hidden>
                            <li className="breadcrumb-item"><Link to="/servicetask/dashboard">{t('menu.tasks_dashboard')}</Link></li>
                            <li hidden className="breadcrumb-item"><Link to="/serviceworkorder/list">{t("common.work_orders")}</Link></li>
                            <li className="breadcrumb-item active">{serviceWorkOrder.selectedServiceWorkOrder.description}</li>
                        </ol>
                        <h1 className="page-header">{t("common.work_order")} - {serviceWorkOrder.selectedServiceWorkOrder.description}</h1>
                        <div className="row">

                            <div className="col-xl-7">
                                <div className="col-xl-12">
                                    <Panel>
                                        <PanelHeader noButton={true}>
                                            <div className="d-flex justify-content-between">
                                                {t("common.general_data")}
                                                <div>
                                                    <button
                                                        className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-2"
                                                        onClick={() => toggleModal('modalAddFiles')}>
                                                        {t("common.add_files")}
                                                    </button>
                                                </div>
                                            </div>
                                        </PanelHeader>
                                        <PanelBody>
                                            <div className="bg-gray-300 text-gray-800 p-15px">
                                                <p className='text-gray'><b>{t("common.work_order").toUpperCase()}</b></p>
                                                <p>Asignada a: {serviceWorkOrder.selectedServiceWorkOrder.operatorFullName}</p>
                                                <p>{t("common.started_on")}: {moment(serviceWorkOrder.selectedServiceWorkOrder.startedOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm")}</p>
                                                {serviceWorkOrder.selectedServiceWorkOrder.finishedOn &&
                                                    <p>{t("common.finished_on")}: {moment(serviceWorkOrder.selectedServiceWorkOrder.finishedOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm")}</p>
                                                }
                                                <p>Estado de la Orden de trabajo: <span className="badge bg-gray ms-5px">{t("workOrder.state_" + serviceWorkOrder.selectedServiceWorkOrder.stateNormalizedName)}</span></p>
                                                <p>{t("common.created_by")}: {serviceWorkOrder.selectedServiceWorkOrder.createdBy}</p>
                                                {serviceWorkOrder.selectedServiceWorkOrder.detail &&
                                                    <div className="bg-white-100 p-15px">
                                                        <p><b>{t("common.actions_taken").toUpperCase()}</b></p>
                                                        {renderLinesBreak(serviceWorkOrder.selectedServiceWorkOrder.detail)}
                                                    </div>
                                                }
                                                {serviceWorkOrder.selectedServiceWorkOrder.movements && serviceWorkOrder.selectedServiceWorkOrder.movements.length > 0 &&
                                                    <div className="bg-white-100 mt-10px p-15px">
                                                        <p><b>{t("common.expenses").toUpperCase()}</b></p>
                                                        <table className="table table-hover text-gray-800">
                                                            <tbody>
                                                                {serviceWorkOrder.selectedServiceWorkOrder.movements.map((movement, index) =>
                                                                    // <div id={index}>{movement.description} - ${movement.amount}</div>
                                                                    <tr key={movement.id}>
                                                                        <td>{moment(movement.movementDate).format("DD/MM/YYYY")}</td>
                                                                        <td align='center'><span className="badge bg-default text-gray-800 me-5px">{movement.typeNormalizedName}</span></td>
                                                                        <td>{movement.description}</td>
                                                                        <td align='right'>{movement.amount} {movement.currencyCode}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </div>
                                            <div className="mt-10px">
                                                <p>{t("common.description")}: <b>{serviceWorkOrder.selectedServiceWorkOrder.description}</b>
                                                    <Link to={"/servicetask/detail?id=" + serviceWorkOrder.selectedServiceWorkOrder.serviceTaskId} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.task")}</Link></p>
                                                {serviceWorkOrder.selectedServiceWorkOrder.maintenanceTaskDetail &&
                                                    <>
                                                        <p><b>{t("common.detail")}:</b></p>
                                                        <p className='fs-20px'>{renderLinesBreak(serviceWorkOrder.selectedServiceWorkOrder.maintenanceTaskDetail)}</p>
                                                    </>
                                                }
                                                {/* <p>{t("common.type")}: <span className="badge bg-default text-gray-800 ms-5px">{t("task.type_" + serviceWorkOrder.selectedServiceWorkOrder.typeNormalizedName)}</span></p> */}
                                                <p>{t("common.priority")}: {t("common.priority_level_" + serviceWorkOrder.selectedServiceWorkOrder.priorityLevelNormalizedName)}</p>

                                                {serviceWorkOrder.selectedServiceWorkOrder.area &&
                                                    // <p>{t("common.area")}: {serviceWorkOrder.selectedServiceWorkOrder.area.name}</p>
                                                    <p>{t("common.area")}: {serviceWorkOrder.selectedServiceWorkOrder.area.name}&nbsp;<Link to={"/area/detail?id=" + serviceWorkOrder.selectedServiceWorkOrder.area.id + "&serviceworkorderid=" + serviceWorkOrder.selectedServiceWorkOrder.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.area")}</Link></p>

                                                }
                                                {serviceWorkOrder.selectedServiceWorkOrder.asset &&
                                                    <p>{t("common.asset")}: {serviceWorkOrder.selectedServiceWorkOrder.asset.name}&nbsp;<Link to={"/asset/detail?id=" + serviceWorkOrder.selectedServiceWorkOrder.asset.id + "&serviceworkorderid=" + serviceWorkOrder.selectedServiceWorkOrder.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.asset")}</Link></p>
                                                }
                                                {serviceWorkOrder.selectedServiceWorkOrder.functionalUnit &&
                                                    <p>{t("common.functional_unit")}: {serviceWorkOrder.selectedServiceWorkOrder.functionalUnit.number}&nbsp;<Link to={"/functionalunit/detail?id=" + serviceWorkOrder.selectedServiceWorkOrder.functionalUnit.id + "&serviceworkorderid=" + serviceWorkOrder.selectedServiceWorkOrder.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.functional_unit")}</Link></p>
                                                }
                                                {serviceWorkOrder.selectedServiceWorkOrder.room &&
                                                    <p>{t("common.room")}: {serviceWorkOrder.selectedServiceWorkOrder.room.name}&nbsp;<Link to={"/room/detail?id=" + serviceWorkOrder.selectedServiceWorkOrder.room.id + "&serviceworkorderid=" + serviceWorkOrder.selectedServiceWorkOrder.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.room")}</Link></p>
                                                }
                                                {serviceWorkOrder.selectedServiceWorkOrder.supplier &&
                                                    <p>{t("common.supplier")}: {serviceWorkOrder.selectedServiceWorkOrder.supplier.name}&nbsp;<Link to={"/supplier/detail?id=" + serviceWorkOrder.selectedServiceWorkOrder.supplier.id + "&serviceworkorderid=" + serviceWorkOrder.selectedServiceWorkOrder.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.supplier")}</Link></p>
                                                }
                                            </div>
                                        </PanelBody>
                                        <PanelFooter>
                                            <div className="d-flex align-items-center">
                                                <div className="ms-auto">
                                                    {(serviceWorkOrder.selectedServiceWorkOrder.workOrderStateId === WorkOrderStateStartedId) &&
                                                        <>
                                                            <button className="btn btn-green btn-sm btn-rounded px-3 rounded-pill m-3px" onClick={() => toggleModal("modalAddServiceWorkOrderDetail")}><i className="fa fa-plus me-1"></i> {t("common.actions_taken")}</button>
                                                            {/* <button className="btn btn-green btn-sm btn-rounded px-3 rounded-pill m-3px" onClick={() => toggleModal('modalAddExpense')}><i className="fa fa-plus me-1"></i> {t("common.add")} {t("common.expenses")}</button> */}
                                                        </>
                                                    }
                                                    {serviceWorkOrder.selectedServiceWorkOrder.workOrderStateId === WorkOrderStateStartedId &&
                                                        <button className="btn btn-primary m-3px" onClick={() => toggleSweetAlert("sweetAlertFinish")}>{t("common.finish")}</button>
                                                    }
                                                    {/* <button className="btn btn-danger m-3px" onClick={() => toggleModal('modalServiceWorkOrderPdf')}>Exportar a PDF</button> */}
                                                    <button className="btn btn-white m-3px" onClick={() => toggleModal('modalWorkOrderPdf')}><span className='d-none d-lg-block'>Exportar a <i className="fa fa-file-pdf fa-fw fa-2x" style={{ color: 'red', fontSize: 18 }} /></span><i className="d-block d-lg-none fa fa-file-pdf fa-fw fa-2x" style={{ color: 'red', fontSize: 18 }} /></button>
                                                </div>
                                            </div>
                                        </PanelFooter>
                                    </Panel>
                                </div>
                            </div>
                            <div className="col-xl-3">
                                {/* <div className="col-xl-12">
                                <Panel>
                                    <PanelHeader noButton={true}>
                                        <div className="d-flex align-items-center">
                                            In charge of
                                        </div>
                                    </PanelHeader>
                                    <PanelBody>
                                        <p>{workOrder.selectedWorkOrder.operatorFullName}</p>
                                    </PanelBody>
                                </Panel>
                            </div> */}
                                {serviceWorkOrder.selectedServiceWorkOrder.logTracks && serviceWorkOrder.selectedServiceWorkOrder.logTracks.length > 0 &&
                                    <div className="col-xl-12">
                                        <Panel>
                                            <PanelHeader noButton={true}>
                                                <div className="d-flex align-items-center">
                                                    Historial
                                                </div>
                                            </PanelHeader>
                                            <PanelBody>
                                                {serviceWorkOrder.selectedServiceWorkOrder.logTracks.map((log, index) =>
                                                    <div key={index}>
                                                        <p><i className="fa fa-circle-info"></i>&nbsp;{moment(log.createdOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm:ss")}&nbsp;-&nbsp;{log.createdByFullName}</p>
                                                        <p>{log.body}</p>
                                                    </div>
                                                )}
                                            </PanelBody>
                                        </Panel>
                                    </div>
                                }
                            </div>
                        </div>

                        <Upload onSubmit={onUploadSubmit} isOpen={modalAddFiles} toggleModal={() => toggleModal('modalAddFiles')}  ></Upload>

                        <Viewer files={serviceWorkOrder.selectedServiceWorkOrder.files} />

                        {sweetAlertFinish &&
                            <SweetAlert showCancel
                                confirmBtnText="Continuar"
                                confirmBtnBsStyle="primary"
                                cancelBtnText="Cancelar"
                                cancelBtnBsStyle="default"
                                title="¿Está seguro?"
                                onConfirm={() => finishServiceWorkOrder(serviceWorkOrder.selectedServiceWorkOrder)}
                                onCancel={() => toggleSweetAlert("sweetAlertFinish")}
                            >
                                Esta acción no puede ser revertida!
                            </SweetAlert>
                        }

                        <Modal size="md" style={{ maxWidth: '400px', width: '100%' }} isOpen={modalAddExpense} toggle={() => toggleModal('modalAddExpense')} >
                            <ModalHeader toggle={() => toggleModal('modalAddExpense')}>Agregar Gasto</ModalHeader>
                            <ModalBody>
                                <form onSubmit={handleSubmit(addMovement)}>

                                    <input type="hidden" id="serviceWorkOrderId" {...register("serviceWorkOrderId", {
                                        value: serviceWorkOrder.selectedServiceWorkOrder.id
                                    })} />

                                    <input type="hidden" id="pettyCashId" {...register("pettyCashId", {
                                        value: user.selectedTenant.pettyCashId[0]
                                    })} />

                                    <input type="hidden" id="typeId" {...register("typeId", {
                                        value: "871248AF-4047-40DE-BF3A-0565262CD584" //ExpenseType
                                    })} />

                                    <div className="form-floating mb-15px">
                                        <input type="text" className="form-control fs-15px" id="description" {...register("description", {
                                            required: true,
                                        })} />
                                        <label htmlFor="floatingInput" className=" fs-13px">
                                            Description
                                        </label>
                                        {errors.description && <span className='invalid-feedback'>This field is required</span>}
                                    </div>

                                    <div className="form-floating mb-15px">
                                        <DatePicker className="form-control fs-15px pt-25px" dateFormat="dd-MM-yyyy" selected={movementDate} onChange={(date) => setMovementDate(date)} />
                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-11px pt-1px mb-10px pb-30px">
                                            Fecha
                                        </label>
                                    </div>

                                    <div className="form-floating mb-15px">
                                        <input type="text" pattern="[+-]?\d+(?:[.]\d+)?" className="form-control fs-15px" id="amount" {...register("amount", {
                                            required: true,
                                        })} />
                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                            Monto
                                        </label>
                                    </div>

                                    <div className="mb-20px">
                                        <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value="Agregar" />
                                        {pettyCash.error && <div className='text-center'>
                                            <span className='text-danger'>{t("common.error", { message: pettyCash.errorMessage })}</span></div>}
                                    </div>
                                </form>
                            </ModalBody>
                            <ModalFooter>
                                <button className="btn btn-white" onClick={() => toggleModal('modalAddExpense')}>Cerrar</button>
                            </ModalFooter>
                        </Modal>

                        <Modal size="md" style={{ maxWidth: '400px', width: '100%' }} isOpen={modalAddServiceWorkOrderDetail} toggle={() => toggleModal('modalAddServiceWorkOrderDetail')} >
                            <ModalHeader toggle={() => toggleModal('modalAddServiceWorkOrderDetail')}>Agregar Trabajo Realizado</ModalHeader>
                            <ModalBody>
                                <form onSubmit={handleSubmitDetail(addServiceWorkOrderDetail)}>

                                    <input type="hidden" id="serviceWorkOrderId" {...registerDetail("serviceWorkOrderId", {
                                        value: serviceWorkOrder.selectedServiceWorkOrder.id
                                    })} />

                                    <div className="form-floating mb-15px">
                                        <textarea rows="5" maxLength={2000} className="form-control fs-15px h-100"
                                            id="serviceWorkOrderDetail" {...registerDetail("serviceWorkOrderDetail", {
                                                required: true,
                                                value: serviceWorkOrder.selectedServiceWorkOrder.detail
                                            })} />
                                        <label htmlFor="floatingInput" className=" fs-13px">
                                            {t('common.detail')}
                                        </label>
                                    </div>
                                    {errorsDetail.serviceWorkOrderDetail && <span className='invalid-feedback'>This field is required</span>}

                                    <div className="mb-20px">
                                        <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" id="SubmitDetail" value="Agregar" />
                                        {serviceWorkOrder.error && <div className='text-center'>
                                            <span className='text-danger'>{t("common.error", { message: serviceWorkOrder.errorMessage })}</span></div>}
                                    </div>
                                </form>
                            </ModalBody>
                            <ModalFooter>
                                <button className="btn btn-white" onClick={() => toggleModal('modalAddServiceWorkOrderDetail')}>Cerrar</button>
                            </ModalFooter>
                        </Modal>

                        <Modal size="lg" style={{ maxWidth: '80vw', width: '100%' }} isOpen={modalWorkOrderPdf} toggle={() => toggleModal('modalWorkOrderPdf')} >
                            <ModalHeader toggle={() => toggleModal('modalWorkOrderPdf')}>Orden de trabajo</ModalHeader>
                            <ModalBody>
                                <WorkOrderPdf workOrder={serviceWorkOrder.selectedServiceWorkOrder} fileName={"WorkOrder_" + serviceWorkOrder.selectedServiceWorkOrder.id + ".pdf"} />
                            </ModalBody>
                            <ModalFooter>
                                <button className="btn btn-white" onClick={() => toggleModal('modalWorkOrderPdf')}>Cerrar</button>
                            </ModalFooter>
                        </Modal>

                    </div>
                }
            </div >
        </Loader>
    )
};

export default ServiceWorkOrderDetail;
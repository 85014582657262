import React, { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';
import { PostResetPassword } from '../../services/redux/userSlice';
import { Loader } from '../widget/loader';

export default function RegisterV3() {
	const dispatch = useDispatch();
	const location = useLocation();
	const context = useContext(AppSettings);
	const { t } = useTranslation('common');
	const query = new URLSearchParams(location.search);

	const user = useSelector(state => state.userReducer);
	const [showErrorValidatePassword, setShowErrorValidatePassword] = useState(false);
	const [showMsgThanks, setShowsgThanks] = useState(false);

	const { register, handleSubmit, formState: { errors } } = useForm();
	const onSubmit = async (data) => {
		// console.log(data);
		if (data.password === data.passwordValidate) {
			setShowErrorValidatePassword(false);
			await dispatch(PostResetPassword(data)).then((response) => {
				// console.log(response);
				response && response.payload.success &&
					setShowsgThanks(true);
			});
		} else {
			setShowErrorValidatePassword(true);
		}

	};

	useEffect(() => {
		context.handleSetAppSidebarNone(true);
		context.handleSetAppHeaderNone(true);
		context.handleSetAppContentClass('p-0');
	}, []);

	return (
		<Loader isLoading={user.isFetching}>
			<div className="register register-with-news-feed">
				<div className="news-feed">
					<div className="news-image" style={{ backgroundImage: 'url(/assets/img/login-bg/login-bg-15.jpg)' }}></div>
					<div className="news-caption">
						<h4 className="caption-title"><b>Wartun</b></h4>
						<p>
							Transformando la forma en que administramos nuestras comunidades	</p>
					</div>
				</div>
				{!showMsgThanks ?
					<div className="register-container">
						<div className="register-header mb-25px h1">
							<div className="mb-1">Confirmar Contraseña</div>
							<small className="d-block fs-15px lh-16">Ingrese su nueva contraseña</small>
						</div>
						<div className="register-content">
							<form onSubmit={handleSubmit(onSubmit)} className="fs-13px">

								<div className="mb-4">
									<label className="mb-2">{t('login.password')} <span className="text-danger">*</span></label>
									<input type="password" className={"form-control fs-13px" + (errors.password ? ' is-invalid' : '')} id="password" {...register("password", {
										required: true,
									})} placeholder={t('login.password')} />
								</div>

								<div className="mb-4">
									<label className="mb-2">Ingrese su contraseña nuevamente <span className="text-danger">*</span></label>
									<input type="password" className={"form-control fs-13px" + (errors.password ? ' is-invalid' : '')} id="passwordValidate" {...register("passwordValidate", {
										required: true,
									})} placeholder="Ingrese su contraseña nuevamente" />
									{showErrorValidatePassword &&
										<p className="mt-5px text-danger bold-100">Las claves ingresadas deben coincidir</p>
									}
								</div>

								<input type="hidden" id="id" {...register("id", {
									value: query.get("id")
								})} readOnly={true} />

								<input type="hidden" id="token" {...register("token", {
									value: query.get("c")
								})} readOnly={true} />

								<input type="hidden" id="email" {...register("email", {
									value: query.get("email")
								})} readOnly={true} />

								<div className="form-check mb-4">
									<input className="form-check-input" type="checkbox" value="" id="agreementCheckbox" />
									<label className="form-check-label" htmlFor="agreementCheckbox">
										By clicking Sign Up, you agree to our <Link to="/user/register">Terms</Link> and that you have read our <Link to="/user/register">Data Policy</Link>, including our <Link to="/user/register">Cookie Use</Link>.
									</label>
								</div>
								<div className="mb-4">
									<button type="submit" className="btn btn-primary d-block w-100 btn-lg h-45px fs-13px">{t('common.confirm')}</button>
									<p className="mt-5px text-danger bold-100">{user.errorMessage}</p>
								</div>
								<div className="mb-4 pb-5">
									Already a member? Click <Link to="/user/login">here</Link> to login.
								</div>
								<hr className="bg-gray-600 opacity-2" />
								<p className="text-center text-gray-600">
									&copy; Wartun All Right Reserved 2023
								</p>
							</form>

						</div>
					</div>
					:
					<div className="register-container">
						<div className="register-header mb-25px h1">
							<div className="mb-1">Listo!</div>
							<small className="d-block fs-15px lh-16">Ya puede iniciar sesión con su nueva clave</small>
						</div>

						<div className="register-content">
							<div className="mb-4">
								<Link type='button' to="/user/login" className="btn btn-primary d-block w-100 btn-lg fs-13px">Iniciar Sesión</Link>
							</div>
						</div>
					</div>
				}
			</div>
		</Loader>
	)
}
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { PURGE } from "redux-persist";

const initialState = {
    serviceTasks: null,
    dataCount: null,
    dataCountFiltered: null,
    selectedServiceTask: null,
    lastSync: null,
    isFetching: false,
    error: false,
    errorMessage: "",
    status: ""
}

export const GetServiceTask = createAsyncThunk('serviceTask/fetchGetServiceTask', async ({ authToken, params }) => {
    let response = await api.getServiceTask(authToken, params);
    return response;
});

export const ListServiceTasks = createAsyncThunk('serviceTask/fetchListServiceTasks', async ({authToken, body}) => {
    let response = await api.listServiceTasks(authToken, body);
    return response;
});

export const CreateServiceTask = createAsyncThunk('serviceTask/fetchCreateServiceTask', async ({ authToken, body }) => {
    let response = await api.createServiceTask(authToken, body);
    return response;
});

export const EditServiceTask = createAsyncThunk('serviceTask/fetchEditServiceTask', async ({ authToken, body }) => {
    //console.log(body);
    let response = await api.editServiceTask(authToken, body);
    return response;
});

export const DeleteServiceTask = createAsyncThunk('tasks/fetchDeleteTask', async ({ authToken, body }) => {
    let response = await api.deleteServiceTask(authToken, body);
    return response;
});

const serviceTaskSlice = createSlice({
    name: "serviceTask",
    initialState,
    reducers: {

    },
    extraReducers: {
        [ListServiceTasks.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [ListServiceTasks.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.serviceTasks = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [ListServiceTasks.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [GetServiceTask.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [GetServiceTask.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedServiceTask = payload.data;
        },
        [GetServiceTask.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [CreateServiceTask.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [CreateServiceTask.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
        },
        [CreateServiceTask.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [EditServiceTask.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [EditServiceTask.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
        },
        [EditServiceTask.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [DeleteServiceTask.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [DeleteServiceTask.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedServiceTask = null;
            state.fullList = null;
        },
        [DeleteServiceTask.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [PURGE]: (state) => {
            return initialState;
        },
    }
})

export default serviceTaskSlice.reducer; 
import React from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import i18next from "i18next";

class DropdownLanguage extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  render() {
    return (
      <Dropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        className="navbar-item navbar-language dropdown"
        tag="div"
      >
        {i18next.language === "en" ? (
          <DropdownToggle className="navbar-link dropdown-toggle" tag="a" style={{ cursor: "pointer" }}>
            <span className="flag-icon flag-icon-us me-5px" title="us"></span>
            <span className="name d-none d-sm-inline">EN</span>
            <b className="caret"></b>
          </DropdownToggle>
        ) : i18next.language === "es" ? (
          <DropdownToggle className="navbar-link dropdown-toggle" tag="a" style={{ cursor: "pointer" }}>
            <span className="flag-icon flag-icon-es me-5px" title="es"></span>
            <span className="name d-none d-sm-inline">ES</span>
            <b className="caret"></b>
          </DropdownToggle>
        ) : i18next.language === "pt" ? (
          <DropdownToggle className="navbar-link dropdown-toggle" tag="a" style={{ cursor: "pointer" }}>
            <span className="flag-icon flag-icon-pt me-5px" title="pt"></span>
            <span className="name d-none d-sm-inline">PT</span>
            <b className="caret"></b>
          </DropdownToggle>
        ) : i18next.language === "it" ? (
          <DropdownToggle className="navbar-link dropdown-toggle" tag="a" style={{ cursor: "pointer" }}>
            <span className="flag-icon flag-icon-it me-5px" title="es"></span>
            <span className="name d-none d-sm-inline">IT</span>
            <b className="caret"></b>
          </DropdownToggle>
        ) : i18next.language === "fr" ? (
          <DropdownToggle className="navbar-link dropdown-toggle" tag="a" style={{ cursor: "pointer" }}>
            <span className="flag-icon flag-icon-fr me-5px" title="fr"></span>
            <span className="name d-none d-sm-inline">FR</span>
            <b className="caret"></b>
          </DropdownToggle>
        ) : (
          <DropdownToggle className="navbar-link dropdown-toggle" tag="a" style={{ cursor: "pointer" }}>
            <span className="flag-icon flag-icon-es me-5px" title="es"></span>
            <span className="name d-none d-sm-inline">ES</span>
            <b className="caret"></b>
          </DropdownToggle>
        )}

        <DropdownMenu className="dropdown-menu dropdown-menu-end" tag="div">
          <DropdownItem onClick={() => i18next.changeLanguage("en")}>
            <span className="flag-icon flag-icon-us me-5px" title="us"></span>{" "}
            English
          </DropdownItem>
          <DropdownItem onClick={() => i18next.changeLanguage("es")}>
            <span className="flag-icon flag-icon-es me-5px" title="es"></span>{" "}
            Español
          </DropdownItem>
          <DropdownItem onClick={() => i18next.changeLanguage("pt")}>
            <span className="flag-icon flag-icon-pt me-5px" title="pt"></span>{" "}
            Portugues
          </DropdownItem>
          <DropdownItem onClick={() => i18next.changeLanguage("it")}>
            <span className="flag-icon flag-icon-it me-5px" title="it"></span>{" "}
            Italiano
          </DropdownItem>
          <DropdownItem onClick={() => i18next.changeLanguage("fr")}>
            <span className="flag-icon flag-icon-fr me-5px" title="fr"></span>{" "}
            Frances
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default DropdownLanguage;

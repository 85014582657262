import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from "reactstrap";
import { ChangeTenant } from "../../../services/redux/userSlice";
import { createImageFromInitials } from "../../utils/ImageFromInitials";
import { useTranslation } from "react-i18next";
import { Navigation } from "react-calendar";

const DropdownProfile = () => {
  const history = useHistory();
  const { t } = useTranslation('common');

  const user = useSelector((state) => state.userReducer);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();

  const toggle = (prevState) => {
    setDropdownOpen(!prevState);
  };

  const changeTenant = async (tenantId) => {
    await dispatch(ChangeTenant(tenantId)).then((response) => {
      if (!response.error)
        history.replace("/dashboard");
    });
  };

  if (user.selectedTenant != null)
    return (
      <Dropdown
        isOpen={dropdownOpen}
        toggle={() => toggle(dropdownOpen)}
        className="navbar-item navbar-user dropdown"
        tag="div"
      >
        <DropdownToggle
          tag="a"
          className="navbar-link dropdown-toggle d-flex align-items-center"
          style={{ cursor: "pointer" }}
        >
          {/* <img src="../assets/img/user/user-12.jpg" alt="" /> */}
          <img
            id='tenantImage'
            src={
              (user.selectedTenant.imgSrc && user.selectedTenant.imgSrc.length > 0)
                ? user.selectedTenant.imgSrc
                : createImageFromInitials(200, user.selectedTenant.name, "#5500ff") //getRandomColor()
            }
            alt={user.selectedTenant.name}
          />
          <span>
            <span className="d-none d-md-inline">
              {user.selectedTenant.name}
            </span>
            <b className="caret"></b>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-end" end tag="div">
          <div className={user.tenants.length > 12 ? "overflow-auto h-300px" : "false"} >
            {user.tenants.map((tenant, index) => (
              <DropdownItem
                key={tenant.id}
                value={tenant.id}
                onClick={() => changeTenant(tenant.id)}
              >
                {tenant.name}
              </DropdownItem>
            ))}
          </div>
          <div className="dropdown-divider"></div>
          <div className="ms-10px me-15px mt-15px mb-15px">{user.selectedTenant.fullName}<br />{user.selectedTenant.userName}</div>
          <div className="dropdown-divider"></div>
          {/*<DropdownItem onClick={() => logout()}>Log Out</DropdownItem> */}
          {/*NO SE PORQUE NO ME FUNCIONA EL REDIRECT AC�*/}
          {/*<DropdownItem><Link to="/user/logout" className="btn btn-primary btn-lg">Log Out EME</Link></DropdownItem>*/}
          <DropdownItem onClick={() => history.replace("/user/logout")}>{t("common.logout")}</DropdownItem>
          {/* href="/user/logout" */}
        </DropdownMenu>
      </Dropdown>
    );
  else return <div></div>;
};

export default DropdownProfile;

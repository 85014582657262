import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { PURGE } from "redux-persist";
import { ChangeTenant } from "../redux/userSlice";

const initialState = {
    list: null,
    fullList: null,
    dataCount: null,
    dataCountFiltered: null,
    selectedArea: null,
    lastSync: null,
    isFetching: false,
    error: false,
    errorMessage: "",
    status: "",
}

export const GetArea = createAsyncThunk('area/fetchGetArea', async ({ authToken, params }) => {
    let response = await api.getArea(authToken, params);
    return response;
});

export const ListAreas = createAsyncThunk('area/fetchListAreas', async ({ authToken, body }) => {
    let response = await api.listAreas(authToken, body);
    return response;
});

export const FullListAreas = createAsyncThunk('area/fetchFullListAreas', async ({ authToken, tenantId }) => {
    let body = {
        "tenantId": tenantId,
        "sortBy": null,
        "sortDir": true,
        "skip": 0,
        "take": null
    };
    let response = await api.listAreas(authToken, body);
    return response;
});

export const CreateArea = createAsyncThunk('area/fetchCreateArea', async ({ authToken, body }) => {
    let response = await api.createArea(authToken, body);
    return response;
});

export const EditArea = createAsyncThunk('area/fetchEditArea', async ({ authToken, body }) => {
    let response = await api.editArea(authToken, body);
    return response;
});

export const DeleteArea = createAsyncThunk('areas/fetchDeleteArea', async ({ authToken, body }) => {
    let response = await api.deleteArea(authToken, body);
    return response;
});

const areaSlice = createSlice({
    name: "area",
    initialState,
    reducers: {

    },
    extraReducers: {

        [GetArea.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [GetArea.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedArea = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [GetArea.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [ListAreas.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [ListAreas.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.list = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [ListAreas.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [FullListAreas.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [FullListAreas.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.fullList = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [FullListAreas.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [CreateArea.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [CreateArea.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedArea = payload.data;
            state.fullList = null;
        },
        [CreateArea.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },
        [EditArea.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },

        [EditArea.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedArea = payload.data;
            state.fullList = null;
        },
        [EditArea.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [DeleteArea.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [DeleteArea.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedArea = null;
            state.fullList = null;
        },
        [DeleteArea.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [ChangeTenant.fulfilled]: (state, { payload }) => {
            return initialState;
            // state.isFetching = false;
            // state.error = false;
            // state.errorMessage = '';
            // state.fullList = null;
            // state.dataCount = null;
            // state.dataCountFiltered = null;
        },

        [PURGE]: (state) => {
            return initialState;
        },
    }
})

export default areaSlice.reducer; 
import React, { useContext, useState, useEffect } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { AppSettings } from '../../config/app-settings.js';
import { useDispatch, useSelector } from 'react-redux';
import { GetInvitation, PostConfirmInvitation } from '../../services/redux/userSlice';
import { Loader } from '../widget/loader';
import { useTranslation } from "react-i18next";

export default function Register() {
	const dispatch = useDispatch();
	const location = useLocation();
	const context = useContext(AppSettings);
	const { t } = useTranslation('common');
	const query = new URLSearchParams(location.search);

	const user = useSelector(state => state.userReducer);
	const [showInvitation, setShowInvitation] = useState(false);
	const [showMsgThanks, setShowsgThanks] = useState(false);

	const { register, handleSubmit, formState: { errors } } = useForm();
	const onSubmit = async (data) => {
		//console.log("data.password")
		//console.log(data.password)
		if (!user?.invitationRegister?.showPassword || checkPassword(data.password)) {
			//console.log(data);
			await dispatch(PostConfirmInvitation(data)).then((response) => {
				//console.log(response);
				if (response?.payload?.success)
					setShowsgThanks(true);
				else
					setShowsgThanks(false);
			});
		}
	};

	useEffect(() => {
		context.handleSetAppSidebarNone(true);
		context.handleSetAppHeaderNone(true);
		context.handleSetAppContentClass('p-0');
		getInvitation();
	}, []);

	const getInvitation = async () => {
		const requestData = {
			"tenantId": query.get("t"),
			"id": query.get("id")
		};
		await dispatch(GetInvitation({ params: requestData })).then((response) => {
			//console.log(response);
			setShowInvitation(true);
		});
	};

	const [passwordValid, setPasswordValid] = useState(false);
	const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{6,}$/;
	const checkPassword = (password) => {
		if (regex.test(password)) {
			//console.log("La contraseña cumple con los requisitos" + password);
			setPasswordValid(true);
			return true;
		} else {
			//console.log("La contraseña no cumple con los requisitos" + password);
			setPasswordValid(false);
			return false;
		}
	}

	return (
		<Loader isLoading={!showInvitation || user.isFetching}>
			{showInvitation &&
				<div className="register register-with-news-feed">
					<div className="news-feed">
						<div className="news-image" style={{ backgroundImage: 'url(/assets/img/login-bg/login-bg-15.jpg)' }}></div>
						<div className="news-caption">
							<h4 className="caption-title"><b>Wartun</b></h4>
							<p>
								Transformando la forma en que administramos nuestras comunidades	</p>
						</div>
					</div>
					{user?.invitationRegister ?
						<div className="register-container">
							<div className="register-header mb-25px h1">
								<div className="mb-1">Confirmar Invitación</div>
								<small className="d-block fs-15px lh-16">Únete a WARTUN y descubre la facilidad de llevar el control de tu barrio privado en la palma de tu mano</small>
							</div>
							<div className="register-content">
								<form onSubmit={handleSubmit(onSubmit)} className="fs-13px">
									<div className="mb-3">
										<label className="mb-2">{t('common.names')} <span className="text-danger">*</span></label>
										<div className="row gx-3">
											<div className="col-md-6 mb-2 mb-md-0">
												<input type="text" className={"form-control fs-13px" + (errors.firstName ? ' is-invalid' : '')} id="firstName" {...register("firstName", {
													required: true,
													value: user?.invitationRegister?.agent?.firstName
												})} placeholder={t('common.name')} />
												{errors.firstName && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
											</div>
											<div className="col-md-6">
												<input type="text" className={"form-control fs-13px" + (errors.lastName ? ' is-invalid' : '')} id="lastName" {...register("lastName", {
													required: true,
													value: user?.invitationRegister?.agent?.lastName
												})} placeholder={t('common.last_name')} />
												{errors.lastName && <span className='invalid-feedback'>{t('validation.required_field')}</span>}

											</div>
										</div>
									</div>
									<div className="mb-3">
										<label className="mb-2">{t('screen_user_profile.email')} <span className="text-danger">*</span></label>
										<input type="text" className={"form-control fs-13px" + (errors.email ? ' is-invalid' : '')} id="email" {...register("email", {
											required: true,
											pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
											value: user?.invitationRegister?.agent?.aspNetUser?.email
										})} placeholder={t('screen_user_profile.email')} readOnly={true} />
										{errors.email?.type === 'required' && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
										{errors.email?.type === 'pattern' && <span className='invalid-feedback'>{t('validation.invalid_email')}</span>}
									</div>
									{user?.invitationRegister?.showPassword &&
										<div className="mb-4">
											<label className="mb-2">{t('login.password')} <span className="text-danger">*</span></label>
											<input type="password" className={"form-control fs-13px" + (errors.password ? ' is-invalid' : '')} id="password" {...register("password", {
												required: true,
											})} placeholder={t('login.password')}
												onChange={e => checkPassword(e.target.value)} />
											<input type="hidden" id="token" {...register("token", {
												value: query.get("c")
											})} readOnly={true} />
											<ul className="text-danger mt-10px" hidden={passwordValid}>
												<li>Debe contener al menos un carácter mayúscula.</li>
												<li>Debe contener al menos un carácter minúscula.</li>
												<li>Debe contener al menos un carácter numérico.</li>
												<li>Debe tener una longitud de 6 caracteres.</li>
											</ul>
										</div>

									}
									<input type="hidden" id="id" {...register("id", {
										value: user?.invitationRegister?.agent?.id
									})} readOnly={true} />
									<input type="hidden" id="tenantId" {...register("tenantId", {
										value: query.get("t")
									})} readOnly={true} />
									<div className="form-check mb-4">
										<input className="form-check-input" type="checkbox" value="" id="agreementCheckbox" />
										<label className="form-check-label" htmlFor="agreementCheckbox">
											By clicking Sign Up, you agree to our <Link to="/user/register">Terms</Link> and that you have read our <Link to="/user/register">Data Policy</Link>, including our <Link to="/user/register">Cookie Use</Link>.
										</label>
									</div>
									<div className="mb-4">
										<button type="submit" className="btn btn-primary d-block w-100 btn-lg h-45px fs-13px">{t('common.confirm_sign_up')}</button>
									</div>
									<div className="mb-4 pb-5">
										Already a member? Click <Link to="/user/login">here</Link> to login.
									</div>
									<hr className="bg-gray-600 opacity-2" />
									<p className="text-center text-gray-600">
										&copy; Wartun All Right Reserved 2023
									</p>
								</form>

							</div>
						</div>
						: showMsgThanks ?
							<div className="register-container">
								<div className="register-header mb-25px h1">
									<div className="mb-1">Su cuenta ha sido confirmada!</div>
								</div>
								<div className="register-content">
									<div className="mb-4">
										<Link type='button' to="/user/login" className="btn btn-primary d-block w-100 btn-lg fs-13px">Iniciar Sesión</Link>
									</div>
								</div>
							</div>
							: user.error ?
							<div className="register-container">
								<div className="register-header mb-25px h1">
									<div className="mb-1">Ups! Ocurrió un error</div>
								</div>
								<div className="register-content">
									<div className="mb-4">
										<div className="mb-1">
											{user.error && <div className='text-center'>
												<span className='text-danger'>Code: X0501 -  {user.errorMessage}</span></div>}
										</div>
									</div>
								</div>
							</div>
							:
							<div className="register-container">
								<div className="register-content">
									<div className="mb-4">
										<Link type='button' to="/user/login" className="btn btn-primary d-block w-100 btn-lg fs-13px">Iniciar Sesión</Link>
									</div>
								</div>
							</div>
					}
				</div>
			}
		</Loader>
	)
}
import React, { useContext, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import { Panel, PanelHeader, PanelFooter, PanelBody } from '.././../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../widget/loader';
import { CreateBuildingSite } from '../../../services/redux/buildingSiteSlice';
import { BuildingSiteStates } from '../../../utils/generalConstants';
import { useTranslation } from "react-i18next";

const BuildingSiteCreate = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const buildingSite = useSelector(state => state.buildingSiteReducer);

    const location = useLocation();
    const { functionalUnitId, functionalUnitNumber } = location.state

    const [startDate, setStartDate] = useState(null);
    const [finishDate, setFinishDate] = useState(null);
    const [estimatedFinishDate, setEstimatedFinishDate] = useState(null);

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
    }, []);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {

        let requestData = {
            "functionalUnitId": functionalUnitId,
            "owner": data.owner,
            "ownerContactInfo": data.ownerContactInfo,
            "startDate": startDate,
            "finishDate": finishDate,
            "estimatedFinishDate": estimatedFinishDate,
            "buildingSiteStateId": data.buildingSiteStateId,
            "geolocation": data.geolocation,
            "observations": data.observations,
            "createdById": user.selectedTenant.agentId,
            "tenantId": user.selectedTenant.id,
        }

        // console.log(requestData);

        await dispatch(CreateBuildingSite({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload && response.payload.data &&
                history.push("/buildingSite/detail?id=" + response.payload.data.id);
        });
    };

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (
        <Loader isLoading={buildingSite.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/buildingSite/list">Unidades Funcionales</Link></li>
                    <li className="breadcrumb-item active">Nueva Obra</li>
                </ol>
                <h1 className="page-header">Nueva Obra Lote #{functionalUnitNumber} <small>complete los campos obligatorios</small></h1>

                <div className="d-flex align-items-center mb-2">
                    <div className="ms-auto">
                        <Link to="/buildingSite/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-7">
                        <div className="col-xl-12">
                            <Panel>
                                <PanelHeader noButton={true}>
                                    <div className="d-flex align-items-center">
                                        {t("common.general_data")}
                                    </div>
                                </PanelHeader>
                                <PanelBody>
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        {/* <div className="form-floating mb-15px">
                                            <input type="text" className="form-control fs-15px" id="parcelNumber" {...register("parcelNumber", {
                                                required: true,
                                                value: functionalUnitNumber
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                # Lote
                                            </label>
                                        </div> */}

                                        <div className="form-floating mb-15px">
                                            <input type="text" className="form-control fs-15px" id="owner" {...register("owner", {
                                                required: true,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                Responsable
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <input type="text" className="form-control fs-15px" id="ownerContactInfo" {...register("ownerContactInfo", {
                                                required: false,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t("common.contact_info")}
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <DatePicker className="form-control fs-15px pt-25px" dateFormat="dd-MM-yyyy" selected={startDate} onChange={(date) => setStartDate(date)} required />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-11px pt-1px mb-10px pb-30px">
                                                Fecha de inicio{/* - SelectedDate {startDate.toString()}*/}
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <DatePicker className="form-control fs-15px pt-25px" dateFormat="dd-MM-yyyy" selected={estimatedFinishDate} onChange={(date) => setEstimatedFinishDate(date)} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-11px pt-1px mb-10px pb-30px">
                                                Fecha estimada de finalización
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <DatePicker className="form-control fs-15px pt-25px" dateFormat="dd-MM-yyyy" selected={finishDate} onChange={(date) => setFinishDate(date)} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-11px pt-1px mb-10px pb-30px">
                                                Fecha de finalización
                                            </label>
                                        </div>

                                        {BuildingSiteStates &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="buildingSiteStateId" {...register("buildingSiteStateId", {
                                                    required: true,
                                                })}>
                                                    <option></option>
                                                    {BuildingSiteStates.map(r => <option key={r.id} value={r.id}>{r.Name}</option>)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    {t("common.state")}
                                                </label>
                                            </div>
                                        }

                                        <div className="form-floating mb-15px">
                                            <input type="text" className="form-control fs-15px" id="geolocation" {...register("geolocation", {
                                                required: false,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t("common.geolocation")}
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <input type="text" className="form-control fs-15px" id="observations" {...register("observations", {
                                                required: false,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t("common.aditional_info")}
                                            </label>
                                        </div>

                                        <div className="mb-20px">
                                            <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value="Guardar" />
                                            {buildingSite.error && <div className='text-center'>
                                                <span className='text-danger'>Ha ocurrido un problema: {buildingSite.errorMessage}</span></div>}
                                        </div>

                                    </form>

                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                </div>
            </div>
        </Loader>
    )
};

export default BuildingSiteCreate;